import React, { useEffect, useState } from "react";
import { Card, Tag, TokenIcon } from "../components/utilityComps.js";
import WETH_MockToken from "../contracts/WETH_MockToken/WETH_MockTokenContract.js";
import { useMetaMaskContext } from "../wrappers/MetaMaskWrapper.js";
import RaceStake from "../contracts/RaceStake/RaceStakeContract.js";
import { ethers } from "ethers";
import { twMerge } from "tailwind-merge";
import { contractAddress_list } from "../contracts/constants.js";
import {
  mm_asset_signer,
  t3_asset_signer,
  t3_contract_call,
} from "../contracts/contract_funcs.js";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import { cdelay, dec, jstr, nils, toeth, tofeth } from "../utils/utils.js";
import { tokdecn } from "../App.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { useMemo } from "react";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { useThirdWebLoginContext } from "./ThirdWebLogin.js";

const TokenAllowanceCard = ({ title, token, raceconaddr }) => {
  const accon = useAccountContext();
  const { vault, auth } = accon;
  const [alw, set_alw] = useState(null);

  const { aumode } = useAuthContext();
  const { active_account } = useThirdWebLoginContext();

  const textcn = (token) =>
    token == "WETH"
      ? "text-pink-400"
      : token == "DEZ"
        ? "text-acc0"
        : "text-blue-300";
  const bgcn = (token) =>
    token == "WETH"
      ? "bg-pink-400/40"
      : token == "DEZ"
        ? "bg-acc0/40"
        : "bg-blue-300/40";
  const shadowcn = (token) =>
    token == "WETH"
      ? "shadow shadow-pink-400"
      : token == "DEZ"
        ? "shadow shadow-acc0"
        : "";

  const paytokenaddr = useMemo(() => {
    let v =
      contractAddress_list[
        token == "WETH"
          ? "weth"
          : token == "DEZ"
            ? "dez"
            : token == "BGC"
              ? "bgc"
              : null
      ];
    if (nils(v)) return "";
    v = v.toLowerCase();
    return v;
  }, [token]);

  const get_allowace = async () => {
    try {
      let pcon =
        aumode == "thirdweb"
          ? await t3_asset_signer(null, 20, paytokenaddr)
          : await mm_asset_signer(null, 20, paytokenaddr);
      let alw = await pcon.allowance(vault, raceconaddr);
      alw = tofeth(alw);
      return alw;
      // return 0;
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  const upd_alw = async () => {
    let a = await get_allowace();
    // console.log("upd_alw", a);
    set_alw(a);
  };

  useEffect(() => {
    if (!auth && nils(vault)) return;
    upd_alw();
  }, [jstr({ auth, vault }), aumode]);

  const add_alw = async () => {
    try {
      let tx = null;
      if (aumode == "thirdweb") {
        let pcon = await t3_asset_signer(null, 20, paytokenaddr);
        let bal = await pcon.balanceOf(vault);
        bal = dec(parseFloat(tofeth(bal)) * 0.95, tokdecn(token));
        tx = await t3_contract_call(
          paytokenaddr,
          "function approve(address spender, uint256 amount) external returns (bool)",
          [raceconaddr, toeth(bal)],
          "txn",
          true,
          { active_account },
        );
      } else {
        const paytokencon = await mm_asset_signer(null, 20, paytokenaddr);
        let bal = await paytokencon.balanceOf(vault);
        bal = dec(parseFloat(tofeth(bal)) * 0.95, tokdecn(token));
        tx = await paytokencon.approve(raceconaddr, toeth(bal));
        tx = await tx.wait();
      }
      upd_alw();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Card
      className={twMerge(
        "bg-r2lig/20 backdrop-blur-md w-full",
        shadowcn(token),
      )}
    >
      <div className="p text-center resp-text-1">{title}</div>
      <div className="fr-cc resp-gap-2">
        <TokenIcon token={token} size="md" />
        <p
          className={twMerge(
            "text-center resp-text-1 font-digi",
            textcn(token),
          )}
        >
          {token}
        </p>
      </div>

      <div className="fr-cc resp-gap-2 my-2">
        {nils(alw) ? (
          "--"
        ) : (
          <>
            <span>{dec(alw, tokdecn(token))}</span>
            <span>{"tokens"}</span>
          </>
        )}
        <Tag
          onClick={() => {
            upd_alw();
          }}
        >
          <FontAwesomeIcon icon={faSyncAlt} />
        </Tag>
      </div>
      <div className="fr-cc resp-gap-2 my-2">
        <Tag
          onClick={() => {
            add_alw();
          }}
          className={twMerge(bgcn(token), "resp-text--2")}
        >
          Add Allowance
        </Tag>
      </div>
    </Card>
  );
};

const ERC721TokenApproveAllCard = ({ title, token, tokenaddr, conaddr }) => {
  const authocn = useAuthContext();
  const { auth, aumode } = authocn;

  const accon = useAccountContext();
  const { vault } = accon;

  const t3con = useThirdWebLoginContext();
  const { active_account } = t3con;

  const [apv, set_apv] = useState(null);

  const textcn = (token) =>
    token == "WETH"
      ? "text-pink-400"
      : token == "DEZ"
        ? "text-acc0"
        : "text-blue-300";
  const bgcn = (token) =>
    token == "WETH"
      ? "bg-pink-400/40"
      : token == "DEZ"
        ? "bg-acc0/40"
        : "bg-blue-300/40";
  const shadowcn = (token) =>
    token == "WETH"
      ? "shadow shadow-pink-400"
      : token == "DEZ"
        ? "shadow shadow-acc0"
        : "";

  // const [tokcon, set_tokcon] = useState(null);
  // useEffect(() => {
  //   const fn = async () => {
  //     let con = await mm_asset_signer(null, 721, tokenaddr);
  //     set_tokcon(con);
  //   };
  //   fn();
  // }, [tokenaddr]);

  const get_tokcon = async (tokenaddr) => {
    let tokcon = null;
    if (aumode == "thirdweb")
      tokcon = await t3_asset_signer(null, 721, tokenaddr);
    else tokcon = await mm_asset_signer(null, 721, tokenaddr);
    return tokcon;
  };

  const upd_apv = async () => {
    let tokcon = await get_tokcon(tokenaddr);
    if (!tokcon) return;
    let a = await tokcon.isApprovedForAll(vault, conaddr);
    // console.log("upd_apv", a);
    set_apv(a);
  };

  useEffect(() => {
    if (!auth && nils(vault)) return;
    upd_apv();
  }, [jstr({ auth, vault }), tokenaddr]);

  const set_approval = async (setto) => {
    try {
      let approval = null;
      if (aumode == "thirdweb") {
        approval = await t3_contract_call(
          tokenaddr,
          "function setApprovalForAll(address operator, bool approved) public",
          [conaddr, setto],
          "txn",
          true,
          { active_account },
        );
      } else {
        let tokcon = await get_tokcon(tokenaddr);
        approval = await tokcon.setApprovalForAll(conaddr, setto);
        approval = await approval.wait();
      }
      // console.log("approval", approval);
      await cdelay(1000);
      upd_apv();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Card
      className={twMerge(
        "bg-r2lig/20 backdrop-blur-md w-full",
        shadowcn(token),
      )}
    >
      <div className="p text-center resp-text-1">{title}</div>
      <div className="fr-cc resp-gap-2">
        <TokenIcon token={token} size="md" />
        <p
          className={twMerge(
            "text-center resp-text-1 font-digi",
            textcn(token),
          )}
        >
          {token}
        </p>
      </div>

      <div className="fr-cc resp-gap-2 my-2">
        {nils(apv) ? (
          "--"
        ) : (
          <>
            <span>{apv ? "Approved" : "Not Approved"}</span>
          </>
        )}
        <Tag
          onClick={() => {
            upd_apv();
          }}
        >
          <FontAwesomeIcon icon={faSyncAlt} />
        </Tag>
      </div>
      <div className="fr-cc resp-gap-2 my-2">
        <Tag
          onClick={() => {
            set_approval(!apv);
          }}
          className={twMerge(bgcn(token), "resp-text--2")}
        >
          {apv === true
            ? "Revoke Approval"
            : apv === false
              ? "set Approval"
              : ""}
        </Tag>
      </div>
    </Card>
  );
};

function Allowance() {
  const aucon = useAuthContext();
  const { aumode, auth, vault } = aucon;
  const t3con = useThirdWebLoginContext();
  const { active_account } = t3con;

  const enabled = useMemo(() => {
    let addr = active_account?.address;
    if (!nils(addr)) addr = addr.toLowerCase();
    if (aumode == "thirdweb" && addr == vault && aucon.auth === true) {
      return true;
    } else {
      return !nils(vault) && aucon.auth === true;
    }
  }, [aumode, auth, active_account, vault]);

  return (
    <div className="h-page">
      <div className="max-w-[98vw] w-[60rem] mx-auto">
        <div className="h-[3rem]"></div>
        <p className="text-center text-acc0 resp-text-1">Allowance</p>
        <div className="h-[1rem]"></div>
        {enabled === true && (
          <>
            <div className="grid grid-cols-2 resp-gap-2 my-2">
              <TokenAllowanceCard
                title="RaceV01"
                token="WETH"
                raceconaddr={contractAddress_list.racestake}
              />
              <TokenAllowanceCard
                title="RaceV02"
                token="WETH"
                raceconaddr={contractAddress_list.racestakev02}
              />
              <TokenAllowanceCard
                title="RaceV03"
                token="DEZ"
                raceconaddr={contractAddress_list.racestakev03dez}
              />
              <TokenAllowanceCard
                title="RaceV04"
                token="BGC"
                raceconaddr={contractAddress_list.racestakev04bgc}
              />
              <ERC721TokenApproveAllCard
                title="RaceV04:BurnBike"
                tokenaddr={contractAddress_list.fbike}
                conaddr={contractAddress_list.racestakev04bgc}
              />
              {/*               <TokenAllowanceCard
                title="Splicing"
                token="WETH"
                raceconaddr={contractAddress_list.splicing}
              /> */}

              <TokenAllowanceCard
                title="SplicingV02"
                token="WETH"
                raceconaddr={contractAddress_list.splicingv02}
              />
              <TokenAllowanceCard
                title="SplicingV02"
                token="BGC"
                raceconaddr={contractAddress_list.splicingv02}
              />
              {/*               <TokenAllowanceCard
                title="SplicingV02"
                token="DEZ"
                raceconaddr={contractAddress_list.splicingv02}
              /> */}

              <TokenAllowanceCard
                title="AutoFiller-WETH"
                token="WETH"
                raceconaddr={contractAddress_list.raceautofiller}
              />
              <TokenAllowanceCard
                title="AutoFiller-DEZ"
                token="DEZ"
                raceconaddr={contractAddress_list.raceautofiller}
              />
              <TokenAllowanceCard
                title="Market-WETH"
                token="WETH"
                raceconaddr={contractAddress_list.market_weth}
              />
              <TokenAllowanceCard
                title="Market-DEZ"
                token="DEZ"
                raceconaddr={contractAddress_list.market_dez}
              />

              <TokenAllowanceCard
                title="CV2-PayRouter-WETH"
                token="WETH"
                raceconaddr={contractAddress_list.cv2_dnapayrouter}
              />

              <TokenAllowanceCard
                title="CV2-PayRouter-DEZ"
                token="DEZ"
                raceconaddr={contractAddress_list.cv2_dnapayrouter}
              />

              <TokenAllowanceCard
                title="CV2-PayRouter-BGC"
                token="BGC"
                raceconaddr={contractAddress_list.cv2_dnapayrouter}
              />

              <TokenAllowanceCard
                title="CV2-PayRouter-SGC"
                token="SGC"
                raceconaddr={contractAddress_list.cv2_dnapayrouter}
              />

              {/* <TokenAllowanceCard
                title="Splicing"
                token="BGC"
                raceconaddr={contractAddress_list.bikecredits}
              /> */}
            </div>
            <div className="resp-text--1">
              <p className="text-center text-yellow-300">
                If you've granted enough allowance for the token, there's no
                need to do it again. Adding more allowance unnecessarily can
                actually reset the existing permission.
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Allowance;
