import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useQueries } from "react-query";
import { useMatch, useNavigate, useParams } from "react-router";
import {
  iserr,
  q_bikeinfo,
  q_que_txn,
  q_splicing_v02_enter_pair_validate,
  q_splicing_v02_pair_info,
  q_splicing_splice_requests,
  q_splicing_v02_splicedoc,
  qiserr,
  qissuccesss,
  q_splicingv02_pending,
  q_splicingv02_delete_request,
} from "../queries/queries.js";
import {
  base64_to_json,
  cdelay,
  dec,
  from_time_mini,
  getv,
  json_to_base64,
  jstr,
  nils,
  toeth,
  tofeth,
  trim2,
  trim_n,
} from "../utils/utils.js";
import {
  Card,
  HeadC2L,
  Img,
  InpText,
  Tag,
  TokenIcon,
} from "../components/utilityComps.js";
import { logoimg } from "../components/Layout.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBirthdayCake,
  faBookmark,
  faBox,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faExclamationTriangle,
  faHashtag,
  faHatHard,
  faMars,
  faRefresh,
  faSpinner,
  faSquare,
  faTimesCircle,
  faVenus,
} from "@fortawesome/free-solid-svg-icons";
import { twMerge } from "tailwind-merge";
import _, { set } from "lodash";
import { faHashnode } from "@fortawesome/free-brands-svg-icons";
import { useVaultContext } from "./Vault.js";
import { PopUp, PopupCloseBtn } from "../components/popup.js";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import SpliceArena from "./SpliceArena.js";
import { tokdecn, useAppContext } from "../App.js";
import { Loader01c } from "../components/anims.js";
import { elementmap, tablecn } from "../utils/cn_map.js";
import WETH_MockToken from "../contracts/WETH_MockToken/WETH_MockTokenContract.js";
import { ethers } from "ethers";
import { ElementTag, GenderTag } from "../components/ShortComps.js";
import moment from "moment";
import { SetBikeName } from "../components/BikeCard";
import { contractAddress_list } from "../contracts/constants.js";
import {
  mm_asset_signer,
  t3_asset_signer,
  t3_contract_call,
} from "../contracts/contract_funcs.js";
import { useMetaMaskContext } from "../wrappers/MetaMaskWrapper.js";
import { BikeImg } from "../components/BikeImg.js";
import colors from "tailwindcss/colors.js";
import { Link } from "react-router-dom";
import { useThirdWebLoginContext } from "./ThirdWebLogin.js";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { polygon } from "thirdweb/chains";
import Splicing_V02 from "../contracts/Splicing_V02/Splicing_v02.js";
import { DNA_Splicing } from "../contracts/v2/DNA_Splicing.js";
import { PayEmbed } from "thirdweb/react";

const SpliceContext = createContext();
const useSpliceContext = () => useContext(SpliceContext);

const SplicingContract = DNA_Splicing;
const splicing_conaddr = SplicingContract.get_contract_address();
const approveto = contractAddress_list.cv2_dnapayrouter;

const service = "splicing_cv2";

const genderbase = (gender) =>
  gender == "male"
    ? ["bg-blue-400", "text-blue-400", faMars]
    : ["bg-pink-400", "text-pink-400", faVenus];

const VaultBikes = ({ gender, set_popup }) => {
  const scon = useSpliceContext();
  const { selbikes, set_selbikes } = scon;

  const vcon = useAccountContext();
  const { bikesob } = vcon;

  const [searchtxt, set_searchtxt] = useState(null);

  const filtbikes = useMemo(() => {
    if (nils(searchtxt))
      return _.chain(bikesob)
        .values()
        .filter((e) => e.gender == gender)
        .value();
    let sech_hid = parseInt(searchtxt);
    if (nils(sech_hid)) sech_hid = null;
    let sear = _.lowerCase(searchtxt);

    let filt = _.chain(bikesob)
      .values()
      .map((h) => {
        let hname = _.lowerCase(h.name);
        if (!nils(sech_hid) && sech_hid == h.hid) return [h, 1];
        else if (
          !nils(sech_hid) &&
          h.hid.toString().startsWith(sech_hid.toString())
        )
          return [h, 2];
        else if (hname.startsWith(sear)) return [h, 3];
        else if (hname.includes(sear)) return [h, 4];
        else return null;
      })
      .compact()
      .sortBy((e) => e[1])
      .filter((e) => e.gender == gender)
      .map(0)
      .value();
    return filt;
  }, [jstr(bikesob), searchtxt]);

  return (
    <>
      <InpText
        {...{
          id: "search-splice-bike",
          placeholder: "Search Core",
          inpprops: {
            className: "w-full  text-white",
          },
          contprops: {
            className: "w-full bg-r2dark/80 text-white",
          },
          setter: (v) => {
            console.log("setter", v);
            if (nils(v)) v = null;
            set_searchtxt(v);
          },
        }}
      />
      <div className="h-[40vh] overflow-auto">
        {_.values(filtbikes).map((h) => {
          let in_stud = getv(h, "splice_core.in_stud");
          return (
            <div
              onClick={() => {
                if (in_stud) {
                  set_selbikes((o) => ({ ...o, [gender]: h.hid }));
                  set_searchtxt("");
                  set_popup(false);
                }
              }}
              className={twMerge(
                "fc-ss w-full resp-gap-2 bg-r2lig/20 rounded-md p-2 my-2",
                in_stud ? "cursor-pointer" : "",
              )}
            >
              <div className="fr-ss w-full resp-gap-2 min-w-[20rem] mx-2">
                <span className="resp-text--3">{h.hid}</span>
                <span className="resp-text-0">{h.name}</span>
              </div>
              <div className="fr-sc w-full resp-gap-2 text-acc0">
                <span className="resp-text--1">{h.type}</span>
                <span className="resp-text--1">{`F${h.fno}`}</span>
                <div className="flex-1"></div>
                {in_stud == true ? (
                  <span className="resp-text--1 text-green-400">{`IN STUD`}</span>
                ) : (
                  <span className="resp-text--1 text-red-400">{`NOT IN STUD`}</span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const ArenaBikes = ({ gender, set_popup }) => {
  const scon = useSpliceContext();
  const { set_selbikes } = scon;

  return (
    <div className="w-[60vw] h-[50vh]">
      <SpliceArena
        {...{
          update_href: false,
          init_filt: {
            gender: [gender],
          },
          on_selection: (hid) => {
            set_selbikes((o) => ({ ...o, [gender]: hid }));
            set_popup(false);
          },
        }}
      />
    </div>
  );
};

const TemplateBike = ({ gender }) => {
  const [bg, text, icon] = genderbase(gender);
  const scon = useSpliceContext();
  const { selbikes, set_selbikes } = scon;
  const selbike = getv(selbikes, gender);

  const [popup, set_popup] = useState(false);

  const [q_seldoc] = useQueries([q_bikeinfo({ hid: selbike })]);
  const selhdoc = useMemo(() => {
    if (qissuccesss(q_seldoc)) return getv(q_seldoc, "data.result");
    else return null;
  }, [q_seldoc.dataUpdatedAt]);
  // useEffect(() => {
  //   console.log({ selbike }, selhdoc);
  // }, [selbike, jstr(selhdoc)]);

  const dets = nils(selbike) ? (
    <></>
  ) : q_seldoc.isLoading ? (
    <span className="text-acc4 resp-text--2">loading details...</span>
  ) : (
    <Card className={"bg-transparent w-full xs:max-w-[10rem] lg:max-w-[15rem]"}>
      <div className="fc-ss resp-text-0 w-max mx-auto">
        {[
          [faBookmark, _.capitalize(selhdoc.type), text],
          [faHashtag, `F${selhdoc.fno}`, text],
          [
            elementmap[selhdoc.element].icon,
            _.capitalize(selhdoc.element),
            elementmap[selhdoc.element].text,
          ],
          [faCircle, _.kebabCase(selhdoc.color), `text-${selhdoc.color}`],
        ].map(([icon, text, icon_cn]) => {
          return (
            <div className="fr-sc">
              <div className="w-[1.5rem]">
                <FontAwesomeIcon icon={icon} className={icon_cn} />
              </div>
              <span>{text}</span>
            </div>
          );
        })}
      </div>
    </Card>
  );

  const [seltab, set_seltab] = useState("vault");

  const selection_popup = (
    <>
      <div className="fr-cc my-2">
        <Tag
          onClick={() => {
            set_popup(true);
          }}
          className={twMerge(
            gender == "male"
              ? "bg-blue-400/30 border border-blue-400 text-white"
              : "bg-pink-400/30 border border-pink-400 text-white",
            " xs:w-[10rem] lg:w-[15rem] text-center h-[4rem] fc-cc",
          )}
        >
          <div class="fc-cc">
            {!nils(selhdoc) && (
              <div class="fr-sc">
                <span className="resp-text--1">{getv(selhdoc, "name")}</span>
              </div>
            )}
            <span className="resp-text--3">{`Click to select ${gender} core`}</span>
          </div>
        </Tag>
      </div>
      <PopUp
        openstate={popup}
        overlayclose={false}
        className="w-full h-full bg-r2dark/60 fc-cc"
        wrapcn={"top-[6rem]"}
        innercn={"translate-y-[0%]"}
      >
        <Card className="w-full h-full card-dark-bg backdrop-blur-md border border-acc4 fc-cc resp-gap-2 overflow-auto">
          <PopupCloseBtn closepopup={() => set_popup(false)} />
          <div className="fr-cc resp-gap-2">
            <Tag
              onClick={() => set_seltab("vault")}
              className={twMerge(
                seltab == "vault"
                  ? "bg-acc0/40 text-white -skew-x-12"
                  : "bg-transparent text-acc0 skew-x-0",
                "",
              )}
            >
              Vault Cores
            </Tag>
            <Tag
              onClick={() => set_seltab("arena")}
              className={twMerge(
                seltab == "arena"
                  ? "bg-acc0/40 text-white -skew-x-12"
                  : "bg-transparent text-acc0 skew-x-0",
                "",
              )}
            >
              Arena Cores
            </Tag>
          </div>
          {seltab == "vault" && (
            <VaultBikes gender={gender} {...{ set_popup }} />
          )}
          {seltab == "arena" && (
            <ArenaBikes gender={gender} {...{ set_popup }} />
          )}
        </Card>
      </PopUp>
    </>
  );

  return (
    <div className="h-full">
      {selection_popup}
      <div
        class={twMerge(
          "w-max mx-auto rounded-md",
          gender == "male"
            ? "bg-blue-400/20 border-2 border-blue-400"
            : "bg-pink-400/20 border-2 border-pink-400",
        )}
      >
        <div
          className={twMerge(
            "mx-auto h-[15rem] xs:w-[10rem] lg:w-[15rem] relative ",
          )}
        >
          <div className="h-[10rem] xs:w-[6rem] lg:w-[10rem] absolute top-[2.5rem] xs:left-[2rem] lg:left-[2.5rem] bg-r2lig/20 backdrop-blur-md rounded-md">
            <div className="fr-cc">
              <div className="h-[10rem] w-[4rem] fc-cc">
                <FontAwesomeIcon
                  icon={icon}
                  className={twMerge(text, "text-[3.5rem]")}
                />
              </div>
              <div class="xs:hidden lg:block">
                <GenderBikeImg {...{ gender }} />
              </div>
            </div>
          </div>
        </div>
        {dets}
      </div>
    </div>
  );
};

const GenderBikeImg = ({ gender }) => {
  const gender_hex = useMemo(() => {
    let hex = gender == "male" ? colors.blue[400] : colors.pink[400];
    if (nils(hex)) return "FFFFFF";
    else return hex.slice(1);
  }, [gender]);
  return <BikeImg hex_code={gender_hex} />;
};

const FixedBike = ({ dir }) => {
  const scon = useSpliceContext();
  const { hdoc } = scon;
  const gender = hdoc.gender;
  const [bg, text, icon] = genderbase(gender);

  const dets = (
    <Card className={"w-full xs:max-w-[10rem] lg:max-w-[15rem]"}>
      <div className="fc-ss resp-text--2">
        {[
          [faBookmark, _.capitalize(hdoc.type), text],
          [faHashtag, `F${hdoc.fno}`, text],
          [
            elementmap[hdoc.element].icon,
            _.capitalize(hdoc.element),
            elementmap[hdoc.element].text,
          ],
          [faCircle, _.kebabCase(hdoc.color), `text-${hdoc.color}`],
        ].map(([icon, text, icon_cn]) => {
          return (
            <div className="fr-sc">
              <div className="w-[1.5rem]">
                <FontAwesomeIcon icon={icon} className={icon_cn} />
              </div>
              <span>{text}</span>
            </div>
          );
        })}
      </div>
    </Card>
  );
  return (
    <div className={twMerge("h-full")}>
      <div class="fc-sc w-max mx-auto">
        <div className="fr-cc my-2">
          <Tag
            className={
              "border border-white text-white xs:w-[10rem] lg:w-[12rem] text-center h-[4rem] fc-cc"
            }
          >
            <span className="font-regular resp-text--1">{hdoc.name}</span>
            <span className="font-thin resp-text--3">{`${hdoc.gender} ready to splice`}</span>
          </Tag>
        </div>
      </div>
      <div className={twMerge("fc-sc w-max mx-auto rounded-md", `${bg}/30`)}>
        <div>
          <div
            className={twMerge(
              "mx-auto h-[15rem] xs:w-[10rem] lg:w-[15rem] relative ",
            )}
          >
            <div className="h-[10rem] xs:w-[6rem] lg:w-[10rem] absolute top-[2.5rem] xs:left-[2rem] lg:left-[2.5rem] bg-r2dark/60 rounded-md">
              <div className="fr-cc">
                <div className="h-[10rem] w-[4rem] fc-cc">
                  <FontAwesomeIcon
                    icon={icon}
                    className={twMerge(text, "text-[3.5rem]")}
                  />
                </div>
                <div class="xs:hidden lg:block">
                  <GenderBikeImg {...{ gender }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {dets}
      </div>
    </div>
  );
};

const ApprovalRow = ({ paytoken, payamt_usd, vault, mark_valid }) => {
  const { aumode } = useAuthContext();
  const { thirdweb_client, active_account, polytokens } =
    useThirdWebLoginContext();
  const { tok_to_usd_val, usd_to_tok_val } = useAppContext();

  const [bal, set_bal] = useState(null);
  const [alw, set_alw] = useState(null);
  const [approving, set_approving] = useState(false);

  const payamt = useMemo(() => {
    if (nils(paytoken)) return null;
    return usd_to_tok_val(payamt_usd, paytoken);
  }, [paytoken, payamt_usd]);

  const upd_bal = async () => {
    try {
      const token = paytoken;
      const con =
        aumode == "thirdweb"
          ? await t3_asset_signer(token)
          : await mm_asset_signer(token);
      let bal = await con.balanceOf(vault);
      bal = parseFloat(tofeth(bal));
      console.log("balance", token, bal);
      set_bal(bal);
    } catch (err) {
      console.log(err);
      set_bal(null);
    }
  };

  const upd_alw = async () => {
    try {
      const token = paytoken;
      const con =
        aumode == "thirdweb"
          ? await t3_asset_signer(token)
          : await mm_asset_signer(token);
      let alw = await con.allowance(vault, approveto);
      alw = parseFloat(tofeth(alw));
      console.log("allowance", token, alw);
      set_alw(alw);
    } catch (err) {
      console.log(err);
      set_alw(null);
    }
  };
  useEffect(() => {
    upd_alw();
    upd_bal();
  }, [paytoken]);

  const approve = async () => {
    try {
      set_approving(true);
      const token = paytoken;
      const con =
        aumode == "thirdweb"
          ? await t3_asset_signer(token)
          : await mm_asset_signer(token);

      let toval = parseFloat(payamt * 1.01);
      toval = parseFloat(toval.toFixed(6));
      if (aumode == "thirdweb") {
        const approval = await t3_contract_call(
          token,
          "approve",
          [approveto, toeth(dec(toval, 6))],
          "txn",
          true,
          { active_account: active_account },
        );
        await cdelay(5 * 1e3);
      } else {
        const approval = await con.approve(
          approveto,
          toeth(dec(toval, tokdecn(token))),
        );
        await approval.wait();
        await cdelay(5000);
      }
      upd_alw();
    } catch (err) {
      console.log(err);
    }
    set_approving(false);
  };

  const enough_bal = useMemo(() => {
    if (nils(paytoken)) return false;
    if (nils(bal)) return false;
    return bal >= payamt;
  }, [paytoken, bal, payamt]);

  const required_bal = useMemo(() => {
    if (enough_bal) return null;
    let n = parseFloat(payamt) - parseFloat(bal);
    return n;
  }, [enough_bal, payamt, bal]);

  const approved = useMemo(() => {
    if (nils(paytoken)) return false;
    if (nils(alw)) return false;
    if (!enough_bal) return false;
    let has_alw = parseFloat(alw) >= parseFloat(payamt);
    let valid = enough_bal && has_alw;

    mark_valid(valid);
    return valid;
  }, [paytoken, payamt, alw, enough_bal]);

  if (nils(bal)) return <></>;
  return (
    <div class="fr-sc my-2 w-full resp-gap-1 border-b border-acc0 px-2 py-1">
      {approved && enough_bal ? (
        <>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="resp-text-2 text-green-400"
          />
          <p>Approved Enough {paytoken} Tokens to Proceed</p>
        </>
      ) : (
        <>
          <div class="fc-ss resp-gap-1 font-digi resp-text-0">
            <div class="fc-ss">
              <p>PayToken: {paytoken}</p>
              <p>PayAmt[inUSD]: {dec(payamt_usd, 2)}</p>
              <p>
                PayAmt[{paytoken}]: {dec(payamt, tokdecn(paytoken))}
              </p>

              <p className={twMerge(enough_bal ? "" : "text-red-400")}>
                Wallet Balance[{paytoken}]: {dec(bal, tokdecn(paytoken))}
              </p>
              {!enough_bal ? (
                <>
                  <p class="text-red-400">
                    Not enough balance: Please top up your wallet
                  </p>
                  <p class="text-ted-400">
                    Falling Short by {dec(required_bal, tokdecn(paytoken))}{" "}
                    {paytoken}
                  </p>
                </>
              ) : (
                <>
                  <p className="fr-sc">
                    <span>
                      Allowance[{paytoken}]: {dec(alw, tokdecn(paytoken))}
                    </span>

                    {nils(alw) ? (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        className="text-acc0 spin-anim"
                      />
                    ) : (
                      <div onClick={upd_alw} class="cursor-pointer">
                        <FontAwesomeIcon
                          icon={faRefresh}
                          className="text-acc0"
                        />
                      </div>
                    )}
                  </p>
                </>
              )}
            </div>
          </div>
          <div class="flex-1"></div>
          {!enough_bal ? (
            <>
              {aumode == "thirdweb" && required_bal > 0 && (
                <PayEmbed
                  client={thirdweb_client}
                  payOptions={{
                    mode: "fund_wallet",
                    prefillBuy: {
                      chain: polygon,
                      token: polytokens[paytoken],
                      amount: dec(required_bal, tokdecn(paytoken)),
                      allowEdits: {
                        amount: true,
                        token: false,
                        chain: false,
                      },
                    },
                  }}
                  supportedTokens={{ 137: [polytokens[paytoken]] }}
                />
              )}
            </>
          ) : (
            <Tag
              onClick={() => {
                approve();
              }}
              className={twMerge(
                "bg-r2dark/60 text-white",
                approving ? "bg-r2dark/20 border border-acc0" : "",
              )}
            >
              <div class="fr-sc resp-text-0">
                {approving ? (
                  <>
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="text-acc0 spin-anim"
                    />
                    <span className="text-acc0">Approving {paytoken}</span>
                  </>
                ) : (
                  <>
                    <span>Approve {paytoken}</span>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </>
                )}
              </div>
            </Tag>
          )}
        </>
      )}
    </div>
  );
};

const Stage_0 = ({ stage, set_next_allowed, pairinfo }) => {
  const scon = useSpliceContext();
  useEffect(() => {
    // console.log({ stage });
    if (stage !== 0) return;
    if (
      !_.isEmpty(pairinfo.f) &&
      !_.isEmpty(pairinfo.m) &&
      _.isEmpty(pairinfo.f.errs) &&
      _.isEmpty(pairinfo.m.errs)
    ) {
      setTimeout(() => {
        set_next_allowed(true);
      }, 500);
    }
  }, [stage, jstr(pairinfo)]);

  const HInfo = (h) => {
    return (
      <>
        <p
          className={twMerge(
            "my-2 text-center resp-text-0",
            h.gender == "male" ? "text-blue-300" : "text-pink-300",
          )}
        >
          {h.name}#{h.hid}
        </p>
        <table className={twMerge(tablecn.table, "resp-text--1")}>
          <tbody>
            <tr className="thintdrow">
              <td>
                <span>FNo</span>
              </td>
              <td>
                <span>F{h.fno}</span>
              </td>
            </tr>
            <tr className="thintdrow">
              <td>
                <span>Type</span>
              </td>
              <td>
                <span>{_.capitalize(h.type)}</span>
              </td>
            </tr>
            <tr className="thintdrow">
              <td>
                <span>Element</span>
              </td>
              <td>
                <ElementTag element={h.element} className={"resp-text--3"} />
              </td>
            </tr>
            <tr className="thintdrow">
              <td>
                <span>Gender</span>
              </td>
              <td>
                <GenderTag gender={h.gender} className={"resp-text--3"} />
              </td>
            </tr>
            <tr className="thintdrow">
              <td>
                <span>Color</span>
              </td>
              <td>
                <div className="fr-sc resp-gap-2">
                  <FontAwesomeIcon
                    icon={faCircle}
                    className={`text-${h.color}`}
                  />
                  <span>{_.capitalize(h.color)}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="h-[2rem]"></div>
        {!_.isEmpty(h.errs) && (
          <div className="p-2 text-red-300 resp-text--1">
            <p className="text-center">Errors!!</p>
            {h.errs.map((e) => {
              return <p className="my-1"></p>;
            })}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="w-full">
      <p className="my-2 text-center font-digi italic resp-text-1">
        Parent Details
      </p>

      <div className="grid grid-cols-2 resp-gap-4">
        <Card
          className={twMerge(
            "w-full h-full bg-r2dark/60 border-t-2",
            "border-blue-500",
          )}
        >
          {HInfo(pairinfo.f)}
        </Card>
        <Card
          className={twMerge(
            "w-full h-full bg-r2dark/60 border-t-2",
            "border-pink-500",
          )}
        >
          {HInfo(pairinfo.m)}
        </Card>
      </div>
    </div>
  );
};

const Stage_1 = ({ stage, set_next_allowed, pairinfo }) => {
  const scon = useSpliceContext();
  useEffect(() => {
    console.log({ stage });
    if (stage !== 1) return;
    if (!_.isEmpty(pairinfo.baby_info)) {
      setTimeout(() => {
        set_next_allowed(true);
      }, 500);
    }
  }, [stage, jstr(pairinfo)]);

  const h = pairinfo.baby_info;

  return (
    <div className="w-full">
      <p className="my-2 text-center font-digi italic resp-text-1">
        Baby Traits
      </p>

      <div className="grid grid-cols-1 resp-gap-4">
        <Card
          className={twMerge(
            "min-w-[50%] w-max mx-auto h-full bg-r2dark/60 border-t-2",
            "border-green-500",
          )}
        >
          <table className={twMerge(tablecn.table, "resp-text--1")}>
            <tbody>
              <tr className="thintdrow">
                <td>
                  <span>FNo</span>
                </td>
                <td>
                  <span>F{h.fno}</span>
                </td>
              </tr>
              <tr className="thintdrow">
                <td>
                  <span>Type</span>
                </td>
                <td>
                  <span>{_.capitalize(h.type)}</span>
                </td>
              </tr>
              <tr className="thintdrow">
                <td>
                  <span>Element</span>
                </td>
                <td>
                  <ElementTag element={h.element} className={"resp-text--3"} />
                </td>
              </tr>
            </tbody>
          </table>
        </Card>
      </div>
    </div>
  );
};

const Stage_2 = ({
  stage,
  set_next_allowed,
  pairinfo,
  popdata,
  set_popdata,
}) => {
  const mmcon = useMetaMaskContext();

  const { aumode, vault, auth } = useAuthContext();
  const { thirdweb_client, active_account } = useThirdWebLoginContext();

  const scon = useSpliceContext();
  const [stage_err, set_stage_err] = useState(null);

  const HInfo = (h) => {
    if (nils(h)) return <></>;
    return (
      <table className={tablecn.table}>
        <tbody className="resp-text--2">
          <tr className="thintdrow">
            <td>Set Price:</td>
            <td>
              <span className="text-white">${dec(h.price, 2)}</span>
            </td>
          </tr>
          <tr className="thintdrow">
            <td>Type:</td>
            <td>
              <span className="text-white">{h.elty}</span>
            </td>
          </tr>
          <tr className="thintdrow">
            <td>Type Base:</td>
            <td>
              <span className="text-white">${dec(h.base, 2)}</span>
            </td>
          </tr>
          <tr className="thintdrow">
            <td>
              <div className="fc-ss">
                <span>Excess:</span>
                <span>=price - (3 x base)</span>
              </div>
            </td>
            <td>
              <span className="text-white">
                ${dec(Math.max(h.price - h.base * 3, 0), 2)}
              </span>
            </td>
          </tr>
          <tr className="thintdrow">
            <td>
              <div className="fc-ss">
                <span>Overage:</span>
                <span>=excess / 2</span>
              </div>
            </td>
            <td>
              <span className="text-white">${dec(h.overage, 2)}</span>
            </td>
          </tr>
          <tr className="thintdrow text-acc0">
            <td>
              <div className="fc-ss">
                <span>Owner Gets:</span>
              </div>
            </td>
            <td>
              <span className="text-acc0">${dec(h.finfee, 2)}</span>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };
  const DNAInfo = (h) => {
    return (
      <table className={tablecn.table}>
        <tbody className="resp-text--2">
          <tr className="thintdrow">
            <td>Base:</td>
            <td>
              <span className="text-white">${dec(h.base, 2)}</span>
            </td>
          </tr>

          <tr className="thintdrow">
            <td>
              <div className="fc-ss">
                <span>Overage Fee:</span>
                <span>=(DAD + MOM Overage)</span>
              </div>
            </td>
            <td>
              <span className="text-white">${dec(h.overage, 2)}</span>
            </td>
          </tr>
          <tr className="thintdrow text-acc0">
            <td>
              <div className="fc-ss">
                <span>DNA Gets:</span>
              </div>
            </td>
            <td>
              <span className="text-acc0">${dec(h.finfee, 2)}</span>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const [paytoken, set_paytoken] = useState(getv(popdata, "paytoken") ?? null);
  const [alweng_paytoken, set_alweng_paytoken] = useState(false);
  const [alweng_bgc, set_alweng_bgc] = useState(false);

  const [bgcpart, set_bgcpart] = useState(getv(popdata, "bgcpart") ?? null);

  const [bgcbal, set_bgcbal] = useState(null);
  const [updref, set_updref] = useState(1);
  useEffect(() => {
    const fn = async () => {
      try {
        const token = "BGC";
        const con =
          aumode == "thirdweb"
            ? await t3_asset_signer(token)
            : await mm_asset_signer(token);
        let bal = await con.balanceOf(vault);
        bal = parseFloat(tofeth(bal));

        set_bgcbal(bal);
      } catch (err) {
        console.log(err);
        set_bgcbal(null);
      }
    };
    fn();
  }, [!nils(bgcpart), aumode, updref]);

  const bgc_useresp = useMemo(() => {
    if (bgcpart == null) return null;
    if (nils(bgcbal)) return;

    let usebgc = parseFloat(getv(bgcpart, "usebgc"));
    if (usebgc > parseFloat(bgcbal))
      return { status: "error", err: "Insufficient BGC" };
    if (usebgc < 0) return { status: "error", err: "Invalid BGC" };

    let dnafee = parseFloat(getv(pairinfo, "prices.dna.finfee"));
    if (usebgc > dnafee)
      return { status: "error", err: `Can set max:${dec(dnafee, 2)}` };

    return { status: "success" };
  }, [jstr(bgcpart), bgcbal, jstr(pairinfo)]);

  const use_max_bgc = async () => {
    let dna = parseFloat(getv(pairinfo, "prices.dna.finfee"));
    let bal = Math.max(parseFloat(dec(bgcbal, 2) - 0.01), 0);
    let usebgc = Math.min(dna, bal);
    set_bgcpart({ usebgc });
  };

  useEffect(() => {
    // console.log({ stage }, pairinfo.prices);
    if (stage !== 2) return;
    set_stage_err(null);

    if (!_.isEmpty(pairinfo.prices)) {
      let valid = alweng_paytoken && nils(bgcpart) ? true : alweng_bgc;
      set_next_allowed(valid);
    } else {
      setTimeout(() => {
        set_stage_err("No Prices Found");
      }, 1000);
    }
  }, [
    stage,
    jstr(pairinfo),
    jstr(bgcpart),
    jstr(bgc_useresp),
    alweng_paytoken,
    alweng_bgc,
  ]);

  useEffect(() => {
    console.log("useeffect:bgcpart", bgcpart, paytoken);
    if (bgc_useresp?.status == "success" && !nils(getv(bgcpart, "usebgc")))
      set_popdata({ ...popdata, bgcpart, paytoken });
    else set_popdata({ ...popdata, bgcpart: null, paytoken });
  }, [jstr(bgc_useresp), jstr(bgcpart), paytoken]);

  return (
    <div className="w-full">
      <p className="my-2 text-center font-digi italic resp-text-1">
        Price Chart
      </p>

      {stage_err && (
        <p className="text-red-300 my-2 border border-red- resp-p-1">
          {stage_err}
        </p>
      )}

      <div className="grid grid-cols-2 resp-gap-2 resp-p-2">
        <Card className={"w-full bg-r2dark/60 border-t-2 border-blue-400"}>
          {HInfo(pairinfo.prices.f)}
        </Card>
        <Card className={"w-full bg-r2dark/60  border-t-2 border-pink-400"}>
          {HInfo(pairinfo.prices.m)}
        </Card>
        <div className="col-span-2">
          <Card className={"w-full bg-r2dark/60  border-t-2 border-acc0"}>
            {DNAInfo(pairinfo.prices.dna)}
          </Card>
        </div>

        <div className="col-span-2">
          <Card className={"w-full bg-r2dark/60  border-t-2 border-acc0"}>
            <p class="my-1 resp-text-0 font-digi">
              Select Pay Token in which you want to pay for the Splice
            </p>
            <div class="w-full fr-cc gap-2">
              {[
                [
                  "WETH",
                  "bg-purple-500/30 border-purple-500",
                  "bg-purple-500 shadow-lg shadow-purple-500",
                ],
                // [
                //   "DEZ",
                //   "bg-teal-500/30 border-teal-500",
                //   "bg-teal-500 shadow-lg shadow-teal-500",
                // ],
              ].map(([tok, cn, acn]) => {
                let active = paytoken == tok;
                return (
                  <div
                    onClick={() => {
                      set_paytoken(tok);
                    }}
                    class={twMerge(
                      "min-w-[6rem] fr-cc resp-text-1 gap-2 font-digi",
                      "rounded-md resp-px-2 resp-py-2 border-2",
                      cn,
                      active ? acn : "",
                    )}
                  >
                    <TokenIcon token={tok} size="xs" />
                    <span>{tok}</span>
                  </div>
                );
              })}
            </div>
          </Card>
        </div>

        {!nils(paytoken) && (
          <div className="col-span-2">
            <Card
              className={twMerge(
                "w-full",
                nils(bgcpart) ? "" : "bg-r2dark/60 border-t-2 border-acc0",
              )}
            >
              <div className="my-1">
                {!nils(bgcpart) && (
                  <div className="resp-text--2">
                    {bgc_useresp && bgc_useresp.status == "error" && (
                      <p className="text-red-300 p-2 border border-red-300 rounded-md">
                        {bgc_useresp.err}
                      </p>
                    )}
                    {bgc_useresp && bgc_useresp.status == "msg" && (
                      <p className="text-green-300 p-2 border border-green-300 rounded-md">
                        {bgc_useresp.msg}
                      </p>
                    )}
                  </div>
                )}
              </div>

              <div className="fr-sc p-2">
                {!nils(bgcpart) && (
                  <div class="fc-ss">
                    <Tag className="text-acc0 font-digi">
                      {`balance: ${nils(bgcbal) ? "--" : dec(bgcbal, 2)} BGC`}
                    </Tag>
                  </div>
                )}
                <div className="flex-1"></div>
                <Tag
                  onClick={() => {
                    if (nils(bgcpart)) {
                      set_bgcpart({
                        use: true,
                      });
                    } else set_bgcpart(null);
                  }}
                  className={twMerge("text-slate-300 fr-sc resp-gap-1")}
                >
                  <FontAwesomeIcon
                    className={twMerge(
                      "text-white",
                      !nils(bgcpart) ? "text-acc0" : "text-slate-300",
                    )}
                    icon={!nils(bgcpart) ? faCheck : faBox}
                  />
                  <span>Pay DNA Fee with BGC coins</span>
                </Tag>
              </div>

              {!nils(bgcpart) && !nils(bgcbal) && (
                <div className="fr-sc resp-gap-2">
                  <span className="text-acc0 resp-text--1">Use BGC:</span>
                  <InpText
                    {...{
                      id: `dnafee-bgc`,
                      placeholder: "...",
                      def_val: getv(bgcpart, "usebgc") ?? "",
                      autoComplete: "off",
                      // label: "Use BGC",
                      inpprops: {
                        className:
                          "w-[8rem] bg-r2dark/60 text-white resp-text--1",
                      },
                      contprops: {
                        className:
                          "w-[8rem] bg-r2dark/60 text-white resp-text--1",
                      },
                      setter: (v) => {
                        v = parseFloat(v);
                        if (nils(v)) v = null;
                        set_bgcpart({
                          ...bgcpart,
                          usebgc: v,
                        });
                      },
                    }}
                  />
                  <Tag
                    onClick={() => use_max_bgc()}
                    className={twMerge("bg-acc0/40 font-digi resp-text--1")}
                  >
                    Use Max
                  </Tag>
                </div>
              )}

              {!nils(bgcpart) &&
                bgc_useresp?.status == "success" &&
                getv(bgcpart, "usebgc") && (
                  <div className="fr-cc resp-text--1 text-acc0 p-2">
                    <span>You Pay</span>
                    <span>
                      $
                      {`${dec(
                        parseFloat(getv(pairinfo, "prices.tot.finfee")) -
                          parseFloat(getv(bgcpart, "usebgc")),
                        2,
                      )} `}
                      [with {paytoken} tokens]
                    </span>
                    <span>+</span>
                    <span>
                      ${`${dec(parseFloat(getv(bgcpart, "usebgc")), 2)} `}
                      [with BGC tokens]
                    </span>
                  </div>
                )}
            </Card>
          </div>
        )}

        <div className="col-span-2">
          {!nils(paytoken) && !nils(getv(bgcpart, "usebgc")) ? (
            <Card className={"w-full bg-r2dark/60  border-t-2 border-acc0"}>
              <ApprovalRow
                {...{
                  paytoken: "BGC",
                  payamt_usd: parseFloat(getv(bgcpart, "usebgc")),
                  vault,
                  mark_valid: set_alweng_bgc,
                }}
              />
              <ApprovalRow
                {...{
                  paytoken: paytoken,
                  payamt_usd: dec(
                    parseFloat(getv(pairinfo, "prices.tot.finfee")) -
                      parseFloat(getv(bgcpart, "usebgc")),
                    2,
                  ),
                  vault,
                  mark_valid: set_alweng_paytoken,
                }}
              />
            </Card>
          ) : !nils(paytoken) ? (
            <Card className={"w-full bg-r2dark/60  border-t-2 border-acc0"}>
              <ApprovalRow
                {...{
                  paytoken,
                  payamt_usd: getv(pairinfo, "prices.tot.finfee"),
                  vault,
                  mark_valid: set_alweng_paytoken,
                }}
              />
            </Card>
          ) : null}
        </div>

        <div className="col-span-2">
          <div className="fr-cc resp-gap-4 resp-text-2">
            <span className="resp-text-1">Total Cost</span>
            <Tag className="border border-acc0 bg-r2dark/60 text-acc0 resp-text-2">
              <span>${dec(getv(pairinfo, "prices.tot.finfee"), 2)}</span>
            </Tag>
          </div>
        </div>
      </div>
    </div>
  );
};

const Stage_3 = ({
  stage,
  set_next_allowed,
  pair_params,
  pairinfo,
  popdata,
  set_popdata,
}) => {
  const scon = useSpliceContext();
  const aucon = useAuthContext();
  const { aumode, vault } = aucon;
  const { thirdweb_client, active_account } = useThirdWebLoginContext();
  const [stage_err, set_stage_err] = useState(null);

  const [qo_splice_requests] = useQueries([
    q_splicingv02_pending(
      {
        fid: pair_params.father_coreid,
        mid: pair_params.mother_coreid,
        vault,
      },
      { enabled: stage == 3 },
    ),
  ]);

  const [new_allowed, not_allowed_msg] = useMemo(() => {
    console.log({ stage }, pairinfo.prices);
    if (stage !== 3) return [false];
    set_stage_err(null);
    if (qo_splice_requests.isLoading) return [false];
    if (qiserr(qo_splice_requests)) {
      // set_stage_err(`err: ${getv}`);
      // set_stage_err(`Error:${qiserr(qo_splice_requests)}`);
      // return;
    }
    let d = getv(qo_splice_requests, "data.result");
    if (nils(d)) return [false, "error fetching splicing requests"];

    if (d.self_pending.length !== 0) {
      return [
        false,
        `${d.self_pending.length} Pending Requests Found on same Pair`,
      ];
    }
    if (d.allpending == 0)
      return [false, `Parents are busy splicing for another vault`];

    return [true, ""];
  }, [stage, jstr(pairinfo), qo_splice_requests.dataUpdatedAt]);

  const spreqs = useMemo(() => {
    let d = getv(qo_splice_requests, "data.result");
    // console.log("qo_splice_requests:data", qo_splice_requests);
    if (!qissuccesss(qo_splice_requests)) return null;
    return d;
  });

  const encoded = useMemo(() => {
    let bgcpart = getv(popdata, "bgcpart") ?? {};
    let use_bgc = bgcpart.usebgc ?? null;
    if (nils(parseFloat(use_bgc))) use_bgc = null;
    else use_bgc = parseFloat(use_bgc);
    let paytoken = getv(popdata, "paytoken") ?? null;
    let encoded = {
      paytoken,
      use_bgc,
    };
    console.log("encoded", encoded);
    return encoded;
  }, [jstr(popdata)]);

  const gen_new_request = async () => {
    let initparams = aucon.get_contract_init_params();
    const splicing = await SplicingContract.get_contract(initparams);

    // const weth = await WETH_MockToken.get_contract();

    // console.log("popdata", popdata);
    // console.log("request_splice:encoded", encoded);
    let encoded_b = json_to_base64(encoded);

    let args = [
      vault,
      pair_params.father_coreid,
      pair_params.mother_coreid,
      encoded_b,
    ];
    // console.log("request_splice:args", args);

    // throw new Error("not implemented");

    let resp = await splicing.request_splice(...args, {});

    // let resp = null;
    // if (aumode == "thirdweb") {
    //   resp = await t3_contract_call(
    //     "splicingv02",
    //     "request_splice",
    //     args,
    //     "txn",
    //     true,
    //     { active_account: active_account },
    //   );
    //   await cdelay(2 * 1e3);
    // } else {
    //   resp = await splicing.request_splice(...args);
    //   console.log("request_splice resp", resp);
    // }

    if (!nils(resp?.hash)) {
      let qdoc = { hash: resp.hash, service, dets: {} };
      console.log("qdoc", qdoc);
      await q_que_txn(qdoc).queryFn();
    }

    console.log("splicing:resp", resp);

    await cdelay(2 * 1e3);
    let logs = await splicing.getParsedLogs(resp.logs);
    console.log("logs", logs);
    let reqid = null;
    for (let l of logs) {
      if (l.name == "SpliceRequested") {
        reqid = l.args?.reqid;
      }
    }
    if (nils(reqid))
      throw new Error(`Sorry couldnt Locate your requests,\n please retry`);
    console.log("reqid", reqid);
    return { reqid, hash: resp.hash, args };
  };

  const [genloading, set_genloading] = useState(false);

  const auto_gen_new_request = async () => {
    set_genloading(true);
    try {
      set_stage_err(null);
      let resp = await gen_new_request();
      let { reqid, hash, args } = resp;
      let ext = { args };

      set_popdata({ ...popdata, stage: 4, reqid });
    } catch (err) {
      let errmsg = !nils(err.reason) ? err.reason : err.message;
      let reg = /^([^\n]*)/;
      let mat = null;
      if ((mat = reg.exec(errmsg.toString()))) {
        console.log("err:enter_action:mat", mat);
        errmsg = mat[1];
      }
      errmsg = trim_n(errmsg, 100);
      set_stage_err(errmsg);
    }
    await cdelay(2000);
    set_genloading(false);
  };

  const select_prevreq = async (reqid) => {
    set_stage_err(null);
    set_popdata({ ...popdata, stage: 4, reqid });
  };

  const [del_resp, set_del_resp] = useState(null);
  const delete_prevreq = async (reqid) => {
    try {
      set_del_resp({ loading: true, msg: "Deleting...", reqid });
      let resp = await q_splicingv02_delete_request({ reqid }).queryFn();
      let done = getv(resp, "result.done") == 1;
      if (done == 1) {
        await cdelay(1000);
        await qo_splice_requests.refetch();
        await cdelay(2000);
      }
    } catch (err) {
      console.log("delete_prevreq", err);
    }
    set_del_resp({});
  };

  return (
    <div className="w-full">
      <p className="my-2 text-center font-digi italic resp-text-1">
        Splicing Requests
      </p>

      {/*<div class="resp-text--1">{jstr(spreqs)}</div> */}

      {stage_err && (
        <p className="text-red-300 resp-text--2 my-2 border border-red-300 resp-p-1 rounded-md">
          {stage_err}
        </p>
      )}

      {qo_splice_requests.isLoading ? (
        <Loader01c />
      ) : qissuccesss(qo_splice_requests) ? (
        <>
          <Card
            className={
              "w-full bg-r2dark/60  border-t-2 border-acc0 fc-cc resp-gap-2"
            }
          >
            <p className="my-2 resp-text-1">Generate a New Request</p>
            <div class="fr-sc resp-text--1 resp-gap-2 font-digi text-acc0">
              <p>PayToken: {encoded?.paytoken}</p>
              {!nils(encoded?.use_bgc) ? (
                <p>Using BGC: {encoded?.use_bgc}</p>
              ) : null}
            </div>

            {new_allowed == true ? (
              <div className="fr-cc">
                {genloading ? (
                  <p className="text-center text-acc0 resp-text--2">
                    {"Generating Request..."}
                  </p>
                ) : (
                  <Tag
                    onClick={auto_gen_new_request}
                    className="bg-acc0/40 -skew-x-12 font-digi"
                  >
                    New Request
                  </Tag>
                )}
              </div>
            ) : (
              <p className="text-center text-yellow-300 resp-text--2">
                {not_allowed_msg}
              </p>
            )}
          </Card>
          <Card className={"w-full bg-r2dark/60   border-t-2 border-acc0"}>
            <div class="fr-sc my-2">
              <p className="my-2 resp-text--1">
                Your Previous Pending Requests on Pair
              </p>
              {qo_splice_requests.isLoading ? <Loader01c size="s" /> : null}
            </div>

            {true ? (
              <>
                {qissuccesss(qo_splice_requests) &&
                !_.isEmpty(spreqs?.self_pending) ? (
                  <>
                    {spreqs.self_pending.map((s) => {
                      return (
                        <div className="resp-p-2 bg-r2dark/50 rounded-sm my-2 resp-text--1">
                          <div className="fr-sc">
                            <p className="text-center text-acc4">{`reqid: ${trim2(s.reqid, 5, 3)}`}</p>
                            <div className="flex-1"></div>
                            <Tag
                              onClick={() => select_prevreq(s.reqid)}
                              className="bg-acc4/50 -skew-x-12"
                            >
                              <span className="resp-text-0">Select</span>
                            </Tag>
                          </div>
                          <div className="fc-ss">
                            <div className="flex-1"></div>
                            <p className="">
                              {`Requested ${from_time_mini(s.requested_at)} ago`}
                            </p>
                            <p className="">
                              {`Expires in ${from_time_mini(s.expires_at)} `}
                            </p>
                          </div>
                          {s.hold_processing == true && (
                            <div class="fr-sc text-yellow-300 resp-gap-2">
                              <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                className="text-yellow-300"
                              />
                              <span>On Hold:</span>
                              <span>{s.errmsg}</span>
                            </div>
                          )}
                          <div class="fr-sc">
                            <div class="flex-1"></div>

                            {!_.isEmpty(del_resp) &&
                            del_resp.reqid == s.reqid ? (
                              <Tag
                                onClick={() => delete_prevreq(s.reqid)}
                                className="fr-sc resp-gap-1 border-red-400 text-red-400 -skew-x-12"
                              >
                                <FontAwesomeIcon
                                  icon={faSpinner}
                                  className="spin-anim text-red-400"
                                />
                                <span class="resp-text--1">{del_resp.msg}</span>
                              </Tag>
                            ) : (
                              <Tag
                                onClick={() => delete_prevreq(s.reqid)}
                                className="bg-red-500/50 -skew-x-12"
                              >
                                <span className="resp-text-0">Delete</span>
                              </Tag>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    <p class="resp-text--1 text-red-300">
                      ** Deleting wont cancel request if the minting transaction
                      is already sent
                    </p>
                  </>
                ) : (
                  <>
                    <p className="text-center text-yellow-200 resp-text--2">
                      No Pending Splice Requests
                    </p>
                  </>
                )}
              </>
            ) : (
              <>
                {/* <p className="text-center text-yellow-300 resp-text--2">
                {spreqs.old_msg}
              </p> */}
              </>
            )}
          </Card>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

const Stage_4 = ({ stage, popdata, set_popdata, closepopup }) => {
  const scon = useSpliceContext();
  const { vault } = useAccountContext();
  const [stage_err, set_stage_err] = useState(null);

  const { aumode } = useAuthContext();
  const { thirdweb_client, active_account } = useThirdWebLoginContext();

  const reqid = popdata.reqid;
  const [qosplicedoc] = useQueries([
    q_splicing_v02_splicedoc(
      { reqid },
      {
        staleTime: 10 * 1e3,
        refetchInterval: 10 * 1e3,
      },
    ),
  ]);

  useEffect(() => {
    console.log({ stage }, { reqid });
    if (stage !== 4) return;
    set_stage_err(null);
    if (nils(reqid)) {
      set_stage_err(`No reqid found`);
      return;
    }
    if (qosplicedoc.isLoading) return;
  }, [stage, jstr(popdata)]);

  const splicedoc = useMemo(() => {
    if (qissuccesss(qosplicedoc)) return getv(qosplicedoc, "data.result");
    return {};
  }, [qosplicedoc.dataUpdatedAt]);

  const [genloading, set_genloading] = useState(false);

  const mxwaittime = useMemo(() => {
    console.log(splicedoc.splice);
    if (nils(splicedoc?.splice))
      return moment().add(10, "minutes").toISOString();
    if (splicedoc.invalid === true) return null;
    let ut = getv(splicedoc, "update_time");
    let st = getv(splicedoc, "splice.stage");
    if (st == 1) return moment(ut).add(2, "minutes").toISOString();
    else if (st >= 2) return null;
    return null;
  }, [jstr(splicedoc)]);

  useEffect(() => {
    let s = getv(splicedoc, "minted");
    if (s === true) set_popdata({ ...popdata, stage: 5 });
  }, [jstr(splicedoc)]);

  return (
    <div className="w-full">
      .
      <div className="fc-cc my-2 text-center font-digi italic ">
        <span className="resp-text-1">Splicing Request</span>
        <span className="resp-text--2 text-acc0">{reqid}</span>
      </div>
      {stage_err && (
        <p className="text-red-300 resp-text--2 my-2 border border-red-300 resp-p-1 rounded-md">
          {stage_err}
        </p>
      )}
      {!nils(reqid) && (
        <>
          {qosplicedoc.isLoading || qiserr(qosplicedoc) ? (
            <>
              <p className="text-acc0 resp-text--2 text-center">
                Waiting for your Splice Document to generate
              </p>
              <Loader01c />
            </>
          ) : qissuccesss(qosplicedoc) ? (
            <>
              {splicedoc.errmsg === true ? (
                <>
                  <div className="fc-cc resp-gap-2">
                    <p class="text-red-300 text-center font-digi resp-text-0">
                      {splicedoc.errmsg}
                    </p>
                  </div>
                </>
              ) : getv(splicedoc, "minted") === true ? (
                <></>
              ) : (
                <>
                  <p className="text-acc0 text-center">
                    Waiting for contract to mint your splice
                  </p>
                  <Loader01c />
                </>
              )}
            </>
          ) : (
            <></>
          )}
          <div className="fr-sc resp-text--2">
            <div className="flex-1"></div>
            {!nils(mxwaittime) && (
              <div className="text-yellow-300 fc-ss resp-gap-1">
                <span>Est Waiting Time</span>
                <span>{from_time_mini(mxwaittime)}</span>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const Stage_5 = ({ stage, popdata, set_popdata }) => {
  const history = useNavigate();
  const scon = useSpliceContext();
  const { vault } = useAccountContext();
  const [stage_err, set_stage_err] = useState(null);

  const reqid = popdata.reqid;
  const [qosplicedoc] = useQueries([
    q_splicing_v02_splicedoc(
      { reqid },
      {
        staleTime: 10 * 1e3,
        refetchInterval: 10 * 1e3,
      },
    ),
  ]);

  useEffect(() => {
    console.log({ stage }, { reqid });
    if (stage !== 5) return;
    set_stage_err(null);
    if (nils(reqid)) {
      set_stage_err(`No reqid found`);
      return;
    }
    if (qosplicedoc.isLoading) return;
  }, [stage, jstr(popdata)]);

  const splicedoc = useMemo(() => {
    if (qissuccesss(qosplicedoc)) return getv(qosplicedoc, "data.result");
    return {};
  }, [qosplicedoc.dataUpdatedAt]);

  const [genloading, set_genloading] = useState(false);

  const mxwaittime = useMemo(() => {
    return moment().add(10, "minutes").toISOString();
    return null;
  }, [jstr(splicedoc)]);

  const hid = getv(splicedoc, "hid") ?? "";

  return (
    <div className="w-full">
      <p className="my-2 text-center font-digi italic resp-text-1">
        Mint Splice<span className="text-acc0">#{hid}</span>
      </p>

      {stage_err && (
        <p className="text-red-300 resp-text--2 my-2 border border-red-300 resp-p-1 rounded-md">
          {stage_err}
        </p>
      )}

      {!nils(reqid) && (
        <>
          {qosplicedoc.isLoading ||
          qiserr(qosplicedoc) ||
          splicedoc.minted != true ? (
            <>
              <p className="text-acc0 resp-text--2 text-center">
                Waiting for your Mint
              </p>
              <Loader01c />
            </>
          ) : qissuccesss(qosplicedoc) && splicedoc.minted === true ? (
            <>
              <p className="text-center text- my-2">
                Congratulations on brand new Splice
              </p>
              <div className="fc-cc my-2">
                <SetBikeName
                  h={{ hid: reqid }}
                  after_setting={() => {
                    history(`/bike/${hid}`);
                  }}
                />
              </div>
              <p className="text-center text-white resp-text-2">-- OR --</p>
              <div className="fc-cc my-2">
                <Tag
                  onClick={() => {
                    history(`/bike/${hid}`);
                  }}
                  className="bg-acc0/40 -skew-x-12"
                >
                  View Core
                </Tag>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="fr-sc resp-text--2">
            <div className="flex-1"></div>
            {!nils(mxwaittime) && (
              <div className="text-yellow-300 fc-ss resp-gap-1">
                <span>Est Waiting Time</span>
                <span>{from_time_mini(mxwaittime)}</span>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const SpliceProceed = ({ ext = {} }) => {
  const accon = useAccountContext();
  const { vault } = accon;
  const scon = useSpliceContext();
  const { pair_params, qo_pairinfo, pairinfo } = scon;

  const [pop, set_pop] = useState(false);
  const [popdata, set_popdata] = useState({});
  const [popresp, set_popresp] = useState({});

  // useEffect(() => {
  //   console.log("SpliceProceed:popdata", popdata);
  // }, [jstr(popdata)]);

  const closepopup = () => {
    set_pop(false);
    set_popdata({
      stage: 0,
    });
    set_popresp({});
  };
  const open_popup = () => {
    set_pop(true);
    set_popdata({
      stage: 0,
      ...ext,
    });
    // set_popdata({
    //   stage: 2,
    //   bgcpart: {
    //     usebgc: 12,
    //   },
    //   paytoken: "WETH",
    // });
    set_popresp({});
  };

  /*
  stage 0: view parent details
  stage 1: view baby generated details
  stage 2: prices breakdown
  stage 3: either check for previous requests else enter new request
  stage 3: view waiting for fees keep the popup open
  stage 4: pay now
  stage 5: waiting for the mint to finish and redirect link to new bike page
  */

  const prevstage = () => {
    let n_s = Math.max(0, popdata.stage - 1);
    set_popdata({ ...popdata, stage: n_s });
  };
  const nextstage = () => {
    let n_s = Math.min(5, popdata.stage + 1);
    set_popdata({ ...popdata, stage: n_s });
  };

  const stage = popdata.stage;
  const mxstage = 5;

  const [next_allowed, set_next_allowed] = useState(false);
  useEffect(() => {
    set_next_allowed(false);
  }, [stage]);

  const stageprops = {
    pair_params,
    stage,
    pop,
    popdata,
    set_popdata,
    popresp,
    set_popresp,
    prevstage,
    nextstage,
    next_allowed,
    set_next_allowed,
    pairinfo,
    closepopup,
  };

  const StageComp = {
    0: Stage_0,
    1: Stage_1,
    2: Stage_2,
    3: Stage_3,
    4: Stage_4,
    5: Stage_5,
  };

  return (
    <>
      <div className="fr-cc">
        <Tag
          onClick={() => {
            open_popup();
          }}
          className="bg-r2lig shadow-lg shadow-acc1 font-digi fr-sc resp-gap-2 resp-p-2 resp-text-1 -skew-x-12"
        >
          <span>Proceed Splicing</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </Tag>

        <PopUp
          overlayclose={false}
          openstate={pop}
          onClose={closepopup}
          wrapcn={"top-[2rem]"}
          innercn={"translate-y-[0%]"}
        >
          <Card
            className={
              "max-w-[95vw] w-[50rem] xs:max-h-[70vh] lg:max-h-[60vh] overflow-auto"
            }
          >
            <div className="fr-sc">
              <div className="flex-1"></div>
              <PopupCloseBtn closepopup={closepopup} />
            </div>
            <div className="h-[0.5rem]"></div>
            <div className="w-full h-[3rem] relative">
              <div className="absolute top-[20%] left-[2.5%] z-[10] w-[95%]">
                <div
                  style={{ width: `${(stage / mxstage) * 100}%` }}
                  className="h-[1rem] rounded-md transition duration-1000 bg-acc0/30"
                ></div>
              </div>
              <div className="absolute w-full z-[20]">
                <div className="flex flex-row justify-between items-center z-[20]">
                  {[0, 1, 2, 3, 4, 5].map((_s) => (
                    <Tag
                      className={twMerge(
                        "fc-cc rounded-full resp-p-2 border",
                        _s <= popdata.stage
                          ? "text-white bg-acc0/40 border-transparent"
                          : "text-acc0 border-acc0",
                        popdata.stage == _s ? "shadow-md shadow-acc0" : "",
                      )}
                    >
                      <span className="resp-text--1 font-digi">{_s}</span>
                    </Tag>
                  ))}
                </div>
              </div>
            </div>
            <div className="h-[0.5rem]"></div>
            <div className="h-[2rem]">
              {stage > 0 && (
                <div className="fr-sc my-2">
                  <Tag
                    onClick={prevstage}
                    className="border border-acc0 resp-text--1 fr-sc resp-gap-2 -skew-x-12 text-acc0"
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                    <span className="font-digi">Back</span>
                  </Tag>
                </div>
              )}
            </div>

            <div className="min-h-[18rem]">
              {qissuccesss(qo_pairinfo) && (
                <>
                  {StageComp[stage] &&
                    React.createElement(StageComp[stage], stageprops)}
                </>
              )}
            </div>

            <div className="h-[2rem]">
              {next_allowed && (
                <div className="fr-sc my-2">
                  <div className="flex-1"></div>
                  <Tag
                    onClick={nextstage}
                    className="border border-acc0 resp-text--1 fr-sc resp-gap-2 -skew-x-12 text-acc0"
                  >
                    <span className="font-digi">Next</span>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Tag>
                </div>
              )}
            </div>
          </Card>
        </PopUp>
      </div>
    </>
  );
};

function SplicePage() {
  const appcon = useAppContext();
  const { s_acc_config, g_acc_config } = useAccountContext();
  const { psearch, upd_psearch } = appcon;

  const toback = psearch.toback;
  const [selbikes, set_selbikes] = useState({
    male: parseInt(psearch.male),
    female: parseInt(psearch.female),
  });

  const pair_params = {
    mother_coreid: selbikes.female,
    father_coreid: selbikes.male,
  };
  const [q_pair_validate] = useQueries([
    q_splicing_v02_enter_pair_validate(pair_params, {
      enabled: !nils(selbikes.male) && !nils(selbikes.female),
    }),
  ]);
  useEffect(() => {
    upd_psearch(selbikes);
  }, [jstr(selbikes)]);

  const [qo_pairinfo] = useQueries([
    q_splicing_v02_pair_info(
      {
        father_coreid: pair_params.father_coreid,
        mother_coreid: pair_params.mother_coreid,
      },
      { enabled: true },
    ),
  ]);
  const pairinfo = useMemo(() => {
    if (qissuccesss(qo_pairinfo)) return getv(qo_pairinfo, "data.result");
    return {};
  }, [qo_pairinfo.dataUpdatedAt]);
  const totfee = getv(pairinfo, "prices.tot.finfee");

  const scon = {
    selbikes,
    set_selbikes,
    q_pair_validate,
    pair_params,

    qo_pairinfo,
    pairinfo,
  };

  return (
    <SpliceContext.Provider value={scon}>
      <div className="h-page">
        <div className="max-w-[98vw] w-[60rem] mx-auto">
          <div className="h-[1rem]"></div>
          <div class="fr-sc mx-auto max-w-[40rem] w-full">
            <Link
              to={!nils(toback) ? base64_to_json(toback)?.url : "/splice-arena"}
            >
              <div class="md:h-[3rem] xs:h-[2rem] fc-cc aspect-[1/1] rounded-full border border-acc4 bg-acc4/20">
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  className="text-white resp-text-2 "
                />
              </div>
            </Link>
          </div>
          <div className="h-[1rem]"></div>

          <p className="text-center resp-text-2 font-digi">
            Select Core to Splice
          </p>
          <div className="h-[2rem]"></div>
          <div class="relative">
            {!nils(totfee) && (
              <div class="absolute top-0 left-[50%] translate-x-[-50%] font-mon resp-p-2 resp-text--1 text-acc4 border border-acc4 bg-acc4/20 rounded-md">
                {`Total Cost $${totfee}`}
              </div>
            )}
            <div className=" grid grid-cols-2 resp-gap-4">
              <TemplateBike gender={"male"} dir={"left"} />
              <TemplateBike gender={"female"} dir={"left"} />
            </div>
          </div>
          {/* <SpliceProceed ext={{ stage: 4, reqid: 9576 }} /> */}
          <Card className="w-full max-w-[40rem] my-4">
            {nils(selbikes.male) || nils(selbikes.female) ? (
              <>
                <p className="text-center text-acc0">
                  Please select both Parent Cores to proceed
                </p>
              </>
            ) : (
              <>
                {q_pair_validate.isLoading ? (
                  <Loader01c size="s" />
                ) : qiserr(q_pair_validate) ? (
                  <p className="text-center resp-text--1 text-red-400 ">
                    {qiserr(q_pair_validate)}
                  </p>
                ) : qissuccesss(q_pair_validate) ? (
                  <SpliceProceed />
                ) : (
                  <></>
                )}
              </>
            )}
          </Card>
        </div>
      </div>
    </SpliceContext.Provider>
  );
}

export default SplicePage;
