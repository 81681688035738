import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from "react-router";
import qs from "query-string";
import { createContext } from "react";
import { MetaMaskWrapper } from "./wrappers/MetaMaskWrapper.js";
import HomePage from "./views/HomePage.js";
import Layout, { clearSiteData } from "./components/Layout.js";
import Races from "./views/Races.js";
import AuthWrapper from "./wrappers/AuthWrapper.js";
import Login from "./views/Login.js";
import Vault from "./views/Vault.js";
import NoColor from "./wrappers/NoColor.js";
import AccountWrapper from "./wrappers/AccountWrapper.js";
import RacePage from "./views/RacePage.js";
import Leaderboard from "./views/Leaderboard.js";
import { useQueries } from "react-query";
import { q_token_prices } from "./queries/queries.js";
import { copy_clip, getv, jparse, jstr, nils } from "./utils/utils.js";
import Ledger from "./views/Ledger.js";
import { HelmetProvider, Helmet } from "react-helmet-async";
import BikePage from "./views/BikePage.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faSpinner,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import Jackpot from "./views/Jackpot.js";
import { FQual, FQualActives } from "./views/FQual.js";
import Ranks from "./views/Ranks.js";
import { Vault3D } from "./views/Vault3D.js";
import Races_Special from "./views/Races_Special.js";
import SpliceArena, { SpliceArenaPage } from "./views/SpliceArena.js";
import SplicePage from "./views/SplicePage.js";
import { RacesNext } from "./views/RacesNext.js";
import {
  HalloweenSkinTeamsListPage,
  HalloweenSkinTeamsPage,
} from "./views/HaloweenSkinTeams.js";
import { SatellitePage, Satellites } from "./views/Satellite.js";
import Allowance from "./views/Allowance.js";
import { SatelliteV2Page, SatellitesV2 } from "./views/SatellitesV2.js";
import { BikesPage } from "./views/BikesPage.js";
import { MarketListingsPage } from "./views/MarketListings.js";
import { ClearCache } from "./views/ClearCache.js";
import TrophiesPage from "./views/Trophies.js";
import { MaidenSatellitePage, MaidenSatellites } from "./views/Maiden.js";
import Swap_DEZ_WETH from "./views/Swap_DEZ_WETH.js";
import { FinishedRacesPage } from "./views/FinishedRacesPage.js";
import { ArcadeRace } from "./views/ArcadeRace.js";
import { CreatorsPage } from "./views/Creators.js";
import { SpliceActivity } from "./views/SpliceActivity.js";
import HelpPage from "./views/HelpPage.js";
import { Inbox } from "./views/Inbox.js";
import { ClaimTrainerPage } from "./views/ClaimTrainerPage.js";
import { StakingPage } from "./views/StakingPage.js";
import { Season0Page } from "./views/Season0Page.js";
import { ListRallyPage, RallyPage } from "./views/Rally.js";
import { TokenConverterPage } from "./views/TokenConverter.js";
import { AutoFillerPage } from "./views/AutoFiller.js";
import { LCTokenSwapPage } from "./views/LCTokenSwap.js";
import { Season1Page } from "./views/Season1Page.js";
import { SponsorPage } from "./views/SponsorPage.js";
import Factions from "./views/Factions/Factions.js";
import { TestPage } from "./views/TestPage.js";
import { DigHStimmyPage } from "./views/DigHStimmyPage.js";
import { ChainPlayPage } from "./views/ChainPlayPage.js";
import { Season2Page } from "./views/Season2Page.js";
import { Scoreboard } from "./views/Scoreboard.js";
import QuestSelectPage from "./views/Quest/QuestSelect.js";
import { TrainerLeaderboard } from "./views/TrainerLeaderboard.js";
import RacingDashboard from "./views/RacingDashboard.js";
import { QuestCards } from "./views/QuestCards.js";
import { ThirdWebLoginWrapper } from "./views/ThirdWebLogin.js";
import { TrailsCardPage, TrailsCardsListPage } from "./views/TrailsCards.js";
import { ErrorBoundary } from "./utils/errbou.js";
import { Tag } from "./components/utilityComps.js";
import { twMerge } from "tailwind-merge";
import { Season3Page } from "./views/Season3Page.js";
import { Season4Page } from "./views/Season4Page.js";
import { FactionsFighting } from "./views/Factions/FactionsFighting.js";
import {
  TrailsPointsLedgerPage,
  TrailsPointsStorePage,
} from "./views/TrailsPoints_Store.js";
import { Roadmap, RoadmapPage } from "./views/Roadmap.js";
import { OverlayWrapper } from "./wrappers/OverlayWrapper.js";
import { FingerPrintWrapper } from "./wrappers/FingerPrint.js";
import { ChallengePage, ChallengesPage } from "./views/Challenge.js";
import _ from "lodash";
import { Maintenence } from "./wrappers/Maintenence.js";
import { ReferralsPage } from "./views/Referrals.js";
import { Season5Page } from "./views/Season5Page.js";

export const AppContext = createContext({});
export const useAppContext = () => useContext(AppContext);

export const NowContext = createContext({ now: null });
export const useNowContext = () => useContext(NowContext);
// console.log("App.js");

const tokdecnmap = {
  WETH: 6,
  weth: 6,
  ETH: 6,
  DEZ: 0,
  USD: 2,
  BGC: 2,
  bgc: 2,
  "weth-mock01": 6,
  lc01: 6,
  lc02: 2,
  lc: 3,
  LC: 3,
};

export const tokdecn = (token) => tokdecnmap[token] ?? 2;
const tokedecn2map = {
  WETH: 3,
  weth: 3,
  ETH: 3,
  DEZ: 0,
  USD: 2,
  BGC: 2,
  bgc: 2,
  "weth-mock01": 3,
  lc01: 3,
  lc02: 2,
  lc: 2,
  LC: 2,
};
export const tokdecn2 = (token) => tokedecn2map[token] ?? 2;

const ErrorPage = ({ sendCrashReport, err_msg, err_stack, err_whole }) => {
  const [sent, set_sent] = useState(false);
  const [crashid, set_crashid] = useState(null);

  const path = window.location.pathname;

  return (
    <div class="h-page bg-pagebg text-white">
      <div class="h-[5rem]"></div>
      <div class="fc-cc max-w-[95vw] mx-auto">
        <p class="resp-text-2">OH NO! Something went wrong!</p>
        <p class="resp-text-1">Please try again later.</p>
        <hr class="min-w-[50%] mx-auto my-[1rem]" />
        <p class="resp-text-1">Or is just cache messing up</p>
        <div class="fc-cc resp-gap-2">
          <Tag
            onClick={() => {
              clearSiteData();
            }}
            className={"bg-red-500 -skew-x-12 font-digi"}
          >
            <span class="resp-text-1">Clear Cache</span>
          </Tag>

          <hr class="min-w-[50%] mx-auto my-[1rem]" />

          <p class="resp-text-1">Path</p>
          <p class="resp-text-1 text-white">{path}</p>
          <p class="resp-text-1">Error Message:</p>
          <p class="resp-text-1 text-red-400">{err_msg}</p>
          <hr class="min-w-[50%] mx-auto my-[1rem]" />
          <p class="resp-text-1">Seeing this message too much</p>
          <Tag
            onClick={async () => {
              if (sent) return;
              set_sent(1);
              let resp = await sendCrashReport();
              set_crashid(resp.id);
              set_sent(true);
            }}
            className={twMerge(
              "bg-red-400 -skew-x-12 font-digi",
              "fr-sc resp-gap-2",
              sent === 1
                ? "bg-transparent text-red-400 border border-red-400"
                : sent
                  ? "bg-transparent text-red-400 border border-red-400"
                  : "bg-red-400 text-white",
            )}
          >
            {sent === 1 ? (
              <div class="fr-sc resp-gap-1 resp-text-1">
                <FontAwesomeIcon className="spin-anim" icon={faSpinner} />
                <span>Sending...</span>
              </div>
            ) : sent ? (
              <div
                onClick={() => {
                  copy_clip(crashid);
                }}
                class="fr-sc resp-gap-1 resp-text-1"
              >
                <FontAwesomeIcon className="" icon={faCheck} />
                <span>Crash Report Sent id= {crashid} </span>
              </div>
            ) : (
              <div class="fr-sc resp-gap-1 resp-text-1">
                <span>Send Crash Report</span>
              </div>
            )}
          </Tag>
        </div>
      </div>
    </div>
  );
};

export const CrashWrapper = (props) => {
  return (
    <>
      <ErrorBoundary err_comp={<ErrorPage />}>{props.children}</ErrorBoundary>
    </>
  );
};

function App() {
  const history = useNavigate();
  const location = useLocation();
  const psearch = qs.parse(location.search);

  const upd_psearch = (upd) => {
    let se = qs.stringify({ ...psearch, ...upd }, { arrayFormat: "bracket" });
    history(`${location.pathname}?${se}`);
  };

  const [now, set_now] = useState(Date.now());
  useEffect(() => {
    setTimeout(() => set_now(Date.now()), 200);
  }, [now]);

  const [qo_tokpri] = useQueries([q_token_prices()]);
  const tokmap = useMemo(() => {
    let resp = getv(qo_tokpri, "data.result");
    if (_.isEmpty(resp)) resp = jparse(localStorage.getItem("tokmap"));
    else localStorage.setItem("tokmap", jstr(resp));
    // console.log("tokmap", resp);
    return resp;
  }, [qo_tokpri.dataUpdatedAt]);

  const tokkey = (c) =>
    c == "WETH"
      ? "ethusd"
      : c == "DEZ"
        ? "dezusd"
        : c == "BGC"
          ? "bgcusd"
          : c == "TP"
            ? "tpusd"
            : null;
  const tok_to_usd_val = (cost, token) => {
    let k = tokkey(token);
    // console.log("tok_to_usd_val", k, tokmap[k]);
    if (!k) return null;
    let tokp = tokmap[k];
    return parseFloat(tokp) * parseFloat(cost);
  };
  const usd_to_tok_val = (cost, token) => {
    let k = tokkey(token);
    if (!k) return null;
    let tokp = tokmap[k];
    return parseFloat(cost) / parseFloat(tokp);
  };
  useEffect(() => {
    let global_token = { tokmap, tok_to_usd_val, usd_to_tok_val };
    window.global_token = global_token;
  }, []);
  // }, [tokmap, usd_to_tok_val, tok_to_usd_val]);

  useEffect(() => {
    let ref = psearch?.ref;
    if (nils(ref)) return;
    localStorage.setItem("ref", ref);
    // console.log({ ref });
  }, [psearch?.ref]);

  const appcon = {
    history,
    location,
    path: location.pathname,
    psearch,
    upd_psearch,

    tokmap,
    tok_to_usd_val,
    usd_to_tok_val,
  };

  const maintenance = false;

  useEffect(() => {
    let body = document.querySelector("body");
    const fn = (e) => {
      if (e.altKey && e.code == "KeyC") {
        let href = window.location.href;
        href = href.replace(
          window.location.origin,
          "https://fbike.dnaracing.run",
        );
        copy_clip(href);
      }
    };
    document.addEventListener("keydown", fn);
    return () => {
      document.removeEventListener("keydown", fn);
    };
  }, []);
  let a = null;
  return (
    <div className="App text-white">
      <div className="h-1"></div>
      <>
        <NowContext.Provider value={{ now }}>
          <AppContext.Provider value={appcon}>
            <MetaMaskWrapper>
              <ThirdWebLoginWrapper>
                <AuthWrapper>
                  <FingerPrintWrapper>
                    <AccountWrapper>
                      <>
                        <OverlayWrapper>
                          <Layout>
                            <HelmetProvider>
                              <Helmet>
                                <title>{"FBike || DNA Racing"}</title>
                              </Helmet>
                              {maintenance ? (
                                <div className="fc-cc text-red-300 my-[30vh] resp-gap-2">
                                  <FontAwesomeIcon
                                    className="text-[3rem]"
                                    icon={faTriangleExclamation}
                                  />
                                  <p className="resp-text-2">
                                    App Under Maintenance
                                  </p>
                                  <p className="resp-text-0">
                                    We will be back soon
                                  </p>
                                </div>
                              ) : (
                                <Routes>
                                  <Route
                                    path="/clear-cache"
                                    exact
                                    element={<ClearCache />}
                                  />
                                  <Route
                                    path="/allowance"
                                    exact
                                    element={<Allowance />}
                                  />
                                  <Route
                                    path="/races"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <Races />
                                      </CrashWrapper>
                                    }
                                  />
                                  <Route
                                    path="/races-special-class"
                                    exact
                                    element={<Races_Special />}
                                  />
                                  <Route
                                    path="/races-next"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <RacesNext />
                                      </CrashWrapper>
                                    }
                                  />
                                  <Route
                                    path="/races-finished"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <FinishedRacesPage />
                                      </CrashWrapper>
                                    }
                                  />
                                  <Route
                                    path="/race/:rid"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <RacePage />
                                      </CrashWrapper>
                                    }
                                  />

                                  <Route
                                    path="/racing-dash/:vault"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <RacingDashboard />
                                      </CrashWrapper>
                                    }
                                  />
                                  <Route
                                    path="/vault/:vault"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <Vault />
                                      </CrashWrapper>
                                    }
                                  />
                                  <Route
                                    path="/my-vault-3d"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <Vault3D />
                                      </CrashWrapper>
                                    }
                                  />
                                  <Route
                                    path="/market-listings"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <MarketListingsPage />
                                      </CrashWrapper>
                                    }
                                  />
                                  <Route
                                    path="/cores"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <BikesPage />
                                      </CrashWrapper>
                                    }
                                  />
                                  <Route
                                    path="/bike/:hid"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <BikePage />
                                      </CrashWrapper>
                                    }
                                  />
                                  <Route
                                    path="/ledger"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <Ledger />
                                      </CrashWrapper>
                                    }
                                  />
                                  <Route
                                    path="/jackpot"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <Jackpot />
                                      </CrashWrapper>
                                    }
                                  />
                                  <Route
                                    path="/fqual/:fid"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <FQual />
                                      </CrashWrapper>
                                    }
                                  />
                                  <Route
                                    path="/fqual"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <FQualActives />
                                      </CrashWrapper>
                                    }
                                  />
                                  <Route
                                    path="/scoreboard"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <Scoreboard />
                                      </CrashWrapper>
                                    }
                                  />

                                  <Route
                                    path="/ranks"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <Ranks />
                                      </CrashWrapper>
                                    }
                                  />
                                  <Route
                                    path="/splice-arena"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <Maintenence maintenance={false}>
                                          <SpliceArenaPage />
                                        </Maintenence>
                                      </CrashWrapper>
                                    }
                                  />
                                  <Route
                                    path="/splice-activity"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <SpliceActivity />
                                      </CrashWrapper>
                                    }
                                  />
                                  <Route
                                    path="/splice"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <Maintenence maintenance={false}>
                                          <SplicePage />
                                        </Maintenence>
                                      </CrashWrapper>
                                    }
                                  />
                                  <Route
                                    path="/satellites"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <Satellites />
                                      </CrashWrapper>
                                    }
                                  />
                                  <Route
                                    path="/satellite/:mega_rid"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <SatellitePage />
                                      </CrashWrapper>
                                    }
                                  />
                                  <Route
                                    path="/satellitesv2/:satid"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <SatelliteV2Page />
                                      </CrashWrapper>
                                    }
                                  />
                                  <Route
                                    path="/satellitesv2"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <SatellitesV2 />
                                      </CrashWrapper>
                                    }
                                  />
                                  <Route
                                    path="/maiden/:satid"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <MaidenSatellitePage />
                                      </CrashWrapper>
                                    }
                                  />

                                  <Route
                                    path="/swap-dez-weth"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <Swap_DEZ_WETH />
                                      </CrashWrapper>
                                    }
                                  />

                                  <Route
                                    path="/trophies/:vault"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <TrophiesPage />
                                      </CrashWrapper>
                                    }
                                  />

                                  <Route
                                    path="/arcaderace/:rid"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <RacePage />
                                      </CrashWrapper>
                                    }
                                  />
                                  <Route
                                    path="/arcaderace"
                                    exact
                                    element={<ArcadeRace />}
                                  />

                                  <Route
                                    path="/creators"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <CreatorsPage />
                                      </CrashWrapper>
                                    }
                                  />

                                  <Route
                                    path="/help"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <HelpPage />
                                      </CrashWrapper>
                                    }
                                  />
                                  <Route
                                    path="/inbox"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <Inbox />
                                      </CrashWrapper>
                                    }
                                  />
                                  <Route
                                    path="/staking/:vault"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <StakingPage />
                                      </CrashWrapper>
                                    }
                                  />
                                  <Route
                                    path="/claim-trainer"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <ClaimTrainerPage />
                                      </CrashWrapper>
                                    }
                                  />

                                  <Route
                                    path="/trainer-leaderboard"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <TrainerLeaderboard />
                                      </CrashWrapper>
                                    }
                                  />

                                  <Route
                                    path="/roadmap"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <RoadmapPage />
                                      </CrashWrapper>
                                    }
                                  />

                                  {/* sheet pages */}
                                  <Route
                                    path="/season0-page"
                                    exact
                                    element={<Season0Page />}
                                  />
                                  <Route
                                    path="/season1-page"
                                    exact
                                    element={<Season1Page />}
                                  />
                                  <Route
                                    path="/season2-page"
                                    exact
                                    element={<Season2Page />}
                                  />
                                  <Route
                                    path="/season3-page"
                                    exact
                                    element={<Season3Page />}
                                  />
                                  <Route
                                    path="/season4-page"
                                    exact
                                    element={<Season4Page />}
                                  />
                                  <Route
                                    path="/season5-page"
                                    exact
                                    element={<Season5Page />}
                                  />

                                  <Route
                                    path="/chainplay"
                                    exact
                                    element={<ChainPlayPage />}
                                  />
                                  <Route
                                    path="/rally/:ralid"
                                    exact
                                    element={<RallyPage />}
                                  />
                                  <Route
                                    path="/rally"
                                    exact
                                    element={<ListRallyPage />}
                                  />

                                  <Route
                                    path="/token-converter"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <TokenConverterPage />
                                      </CrashWrapper>
                                    }
                                  />

                                  <Route
                                    path="/races-auto-filler"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <AutoFillerPage />
                                      </CrashWrapper>
                                    }
                                  />

                                  <Route
                                    path="/lc-token-swap"
                                    exact
                                    element={<LCTokenSwapPage />}
                                  />

                                  <Route
                                    path="/sponsor-package"
                                    exact
                                    element={<SponsorPage />}
                                  />

                                  <Route
                                    path={`/factions/:vault`}
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <Factions />
                                      </CrashWrapper>
                                    }
                                  />

                                  <Route
                                    path={`/factions-fighting`}
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <FactionsFighting />
                                      </CrashWrapper>
                                    }
                                  />

                                  <Route
                                    path={`/digh_stimmy`}
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <DigHStimmyPage />
                                      </CrashWrapper>
                                    }
                                  />

                                  <Route
                                    path="/quest-enter"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <QuestSelectPage />
                                      </CrashWrapper>
                                    }
                                  />

                                  <Route
                                    path="/quest-cards"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <QuestCards />
                                      </CrashWrapper>
                                    }
                                  />

                                  <Route
                                    path="/trails-cards"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <TrailsCardsListPage />
                                      </CrashWrapper>
                                    }
                                  />

                                  <Route
                                    path="/trails-cards/:id"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <TrailsCardPage />
                                      </CrashWrapper>
                                    }
                                  />

                                  <Route
                                    path="/trailspoints-ledger"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <TrailsPointsLedgerPage />
                                      </CrashWrapper>
                                    }
                                  />

                                  <Route
                                    path="/trailspoints-store"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <TrailsPointsStorePage />
                                      </CrashWrapper>
                                    }
                                  />

                                  <Route
                                    path="/challenge"
                                    exact
                                    element={<ChallengesPage />}
                                  />

                                  <Route
                                    path="/challenge/:id"
                                    exact
                                    element={<ChallengePage />}
                                  />

                                  <Route
                                    path="/referrals"
                                    exact
                                    element={<ReferralsPage />}
                                  />

                                  <Route
                                    path="/test"
                                    exact
                                    element={<TestPage />}
                                  />
                                  <Route
                                    path="/"
                                    exact
                                    element={
                                      <CrashWrapper>
                                        <HomePage />
                                      </CrashWrapper>
                                    }
                                  />
                                </Routes>
                              )}
                            </HelmetProvider>
                          </Layout>
                        </OverlayWrapper>
                      </>
                    </AccountWrapper>
                  </FingerPrintWrapper>
                </AuthWrapper>
              </ThirdWebLoginWrapper>
            </MetaMaskWrapper>
            <NoColor />
          </AppContext.Provider>
        </NowContext.Provider>
      </>
    </div>
  );
}

export default App;
