import React, { useEffect, useMemo, useRef } from "react";
import { twMerge } from "tailwind-merge";
import { Card, Tag } from "./utilityComps.js";
import {
  tokenaumode_locpath,
  useMetaMaskContext,
} from "../wrappers/MetaMaskWrapper.js";
import { useAppContext } from "../App.js";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fa1,
  faAngleDown,
  faArrowsUpDown,
  faBagShopping,
  faBank,
  faBars,
  faBox,
  faBoxOpen,
  faCheck,
  faCheckSquare,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCoins,
  faDna,
  faEnvelopesBulk,
  faFire,
  faFlag,
  faFlagCheckered,
  faGear,
  faGhost,
  faHandFist,
  faInbox,
  faMarker,
  faMessage,
  faPen,
  faPerson,
  faQuestionCircle,
  faRankingStar,
  faSatellite,
  faSatelliteDish,
  faSignOut,
  faSquare,
  faStar,
  faSwatchbook,
  faSync,
  faTicket,
  faTrash,
  faTrophy,
  faUpDown,
  faUser,
  faVault,
  faVrCardboard,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { motion } from "framer-motion";
import { getv, jstr, nils } from "../utils/utils.js";
import { AccountBalanceTag } from "../views/Ledger.js";
import { JackpotNav } from "../views/Jackpot.js";
import { Mo, MoVariants } from "../utils/motion_helper.js";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import _ from "lodash";
import { useQueries } from "react-query";
import {
  q_pagebanner,
  qissuccesss,
  q_inbox_actions,
  q_inbox_mark_read,
} from "../queries/queries.js";
import { faHackerNews } from "@fortawesome/free-brands-svg-icons";
import Login from "../views/Login.js";
import { PopUp, PopupCloseBtn } from "./popup.js";
import { InboxMsgCard } from "../views/Inbox.js";
import { set_state_ob } from "./input.js";
import { SMCloseBtn } from "../views/Races.js";
import {
  ThridWebLoginSection,
  useThirdWebLoginContext,
} from "../views/ThirdWebLogin.js";
import { AutoConnect } from "thirdweb/react";
import { HelpPop } from "./HelpPop.js";
import { roadmap_show_link, RoadmapBackLink } from "../views/Roadmap.js";
// export const logoimg = "https://i.imgur.com/ngmPy1x.png";
export const logoimg = "/dna_logo.png";

const portal = "https://www.dnaracing.run";

export const clearSiteData = async () => {
  // Clear localStorage
  let savedkeys = [
    "jwt_auth_token",
    "jwt_auth_account",
    "jwt_auth_aumode",
    "jwt_auth_auth",
  ];
  let save = savedkeys.map((k) => [k, localStorage.getItem(k)]);
  localStorage.clear();

  for (let [k, v] of save) {
    if (!nils(v)) localStorage.setItem(k, v);
  }

  // Clear cookies
  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });

  // Clear IndexedDB (example for a database named 'myDatabase')
  const req = indexedDB.deleteDatabase("gltfCache");
  req.onsuccess = function () {
    console.log("Deleted database successfully");
  };
  req.onerror = function () {
    console.log("Couldn't delete database");
  };
  req.onblocked = function () {
    console.log("Couldn't delete database due to the operation being blocked");
  };

  setTimeout(() => {
    window.location.reload();
  }, 3000);
};

export const Logo = ({ title = "", title2 = "" }) => {
  return (
    <div className="fr-cc">
      <div className="xs:w-[8rem] lg:w-[15rem] aspect-[1879/504]  xs:ml-1 lg:ml-4 resp-p-2">
        <img className="h-full object-contain" src={logoimg} alt="" />
      </div>
    </div>
  );
};

const NavLogo = () => {
  const aucon = useAuthContext();
  return (
    <div class="layout-navlogo">
      <Link to={aucon?.auth === true ? "/races" : "/"}>
        <Logo />
      </Link>
    </div>
  );
};

const AccountDrop = ({ options, size = "lg" }) => {
  const aucon = useAuthContext();
  const { vault } = aucon;

  const vault_name = getv(aucon, "vaultdoc.vault_name");

  const [hover, set_hover] = useState(false);

  const linkcn = "text-white hover:text-acc0 resp-py-1 resp-px-2 resp-text-1";

  return (
    <div
      className="cursor-pointer"
      {...{
        ...(size == "lg" ? { onMouseLeave: () => set_hover(false) } : {}),
        ...(size == "xs" ? { onClick: () => set_hover(!hover) } : {}),
      }}
    >
      <div
        {...{
          ...(size == "lg" ? { onMouseEnter: () => set_hover(true) } : {}),
          ...(size == "xs" ? {} : {}),
        }}
        className="fr-cc resp-mx-4 resp-gap-1 relative"
      >
        <div className="xs:w-[1.5rem] xs:h-[1.5rem] lg:w-[3rem] lg:h-[3rem] bg-dark fc-cc rounded-full">
          <FontAwesomeIcon className="text-acc0 resp-text-1" icon={faUser} />
        </div>
      </div>
      <motion.div
        variants={MoVariants.show_hide}
        animate={hover ? "visible" : "hidden"}
        className="absolute min-w-[15rem] w-[15rem] bg-r2dark/80 backdrop-blur-md m-1 border border-acc4 top-[3rem] right-0 rounded-md resp-p-4 z-30"
      >
        <p className="font-digi text-acc0 resp-py-1 resp-px-2 mb-2 resp-text-1">
          {vault_name}
        </p>
        <hr />
        <div class="xs:h-[60vh] lg:h-auto overflow-auto">
          {options.map(([to, txt, cn, fn, ico]) => {
            return (
              <div className="w-full resp-py-2">
                <Link
                  onClick={() => {
                    if (fn) fn();
                  }}
                  to={to}
                  className={twMerge(cn, "fr-sc resp-gap-2")}
                >
                  <FontAwesomeIcon className="w-[1rem]" icon={ico} />
                  <span>{txt}</span>
                </Link>
              </div>
            );
          })}
        </div>
      </motion.div>
    </div>
  );
};

const linkcn = "hover:text-acc0 font-mon resp-px-2 resp-text-0 fr-sc h-full";
const smlinkcn = "hover:text-acc0 font-digi resp-px-2 resp-text-2";

const SM_DropLink = ({ txt, links, set_smshow }) => {
  const dropcn = `smdroplink-${txt}`;
  const [show, set_show] = useState(false);

  return (
    <div className={twMerge(dropcn, "w-max h-full cursor-pointer relative")}>
      <div
        onClick={() => {
          set_show(!show);
        }}
        className={twMerge(smlinkcn, "fr-cc resp-gap-1")}
      >
        <span>{txt}</span>
        <FontAwesomeIcon
          className={twMerge(smlinkcn, show ? "rotate-180" : "")}
          icon={faAngleDown}
        />
      </div>
      <motion.div
        variants={MoVariants.show_hide}
        className=""
        animate={show ? "visible" : "hidden"}
      >
        <div class="fc-cc resp-p-1 resp-gap-1 rounded-lg bg-r2lig/20 backdrop-blur-md">
          {links.map(([to, txt, cn, fn, ico], li) => {
            return (
              <div className="w-full  resp-py-2">
                <Link
                  onClick={() => {
                    set_smshow(false);
                  }}
                  to={to}
                  className={twMerge(
                    `link-${li}`,
                    cn,
                    smlinkcn,
                    "fr-sc resp-gap-2",
                  )}
                >
                  <FontAwesomeIcon className="w-[1rem]" icon={ico} />
                  <span>{txt}</span>
                </Link>
              </div>
            );
          })}
        </div>
      </motion.div>
    </div>
  );
};

const DropLink = ({ txt, links }) => {
  const dropcn = `droplink-${txt}`;
  const dropref = useRef(null);

  const [hover, set_hover] = useState(false);
  const history = useNavigate();

  const linkcn = "hover:text-acc4 font-mon resp-px-2 resp-text-0 fr-sc h-full";
  return (
    <div
      ref={dropref}
      onMouseLeave={() => {
        set_hover(false);
      }}
      className={twMerge(dropcn, "w-max h-full cursor-pointer relative")}
    >
      <div
        onMouseEnter={() => {
          set_hover(true);
        }}
        onClick={() => {
          let url = links[0][0];
          history(url);
        }}
        className={twMerge("main-link", linkcn, "fr-sc resp-gap-1")}
      >
        <span className="resp-text-0 font-reg">{txt}</span>
        <FontAwesomeIcon
          className={twMerge(
            "text-slate-600 resp-text--1 transition duration-300",
            hover ? "rotate-180" : "",
          )}
          icon={faAngleDown}
        />
      </div>
      <motion.div
        variants={MoVariants.show_hide}
        animate={hover ? "visible" : "hidden"}
        className="absolute min-w-[15rem] w-[15rem] bg-r2lig/40 backdrop-blur-md top-[3rem] left-0 rounded-md z-30"
      >
        <div className="border border-acc0 rounded-md p-[0.2rem]">
          <div className="resp-p-4 ">
            {links.map(([to, txt, cn, fn, ico]) => {
              return (
                <div className="w-full resp-py-2">
                  <Link
                    onClick={() => {
                      if (fn) fn();
                      set_hover(false);
                    }}
                    to={to}
                    className={twMerge(cn, "fr-sc resp-gap-2")}
                  >
                    <FontAwesomeIcon className="w-[1rem]" icon={ico} />
                    <span>{txt}</span>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export const InfoBanner = () => {
  const [i, set_i] = useState(0);
  const timeref = useRef(null);
  useEffect(() => {
    if (timeref.current) clearTimeout(timeref.current);
    timeref.current = setTimeout(() => {
      set_i(i + 1);
    }, 4000);
    return () => {
      clearTimeout(timeref.current);
    };
  }, [i]);

  const prev = () => {
    set_i(Math.max(0, i - 1));
  };
  const next = () => {
    set_i(i + 1);
  };

  const [q] = useQueries([q_pagebanner()]);
  const banner = useMemo(() => {
    if (qissuccesss(q)) return getv(q, "data.result");
    else return [];
  }, [q.dataUpdatedAt]);

  const idx = useMemo(() => {
    let n = 1 + (banner?.length ?? 0);
    return i % n;
  }, [i, banner?.length]);

  return (
    <div
      className={twMerge(
        "w-max mx-auto lg:w-[25rem] resp-text--2 rounded-full bg-r2reg/20 border border-acc0",
        "fr-sc resp-gap-2 px-4 min-h-[5rem]",
      )}
    >
      <div onClick={prev}>
        <FontAwesomeIcon
          onClick={prev}
          className="p-1 cursor-pointer"
          icon={faChevronLeft}
        />
      </div>
      <div className="flex-1">
        {idx == 0 && <JackpotNav />}
        {!_.isEmpty(banner) && (
          <p className="text-acc0 text-center whitespace-pre-wrap">
            {banner[idx - 1]}
          </p>
        )}
      </div>
      <div onClick={next}>
        <FontAwesomeIcon className="p-1 cursor-pointer" icon={faChevronRight} />
      </div>
    </div>
  );
};

const InboxTag = () => {
  const { path } = useAppContext();

  const lcon = useLayoutContext();
  const { qo_inbox_actions } = lcon;

  const { vault } = useAuthContext();
  // const resp_actions = useMemo(() => {
  //   return getv(qo_inbox_actions, "data.result") ?? [];
  // }, [qo_inbox_actions.dataUpdatedAt]);

  const [actions, set_actions] = useState([]);
  // useEffect(() => {
  //   if (jstr(actions) != jstr(resp_actions)) set_actions(resp_actions);
  // }, [jstr(resp_actions)]);

  useEffect(() => {
    let resp = getv(qo_inbox_actions, "data.result") ?? [];
    set_actions(resp);
  }, [qo_inbox_actions.dataUpdatedAt]);

  const [popup, set_popup] = useState(false);
  const [i, set_i] = useState(0);

  const open_popup = () => {
    set_popup(true);
    set_i(0);
  };

  const close_popup = () => {
    set_popup(false);
  };

  useEffect(() => {
    if (actions.length > 0) setTimeout(open_popup, 3000);
  }, [jstr(actions)]);

  const prev = () => set_i(i == 0 ? actions.length - 1 : Math.max(0, i - 1));
  const next = () => set_i((i + 1) % actions.length);

  const linkcn = "fc-cc resp-p-2 transition duration-200 cursor-pointer";

  const i_read = useMemo(() => {
    if (_.isEmpty(actions)) return false;
    return getv(actions, `${i}.read`) ?? false;
  }, [jstr(actions)]);
  useEffect(() => {
    if (_.isEmpty(actions)) close_popup();
    set_i(Math.min(i, actions.length - 1));
  }, [i, popup, jstr(actions)]);

  const mark_inbox_read = async (i) => {
    try {
      let ac = getv(actions, `${i}`);
      let { id } = ac;

      let nactions = _.map(actions, (e, eidx) => {
        if (eidx == i) return { ...e, read: true };
        else return e;
      });
      // set_state_ob(actions, set_actions, `${i}.read`, true);
      set_actions(nactions);

      setTimeout(() => {
        set_actions(_.filter(nactions, (e) => e?.read !== true));
      }, 500);

      let resp = await q_inbox_mark_read(
        ac.is_acc == true
          ? { id, is_acc: ac.is_acc, accid: ac.accid, read: true, vault }
          : { id, read: true },
      ).queryFn();
      // console.log(resp);
      setTimeout(qo_inbox_actions.refetch, 10 * 1e3);
      if (nactions.length <= 1) close_popup();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Link to="/inbox">
        <div className="relative resp-p-2 bg-dark hover:bg-lig transition duration-200 rounded-md">
          <FontAwesomeIcon icon={faMessage} className="resp-text-2" />
          <div
            className={twMerge(
              "absolute resp-text--4 bottom-0 right-0 bg-acc1 transform translate-x-[50%] translate-y-[50%] fc-cc xs:w-[0.8rem] xs:h-[0.8rem] lg:w-[1.2rem] lg:h-[1.2rem]  resp-p-1 rounded-full",
              !_.isEmpty(actions) ? "bg-acc1 opacity-100" : "opacity-0",
            )}
          >
            <span>
              {qo_inbox_actions.isLoading ? "-" : (actions.length ?? 0)}
            </span>
          </div>
        </div>
      </Link>

      <PopUp
        wrapcn={"top-[6rem]"}
        innercn={"translate-y-[0%]"}
        {...{ openstate: popup, overlayclose: true, onClose: close_popup }}
      >
        <Card className="caret-dark-bg backdrop-blur-md border border-acc4 w-[50rem] max-w-[95vw] xs:h-[70vh] lg:h-[70vh]">
          <div className="fr-sc">
            <div className="flex-1"></div>
            <PopupCloseBtn closepopup={close_popup} />
          </div>
          <div className="flex flex-row justify-start items-stretch max-w-full">
            <div
              className={twMerge(linkcn, i == 0 ? "opacity-0" : "")}
              onClick={prev}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            <div className="flex-1 xs:min-h-[30vh] lg:min-h-[40vh] xs:h-[30vh] lg:h-[40vh] oveflow-auto resp-p-2">
              <InboxMsgCard idoc={actions[i]} />
            </div>
            <div
              className={twMerge(
                linkcn,
                i + 1 == actions.length ? "opacity-0" : "",
              )}
              onClick={next}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </div>

          <div className="fr-sc resp-gap-2 my-1">
            <div className="flex-1"></div>
            <Tag
              onClick={() => {
                mark_inbox_read(i);
              }}
              className={twMerge("fr-sc resp-gap-2 cursor-pointer")}
            >
              <FontAwesomeIcon icon={i_read ? faCheckSquare : faSquare} />
              <span className="resp-text--1">Mark as Read</span>
            </Tag>
          </div>

          <div className="fr-cc resp-gap-2 resp-text--1 font-digi text-acc0">
            <span>{i + 1}</span>
            <span>/</span>
            <span>{actions.length}</span>
            <span></span>
          </div>
        </Card>
      </PopUp>
    </>
  );
};

const Nav = ({ loginpop, set_loginpop }) => {
  const { path, psearch, history } = useAppContext();
  const mmcon = useMetaMaskContext();
  const aucon = useAuthContext();
  const accon = useAccountContext();
  const tcon = useThirdWebLoginContext();
  const lcon = useLayoutContext();

  const { aumode, auth } = aucon;
  const { vault, vdoc } = accon;
  const vstar = getv(vdoc, "vstar", 0);
  const { helppop, set_helppop, help_override_path } = lcon;

  const activecn = "border-b border-acc0 text-acc0";

  const droplinks = [
    ...(roadmap_show_link({ vstar, psearch, path })
      ? [
          [
            "Roadmap",
            [
              [
                "/trainer-leaderboard?tab=roadmap",
                "Roadmap",
                linkcn,
                null,
                faFlag,
              ],
            ],
          ],
        ]
      : []),
    [
      "Races",
      [
        [`/races`, "Main Races", linkcn, null, faFlag],
        [`/races-auto-filler`, "AutoFiller", linkcn, null, faEnvelopesBulk],
        // [`/satellites`, "Satellites", linkcn, null, faSatelliteDish],
        [`/satellitesv2`, "SatellitesV2", linkcn, null, faSatelliteDish],
        ["/quest-enter", "Quest", linkcn, null, faFlag],
        [`/trainer-leaderboard`, "Trainer", linkcn, null, faFlag],
        // [`/maiden`, "Maiden", linkcn, null, faHackerNews],
        // [
        //   `/races-special-class`,
        //   "Special Races",
        //   linkcn,
        //   null,
        //   faFlagCheckered,
        // ],
        [`/races-next`, "Next To Run", linkcn, null, faFlagCheckered],
        [`/races-finished`, "Finished Races", linkcn, null, faFlagCheckered],
      ],
    ],
    [
      "Events",
      [
        [`/fqual`, "Active Events", linkcn, null, faTrophy],
        [`/challenge`, "Challenge", linkcn, null, faTrophy],
        [`/scoreboard`, "Scoreboard", linkcn, null, faStar],
        [`/ranks`, "Ranks Leaderboard", linkcn, null, faRankingStar],
        [`/rally`, "Rally", linkcn, null, faTrophy],
      ],
    ],
    [
      "Vault",
      [
        [`/vault/${vault}`, "My Vault", linkcn, null, faVault],
        [`/my-vault-3d`, "My Vault 3D", linkcn, null, faVrCardboard],
        [`/racing-dash/${vault}`, "Racing Dashboard", linkcn, null, faVault],
        [`/roadmap`, "Roadmap", linkcn, null, faVault],
        [`/trailspoints-store`, "TP Store", linkcn, null, faBagShopping],
        [`/quest-cards`, "Quest Cards", linkcn, null, faBagShopping],
        [`/trails-cards`, "Trails Cards", linkcn, null, faBagShopping],
        [`/staking/${vault}`, "Staking", linkcn, null, faCoins],
        [`/trophies/${vault}`, "My Trophies", linkcn, null, faTrophy],
        [`/ledger/`, "Races Ledger", linkcn, null, faBank],
        [
          `/splice-activity/?vault=${vault}`,
          "Splice Ledger",
          linkcn,
          null,
          faGear,
        ],
        [
          `https://www.dnaracing.run/bikeburn?tab=elig`,
          "Core Burn",
          linkcn,
          null,
          faFire,
        ],
        [`${portal}/prizebox`, "Prizebox", linkcn, null, faBoxOpen],
        [`${portal}/tp_giftcard`, "TP Gift Cards", linkcn, null, faTicket],
        [`/referrals`, "Referrals", linkcn, null, faTicket],
      ],
    ],
    [
      "Factions",
      [
        [`/factions/${vault}`, "Factions", linkcn, null, faVault],
        [`/factions-fighting`, "Fighting", linkcn, null, faHandFist],
        [`${portal}/factions`, "Agent Mint", linkcn, null, faVault],
        [`${portal}/seasonpass`, "Season Pass", linkcn, null, faTicket],
      ],
    ],
  ];

  const links = [
    ["/cores", "Cores", linkcn, () => {}, faDna],
    ["/splice-arena", "Splicing", linkcn, () => {}, faDna],
    // ["/market-listings", "Market", linkcn, () => {}, faMarker],
    [`https://market.dnaracing.run/`, "Market", linkcn, null, faCircle],
  ];

  const whole_logout = async () => {
    await tcon.logout();
    await aucon.logout();
  };

  const options = [
    ["/lc-token-swap", "Swap LC/WETH", linkcn, null, faArrowsUpDown],
    ["/swap-dez-weth", "Swap DEZ/WETH", linkcn, null, faArrowsUpDown],
    ["/token-converter", "DEZ to BGC", linkcn, null, faArrowsUpDown],
    ["/jackpot", "JackPot", linkcn, null, faBox],
    ["/allowance", "Token Allowances", linkcn, null, faCheck],
    [`/ledger`, "Ledger", linkcn, null, faBank],
    [`/creators`, "Creators", linkcn, null, faPen],
    ["/settings", "Settings", linkcn, null, faGear],
    ["/help", "User Guide", linkcn, null, faQuestionCircle],
    ["/inbox", "Inbox", linkcn, null, faMessage],
    [
      null,
      "clear cache",
      twMerge(linkcn, "text-orange-300"),
      clearSiteData,
      faTrash,
    ],
    [null, "Logout", twMerge(linkcn, "text-red-300"), whole_logout, faSignOut],
  ];

  const noauth_links = [
    ["/cores", "Cores", linkcn, () => {}, faDna],
    [`https://market.dnaracing.run/`, "Market", linkcn, null, faCircle],
    ["/help", "Help", linkcn, null, faQuestionCircle],
    ["/claim-trainer", "Claim Free Trainer", linkcn, null, faQuestionCircle],
  ];

  const [smshow, set_smshow] = useState(false);
  const show_t3login = !(aumode == "wallet" && auth == true);
  // console.log("show_t3login", show_t3login);

  return (
    <>
      <div class="large-screen xs:hidden lg:block">
        <div class="bg-pagebg/50 h-[4.4rem] fr-sc">
          <NavLogo />
          <div className="flex-1"></div>
          {aucon.auth ? (
            <>
              <div class="w-[6rem]"></div>
              {droplinks.map(([txt, links]) => {
                return <DropLink {...{ key: txt, txt, links }} />;
              })}
              {links.map(([to, txt]) => {
                let target = to && to.startsWith("/") ? "_self" : "_blank";
                return (
                  <Link
                    key={to}
                    to={to}
                    target={target}
                    className={twMerge(linkcn, path == to ? activecn : "")}
                  >
                    <span>{txt}</span>
                  </Link>
                );
              })}
            </>
          ) : (
            <>
              {noauth_links.map(([to, txt]) => {
                let target = to && to.startsWith("/") ? "_self" : "_blank";
                return (
                  <Link
                    key={to}
                    to={to}
                    target={target}
                    className={twMerge(linkcn, path == to ? activecn : "")}
                  >
                    <span>{txt}</span>
                  </Link>
                );
              })}
            </>
          )}
          <div className="flex-1"></div>
          <HelpPop
            {...{
              pop: helppop,
              set_pop: set_helppop,
              overide_path: help_override_path,
            }}
          />

          {aucon.auth !== true && aumode === "thirdweb" && (
            <>
              <Tag
                onClick={() => {
                  tcon.on_connect_fn(tcon.active_wallet);
                }}
                className={twMerge(
                  "fr-sc resp-gap-1 bg-orange-400/20 text-orange-500",
                )}
              >
                <FontAwesomeIcon icon={faSync} className="" />
                <div class="resp-text--1">Retry</div>
              </Tag>
            </>
          )}

          {show_t3login && (
            <ThridWebLoginSection
              btntext="Login"
              postlogin={async () => {
                await aucon.auth_refresh();
              }}
            />
          )}

          <AutoConnect client={tcon.thirdweb_client} wallets={tcon.wallets} />

          {aucon.auth ? (
            <>
              <AccountBalanceTag />
              <div className="w-[1rem]"></div>
              <InboxTag />
              <AccountDrop options={options} />
            </>
          ) : (
            <>
              {false && (
                <div
                  onClick={() => {
                    set_loginpop(true);
                  }}
                  className=" cursor-pointer  mx-2 rounded-full bg-acc1 font-mon px-4 py-2"
                >
                  Login
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div class="small-screen xs:block lg:hidden">
        <div class="bg-pagebg/50 h-[4.4rem] fr-sc resp-gap-1 resp-px-2">
          <SM_MenuLayout
            show={smshow}
            set_show={set_smshow}
            trigger={
              <div class="resp-p-2">
                <FontAwesomeIcon
                  icon={faBars}
                  className="text-white resp-text-3"
                />
              </div>
            }
          >
            <div class="fc-cc resp-gap-1 resp-text-2">
              <div class="h-[4rem]"></div>
              {aucon.auth ? (
                <>
                  <Card class="w-[25rem] bg-r2lig/20 backdrop-blur-md border border-acc4 ">
                    <div class="fr-sc">
                      <div className="w-[3rem] h-[3rem] bg-r2dark fc-cc rounded-full">
                        <FontAwesomeIcon
                          className="text-acc0 resp-text-1"
                          icon={faUser}
                        />
                      </div>
                      <div class="fc-ss">
                        <span className="resp-text-2 px-4 text-r2lig">
                          <span>{getv(aucon, "vaultdoc.vault_name")}</span>
                        </span>
                        <div class="fr-sc resp-gap-2">
                          <div class="flex-1"></div>
                          <Tag
                            className="bg-orange-400/20 text-orange-500"
                            onClick={clearSiteData}
                          >
                            <div class="fr-sc resp-gap-1">
                              <FontAwesomeIcon icon={faTrash} />
                              <span className="resp-text--1">Clear Cache</span>
                            </div>
                          </Tag>
                          <Tag
                            className="bg-red-400/20 text-red-500"
                            onClick={whole_logout}
                          >
                            <div class="fr-sc resp-gap-1">
                              <FontAwesomeIcon icon={faSignOut} />
                              <span className="resp-text--1">Logout</span>
                            </div>
                          </Tag>
                        </div>
                      </div>
                    </div>
                  </Card>
                  {droplinks.map(([txt, links]) => {
                    return (
                      <SM_DropLink {...{ key: txt, txt, links, set_smshow }} />
                    );
                  })}
                  {links.map(([to, txt]) => {
                    let target = to && to.startsWith("/") ? "_self" : "_blank";
                    return (
                      <Link
                        onClick={() => {
                          set_smshow(false);
                        }}
                        key={to}
                        to={to}
                        target={target}
                        className={twMerge(
                          smlinkcn,
                          path == to ? activecn : "",
                        )}
                      >
                        <span>{txt}</span>
                      </Link>
                    );
                  })}
                  {options.slice(0, -2).map(([to, txt, cn, fn, ico]) => {
                    return (
                      <Link
                        onClick={() => {
                          set_smshow(false);
                        }}
                        to={to}
                        className={twMerge(smlinkcn)}
                      >
                        {txt}
                      </Link>
                    );
                  })}
                </>
              ) : (
                <>
                  {noauth_links.map(([to, txt]) => {
                    return (
                      <Link
                        onClick={() => {
                          set_smshow(false);
                        }}
                        to={to}
                        className={twMerge(smlinkcn)}
                      >
                        {txt}
                      </Link>
                    );
                  })}
                </>
              )}
            </div>
            <div className="h-[5rem]"> </div>
          </SM_MenuLayout>
          <NavLogo />
          <div class="flex-1"></div>

          {aucon.auth !== true && aumode === "thirdweb" && (
            <>
              <Tag
                onClick={() => {
                  tcon.on_connect_fn(tcon.active_wallet);
                }}
                className={twMerge(
                  "fr-sc resp-gap-1 bg-orange-400/20 text-orange-500",
                )}
              >
                <FontAwesomeIcon icon={faSync} className="" />
                <div class="resp-text--1">Retry</div>
              </Tag>
            </>
          )}

          <div class="scale-[0.5] max-w-[6rem] transform translate-x-[-2rem]">
            {show_t3login && (
              <ThridWebLoginSection
                btntext="Login"
                postlogin={async () => {
                  await aucon.auth_refresh();
                }}
              />
            )}
          </div>
          {aucon.auth ? (
            <AccountBalanceTag />
          ) : (
            <>
              {false && (
                <div
                  onClick={() => {
                    set_loginpop(true);
                  }}
                  className=" cursor-pointer mx-2 rounded-full bg-acc1 font-mon px-3 py-1"
                >
                  Login
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const LayoutContext = React.createContext();
export const useLayoutContext = () => React.useContext(LayoutContext);

const SM_MenuLayout = ({ trigger, show, set_show, ...props }) => {
  // const [show, set_show] = useState(true);
  const { auth } = useAuthContext();
  const { vault, vdoc } = useAccountContext();

  return (
    <>
      <div
        onClick={() => set_show(!show)}
        className={twMerge(twMerge("sm-nav-menu-trigger", "cursor-pointer"))}
      >
        {trigger}
      </div>
      <motion.div
        {...Mo.slide({ x: ["-100%", 0, "100%"] })}
        animate={show ? "visible" : "hidden"}
        transition={{ delay: show ? 0.1 : 0.25 }}
        className="z-[50] h-page fixed top-[4.4rem] left-0 w-full bg-r2dark"
      >
        <div class="h-full w-full bg-pagebg/50 backdrop-blur-md">
          <div class="h-[75vh] overflow-auto">{props.children}</div>
          <SMCloseBtn closefn={() => set_show(false)} extcn="smclose-nav" />
        </div>
      </motion.div>
    </>
  );
};

function Layout(props) {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const [loginpop, set_loginpop] = useState(false);
  const [helppop, set_helppop] = useState(psearch?.help == "true" ?? false);
  const [help_override_path, set_help_override_path] = useState(null);

  const { vault } = useAuthContext();
  const [qo_inbox_actions] = useQueries([
    q_inbox_actions(
      { vault },
      {
        enabled: !nils(vault),
        staleTime: 1 * 60 * 1e3,
        refetchInterval: 1 * 60 * 1e3,
      },
    ),
  ]);
  const laycon = {
    loginpop,
    set_loginpop,
    qo_inbox_actions,
    helppop,
    set_helppop,
    help_override_path,
  };
  return (
    <div className="relative">
      <LayoutContext.Provider value={laycon}>
        <Nav {...{ loginpop, set_loginpop }} />
        <main
          className={
            twMerge("relative box-border")
            // "md:max-w-[80rem] md:w-full mx-auto",
            // "xs:max-w-[95vw] mx-auto"
          }
        >
          {loginpop && (
            <div className="absolute z-[3000] top-[15vh] left-[50%] transform translate-x-[-50%] h-0">
              <Login onclose={() => set_loginpop(false)} />
            </div>
          )}
          <div class="absolute h-0 w-full top-[-12px] left-0 z-[4]">
            <div class="w-[60rem] max-w-[98vw] mx-auto fr-sc">
              <RoadmapBackLink />
            </div>
          </div>
          <>{props.children}</>
        </main>
      </LayoutContext.Provider>
    </div>
  );
}

export default Layout;
