import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuthContext } from "./AuthWrapper.js";
import {
  q_balance,
  q_bikeinfo,
  q_mm_weth_balance,
  q_mm_dez_balance,
  q_token_prices,
  q_vaultbikes,
  q_vaultinfo,
  qissuccess,
  useStepQuery,
  qissuccesss,
  q_vaultbikes_cc,
  q_bikeinfo_cac,
  q_vault_get_cprofiles,
  q_roadmap_progress,
  q_roadmap_cc,
  q_vault_settings_presetslist,
  q_vault_settings_save,
  q_vault_settings_delete,
  q_vault_settings_get,
  q_vaultbikesinf,
  q_bikes_stamina_map,
} from "../queries/queries.js";
import { useQueries } from "react-query";
import {
  cdelay,
  gen_hash,
  get_inp,
  getv,
  iso,
  iso_format,
  jparse,
  jstr,
  nils,
} from "../utils/utils.js";
import _, { isEmpty } from "lodash";
import { set_state_ob } from "../components/input.js";
import { get_dez_balance } from "../contracts/contract_funcs.js";
import {
  InpText,
  Tag,
  useDebouncedEffect,
} from "../components/utilityComps.js";
import { useThirdWebLoginContext } from "../views/ThirdWebLogin.js";
import colors from "tailwindcss/colors.js";
import { useEnvironment } from "@react-three/drei";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  def_roadmap_link,
  get_roadmap_status,
  roadmap_status_redid,
} from "../views/Roadmap.js";
import moment from "moment";
import { Loader01c } from "../components/anims.js";
import { twMerge } from "tailwind-merge";
import {
  faCircle,
  faDotCircle,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCoresFullData } from "../hooks/useCoresFullData.js";

export const AccountContext = createContext({});
export const useAccountContext = () => useContext(AccountContext);

const def_acc_config = {
  mode: "all",
  vaulthconfs: {},
};

export const cprofile_key = "use_cprofile";
export const mycprofile_key = "my_cprofile";
export const default_cprofile = {
  hash: "cb6a3d2a22",
  pagebg: { r: 0, g: 0, b: 0, hex: "#1C2025" },
  dark: { r: 28, g: 32, b: 37, hex: "#1C2025" },
  reg: { r: 32, g: 41, b: 52, hex: "#202934" },
  lig: { r: 33, g: 55, b: 74, hex: "#21374A" },
  r2dark: { r: 26, g: 34, b: 58, hex: "#1a223a" },
  r2reg: { r: 55, g: 89, b: 154, hex: "#37599a" },
  r2lig: { r: 99, g: 170, b: 231, hex: "#63aae7" },
  acc0: { r: 6, g: 222, b: 195, hex: "#06DEC3" },
  acc4: { r: 9, g: 211, b: 255, hex: "#09D3FF" },
};

const to_sync_keys = ["leftsidebar", "vaulthconfs"];

export const gen_phash = (doc) => {
  let ndoc = _.cloneDeep(doc);
  return gen_hash(ndoc, 6);
};

export const SavedSettingPresetsSection = () => {
  const accon = useAccountContext();
  const { auth, vault } = accon;
  const { acc_config, set_acc_config, s_acc_config, g_acc_config } = accon;
  const { qolist, use_preset } = accon;

  const [currphash] = useMemo(() => {
    let settings = {};
    for (let k of to_sync_keys) {
      settings[k] = acc_config[k];
    }
    let phash = gen_phash({ settings });
    return [phash];
  }, [jstr(acc_config)]);

  const plist = useMemo(() => {
    return getv(qolist, "data.result", []);
  }, [qolist.dataUpdatedAt]);

  const untracked = useMemo(() => {
    let hs = _.map(plist, "phash");
    if (_.isEmpty(hs)) return true;
    return !hs.includes(currphash);
  }, [jstr(plist), currphash]);

  const [resp, set_resp] = useState({});

  const save_preset = async () => {
    try {
      if (resp.loading) return;
      set_resp({ loading: true, action: "save_preset", status: "loading" });
      console.log("save_preset");
      let doc = { settings: {} };
      for (let k of to_sync_keys) {
        doc.settings[k] = acc_config[k];
      }
      doc.phash = gen_phash({ settings: doc.settings });
      // console.log("phash", doc.phash);
      doc.pname = get_inp("settings:preset_name");

      let presp = await q_vault_settings_save({ vault, doc }).queryFn();
      if (!nils(presp.err)) throw new Error(presp.err);
      await cdelay(1 * 1e3);
      await qolist.refetch();
      set_resp({});
    } catch (err) {
      console.log("err:save_preset", err);
      set_resp({ action: "save_preset", status: "error", msg: err.message });
      setTimeout(() => {
        set_resp({});
      }, 5 * 1e3);
    }
  };

  const delete_preset = async (phash) => {
    try {
      if (resp.loading) return;
      set_resp({
        action: "delete_preset",
        loading: true,
        status: "loading",
        phash,
      });
      await q_vault_settings_delete({ vault, phash }).queryFn();
      await cdelay(1 * 1e3);
      await qolist.refetch({});
    } catch (err) {}
    set_resp({});
  };

  useEffect(() => {
    console.log("resp", resp);
  }, [jstr(resp)]);

  const use_preset_fn = (phash) => {
    try {
      if (resp.loading) return;
      set_resp({
        loading: true,
        action: "use_preset",
        phash,
        status: "loading",
      });
      use_preset(phash);
    } catch (err) {}
    set_resp({});
  };

  return (
    <div className="resp-text--1">
      <p className="resp-text-0 font-digi w-full text-center my-1">
        Saved Setting Presets
      </p>

      <div class="fr-sc my-1">
        {/*         <p>CurrHash: {currphash}</p> */}
        <div class="flex-1"></div>
        {qolist.isLoading && <Loader01c size="s" />}
      </div>
      {untracked && (
        <>
          <div class="resp-p-2 bg-acc4/20 ">
            <p className="font-digi">Current Config is not Saved</p>
            <div class="fr-sc">
              <InpText
                {...{
                  id: "settings:preset_name",
                  placeholder: "Preset Name",
                  setter: () => {},
                  contprops: {
                    className: "w-max flex-1",
                  },
                  inpprops: {
                    className: "w-full",
                  },
                  autoComplete: "off",
                }}
              />

              <Tag
                onClick={() => {
                  save_preset();
                }}
                className="border border-acc4 rounded-md fr-sc resp-gap-1"
              >
                {resp.action == "save_preset" && resp.loading == true ? (
                  <>
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="spin-anim resp-text-1 text-acc4"
                    />
                    <span className="text-acc4">Saving...</span>
                  </>
                ) : (
                  <>
                    <span class="resp-text-0">Save</span>
                  </>
                )}
              </Tag>
            </div>
          </div>
        </>
      )}
      {!nils(resp.msg) && (
        <p
          className={twMerge(
            "border resp-p-1 resp-text--1 rounded-md resp-m-1 my-2",
            resp.status == "error"
              ? "text-red-400 border-red-400"
              : resp.status == "success"
                ? "text-green-400 border-green-400"
                : resp.status == "loading"
                  ? "text-blue-400 border-blue-400"
                  : "text-blue-400 border-blue-400",
          )}
        >
          {resp.msg}
        </p>
      )}

      <div class="fc-cc">
        {plist.map((p) => {
          const active = p.phash == currphash;
          return (
            <div
              onClick={(e) => {
                use_preset_fn(p.phash);
              }}
              class={twMerge(
                "fr-sc w-full resp-gap-1 my-1 resp-px-2 resp-py-2",
                "border border-acc4 rounded-md cursor-pointer",
                active ? "bg-acc4/40 " : "bg-transparent",
              )}
            >
              {resp.action == "use_preset" &&
              resp.loading == true &&
              resp.phash == p.phash ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className={twMerge(
                    "text-acc4",
                    "resp-text-1 resp-p-2 spin-anim",
                  )}
                />
              ) : (
                <FontAwesomeIcon
                  icon={active ? faDotCircle : faCircle}
                  className={twMerge(
                    active ? "text-acc4" : "text-slate-400",
                    "resp-text-1 resp-p-2",
                  )}
                />
              )}
              <div class="fc-cs flex-1">
                <p className="resp-text--2 font-digi">
                  {p.pname ?? `${p.phash}#Preset`}
                </p>
                <p className="resp-text--4 font-digi text-slate-400">
                  {iso_format(p.create_time)}
                </p>
                <div class="fr-sc"></div>
              </div>

              <Tag
                onClick={(e) => {
                  e.preventDefault();
                  if (resp.loading) return;
                  delete_preset(p.phash);
                  e.stopPropagation();
                }}
                className="rounded-md resp-p-1"
              >
                {resp.action == "delete_preset" &&
                resp.loading == true &&
                resp.phash == p.phash ? (
                  <>
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="spin-anim resp-text--1 text-acc4"
                    />
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="resp-text-1 text-red-300"
                    />
                  </>
                )}
              </Tag>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default function AccountWrapper(props) {
  const history = useNavigate();

  const aucon = useAuthContext();
  const { aumode, auth, vault } = aucon;
  const auth_vault = vault;

  const t3con = useThirdWebLoginContext();
  const { active_account } = t3con;

  const enabled = !nils(vault) && aucon.auth === true;

  const balanceenabled = useMemo(() => {
    let addr = active_account?.address;
    if (!nils(addr)) addr = addr.toLowerCase();
    if (aumode == "thirdweb" && addr == vault) {
      return true;
    } else {
      return enabled;
    }
  }, [aumode, auth, enabled]);

  const [qo_vaultinfo, qo_vaultbikes, qo_balance, qo_dezbalance] = useQueries([
    q_vaultinfo({ vault }, { enabled }),
    q_vaultbikesinf({ vault }, { enabled }),
    q_mm_weth_balance(
      { vault },
      {
        staleTime: 30 * 1e3,
        refetchInterval: 30 * 1e3,
        enabled: balanceenabled,
      },
    ),
    q_mm_dez_balance(
      { vault },
      {
        staleTime: 30 * 1e3,
        refetchInterval: 30 * 1e3,
        enabled: balanceenabled,
      },
    ),
  ]);
  const vdoc = useMemo(() => {
    let vaultdoc = getv(qo_vaultinfo, "data.result");
    return vaultdoc;
  }, [qo_vaultinfo.dataUpdatedAt]);

  const [hids, base_bikesob] = useMemo(() => {
    let hs = getv(qo_vaultbikes, "data.result") ?? [];
    let hids = _.map(hs, (e) => e.hid);
    let hsob = _.keyBy(hs, "hid");
    return [hids, hsob];
  }, [qo_vaultbikes.dataUpdatedAt]);

  // const qst_bdocs = useStepQuery({
  //   q_: q_bikeinfo_cac,
  //   par_ar: hids.map((hid) => [{ hid }, { enabled: false }]),
  //   lim: 2,
  // });

  const qst_bdocs = useCoresFullData(hids);
  // useEffect(() => {
  //   console.log("qst_bdocs", qst_bdocs);
  // }, [jstr(qst_bdocs)]);

  const bikesob = useMemo(() => {
    let full_data_n = 0;
    let b = {};
    for (let q of qst_bdocs.qs) {
      let d = getv(q, "data.result");
      if (nils(d)) continue;
      b[d.hid] = d;
      full_data_n++;
    }
    for (let hid of hids) {
      if (b[hid]) continue;
      else {
        b[hid] = base_bikesob[hid];
      }
    }
    console.log("updated:bikesob", { hids_n: hids.length, full_data_n });
    return b;
  }, [
    jstr(_.map(qst_bdocs.qs, "dataUpdatedAt")),
    jstr(base_bikesob),
    jstr(hids),
  ]);

  const acc_config_locpath = `acc_config-${vault}`;
  const [acc_config, set_acc_config] = useState(
    jparse(localStorage.getItem(acc_config_locpath), def_acc_config),
  );
  useEffect(() => {
    if (_.isEmpty(acc_config)) {
      let ob = jparse(localStorage.getItem(acc_config_locpath), def_acc_config);
      localStorage.setItem(acc_config_locpath, jstr(def_acc_config));
      set_acc_config(ob);
    } else {
      localStorage.setItem(acc_config_locpath, jstr(acc_config));
    }
  }, [acc_config]);

  const udpate_roadmap_status = async (v) => {
    let cac = get_roadmap_status(v);
    // console.log("cac:roadmap", cac);
    let lastupdate = getv(cac, "updated_at");
    if (!nils(lastupdate)) {
      let diff = moment().diff(moment(lastupdate), "minutes");
      // console.log("cac:roadmap:diff", { lastupdate }, diff);
      if (diff < 5) return;
    }
    // console.log("fetcing:udpate_roadmap_status", v);
    let resp = await q_roadmap_progress({ vault: v }).queryFn();
    resp = getv(resp, "result", {});
    // console.log("fetcing:udpate_roadmap_status resp", resp);
    if (_.isEmpty(resp)) return;
    let { si, ti, currtaskid } = resp;
    let save = {
      vault: v,
      si,
      ti,
      currtask: {
        id: currtaskid,
        taskid: currtaskid,
      },
      updated_at: iso(),
    };
    // console.log("udpate_roadmap_status", getv(save, "currtask.id"));
    localStorage.setItem(roadmap_status_redid(v), jstr(save));
  };

  useEffect(() => {
    if (nils(vault)) return;
    setTimeout(() => {
      udpate_roadmap_status(vault);
    }, 5 * 1e3);
  }, [jstr(vault)]);

  useEffect(() => {
    let vstar = getv(vdoc, "vstar");
    if (nils(vault) || nils(vstar)) return;
    let v = vault.toLowerCase();
    let redid = `vstar-${v}`;
    localStorage.setItem(redid, jstr({ vault: v, vstar }));
  }, [vdoc?.vstar, vault]);

  const s_acc_config = (p, v) => {
    if (!auth) return;
    set_state_ob(acc_config, set_acc_config, p, v);
  };
  const g_acc_config = (p, def) => {
    return getv(acc_config, p) ?? def;
  };

  const balance = useMemo(() => {
    if (!qissuccess(qo_balance)) return null;
    let bal = getv(qo_balance, "data.result.bal");
    return bal;
  }, [qo_balance.dataUpdatedAt]);

  const dezbalance = useMemo(() => {
    if (!qissuccess(qo_dezbalance)) return null;
    let bal = getv(qo_dezbalance, "data.result.bal");
    return bal;
  }, [qo_dezbalance.dataUpdatedAt]);

  const [qo_tokpri] = useQueries([q_token_prices()]);
  const tok_ethusd = useMemo(
    () => getv(qo_tokpri, "data.result.ethusd"),
    [qo_tokpri.dataUpdatedAt],
  );

  const bikeslist_hids_show_fn = ({ hids, mode, c, cb, rvmode }) => {
    let hasccb = !nils(cb);
    let hs = _.chain(hids)
      .map((hid) => {
        let h = bikesob[hid];
        if (nils(h)) return null;

        const hconf = getv(acc_config, `vaulthconfs.${rvmode}.${hid}`) || {};

        let nothide = hconf?.hide !== true;
        let cbcmatch = [50, 60, 70, 80, 90].includes(c)
          ? true
          : hasccb
            ? true
            : true;

        let notcbchide = !hasccb
          ? true
          : _.isEmpty(hconf?.cbmap)
            ? true
            : hconf.cbmap.includes(cb);

        let show = 10;
        if (show == 10 && !nothide) show = 0;
        if (show == 10 && !notcbchide) show = 2;
        return [hid, show, cbcmatch, { nothide, cbcmatch, notcbchide }, hconf];
      })
      .compact()
      .filter((h) => {
        if (hasccb) return h[2];
        else return true;
      })
      .filter((h) => {
        if (mode == "filtered") return h[1] == 10;
        else return true;
      })
      .sortBy((o) => {
        let hid = o[0];
        let h = bikesob[hid];
        let path = `hstats_${rvmode}.${cb}.win_p`;
        let ovwinp = getv(h, path, 0);
        ovwinp = parseFloat(ovwinp);
        // console.log({ path, hid, ovwinp });
        return -ovwinp;
      })
      .value();
    hs = _.map(hs, (ha) => {
      let hid = ha[0];
      let h = bikesob[hid];
      h.show = ha[1];
      return h;
    });
    // console.log("order:hs", _.map(hs, "hid"));
    return hs;
  };

  const refresh_bikeslist = async ({ vault } = {}) => {
    try {
      if (nils(vault)) vault = auth_vault;
      let resp = await q_vaultbikes_cc({ vault }).queryFn();
      await cdelay(1.5 * 1e3);
      await qo_vaultbikes.refetch();
    } catch (err) {
      console.log("err:refresh_bikeslist", err);
    }
  };

  const [cprofile, set_cprofile] = useState(
    (() => {
      let v = localStorage.getItem(mycprofile_key);
      v = jparse(v);
      let res = _.isEmpty(v) ? default_cprofile : v;
      return res;
    })(),
  );

  const location = useLocation();
  const params = useParams();
  const use_this_vault_cprofile = async (v) => {
    let resp = await q_vault_get_cprofiles({ vault: v }).queryFn();
    resp = getv(resp, "result");
    // console.log("use_another_vault_cprofile", v, resp);
    resp = getv(resp, "0");
    if (!_.isEmpty(resp)) {
      set_cprofile(resp);
      return resp;
    }
    return default_cprofile;
  };

  const isanothervaultpage = useMemo(() => {
    const path = location.pathname;
    const [a, basepath, pvault] = path.split("/");
    // console.log("path", path, pvault);
    if (basepath == "vault") {
      let pv = pvault;
      if (nils(pv)) return;
      if (nils(vault) || (!nils(pv) && pv !== vault)) {
        use_this_vault_cprofile(pv);
        return true;
      }
    } else {
      if (auth == true) {
        let using = jparse(localStorage.getItem(mycprofile_key));
        // console.log("using", using.hash, vdoc);
        if (using?.hash !== getv(vdoc, "cprofiles.0.hash"))
          using = getv(vdoc, "cprofiles.0");
        set_cprofile(using);
      } else {
        set_cprofile(default_cprofile);
      }
    }
  }, [location.pathname.startsWith("/vault/"), auth, jstr(vdoc)]);

  useEffect(() => {
    // console.log("update_cprofile", { isanothervaultpage }, cprofile.hash);
    localStorage.setItem(cprofile_key, jstr(cprofile));
    if (isanothervaultpage !== true) {
      // console.log("set mycprofile");
      localStorage.setItem(mycprofile_key, jstr(cprofile));
    }
    for (let [k, v] of _.entries(cprofile)) {
      if (nils(v) || nils(v?.r)) continue;
      let cstr = `${v.r} ${v.g} ${v.b}`;
      // console.log(k, cstr);
      document.documentElement.style.setProperty(`--cprofile-${k}`, cstr);
    }
  }, [jstr(cprofile), isanothervaultpage]);

  const refresh_roadmap = async () => {
    if (nils(vault)) return;
    let v = vault.toLowerCase();
    let resp = await q_roadmap_cc({ k: "r_progress", vault: v }).queryFn();
    await cdelay(1 * 1e3);
    let resp2 = await q_roadmap_progress({ vault: v }).queryFn();
  };

  const roadmap_post_enter_race = async () => {
    // if (nils(vault)) return;
    // let v = vault.toLowerCase();
    // let vstarredid = `vstar-${v}`;
    // let vstardoc = jparse(localStorage.getItem(vstarredid));
    // if (!_.isEmpty(vstardoc)) {
    //   let vstar = getv(vstardoc, "vstar");
    //   if (vstar > 0 && vstar < 4) {
    //     await cdelay(3 * 1e3);
    //     history(def_roadmap_link);
    //     await cdelay(1 * 1e3);
    //     await refresh_roadmap();
    //   }
    // }
  };

  // useEffect(() => { console.log("upd:acc_config", acc_config); }, [jstr(acc_config)]);

  const [qolist] = useQueries([
    q_vault_settings_presetslist({ vault }, { enabled: !nils(vault) }),
  ]);

  const use_preset = async (phash) => {
    try {
      // if (resp.loading) return;
      // set_resp({ loading: true, action: "use_preset", phash, status: "loading", });
      let doc = await q_vault_settings_get({ vault, phash }).queryFn();
      doc = getv(doc, "result");
      if (nils(doc)) return;
      console.log("use_preset", phash, doc);

      let ndoc = _.cloneDeep(acc_config);
      for (let k of to_sync_keys) {
        ndoc[k] = getv(doc, `settings.${k}`);
      }
      console.log("ndoc", ndoc);
      set_acc_config(ndoc);
      console.log("done:new", acc_config);
    } catch (err) {}
    // set_resp({});
  };

  const [qostaminamap] = useQueries([
    q_bikes_stamina_map(
      { hids: hids },
      { enabled: !_.isEmpty(hids), refetchInterval: 2 * 60 * 1e3 },
    ),
  ]);

  const all_bikes_loaded = useMemo(() => {
    if (_.isEmpty(hids) && qissuccesss(qo_vaultbikes)) return true;
    let n1 = hids.length;
    let n2 = _.keys(base_bikesob).length;
    let n3 = _.filter(qst_bdocs.qs, (q) => qissuccesss(q)).length;
    // console.log("all_bikes_loaded", { n1, n2, n3 });
    return n1 == n2 && n2 == n3;
  }, [jstr(hids), jstr(bikesob), jstr(base_bikesob)]);

  useEffect(() => {
    console.log("all_bikes_loaded", { loaded: all_bikes_loaded });
  }, [all_bikes_loaded]);

  const balance_ob = {
    WETH: balance,
    DEZ: dezbalance,
  };

  const accon = {
    vault,

    vdoc,
    hids,
    bikesob,

    qo_vaultinfo,
    qo_vaultbikes,
    qst_bdocs,
    qostaminamap,

    acc_config,
    set_acc_config,
    s_acc_config,
    g_acc_config,

    qolist,
    use_preset,

    qo_balance,
    balance,

    qo_dezbalance,
    dezbalance,

    tok_ethusd,

    bikeslist_hids_show_fn,
    refresh_bikeslist,

    cprofile,
    set_cprofile,

    roadmap_post_enter_race,
    refresh_roadmap,
    all_bikes_loaded,

    balance_ob,
  };

  return (
    <AccountContext.Provider value={accon}>
      {props.children}
    </AccountContext.Provider>
  );
}
