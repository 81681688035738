import _ from "lodash";
import {
  cb_cn,
  class_cn,
  class_text,
  elementmap,
  gendermap,
} from "../utils/cn_map.js";
import { Tag } from "./utilityComps.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { twMerge } from "tailwind-merge";
import { forwardRef, useEffect, useState } from "react";
import { useMemo } from "react";
import { copy_clip, nils } from "../utils/utils.js";
import { Helmet } from "react-helmet-async";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

export const MiniElementTag = ({ element, className }) => {
  return (
    <Tag
      className={twMerge(
        "fr-sc resp-gap-1 transform -skew-x-12 resp-text--4",
        elementmap[element]?.text,
        className,
      )}
    >
      <FontAwesomeIcon icon={elementmap[element]?.icon} />
    </Tag>
  );
};

export const ElementTag = ({ element, className }) => {
  return (
    <Tag
      className={twMerge(
        "fr-sc resp-gap-1 transform -skew-x-12 resp-text--4",
        elementmap[element]?.bg,
        className,
      )}
    >
      <FontAwesomeIcon icon={elementmap[element]?.icon} />
      <span className="font-digi">{_.upperCase(element)}</span>
    </Tag>
  );
};

export const GenderTag = ({ gender }) => {
  return (
    <Tag
      className={twMerge(
        "fr-sc resp-gap-1 transform -skew-x-12",
        gendermap[gender]?.bg,
        "resp-text--2",
      )}
    >
      <FontAwesomeIcon icon={gendermap[gender]?.icon} />
      <span className="font-digi">{_.upperCase(gender)}</span>
    </Tag>
  );
};

export const MiniGenderTag = ({ gender }) => {
  return (
    <Tag
      className={twMerge(
        "fr-sc resp-gap-1",
        gendermap[gender]?.text,
        "resp-text-1",
      )}
    >
      <FontAwesomeIcon icon={gendermap[gender]?.icon} />
    </Tag>
  );
};

export const TypeTag = ({ type, className }) => {
  return (
    <Tag
      className={twMerge(
        "fr-sc resp-gap-1 transform -skew-x-12",
        "bg-acc0/40",
        "resp-text--2",
        className,
      )}
    >
      <span className="font-digi">{_.upperCase(type)}</span>
    </Tag>
  );
};

export const FNoTag = ({ fno, className }) => {
  return (
    <Tag
      className={twMerge(
        "fr-sc resp-gap-1 transform -skew-x-12",
        "bg-transparent",
        className,
      )}
    >
      <span className="font-digi resp-text-1 text-acc0">
        F{_.upperCase(fno)}
      </span>
    </Tag>
  );
};

export const ClassTag = ({ c, className, format = "t" }) => {
  return (
    <Tag
      className={twMerge(
        "fr-sc resp-gap-1 transform -skew-x-12",
        class_cn(c),
        className,
      )}
    >
      <span className="font-digi">{class_text(c, format)}</span>
    </Tag>
  );
};

export const CBTag = ({ cb, className }) => {
  return (
    <Tag
      className={twMerge(
        "fr-sc resp-gap-1 transform -skew-x-12",
        cb_cn(cb),
        className,
      )}
    >
      <span className="font-digi">{`CB${cb}00M`}</span>
    </Tag>
  );
};

export const PageWrapper = ({
  page_title_fn = null,
  children = null,
  cont_cn,
}) => {
  const pagetitle = useMemo(() => {
    if (nils(page_title_fn)) return `FBike | DNA Racing`;
    else return `${page_title_fn()} | FBike | DNA Racing`;
  }, [page_title_fn]);

  return (
    <>
      <Helmet>
        <title>{pagetitle}</title>
      </Helmet>
      <div class="h-page">
        <div class="xs:h-[2rem] lg:h-[4rem]"></div>
        <div
          className={twMerge(
            "max-w-[98vw] w-[60rem] mx-auto resp-text-0",
            cont_cn,
          )}
        >
          {children}
        </div>
        <div class="h-[4rem]"></div>
      </div>
    </>
  );
};

export const CopyBtn = forwardRef(
  (
    { txt, right_jsx, className = "", icon_className, show_icon = true },
    ref,
  ) => {
    const [show, set_show] = useState(false);
    useEffect(() => {
      if (show == true) {
        setTimeout(() => {
          set_show(false);
        }, 1 * 1e3);
      }
    }, [show]);
    return (
      <div
        ref={ref}
        onClick={() => {
          copy_clip(txt);
          set_show(true);
        }}
        class={twMerge(
          "relative cursor-pointer w-max fr-sc resp-p-1 resp-gap-1 rounded-md border border-transparent",
          "hover:border hover:border-acc4 hover:border-dashed",
          className,
        )}
      >
        {show_icon && (
          <FontAwesomeIcon
            className={twMerge("resp-text-1", icon_className)}
            icon={faCopy}
          />
        )}
        <div
          class={twMerge(
            "absolute left-[100%] resp-px-3 top-0 bg-pagebg rounded-full resp-p-1 resp-text--1 font-digi text-acc0",
            show
              ? "opacity-100 translate-x-[0px]"
              : "opacity-0 translate-x-[50px]",
            "transition-all duration-300",
          )}
        >
          Copied
        </div>
        {right_jsx}
      </div>
    );
  },
);
