import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  cdelay,
  getv,
  iso,
  jstr,
  nano,
  nils,
  trim2,
  trim_n,
} from "../utils/utils";
import { useQueries } from "react-query";
import { PageWrapper } from "../components/ShortComps";
import { useAppContext } from "../App";
import { useAuthContext } from "../wrappers/AuthWrapper";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  q_open_races,
  q_trails_cards_attempt_block,
  q_trails_cards_burn,
  q_trails_cards_cc,
  q_trails_cards_owns_cards,
  q_trails_cards_points_cc,
  q_trails_cards_points_get,
  q_trails_cards_start,
} from "../queries/queries";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faCheckCircle,
  faFire,
  faFlagCheckered,
  faLock,
  faPersonRunning,
  faQuestion,
  faQuestionCircle,
  faSpinner,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  Img,
  Tag,
  TokenIcon,
  tokenimgmap,
} from "../components/utilityComps";
import _, { values } from "lodash";
import { Loader01c } from "../components/anims";
import { RVImg } from "../components/BikeImg";
import { PopUp, PopupCloseBtn } from "../components/popup";
import { RacesListInner } from "./Races";
import { useVaultContext } from "./RacingDashboard";
import { useAccountContext } from "../wrappers/AccountWrapper";
import { QuestSelect } from "./Quest/QuestSelect";
import { Link } from "react-router-dom";

const trailsbase = "https://cdn.dnaracing.run/trails_cards_imgs";

const trails_img = (img) => {
  return `${trailsbase}/${img}`;
};

const imgmap = {
  questcard:
    " https://cdn.dnaracing.run/imgs/playing-cards-factions/card_back_p01.png",
};

const TrailsPointsContext = createContext({});
export const useTrailsPointsContext = () => useContext(TrailsPointsContext);

export const TP_Nav = ({}) => {
  const tpcon = useTrailsPointsContext();

  const { pathname } = useLocation();

  const cn = (path) => {
    return twMerge(
      "fr-sc resp-gap-2",
      "cursor-pointer transition-all duration-300",
      pathname === path
        ? "-skew-x-12 border border-purple-500 bg-purple-500/40"
        : " text-purple-400 ",
    );
  };

  return (
    <div class="fr-sc my-2 pb-2 border-b border-acc4/50">
      {[
        ["/trails-cards", "Cards"],
        ["/trailspoints-store", "Store"],
        ["/trailspoints-ledger", "Ledger"],
        ["https://www.dnaracing.run/tp_giftcard", "Gift Cards"],
      ].map(([path, name]) => {
        return (
          <Link
            key={path}
            to={path}
            target={path.startsWith("https://") ? "_blank" : "_self"}
          >
            <Tag className={cn(path)}>
              <span class="resp-text--1">{name}</span>
            </Tag>
          </Link>
        );
      })}

      <div class="flex-1"></div>
      <TP_TokenTag {...tpcon} />
    </div>
  );
};

export const TrailsPointsWrapper = ({ vault, ...props }) => {
  const [qo_points] = useQueries([
    q_trails_cards_points_get({ vault }, { enabled: !nils(vault) }),
  ]);

  const points = useMemo(() => {
    let points = getv(qo_points, "data.result.points", 0);
    return points;
  }, [qo_points.dataUpdatedAt]);

  const [refreshing, set_refreshing] = useState(false);
  const refresh_points = async () => {
    try {
      set_refreshing(true);
      await q_trails_cards_points_cc({ k: "r_get", vault }).queryFn();
      await cdelay(1000);
      await qo_points.refetch();
    } catch (err) {}
    set_refreshing(false);
  };
  const tpcon = {
    vault,
    qo_points,
    points,
    refresh_points,
    refreshing,
  };

  return (
    <>
      <TrailsPointsContext.Provider value={tpcon}>
        {props.children}
      </TrailsPointsContext.Provider>
    </>
  );
};

export const TP_TokenTag = ({ points, refresh_points, refreshing }) => {
  return (
    <div
      onClick={() => {
        if (refreshing) return;
        if (refresh_points) refresh_points();
      }}
      className={twMerge(
        "border border-purple-500 bg-purple-500/40",
        "fr-sc resp-gap-1 py-0 xs:px-[0.5rem] lg:px-[1rem] rounded-full",
        "cursor-pointer",
      )}
    >
      {refreshing && (
        <FontAwesomeIcon
          icon={faSpinner}
          className="text-purple-200 spin-anim"
        />
      )}
      <TokenIcon token="tp" size="md" />
      <span className="font-digi resp-text-1">{parseInt(points ?? 0)}</span>
      <p className="resp-text--1 font-digi">Trail Points</p>
    </div>
  );
};

export const TrailsCardsListPage = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;
  const aucon = useAuthContext();
  const vault = useMemo(() => {
    let v = nils(psearch.v) ? null : psearch.v.toLowerCase();
    if (!nils(v)) return v;
    return aucon.vault;
  }, [psearch.v, aucon.vault]);

  return (
    <TrailsPointsWrapper {...{ vault }}>
      <TrailsCardsListPageInner {...{ vault }} />
    </TrailsPointsWrapper>
  );
};

export const TrailsCardsListPageInner = ({ vault }) => {
  const [qo_cards] = useQueries([
    q_trails_cards_owns_cards({ vault }, { enabled: !nils(vault) }),
  ]);

  const cards = useMemo(() => {
    let cards = getv(qo_cards, "data.result", []);
    return cards;
  }, [qo_cards.dataUpdatedAt]);

  const tpcon = useTrailsPointsContext();

  return (
    <PageWrapper cont_cn={"lg:w-[80rem]"} page_title_fn={() => "Trails Cards"}>
      <TP_Nav />
      <p className="resp-text-2 font-digi text-center my-2">Trails Cards</p>
      {_.isEmpty(cards) ? (
        <div class="fc-cc resp-gap-2">
          <p>You dont own any Trail Cards yet</p>
          <p>Keep Racing to earn some</p>
        </div>
      ) : (
        <div class="flex flex-row justify-center items-stretch gap-4 w-full flex-wrap">
          {cards.map((c) => {
            return <TrailsCardComapct {...{ card: c, key: c.id }} />;
          })}
        </div>
      )}
    </PageWrapper>
  );
};

export const TrailsCardImg = ({ img = "back", className }) => {
  return <Img img={trails_img(img)} className={className} />;
};

const TrailsCardComapct = ({ card }) => {
  return (
    <>
      <Link to={`/trails-cards/${card.id}`} className="">
        <div class="xs:w-[15rem] lg:w-[35rem] h-full">
          <Card className={"w-full border border-acc4 h-full"}>
            <TrailsCardImg img={getv(card, "cardtype.img")} className="" />
            <div class="fc-ss resp-gap-1">
              <p className="w-full text-center resp-text-2 font-digi">
                {getv(card, "cardtype.title")}
              </p>
              <div class="flex flex-row justify-around items-center  w-full font-digi resp-text-1">
                {card.status == "burned" ? (
                  <>
                    <div class="flex-1"></div>
                    <FontAwesomeIcon
                      className="resp-text-2 text-orange-400"
                      icon={faFire}
                    />
                    <p class="text-orange-400">BURNED</p>
                  </>
                ) : card.status == "allotted" ? (
                  <>
                    <div class="flex-1"></div>
                    <p class="text-yellow-300">ALLOTTED</p>
                  </>
                ) : card.status == "pending" ? (
                  <>
                    <p class="text-acc4">Pending Progress</p>
                    <p>
                      {card.curr_block} / {card.blocks_n}
                    </p>
                  </>
                ) : card.status == "completed" ? (
                  <>
                    <div class="flex-1"></div>
                    <p class="text-green-300">COMPLETED</p>
                  </>
                ) : null}
              </div>

              <div class="fr-cc w-full resp-gap-1">
                <div class="fr-sc resp-gap-2 resp-px-2">
                  <FontAwesomeIcon
                    className="resp-text-2"
                    icon={faPersonRunning}
                  />
                  <p className="resp-text-1 ">Start</p>
                </div>
                {[...new Array(card.blocks_n)].map((e, i) => {
                  let status =
                    i < card.curr_block
                      ? "done"
                      : i == card.curr_block
                        ? "active"
                        : "inactive";
                  return (
                    <div
                      class={twMerge(
                        "rounded-md w-full max-w-[10%] xs:h-[1rem] lg:h-[2rem]",
                        status == "done"
                          ? "bg-acc4/20 border border-acc4/40 text-acc4"
                          : status == "active"
                            ? "bg-acc4/40 border border-acc4 text-acc4"
                            : "bg-slate-400/20 border border-slate-400",
                      )}
                    ></div>
                  );
                })}

                <div class="fr-sc resp-gap-2 resp-px-2">
                  <p className="resp-text-1 ">Finish</p>
                  <FontAwesomeIcon
                    className="resp-text-2"
                    icon={faFlagCheckered}
                  />
                </div>
              </div>
              <div class="fr-sc my-2 w-full">
                <div class="flex-1"></div>
                <Tag className="fr-sc resp-gap-2 text-acc4">
                  <span className="resp-text-0 font-digi">View Details</span>
                  <FontAwesomeIcon
                    className="resp-text-2"
                    icon={faArrowRight}
                  />
                </Tag>
              </div>
            </div>
          </Card>
        </div>
      </Link>
    </>
  );
};

const CardRacesSection = ({ block, block_idx, set_popmode }) => {
  const { card, refresh_card } = useTrailsCardContext();

  const { tokmap, tok_to_usd_val } = useAppContext();
  const valfilt = {
    class: [80],
    giveeacards: true,
    giveeacardstype: ["questcard"],
    // rgate: 12,
    rvmode: [block.rvmode],
  };
  const [qo_races] = useQueries([q_open_races(valfilt, { enabled: true })]);

  const [races, races_n] = useMemo(() => {
    let rs = getv(qo_races.data, "result");
    if (_.isEmpty(rs)) return [[], 0];
    let races = [];
    for (let r of rs) {
      let race = r;
      if (race) {
        race.fee_usd = tok_to_usd_val(race.fee, race.paytoken);
        race.prize_usd = tok_to_usd_val(race.prize, race.paytoken);
        // console.log(race);
      }
      races.push(race);
    }
    races = _.compact(races);
    races = _.compact(races);
    return [races, races.length];
  }, [qo_races.dataUpdatedAt, jstr(valfilt), jstr(tokmap)]);

  const [selrace, set_selrace] = useState(null);

  const post_enterrace = async (args) => {
    console.log("post_enterrace", args);
    let { rid, hid } = args;
    try {
      let attempt_info = {
        rid,
        hid,
        date: iso(),
      };
      let resp = await q_trails_cards_attempt_block({
        id: card.id,
        block_idx,
        attempt_info,
      }).queryFn();
      await cdelay(1000);
      await refresh_card();
      if (resp?.err) throw new Error(resp.err);
    } catch (err) {
      console.log("post_enterrace", err);
    }
  };

  const races_context = {
    races,
    qo_races,
    selrace,
    set_selrace,
    post_enterrace,
  };

  const closepopup = () => {
    set_popmode(null);
  };

  return (
    <>
      <Card
        className={
          "w-full bg-r2dark/40 border border-acc4 xs:w-[98vw] lg:w-[60rem] h-[70vh] overflow-auto"
        }
      >
        <PopupCloseBtn {...{ closepopup }} />
        {qo_races.isLoading ? (
          <Loader01c />
        ) : _.isEmpty(races) ? (
          <div class="fc-cc">
            <p className="text-center text-red-300 resp-text-2 font-digi">
              No Races found right now
            </p>
          </div>
        ) : (
          <div className="block">
            <RacesListInner
              {...{
                ...races_context,
              }}
            />
          </div>
        )}
      </Card>
    </>
  );
};

const CardQuestSection = ({ block, block_idx, set_popmode }) => {
  const { card, refresh_card } = useTrailsCardContext();

  const post_enterrace = async ({ rid, hid }) => {
    try {
      let attempt_info = {
        rid,
        hid,
        date: iso(),
      };
      let resp = await q_trails_cards_attempt_block({
        id: card.id,
        block_idx,
        attempt_info,
      }).queryFn();
      await cdelay(1000);
      await refresh_card();
      if (resp?.err) throw new Error(resp.err);
    } catch (err) {
      console.log("post_enterrace", err);
    }
  };

  const closepopup = () => {
    set_popmode(null);
  };

  return (
    <>
      <Card
        className={twMerge(
          "bg-r2lig/20 backdrop-blur-md border border-acc4 w-full",
          "xs:w-[95vw] lg:w-[60rem]",
        )}
      >
        <PopupCloseBtn {...{ closepopup }} />
        <QuestSelect
          {...{
            init_stage: 1,
            override_info: {
              rvmode: block.rvmode,
            },
            blocked_options: ["rvmode"],
            block_url_update: true,
            post_enterrace,
            card_cn: " bg-transparent",
          }}
        />
      </Card>
    </>
  );
};

const TrailsCardContext = createContext({});
export const useTrailsCardContext = () => useContext(TrailsCardContext);

const BlockRow = ({ block, i }) => {
  const tconn = useTrailsCardContext();
  const { card, refresh_card } = tconn;

  const [popmode, set_popmode] = useState(block.rvmode);
  const a = getv(block, "attempt_info");

  const acccon = useAccountContext();
  const { bikesob } = acccon;

  const [cancelling, set_cancelling] = useState(false);
  const clear_attempt_info = async () => {
    try {
      set_cancelling(true);
      q_trails_cards_attempt_block({
        id: card.id,
        block_idx: i,
        attempt_info: null,
      }).queryFn();
      await cdelay(1000);
      await refresh_card();
      await cdelay(1000);
      set_cancelling(false);
    } catch (err) {
      console.log("err:clear_attempt_info", err);
    }
  };

  const rop = getv(block, "races_ops", 3);

  return (
    <div class="w-full">
      <div class="grid grid-cols-12 my-2">
        <div class="xs:w-[2rem] lg:w-[4rem] aspect-[1/1] rounded-md shadow-md fc-cc font-digi resp-text-2 border border-acc4 bg-acc4/20 text-accc4">
          <span>{i + 1}</span>
        </div>
        <div class="xs:col-span-2 lg:col-span-1 w-[4rem] fc-cc aspect-[1/1]">
          <RVImg rvmode={block.rvmode} />
        </div>
        <div class="xs:col-span-7 col-span-8 flex flex-col justify-center items-start">
          <div class="fr-sc resp-gap-2">
            <p class="resp-text-1 font-digi">
              Mission: Race a {_.capitalize(block.rvmode)} in one of the options
              below and finish in the money. to complete the block.
            </p>
          </div>
          {!_.isEmpty(block?.attempt_info) ? (
            <div className="my-2 w-full">
              <div class="fr-cc resp-gap-2 font-digi resp-text-1 w-full">
                {block.status == "pending" ? (
                  <p className="text-acc4">Attempting...</p>
                ) : block.status == "completed" ? (
                  <p className="text-green-300">Completed</p>
                ) : null}
                <Link to={`/race/${a?.rid}`} className="w-full">
                  <p className="resp-text--1">Race: {trim2(a?.rid, 9, 4)}</p>
                </Link>
                <div class="flex-1"></div>
                {block.status == "pending" && (
                  <Tag
                    onClick={() => {
                      if (cancelling) return;
                      clear_attempt_info();
                    }}
                    className="fr-sc resp-gap-1 border border-red-300 text-red-300"
                  >
                    {cancelling ? (
                      <FontAwesomeIcon
                        className="resp-text-1 spin-anim text-red-300"
                        icon={faSpinner}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="resp-text-1 text-red-300"
                        icon={faTimesCircle}
                      />
                    )}
                    <span className="resp-text-0 font-digi">Cancel</span>
                  </Tag>
                )}
              </div>
              <p className="font-digi resp-text-1">
                Core: #{a?.hid}-{bikesob?.[a?.hid]?.name}
              </p>
              {!nils(block.err) && (
                <>
                  <p class="resp-text-1 font-digi text-red-300 ">
                    Error: {block.err}
                  </p>
                  <p class="resp-text-1 font-digi text-red-300 ">
                    Recommended to cancel this attempt and retry in a new race
                  </p>
                </>
              )}
            </div>
          ) : (
            <>
              <div class="fr-cc resp-gap-2 w-full my-2">
                {[1, 3].includes(rop) && (
                  <div
                    onClick={() => {
                      set_popmode("cardrace");
                    }}
                    class={twMerge(
                      "border border-acc4 cursor-pointer h-full p-[1rem] w-[20rem] rounded-[1rem] border-acc4",
                      "bg-r2dark/40 hover:bg-acc4/40 fc-cc",
                    )}
                  >
                    <p class="resp-text-1 text-center my-1">{"Card Race"}</p>
                  </div>
                )}
                {[2, 3].includes(rop) && (
                  <div
                    onClick={() => {
                      set_popmode("quest");
                    }}
                    class={twMerge(
                      "border border-acc4 cursor-pointer h-full p-[1rem] w-[20rem] rounded-[1rem] border-acc4",
                      "bg-r2dark/40 hover:bg-acc4/40 fc-cc",
                    )}
                  >
                    <p class="resp-text-1 text-center my-1">{`Finish Top3 in the 3rd Round of a quest`}</p>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div class="col-span-2 resp-text-1 font-digi text-right flex flex-col justify-center items-end h-[4rem]">
          {block.status == "pending" ? (
            <span className="text-acc4">Pending</span>
          ) : block.status == "completed" ? (
            <span className="text-green-300">Completed</span>
          ) : (
            ""
          )}
        </div>
      </div>
      <PopUp
        {...{
          openstate: popmode == "cardrace",
          onClose: () => {
            set_popmode(null);
          },
          wrapcn: "top-[6rem]",
          innercn: "translate-y-[0%]",
        }}
      >
        <div class="">
          <CardRacesSection {...{ block, block_idx: i, key: i, set_popmode }} />
        </div>
      </PopUp>

      <PopUp
        {...{
          openstate: popmode == "quest",
          onClose: () => {
            set_popmode(null);
          },
          wrapcn: "top-[6rem]",
          innercn: "translate-y-[0%]",
        }}
      >
        <div class="">
          <CardQuestSection {...{ block, block_idx: i, key: i, set_popmode }} />
        </div>
      </PopUp>

      <div class="h-[0.5rem]"></div>
    </div>
  );
};

const CardWhenAllotted = ({}) => {
  const tcon = useTrailsCardContext();
  const { card, refresh_card } = tcon;
  const [starting, set_starting] = useState(false);
  const [err, set_err] = useState(null);
  const start_trail = async () => {
    try {
      set_starting(true);
      set_err(null);
      let resp = await q_trails_cards_start({ id: card.id }).queryFn();
      if (resp?.err) throw new Error(resp.err);
      await cdelay(2 * 1e3);
      await refresh_card();
    } catch (err) {
      console.log("start_trail", err);
      set_err(err.message);
    }
    set_starting(false);
  };
  if (card.status != "allotted") return null;
  return (
    <div class="fc-cc my-2 resp-gap-2">
      <Tag
        onClick={() => {
          if (starting) return;
          start_trail();
        }}
        className={twMerge(
          "transition duration-300 cursor-pointer shadow-md shadow-acc4 border border-acc4",
          "-skew-x-12 fr-sc resp-gap-2",
          starting ? "bg-r2dark/40 text-acc4" : "bg-acc4/40",
        )}
      >
        {starting && (
          <FontAwesomeIcon className="resp-text-2 spin-anim" icon={faSpinner} />
        )}
        <span className="resp-text-2 font-digi">Start Trail Now</span>
      </Tag>
      {!nils(err) && (
        <pre className="resp-text-1 text-center max-w-full text-red-300 font-digi">
          {err}
        </pre>
      )}
    </div>
  );
};

const CardBurn = ({}) => {
  const tcon = useTrailsCardContext();
  const { card, refresh_card } = tcon;
  const [burning, set_burning] = useState(false);
  const [err, set_err] = useState(null);
  const burn_trail = async () => {
    try {
      set_burning(true);
      set_err(null);
      let resp = await q_trails_cards_burn({ id: card.id }).queryFn();
      if (resp?.err) throw new Error(resp.err);
      await cdelay(2 * 1e3);
      await refresh_card();
    } catch (err) {
      console.log("start_trail", err);
      set_err(err.message);
    }
    set_burning(false);
  };
  if (!["pending", "allotted"].includes(card.status)) return null;

  return (
    <div class="w-max flex flex-col justify-end items-end">
      <Tag
        onClick={() => {
          if (burning) return;
          burn_trail();
        }}
        className={twMerge(
          "transition duration-300 cursor-pointer shadow-md shadow-orange-400 border border-orange-400",
          "fr-sc resp-gap-2",
          burning ? "bg-r2dark/40 text-acc4" : "bg-orange-400/40",
        )}
      >
        {burning ? (
          <FontAwesomeIcon className="resp-text-2 spin-anim" icon={faSpinner} />
        ) : (
          <FontAwesomeIcon className="resp-text-2" icon={faFire} />
        )}
        <span className="resp-text-0 font-digi">Burn Card</span>
      </Tag>
      {!nils(err) && (
        <pre className="resp-text--1 text-center max-w-full text-red-300 font-digi">
          {err}
        </pre>
      )}
    </div>
  );
};

const PrizePayouts = () => {
  const ccon = useTrailsCardContext();
  const { card, refresh_card } = ccon;
  const payouts = getv(card, "payouts", []);
  if (_.isEmpty(payouts)) return;

  return (
    <div class="w-full ">
      <p class="resp-text-2 font-digi">Prize Payouts</p>
      <div class="flex flex-row justify-center items-stretch w-full resp-gap-4">
        {_.values(payouts).map((o, i) => {
          const img =
            o.token == "trailspoints" ? tokenimgmap["tp"] : imgmap[o.token];
          return (
            <div className="border border-acc4 bg-acc4/20 rounded-md p-4 resp-text-1 font-digi">
              <p className="my-1">
                {o.amt}x-{_.capitalize(o.token)}
              </p>
              {o.token == "questcard" ? (
                <>
                  <Link to={`/quest-cards`}>
                    <div class="fc-cc">
                      <div class="h-[4rem]">
                        <Img img={img} className="w-full h-full" />
                      </div>
                    </div>
                  </Link>
                </>
              ) : o.token == "trailspoints" ? (
                <Link to={`/trailspoints-store`}>
                  <div class="fc-cc">
                    <div class="h-[4rem]">
                      <Img img={img} className="w-full h-full" />
                    </div>
                  </div>
                </Link>
              ) : null}
              <div class="fr-sc">
                <div class="flex-1"></div>
                {o.done ? (
                  <FontAwesomeIcon
                    className="resp-text-2 text-green-300"
                    icon={faCheckCircle}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="resp-text-2 text-acc4 spin-anim"
                    icon={faSpinner}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const TrailsCardPage = () => {
  const aucon = useAuthContext();
  const { vault } = aucon;
  return (
    <>
      <TrailsPointsWrapper {...{ vault }}>
        <TrailsCardPageInner {...{ vault }} />
      </TrailsPointsWrapper>
    </>
  );
};

const TrailsCardPageInner = ({ vault }) => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const tpcon = useTrailsPointsContext();

  const params = useParams();
  const id = params?.id;

  const [qo_card] = useQueries([
    q_trails_cards_owns_cards({ id }, { enabled: !nils(id) }),
  ]);
  const card = useMemo(() => {
    let card = getv(qo_card, "data.result.0");
    return card;
  }, [qo_card.dataUpdatedAt]);

  const refresh_card = async () => {
    try {
      await q_trails_cards_cc({ k: "owns_cards", id }).queryFn();
      await cdelay(1000);
      qo_card.refetch();
    } catch (err) {}
  };

  const tcon = {
    qo_card,
    card,
    refresh_card,
    ...tpcon,
  };

  return (
    <PageWrapper
      page_title_fn={() => ` ${getv(card, "cardtype.title")} | Trails Card`}
    >
      <TrailsCardContext.Provider value={tcon}>
        <div class="fr-sc my-2">
          <Link to={`/trails-cards`}>
            <Tag
              className={twMerge(
                "bg-acc4/20 border border-acc4 text-acc4 font-digi -skew-x-12",
                "fr-sc resp-gap-2",
              )}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
              <span>Back to Trails Cards</span>
            </Tag>
          </Link>
          <div class="flex-1"></div>
          <TP_TokenTag {...tpcon} />
        </div>
        {qo_card.isLoading ? (
          <Loader01c />
        ) : !nils(card) ? (
          <>
            <div class="xs:w-[50vw] lg:w-[40rem] mx-auto">
              <TrailsCardImg img={getv(card, "cardtype.img")} className="" />
            </div>
            <p className="w-full text-center resp-text-2 font-digi my-2">
              {getv(card, "cardtype.title")}
            </p>
            <div class="grid grid-cols-12 my-2 gap-4">
              <div class="xs:col-span-12 lg:col-span-8 fc-ss resp-gap-1">
                <div class="flex flex-row justify-around items-center  w-full font-digi resp-text-1">
                  {card.status == "burned" ? (
                    <>
                      <div class="flex-1"></div>
                      <FontAwesomeIcon
                        className="resp-text-2 text-orange-400"
                        icon={faFire}
                      />
                      <p class="text-orange-400">BURNED</p>
                    </>
                  ) : card.status == "allotted" ? (
                    <>
                      <div class="flex-1"></div>
                      <p class="text-yellow-300">ALLOTTED</p>
                    </>
                  ) : card.status == "pending" ? (
                    <>
                      <p class="text-acc4">Pending Progress</p>
                      <p>
                        {card.curr_block} / {card.blocks_n}
                      </p>
                    </>
                  ) : card.status == "completed" ? (
                    <>
                      <div class="flex-1"></div>
                      <p class="text-green-300">COMPLETED</p>
                    </>
                  ) : null}
                </div>

                <div class="fr-cc w-full resp-gap-1">
                  <div class="fr-sc resp-gap-2 resp-px-2">
                    <FontAwesomeIcon
                      className="resp-text-2"
                      icon={faPersonRunning}
                    />
                    <p className="resp-text-1 ">Start</p>
                  </div>
                  {[...new Array(card.blocks_n)].map((e, i) => {
                    let status =
                      i < card.curr_block
                        ? "done"
                        : i == card.curr_block
                          ? "active"
                          : "inactive";
                    return (
                      <div
                        class={twMerge(
                          "rounded-md w-full max-w-[10%] xs:h-[1rem] lg:h-[2rem]",
                          status == "done"
                            ? "bg-acc4/20 border border-acc4/40 text-acc4"
                            : status == "active"
                              ? "bg-acc4/40 border border-acc4 text-acc4"
                              : "bg-slate-400/20 border border-slate-400",
                        )}
                      ></div>
                    );
                  })}

                  <div class="fr-sc resp-gap-2 resp-px-2">
                    <p className="resp-text-1 ">Finish</p>
                    <FontAwesomeIcon
                      className="resp-text-2"
                      icon={faFlagCheckered}
                    />
                  </div>
                </div>
              </div>
              <div class="xs:col-span-12 lg:col-span-4 flex flex-row justify-center items-stretch resp-gap-2  my-2 resp-text-1 font-digi">
                <p className="p-4">Prize</p>
                {card.prizes.map((p) => {
                  const img =
                    p.token == "trailspoints"
                      ? tokenimgmap["tp"]
                      : imgmap[p.token];
                  return (
                    <div class="border border-acc4 bg-acc4/30 p-2 rounded-md">
                      <div class="fr-sc resp-gap-2 w-max">
                        <p className="resp-text-1">{p.amt} x</p>
                        <div class="w-[4rem]">
                          <Img img={img} className="w-full h-full" />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div class="my-2 fr-sc resp-gap-2">
              {!_.isEmpty(card.rewarded_for) ? (
                <>
                  <Link to={`/race/${getv(card.rewarded_for, "rid")}`}>
                    <p className="resp-text-0 font-digi text-acc4 underline">
                      Rewarded for Race:{getv(card.rewarded_for, "rid")}
                    </p>
                  </Link>
                </>
              ) : null}
              <div class="flex-1"></div>
              <CardBurn {...{}} />
            </div>

            <div class="bg-white/30 min-h-[0.1rem] my-[2rem] mx-auto xs:w-full lg:w-[50rem]"></div>

            {card.status == "burned" ? (
              <div class="fc-cc resp-gap-2">
                <p className="resp-text-1 font-digi">
                  This card has been burned and is no longer available
                </p>
              </div>
            ) : card.status == "allotted" ? (
              <>
                <div class="fr-cc">
                  <CardWhenAllotted {...{}} />
                </div>
              </>
            ) : (
              [...new Array(card.blocks_n)].map((e, i) => {
                let block = card.blocks[i];
                if (!block)
                  return (
                    <div class="grid grid-cols-12 my-2">
                      <div class="xs:w-[2rem] lg:w-[4rem] aspect-[1/1] rounded-md shadow-md fc-cc font-digi resp-text-2 border border-red-400 bg-red-400/20 text-accc4">
                        <span>{i + 1}</span>
                      </div>
                      <div class="xs:col-span-2 lg:col-span-1 w-[4rem] fc-cc aspect-[1/1]">
                        <FontAwesomeIcon
                          className="text-[2rem]"
                          icon={faQuestion}
                        />
                      </div>
                      <div class="xs:col-span-7 col-span-8 flex flex-col justify-center items-start">
                        <div class="fr-sc resp-gap-2">
                          <FontAwesomeIcon
                            className="text-[1.5rem] text-red-300"
                            icon={faLock}
                          />
                          <p className="resp-text-1 font-digi italic text-red-300">
                            Locked
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                return <BlockRow {...{ card, block, i, key: i }} />;
              })
            )}

            <div class="bg-white/30 min-h-[0.1rem] my-[2rem] mx-auto xs:w-full lg:w-[50rem]"></div>
            <PrizePayouts />
          </>
        ) : (
          <div class="fc-ss w-full">
            <p class="resp-text-1 w-full text-center text-red-300">
              No Trail Cards Found for id={id}
            </p>
          </div>
        )}
      </TrailsCardContext.Provider>
    </PageWrapper>
  );
};

export const RacePage_TrailsCard = ({ cs, racing_vaults, vmap }) => {
  const { vault: auth_vault } = useAccountContext();
  const history = useNavigate();
  return (
    <div class=" cursor-pointer w-full font-digi resp-text--1 resp-gap-2 grid xs:grid-cols-2 lg:grid-cols-3">
      {(cs || []).map((c) => {
        // console.log(c.card, issamevault);
        return (
          <div
            onClick={() => {
              if (c.vault == auth_vault) {
                history(`/trails-cards/${c.id}`);
              } else {
                history("/trails-cards");
              }
            }}
            class="w-full h-[10rem] fr-cc border-2 bg-r2dark/40 border border-acc4 p-2 rounded-md"
          >
            <div class="w-max p-2">
              <TrailsCardImg
                img={c.img}
                className={"xs:h-[2rem] lg:h-[5rem]"}
              />
            </div>
            <div class="fc-ss flex-1 text-white">
              <p className="resp-text--2">Rewarded to</p>
              <p className="resp-text--1">
                {!nils(vmap[c.vault]) ? trim_n(vmap[c.vault], 25) : c.vault}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};
