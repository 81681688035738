import _ from "lodash";
import { useQueries } from "react-query";
import {
  polytxnidlink,
  q_arcade_race_hstats,
  q_bike_mini_info,
  q_fqual_actives,
  q_fqual_entrants,
  q_fqual_info,
  q_fqual_leader,
  q_fqual_signup_enter,
  q_fqual_signup_hvalid,
  q_fqual_signup_vault_hvalids,
  q_hstats,
  q_logtxnqueue,
  qiserr,
  qissuccesss,
  useStepQuery,
  q_fqual_reset_score,
  q_fqual_get_likes,
  q_fqual_set_likes,
  q_fqual_eligraces,
  q_fqual_sheet_allowed,
  q_fqual_updatesheet,
} from "../queries/queries.js";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  cdelay,
  copy_clip,
  dec,
  from_time_mini,
  getv,
  iso,
  iso_format,
  jstr,
  nano,
  nils,
  to_time_mini,
  toeth,
  tofeth,
  emp,
  trim_n,
  snake_str_to_caps,
} from "../utils/utils.js";
import {
  BImg,
  Card,
  Img,
  InpText,
  Tag,
  TokenIcon,
  ToolTip,
} from "../components/utilityComps.js";
import { Loader01c } from "../components/anims.js";
import { createContext } from "react";
import { Link, useParams } from "react-router-dom";
import { elementmap, gendermap, pos_txt, tablecn } from "../utils/cn_map.js";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faChevronDown,
  faChevronRight,
  faCircle,
  faFlagCheckered,
  faThumbsDown,
  faThumbsUp,
  faToggleOff,
  faToggleOn,
  faUsd,
} from "@fortawesome/free-solid-svg-icons";
import { tokdecn, useAppContext, useNowContext } from "../App.js";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { PopUp, PopupCloseBtn } from "../components/popup.js";
import RaceStakeV02 from "../contracts/RaceStakeV02/RaceStakeV02.js";
import RaceStakeV03_DEZ from "../contracts/RaceStakeV03_DEZ/RaceStakeV03_DEZ.js";
import {
  mm_asset_signer,
  que_con_fn_runner,
} from "../contracts/contract_funcs.js";
import { contractAddress_list } from "../contracts/constants.js";
import { faction_img, polychainimg } from "../utils/links";
import { FqualTrainerInfo } from "./ClaimTrainerPage.js";
import { MiniElementTag, MiniGenderTag } from "../components/ShortComps.js";
import { SortHead, sort_fn, sort_list } from "../utils/filt.js";
import { CapsuleSwitch } from "../components/input.js";
import { SkewBtn } from "../utils/raceutils2.js";
import { MoVariants } from "../utils/motion_helper.js";
import { motion } from "framer-motion";
import { RacesListInner } from "./Races.js";
import { RacingDashBackLink } from "./RacingDashboard.js";
import { gen_faction_playinc_card_img } from "./QuestCards.js";
import { RVImg } from "../components/BikeImg.js";

const FQualContext = createContext({});
const useFQualContext = () => useContext(FQualContext);

export const EntryCriRow = ({ ef }) => {
  const efno = ef.fno || null;
  return (
    <div className="fr-sc border-b border-acc1 resp-gap-1 p-1 resp-text--1">
      {!_.isEmpty(ef.type) && (
        <>
          {ef.type.map((e) => (
            <Tag
              className={twMerge(
                "bg-r2dark/60 text-white ",
                "font-digi resp-text-0 transform -skew-x-12",
              )}
            >
              {_.capitalize(e)}
            </Tag>
          ))}
        </>
      )}
      {!_.isEmpty(ef.element) && (
        <>
          {ef.element.map((e) => (
            <Tag
              className={twMerge(
                "text-white fr-sc resp-gap-1",
                "font-digi resp-text-0 transform -skew-x-12",
                elementmap[e]?.bg,
              )}
            >
              <FontAwesomeIcon icon={elementmap[e]?.icon} />
              <span>{_.capitalize(e)}</span>
            </Tag>
          ))}
        </>
      )}

      {nils(efno) ? null : _.isArray(efno) && !_.isEmpty(efno) ? (
        <div className="fr-sc flex-wrap resp-gap-2 resp-text-1 font-digi text-white italic">
          {efno.map((f) => (
            <span>F{f}</span>
          ))}
        </div>
      ) : _.isObject(efno) && !_.isEmpty(efno) ? (
        <div className="resp-text-1 font-digi text-white italic">
          {!nils(efno.mi) && !nils(efno.mx)
            ? `F${efno.mi}-${efno.mx}`
            : nils(efno.mi)
              ? `F${efno.mx}-`
              : nils(efno.mx)
                ? `F${efno.mi}+`
                : null}
        </div>
      ) : null}

      {!nils(ef.skin) && (
        <>
          {ef.skin.by == "any" && (
            <Tag className="text-orange-200">{"SKIN: Any"}</Tag>
          )}
          {["rarity", "name"].includes(ef.skin?.by) && (
            <>
              <Tag className=" text-white">
                {"SKIN: "}
                {_.capitalize(ef.skin?.by)}
              </Tag>
              {ef.skin?.ar?.map((e) => {
                return <span className="text-orange-200 italic px-1">{e}</span>;
              })}
            </>
          )}
        </>
      )}

      {emp(ef.races_n) ? null : (
        <span className="text-acc4">
          {"Races:"}
          {ef.races_n.cb}{" "}
          {nils(ef.races_n.mi) || ef.races_n.mi == 0
            ? `<${ef.races_n.mx}`
            : nils(ef.races_n.mx) || ef.races_n.mx == 0
              ? `>${ef.races_n.mi}`
              : `${ef.races_n.mi}-${ef.races_n.mx}`}
        </span>
      )}
    </div>
  );
};

const ScoringChart = ({ f }) => {
  // const { f } = useFQualContext();
  const cris = f?.scoring?.criteria;
  const by = getv(f, "scoring.scoring_by") ?? "pos";

  const is_trainer_fqual = /trainer/i.test(f.name);
  const trainer_fq_qual_n = getv(f, "trainer_highlights");

  return (
    <>
      <div className="fr-cc resp-my-1">
        <div className="fc-ss">
          <div className="fr-sc resp-gap-2">
            <span className="resp-text-0">Qualifying Score:</span>
            {by == "prize_eth" ? (
              <>
                <FontAwesomeIcon icon={faEthereum} className="text-r2lig" />
                <span className="font-digi italic text-r2lig resp-text--2">
                  {dec(f.qualifying_score, 4)}
                </span>
              </>
            ) : (
              <span className="font-digi italic text-r2lig resp-text-1">
                {dec(f.qualifying_score, 2)}
              </span>
            )}
          </div>
          {is_trainer_fqual && !nils(trainer_fq_qual_n) ? (
            <>
              <div class="fc-ss">
                <div class="fr-sc resp-text-0">
                  <span>using regulars: </span>
                  <span className="font-digi italic text-r2lig resp-text--1">
                    TOP_{dec(trainer_fq_qual_n.split("-")[0], 0)}
                  </span>
                </div>
                <div class="fr-sc resp-text-0">
                  <span>using trainers:</span>
                  <span className="font-digi italic text-r2lig resp-text--1">
                    TOP_{dec(trainer_fq_qual_n.split("-")[1], 0)}
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              {nils(f?.qualifiers_n) ? null : (
                <div className="fr-sc resp-gap-2 resp-text--1">
                  <span>using till</span>
                  <span className="font-digi italic text-r2lig resp-text-1">
                    {`TOP ${f.qualifiers_n} ${f.qualifiers_n_mode == "%age" ? "%" : ""}`}
                  </span>
                </div>
              )}
            </>
          )}
        </div>
        <div className="flex-1"></div>
      </div>

      {!_.isEmpty(cris) && (
        <Show_Drop show_txt="Show Scoring">
          <div className="w-full">
            {cris.map((c) => {
              const r = c.race;
              const sc = c.posscores;
              return (
                <div className="w-full py-1 my-1 border-b border-acc0">
                  <div className="fr-sc flex-wrap resp-gap-2">
                    <span className="resp-text-0">On Race</span>
                    {!nils(r.is_chain) && (
                      <Tag className="bg-acc0/40 font-digi transform -skew-x-12">
                        {r.is_chain == true && "PAID"}
                        {r.is_chain == false && "FREE"}
                      </Tag>
                    )}
                    {!_.isEmpty(r.rgate) && (
                      <Tag className="bg-purple-400/50 font-digi transform -skew-x-12">
                        {r.rgate.join(", ")} Gates
                      </Tag>
                    )}
                    {!_.isEmpty(r.cb) &&
                      r.cb.map((e) => (
                        <Tag className="bg-acc1/40 font-digi transform -skew-x-12">
                          CB{e}00
                        </Tag>
                      ))}
                    {!_.isEmpty(r.format) &&
                      "normal,reduced,spin_n_go" !== r.format.join(",") &&
                      r.format.map((e) => (
                        <Tag className="bg-acc1/40 font-digi transform -skew-x-12">
                          {_.upperCase(e)}
                        </Tag>
                      ))}
                  </div>
                  <table
                    className={twMerge(
                      tablecn.table,
                      "w-full max-w-full bg-r2dark/60",
                    )}
                  >
                    <tbody>
                      <tr className={"thintdrow"}>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => (
                          <td>
                            <span
                              className={twMerge(
                                "resp-text-0",
                                e <= 3 ? "text-r2lig" : "",
                              )}
                            >
                              {pos_txt(e)}
                            </span>
                          </td>
                        ))}
                      </tr>
                      <tr className={"thintdrow"}>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e, idx) => (
                          <td>
                            <span className="resp-text-0">{sc[idx]}</span>
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
          </div>
        </Show_Drop>
      )}
    </>
  );
};

const LikeDislike_Section = ({ useContext }) => {
  const { fid, f, fl } = useContext();
  const { vault, auth } = useAuthContext();

  const [qolikes] = useQueries([
    q_fqual_get_likes(
      { fid, vault },
      {
        staleTime: 30 * 1e3,
        refetchInterval: 30 * 1e3,
      },
    ),
  ]);

  const [likeo, set_likeo] = useState({});
  useEffect(() => {
    if (qissuccesss(qolikes)) {
      let o = getv(qolikes, "data.result");
      set_likeo(o);
    }
  }, [qolikes.dataUpdatedAt]);

  useEffect(() => {
    // console.log("likeo", likeo);
  }, [jstr(likeo)]);

  const set_like_motion = async (lcode) => {
    let motion = lcode == 1 ? "like" : lcode == -1 ? "dislike" : null;
    // console.log("set_like_motion", lcode, motion);
    if (motion == null) return;

    let lo = _.cloneDeep(likeo);
    if (lo.vault_like === motion) return;

    if (!nils(lo.vault_liked) && lo.vault_liked !== motion) {
      lo[lo.vault_liked] -= 1;
    }
    lo.vault_liked = motion;
    lo[motion] += 1;

    // console.log("set_like_motion", lo);
    set_likeo(lo);

    let doc = { fid, vault, motion };
    await q_fqual_set_likes(doc).queryFn();

    // qolikes.refetch();
  };

  return (
    <div class="fr-sc resp-gap-2 font-digi cursor-pointer p-1">
      <div
        onClick={() => {
          if (!auth) return;
          set_like_motion(1);
        }}
        class={twMerge(
          "fr-sc resp-gap-1",
          likeo.vault_liked == "like" ? "text-acc0" : "text-white",
        )}
      >
        <FontAwesomeIcon icon={faThumbsUp} />
        <span>{getv(likeo, "like", 0)}</span>
      </div>
      <div
        onClick={() => {
          if (!auth) return;
          set_like_motion(-1);
        }}
        class={twMerge(
          "fr-sc resp-gap-1",
          likeo.vault_liked == "dislike" ? "text-acc0" : "text-white",
        )}
      >
        <FontAwesomeIcon icon={faThumbsDown} />
        <span>{getv(likeo, "dislike", 0)}</span>
      </div>

      <a
        href="https://twitter.com/share?ref_src=twsrc%5Etfw"
        class="twitter-share-button"
        data-text={`I love the "${f?.name}"`}
        data-url="https://www.dnaracing.run/"
        data-hashtags="dnaracing"
        data-related="dnaracing2023,spotonparts"
        data-show-count="false"
      >
        Tweet
      </a>
    </div>
  );
};

const Rules = ({ f }) => {
  // const { f } = useFQualContext();
  const rules = f.rules || [];
  const title_rules = f.title_rules || [];

  return (
    <>
      <>
        <div className="w-full">
          {_.isEmpty(title_rules) &&
            rules.map((c, i) => {
              return (
                <div className="flex flex-row justify-start items-start resp-gap-1 my-1">
                  <span className="resp-text-0">{i + 1}.</span>
                  <p className="resp-text-0 whitespace-pre flex-1">{c}</p>
                </div>
              );
            })}
        </div>
        <div className="w-full">
          <table className={twMerge(tablecn.table, "w-full")}>
            {title_rules.map(([a, b]) => (
              <tr className="thintdrow">
                <td className="resp-text-0 text-acc0">
                  <p className=" whitespace-pre-line resp-px-2 resp-text--2 font-digi">
                    {a}
                  </p>
                </td>
                <td className="resp-text-0">
                  <p className="whitespace-pre-line resp-px-2 resp-text--2 font-mon">
                    {b}
                  </p>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </>
    </>
  );
};

const SignUpHrow = ({
  hid,
  h,
  fid,
  f,
  show_entry_status,
  entry_confirmed,
  popresp,
  set_popresp,
  close_popup,
}) => {
  const { vault } = useAccountContext();
  const fcon = useFQualContext();
  const { conf_entrants_n } = fcon;

  const hdets = (
    <div className="fr-sc resp-gap-2 font-mon text-acc0 resp-text--2">
      <div className="fr-cc xs:w-[1rem] lg:w-[2rem] resp-gap-1">
        <span>F{h.fno}</span>
      </div>
      <span>/</span>
      <div className="fr-cc xs:w-[2rem] lg:w-[4rem] resp-gap-1">
        <span>{_.capitalize(h.type)}</span>
      </div>
    </div>
  );

  const hname = (
    <div className="fr-sc resp-gap-1">
      <Link to={`/bike/${h.hid}`}>
        <p className="resp-text-1 font-digi">{h.name}</p>
      </Link>
    </div>
  );

  const hvault = (
    <Link to={`/vault/${h.vault}`}>
      <p className="resp-text--3 font-digit text-acc0">{h.vault_name}</p>
    </Link>
  );

  const himg = <BImg className={"w-[2rem] h-[2rem]"} hex_code={h.hex_code} />;

  const hhidrow = (
    <div className="fr-sc resp-gap-2">
      <span className="resp-text--3">{hid}</span>
    </div>
  );

  const hentrystatus = show_entry_status ? (
    <>
      <div className="fr-cc resp-gap-1">
        <div className="flex-1"></div>

        <>
          {entry_confirmed == true && (
            <span className="resp-text--1 text-green-300">
              {"Entry Confirmed!!"}
            </span>
          )}
          {entry_confirmed == false && (
            <>
              <Loader01c className="bg-orange-400" size="s" />
              <span className="resp-text--1 text-orange-300">
                {"Awaiting..."}
              </span>
            </>
          )}
        </>
      </div>
    </>
  ) : (
    <></>
  );

  const hlisting = nils(h.listing) ? null : (
    <>
      <Tag
        redirect={`https://market.dnaracing.run/asset/core/${hid}`}
        className={twMerge(
          "resp-text--1 text-acc0 fr-sc border border-r2lig resp-gap-1 bg-r2dark",
        )}
      >
        <span>{dec(getv(h.listing, "price"), 4)}</span>
        <span>{"WETH"}</span>
      </Tag>
    </>
  );

  const [loading, set_loading] = useState(false);
  const init_signup = async () => {
    try {
      set_loading(true);
      await cdelay(500);
      set_popresp({ loading: true, msg: `Signing up Bike#${hid}` });

      let resp = await q_fqual_signup_hvalid({ fid, hid }).queryFn();
      let valid = getv(resp, "result.valid");
      console.log(resp, valid);
      if (valid !== true)
        throw new Error("bike is not valid to signup for this fqual");

      let version = getv(f, "signup.version");
      let rid = getv(f, "signup_rid");
      let fee = parseFloat(getv(f, "signup.fee"));
      let not_chain = version == 5;
      if (fee === 0 || not_chain) {
        resp = await q_fqual_signup_enter({
          fid,
          hid,
        }).queryFn();
        if (resp.status == "error") throw new Error(getv(resp, "err"));

        set_popresp({
          loading: true,
          msg: `Bike will be added in a few minutes`,
        });

        set_loading(false);
        setTimeout(close_popup, 2000);
      } else if (!nils(fee) && fee > 0) {
        let [rcon, paytoken] =
          version == 2
            ? [await RaceStakeV02.get_contract(), "WETH"]
            : version == 3
              ? [await RaceStakeV03_DEZ.get_contract(), "DEZ"]
              : [null, null];

        if (nils(rcon)) throw new Error("invalid version of fqual");
        let payconaddr =
          contractAddress_list[
            paytoken == "WETH" ? "weth" : paytoken == "DEZ" ? "dez" : null
          ];
        if (nils(payconaddr)) throw new Error("invalid paytoken");

        let paycon = await mm_asset_signer(null, 20, payconaddr);
        if (nils(paycon)) throw new Error("invalid paytoken");

        let bal = await paycon.balanceOf(vault);
        bal = parseFloat(tofeth(bal));
        if (nils(bal)) throw new Error("invalid balance");
        if (bal < fee) throw new Error("insufficient balance");

        let alw = await que_con_fn_runner(
          async () => paycon.allowance(vault, rcon.contractAddress),
          `allowance-${rid}`,
        );
        alw = parseFloat(tofeth(alw));
        if (nils(alw)) throw new Error("invalid allowance");
        if (alw < fee) {
          set_popresp({
            loading: true,
            msg: `Confirm Metamask for token allowance`,
          });
          const approval = await paycon.approve(
            rcon.contractAddress,
            toeth(fee),
          );
          await approval.wait();
          await cdelay(1000);
        }

        resp = await q_fqual_signup_enter({
          fid,
          hid,
        }).queryFn();
        if (resp.status == "error") throw new Error(getv(resp, "error"));

        set_popresp({ loading: true, msg: `Confirm Metamask for signup` });
        let tx_ext_info = {};
        console.log(
          "conf_entrants_n",
          conf_entrants_n,
          !nils(conf_entrants_n) && conf_entrants_n > 20,
        );
        if (!nils(conf_entrants_n) && conf_entrants_n > 20) {
          tx_ext_info.gasLimit = 280000 + 20000 * conf_entrants_n;
        } else {
          tx_ext_info.gasLimit = 280000 + 20000 * 50;
        }
        let tx = await rcon.enterRace(rid, hid, tx_ext_info);

        if (nils(tx.hash)) throw new Error("invalid tx hash");
        if (tx?.hash) {
          await q_logtxnqueue({
            data: {
              type: "after_cont",
              rid,
              hid,
              vault,
              time: iso(),
              hash: tx.hash,
            },
          }).queryFn();
        }

        set_popresp({
          loading: true,
          msg: `Bike will be added in a few minutes`,
        });
      }
      set_loading(false);
      setTimeout(close_popup, 2000);
    } catch (err) {
      console.log(err);
      let msg = null;
      if (!nils(err.reason)) msg = err.reason.slice(0, 100);
      else if (!nils(err.message)) msg = err.message.slice(0, 100);
      else msg = "unknown error";
      set_popresp({ loading: false, status: "error", msg });
      set_loading(false);
    }
  };

  const SignUpBtn = (
    <>
      {loading ? (
        <span className="text-acc0 resp-text--1">signing up...</span>
      ) : (
        <Tag onClick={init_signup} className="bg-acc0/40 font-digi">
          <span className="resp-text-0">{"Signup"}</span>
        </Tag>
      )}
    </>
  );

  return (
    <Card className={twMerge("resp-p-2 resp-gap-2 w-full bg-r2dark/60")}>
      <div className="xs:hidden lg:block ">
        <div className="fr-sc w-full resp-gap-2">
          {himg}
          <div className="fc-ss">
            {hhidrow}
            {hname}
            {hvault}
          </div>
          <div className="flex-1 fr-cc">
            <div className="flex-1"></div>
            <div className="fc-cc">
              {hdets}
              {hlisting}
            </div>
          </div>
          <div className="flex flex-col justify-end items-end resp-gap-2">
            {SignUpBtn}
            {hentrystatus}
          </div>
        </div>
      </div>
      <div className="lg:hidden xs:block">
        <div className="fr-cc w-full">
          {hname}
          <div className="flex-1"></div>
          <div className="fc-cc">{hdets}</div>
        </div>
        <div className="fr-cc w-full">
          <div className="fc-ss">{hvault}</div>
          <div className="flex-1"></div>
          <div className="flex flex-col justify-end items-end resp-gap-1">
            {SignUpBtn}
            {hentrystatus}
          </div>
        </div>
      </div>
    </Card>
  );
};
const SignupSection = ({}) => {
  const { now } = useNowContext();
  const fcon = useFQualContext();
  const { f, fl, copytosheet, fe, conf_entrants_n } = fcon;

  const appcon = useAppContext();
  const { tok_to_usd_val, usd_to_tok_val, tokmap } = appcon;

  const accon = useAccountContext();
  const { vault, bikesob } = accon;

  const paytoken =
    getv(f, "signup.version") == 2
      ? "WETH"
      : getv(f, "signup.version") == 3
        ? "DEZ"
        : getv(f, "signup.version") == 5
          ? "TP"
          : null;

  const fee_usd = useMemo(() => {
    let fee = getv(f, "signup.fee");
    let fee_usd = tok_to_usd_val(fee, paytoken);
    return fee_usd;
  }, [jstr(f), jstr(tokmap)]);

  const [pop, set_pop] = useState(false);
  const [popresp, set_popresp] = useState({});

  const [qo_valids] = useQueries([
    q_fqual_signup_vault_hvalids({ fid: f.fid, vault }, { enabled: pop }),
  ]);
  const hids = useMemo(() => {
    if (qissuccesss(qo_valids)) return getv(qo_valids, "data.result");
    return [];
  }, [qo_valids.dataUpdatedAt]);
  // const qshs = useStepQuery({
  //   q_: q_bike_mini_info,
  //   par_ar: (hids || []).map((hid) => [{ hid }]),
  //   lim: 3,
  //   options: { enabled: pop },
  // });

  const [searchtxt, set_searchtxt] = useState(null);
  const filths = useMemo(() => {
    if (nils(searchtxt)) return hids;
    let sech_hid = parseInt(searchtxt);
    if (nils(sech_hid)) sech_hid = null;
    let sear = _.lowerCase(searchtxt);

    let filt = _.chain(hids)
      .map((hid) => {
        let h = bikesob[hid];

        let hname = _.lowerCase(h.name);
        if (!nils(sech_hid) && sech_hid == h.hid) return [hid, 1];
        else if (
          !nils(sech_hid) &&
          h.hid.toString().startsWith(sech_hid.toString())
        )
          return [h, 2];
        else if (hname.startsWith(sear)) return [hid, 3];
        else if (hname.includes(sear)) return [hid, 4];
        else return null;
      })
      .compact()
      .sortBy((e) => e[1])
      .map(0)
      .value();
    console.log("enter filt", filt);
    return filt;
  }, [jstr(hids), searchtxt, jstr(bikesob)]);

  const open_popup = () => {
    set_pop(true);
    if (!qo_valids.isLoading) {
      qo_valids.refetch();
    }
  };
  const close_popup = () => {
    set_pop(false);
  };

  const is_blind = getv(f, "signup.is_blind") == true;

  return (
    <>
      <Card className={"my-2 card-dark-bg w-full"}>
        <div className="fr-sc resp-text--1">
          <div className="fc-ss">
            <div className="fr-sc resp-gap-2 text-acc0">
              <span>Signup Fee:</span>
              {paytoken == "TP" ? (
                <>
                  <div className="flex flex-col justify-start items-start">
                    <span className="resp-text-1 font-digi">
                      {dec(fee_usd, 2)}
                    </span>
                    <span>Trail Points</span>
                  </div>
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faUsd} />
                  <div className="flex flex-col justify-start items-start">
                    <span className="resp-text-1 font-digi">
                      {dec(fee_usd, 2)}
                    </span>
                  </div>
                </>
              )}
            </div>

            <div
              className={twMerge(
                "fr-sc",
                "my-1 resp-text--2 w-full text-right",
                paytoken == "DEZ"
                  ? "text-acc0"
                  : paytoken == "TP"
                    ? "text-purple-400"
                    : "text-pink-400",
              )}
            >
              <span>{"Pay with "}</span>
              <div className="w-[2rem]">
                <TokenIcon token={paytoken} />
              </div>
              <span>{paytoken}</span>
            </div>
          </div>
          <div className="flex-1"></div>
          <div className="fc-cc reap-gap-2">
            {now < nano(getv(f, "qualifying_times.st")) &&
              conf_entrants_n < getv(f, "signup.rgate") && (
                <>
                  <Tag
                    onClick={() => open_popup()}
                    className="bg-acc0/40 -skew-x-12 font-digi"
                  >
                    <span className="resp-text-1">SignUP</span>
                  </Tag>
                </>
              )}
            {is_blind !== true && (
              <span className="font-digi resp-text--1 my-2">
                {conf_entrants_n} / {getv(f, "signup.rgate")} Entrants
              </span>
            )}
          </div>
        </div>
      </Card>
      <PopUp
        wrapcn={"top-[6rem]"}
        innercn={"translate-y-[0%]"}
        overlayclose={false}
        openstate={pop}
        onClose={close_popup}
      >
        <Card
          className={
            "max-w-[98vw] w-[70rem] bg-r2lig/20 backdrop-blur-md border border-acc4 resp-text--2"
          }
        >
          <div className="fr-sc">
            <div className="flex-1"></div>
            <PopupCloseBtn {...{ closepopup: close_popup }} />
          </div>

          <div className="my-2">
            {_.isEmpty(popresp) ? null : (
              <div
                className={twMerge(
                  "fr-sc resp-gap-2",
                  "resp-text--1 border rounded-md resp-p-2",
                  popresp.status == "error"
                    ? "border-red-400 text-red-400"
                    : "border-r2lig text-r2lig",
                )}
              >
                {popresp.loading && <Loader01c size="s" />}
                <span>{popresp.msg}</span>
              </div>
            )}
          </div>

          <div className="fr-cc resp-gap-1 resp-px-1 resp-my-1">
            <div className="flex-1">
              <InpText
                {...{
                  id: `entry-search-fqual-${f.fid}`,
                  placeholder: "Search Core",
                  inpprops: {
                    className:
                      "resp-text-1 font-digi bg-tranparent text-acc0 w-full",
                  },
                  contprops: {
                    className:
                      "resp-text-1 font-digi bg-r2dark/20 text-acc0 w-full",
                  },
                  setter: (v) => {
                    console.log("setter", v);
                    if (nils(v)) v = null;
                    set_searchtxt(v);
                  },
                }}
              />
            </div>
          </div>

          <p className="text-center resp-text--1  "></p>
          {qo_valids.isLoading ? (
            <div className="fr-cc resp-text-1 resp-gap-2 text-acc4">
              <Loader01c size="s" />
              <span>Searching eligible bikes...</span>
            </div>
          ) : qiserr(qo_valids) ? (
            <p className="text-center text-red-400">{qiserr(qo_valids)}</p>
          ) : qissuccesss(qo_valids) && _.isEmpty(hids) ? (
            <p className="text-center text-yellow-400">
              {"no valid bikes that can signup"}
            </p>
          ) : qissuccesss(qo_valids) && !_.isEmpty(hids) ? (
            <div className="overflow-auto xs:h-[60vh] lg:h-[60vh]">
              <p className="text-center text-acc4 font-digi resp-text-1 my-2">
                Select Bike to SignUp
              </p>
              {filths.map((hid, idx) => {
                let h = getv(bikesob, hid);
                if (_.isEmpty(h)) return null;
                return (
                  <SignUpHrow
                    key={hid}
                    {...{
                      hid,
                      h,
                      fid: f.fid,
                      f,
                      popresp,
                      set_popresp,
                      close_popup,
                    }}
                  />
                );
              })}
            </div>
          ) : null}
        </Card>
      </PopUp>
    </>
  );
};

export const Show_Drop = ({ show_txt = "show", children }) => {
  const [show, set_show] = useState(false);

  return (
    <Card className={"card-dark-bg resp-p-2 w-full"}>
      {show ? (
        <div class="fr-sc">
          <div class="flex-1"></div>
          <div onClick={() => set_show(false)} className="p-1 cursor-pointer">
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </div>
      ) : (
        <div
          onClick={() => {
            set_show(true);
          }}
          class="fr-sc resp-gap-2"
        >
          <p class="resp-text--1 flex-1">{show_txt}</p>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      )}
      <motion.div
        variants={MoVariants.show_hide}
        initial="hidden"
        animate={show ? "visible" : "hidden"}
      >
        <>{children}</>
      </motion.div>
    </Card>
  );
};

const FqualEntryCriteriaSection = ({ txt_fn = (i) => `E${i + 1}` }) => {
  const { f } = useFQualContext();
  if (nils(f)) return null;
  return (
    <>
      {f.entry_filters.map((ef, i) => {
        return (
          <div className="fr-sc">
            <div className="">
              <span className="resp-text-0">{txt_fn(i)}</span>
            </div>
            <div className="flex-1">
              <EntryCriRow ef={ef} />
            </div>
          </div>
        );
      })}
    </>
  );
};

export const InfoCard = ({ useContext = useFQualContext }) => {
  const fcon = useContext();
  // const { f, fl, copytosheet } = fcon;
  const { fid, f, fl } = fcon;

  const copytosheet = () => {
    let leader_mode = getv(f, "leader_mode") ?? "bike";
    if (leader_mode == "faction") {
    } else if (leader_mode == "vault") {
      let score_by = getv(f, "scoring.scoring_by") ?? "pos";
      if (score_by == "quest_cards") {
        let hs = _.chain(fl.qualifiers)
          .map((v) => {
            v._hand = v.hand.join(",");
            return v;
          })
          .value();

        let str = "";
        let keys = [
          "rank",
          "vault",
          "vault_name",
          "_hand",
          "score",
          "combo",
          "combo_txt",
          "score",
          "disp_score",
        ];

        for (let k of keys) str += `${k}	`;
        str += "\n";
        for (let h of hs) {
          for (let k of keys) str += `${h[k]}	`;
          str += "\n";
        }
        copy_clip(str);
      } else {
        let hs = _.chain(fl.qualifiers)
          .map((v) => {
            return v;
          })
          .value();

        let str = "";
        let keys = [
          "rank",
          "vault",
          "vault_name",
          "races_n",
          "sel_races_n",
          "score",
        ];

        for (let k of keys) str += `${k}	`;
        str += "\n";
        for (let h of hs) {
          for (let k of keys) str += `${h[k]}	`;
          str += "\n";
        }
        copy_clip(str);
      }
    } else if (leader_mode == "faction_agent") {
      let hs = _.chain(fl.qualifiers)
        .map((qh) => {
          let vault = fl.agmap[qh.agid]?.vault;
          let vault_name = fl.vsmap[vault];
          return { ...qh, ...fl.agmap[qh.agid], vault_name };
        })
        .value();

      let str = "";
      let keys = [
        "rank",
        "agid",
        "agent_name",
        "faction",
        "qualified",
        "rarity",
        "score",
        "vault",
        "vault_name",
      ];

      for (let k of keys) str += `${k}	`;
      str += "\n";
      for (let h of hs) {
        for (let k of keys) str += `${h[k]}	`;
        str += "\n";
      }
      copy_clip(str);
    } else if (leader_mode == "bike") {
      let hs = fl.qualifiers.map((qh) => {
        let hdets = fl.hsmap[qh.hid];
        let h = { ...qh, ...hdets };
        h.vault_name = fl.vsmap[hdets.vault];
        return h;
      });
      hs = _.filter(hs, (h) => h.qualified == true);

      let str = "";
      let keys = [
        "rank",
        "hid",
        "name",
        "vault",
        "vault_name",
        "score",
        "races_n",
      ];

      for (let k of keys) str += `${k}	`;
      str += "\n";
      for (let h of hs) {
        for (let k of keys) str += `${h[k]}	`;
        str += "\n";
      }
      copy_clip(str);
    }
  };

  const is_trainer = useMemo(() => {
    if (_.isEmpty(f)) return false;
    let mat = /trainer/i.test(f.name);
    return mat;
  }, [jstr(f)]);

  const scoring_jsx = <ScoringChart {...{ f }} />;
  const rules_jsx = <Rules {...{ f }} />;
  const entry_filts_jsx = !_.isEmpty(f?.entry_filters) ? (
    <div className="my-2">
      <FqualEntryCriteriaSection />
    </div>
  ) : (
    <p class="my-2 text-white text-center">No Entry Criteria for this FQual</p>
  );

  return (
    <>
      <Card
        className={
          "bg-r2lig/20 backdrop-blur-md border border-acc4 w-full mx-auto max-w-[45rem]"
        }
      >
        <div className="fr-sc">
          <div className="flex-1 font-digi text-center resp-text-2 resp-px-2">
            {f.name}
          </div>
        </div>
        <div className="fr-sc my-2 resp-gap-2">
          <div className="flex-1"></div>
          <div className="flex flex-col justify-end items-end">
            <div className="fr-sc resp-gap-2">
              <span>Status</span>
              <span className="text-green-300">{_.upperCase(f.status)}</span>
            </div>

            {f.status == "yettostart" && (
              <>
                <span>Starting at {iso_format(f.qualifying_times.st)}</span>
                <span>in {from_time_mini(f.qualifying_times.st)}</span>
              </>
            )}
            {f.status == "qualifying" && (
              <>
                <span>Ends in {from_time_mini(f.qualifying_times.ed)}</span>
              </>
            )}
          </div>
        </div>

        {scoring_jsx}
        {!_.isEmpty(f.entry_filters) && (
          <Show_Drop show_txt="Show Entry Criteria">
            {entry_filts_jsx}
          </Show_Drop>
        )}
        {(!_.isEmpty(f.rules) || !_.isEmpty(f.title_rules)) && (
          <Show_Drop show_txt="Show Rules">{rules_jsx}</Show_Drop>
        )}

        {getv(f, "signup.auto_signup_race") && !nils(getv(f, "signup_rid")) && (
          <>
            <SignupSection />
          </>
        )}
        <div className="fr-sc"></div>
        <div className="fr-sc">
          <LikeDislike_Section
            {...{
              useContext,
            }}
          />
          <div class="flex-1"></div>
          {!_.isEmpty(fl.qualifiers) && (
            <Tag
              onClick={copytosheet}
              className=" bg-r2lig/60 text-white font-digi transform"
            >
              Copy Qualifiers
            </Tag>
          )}
        </div>
      </Card>

      {is_trainer && (
        <div className={"mx-auto max-w-[70rem]"}>
          <FqualTrainerInfo />
        </div>
      )}
    </>
  );
};

const ResetScore = ({ ext = {} }) => {
  const fcon = useFQualContext();
  const { f } = fcon;
  const [pop, set_pop] = useState(false);
  const [popresp, set_popresp] = useState({});

  const reset_score = async () => {
    try {
      set_popresp({ loading: true, msg: "Resetting Score..." });
      let resp = await q_fqual_reset_score({ fid: f.fid, ...ext }).queryFn();
      if (resp.status == "error") throw new Error(getv(resp, "err"));
      let done = getv(resp, "result.done");
      if (done == 1)
        set_popresp({ loading: false, type: "success", msg: "Score Reset" });
      await cdelay(2000);
      set_pop(false);
      set_popresp({});
    } catch (err) {
      console.log(err);
      set_popresp({ loading: false, type: "error", msg: err.message });
      setTimeout(() => {
        set_popresp({});
      }, 5 * 1e3);
    }
  };

  const close_popup = () => {
    set_pop(false);
    set_popresp({});
  };
  return (
    <div className="">
      <Tag
        onClick={() => {
          set_pop(true);
        }}
        className="bg-acc4/40 font-digi resp-text--2"
      >
        <span class="w-max">Reset</span>
      </Tag>
      <PopUp openstate={pop} onClose={close_popup}>
        <Card
          className={
            "bg-r2lig/20 backdrop-blur-md border border-acc4 xs:w-[98vw] lg:w-[40rem]"
          }
        >
          <PopupCloseBtn {...{ closepopup: close_popup }} />
          <div class="fc-cc resp-text-1 my-[1rem]">
            <div class="text-center my-[1rem]">
              <p className="">Are you sure you want to reset you score?</p>
              <p className="">** This action is not reversible **</p>
            </div>
            <div class="fr-cc">
              {popresp?.loading ? null : (
                <Tag onClick={reset_score} className="bg-acc0/40 font-digi">
                  <span>Confirm Reset</span>
                </Tag>
              )}
            </div>
          </div>

          {!_.isEmpty(popresp) && (
            <div
              class={twMerge(
                "my-1 resp-p-2 rounded-md",
                "resp-text-0 text-center",
                "border border-trasparent",
                popresp.type == "error"
                  ? "text-red-400 border-red-400"
                  : "text-green-400 border-green-400",
              )}
            >
              <p>{popresp.msg}</p>
            </div>
          )}
        </Card>
      </PopUp>
    </div>
  );
};

const PodiumImg = ({ pos1, rvmode }) => {
  const base = "https://cdn.dnaracing.run/imgs";
  // const base = "/assets";
  const bg_img = `${base}/fqual-pos1img/podium.png`;
  const rv_img = `${base}/fqual-pos1img/${rvmode}.png`;
  return (
    <>
      <div class="relative xs:w-[50rem] lg:w-[70rem] max-w-[95vw] mx-auto aspect-[1200/673]">
        <div class="absolute z-[1] top-0 left-0 w-full h-full">
          <Img img={bg_img} />
        </div>
        <div class="absolute z-[2] top-0 left-0 w-full h-full">
          <Img img={rv_img} />
        </div>
        <div class="absolute z-[3] fc-cc w-full left-0 bottom-[15%] font-digi">
          <span class="resp-text-3">{pos1.name}</span>
          <span class="resp-text-2">{pos1.vault_name}</span>
        </div>
      </div>
    </>
  );
};

export const Leaderboard = ({ useContext = useFQualContext }) => {
  const { auth } = useAuthContext();
  const { vault, hids: vhids } = useAccountContext();
  const fcon = useContext();
  const { f = {}, fl, my, set_my } = fcon;
  const by = getv(f, "scoring.scoring_by") ?? "pos";
  const skinevent = getv(f, "skinevent");

  const get_xmas_highlight = (h) => {
    let tops = [...(f.top_grinds || []), ...(f.top_quals || [])];
    if (tops.includes(h.hid)) {
      return ["Frostbite", "Bad Santa"].includes(h.currskin)
        ? "bg-red-500/50"
        : ["North Star", "Good Santa"].includes(h.currskin)
          ? "bg-blue-500/50"
          : "";
    } else {
      return h.qualified == true ? "bg-acc0/20" : "";
      // return "";
    }
  };

  const leader_mode = getv(f, "leader_mode") ?? "bike";
  const rvmode = getv(f, "rvmode") ?? "bike";

  // const { sorts, set_sorts, sort_ob, qolists } = con;
  const [sorts, set_sorts] = useState([]);
  const sort_ob = {
    rank: { disp: "Rank", fn: (i1) => sort_fn(i1, "rank", "n") },
    hid: { disp: "HID", fn: (i1) => sort_fn(i1, "hid", "n") },
    totraces: {
      disp: "Tot.Race",
      fn: (i1) => sort_fn(i1, "races_n", "n"),
    },
    selraces: {
      disp: "Sel.Race",
      fn: (i1) => sort_fn(i1, "sel_races_n", "n"),
    },
    score: { disp: "Score", fn: (i1) => sort_fn(i1, "score", "n") },
    skin: { disp: "Skin", fn: (i1) => sort_fn(i1, "skinleague", "n") },
  };
  const sortargs = { sorts, set_sorts, sort_ob };

  const hs = useMemo(() => {
    return (
      _.chain(fl.qualifiers)
        .map((qh) => {
          let h = qh;
          let owns = false;
          if (nils(leader_mode) || leader_mode == "bike") {
            let hdets = fl.hsmap[qh.hid] || {};
            h = { ...qh, ...hdets };
            owns = h.vault == vault;
            h.vault_name = fl.vsmap[hdets?.vault];
          } else if (leader_mode == "vault") {
            let vault_name = fl.vsmap[qh.vault];
            h.vault_name = vault_name;
            owns = vault == qh.vault;
          } else if (leader_mode == "faction_agent") {
            let ag = fl.agmap[qh.agid] || {};
            h = { ...qh, ...ag };
            h.vault_name = fl.vsmap[ag.vault];
          }
          const show = my == true ? h.vault == vault : true;
          const key = leader_mode == "vault" ? qh.vault : h.hid;
          const id = leader_mode == "vault" ? qh.vault : h.hid;
          return { id, ...h, show, key, owns };
        })
        // .filter((e) => e.show)
        .value()
    );
  }, [jstr(fl.qualifiers), my]);

  const sorted_hs = useMemo(() => {
    if (_.isEmpty(hs)) return [];

    let ar = sort_list({
      list: hs.filter((e) => e.show),
      sorts,
      sort_ob,
      id: "id",
    });

    return ar;
  }, [jstr(hs), jstr(sorts), jstr(sort_ob), my]);

  const capmap = _.chain([
    [true, leader_mode == "vault" ? "My Vault" : "My Bikes"],
    [false, "All"],
  ])
    .fromPairs()
    .value();

  const f_by = getv(f, "scoring.scoring_by") ?? "pos";
  const entry_user_resettable = getv(f, "entry_user_resettable") ?? false;

  const pos1 = useMemo(() => {
    return getv(hs, "0");
  }, [jstr(hs)]);

  return (
    <>
      {leader_mode == "bike" && !_.isEmpty(pos1) && (
        <PodiumImg {...{ pos1, rvmode: getv(f, "rvmode.0") ?? "bike" }} />
      )}

      <div class="fr-sc resp-gap-2 my-2">
        <div class="flex-1"></div>
        <Link to={`/season5-page`}>
          <p class="text-acc4 font-digi underline underline-offset-4 resp-text-1">
            currently on Season 5
          </p>
        </Link>
      </div>

      {!_.isEmpty(f?.multi_finale_rids) ? (
        <>
          <div class="fr-cc my-[1rem]">
            <span className="font-digi resp-text-1 italic flex-wrap">
              Multi Finale Races:{" "}
            </span>
            {f.multi_finale_rids.map((rid, i) => {
              let idx = i + 1;
              return (
                <Link to={`/race/${rid}`}>
                  <Tag className="bg-acc1 shadow-lg shadow-r2lig text-white font-digi transform -skew-x-12">
                    <span className="resp-text-1">Finale Race #{idx}</span>
                  </Tag>
                </Link>
              );
            })}
          </div>
        </>
      ) : f?.finale_created == true ? (
        <div className="my-4 p-2 fr-cc resp-gap-2 w-full">
          <Link to={`/race/${f.finale_rid}`}>
            <Tag className="bg-acc1 shadow-lg shadow-r2lig text-white font-digi transform -skew-x-12">
              <span className="resp-text-1">Finale Race</span>
            </Tag>
          </Link>
        </div>
      ) : null}

      {skinevent == "xmas2023" && f.xmas_nntotals == true && (
        <>
          <div className="grid grid-cols-2 resp-gap-4">
            {[
              ["nice", "", "text-blue-400", "bg-blue-800"],
              ["naughty", "", "text-red-400", "bg-red-800"],
            ].map((e) => {
              let [k, a, txtcn, bgcn] = e;
              return (
                <Card
                  className={twMerge(
                    "bg-opacity-30 w-full mx-auto max-w-[12rem]",
                    bgcn,
                  )}
                >
                  <div className="fc-cc resp-gap-2">
                    <p className={twMerge("resp-text-1 font-digi", txtcn)}>
                      {_.upperCase(k)}
                    </p>
                    <p className={twMerge("resp-text-2 font-digi ", txtcn)}>
                      {nils(getv(f, `xmas_nntotals_ob.${k}`))
                        ? "--"
                        : getv(f, `xmas_nntotals_ob.${k}`)}
                    </p>
                  </div>
                </Card>
              );
            })}
          </div>
        </>
      )}
      {_.isEmpty(fl.qualifiers) ? (
        <p className="text-center text-yellow-300">Nothing found yet</p>
      ) : (
        <Card
          className={twMerge(
            "fqual-leaderboard-list overflow-auto",
            "bg-r2dark/40 backdrop-blur-md border border-acc4 w-[70rem] max-w-[98vw]",
            // f_by == "relay" ? "w-[80rem]" : "",
          )}
        >
          {leader_mode == "bike" && (
            <div className={twMerge("", "fr-sc resp-gap-2")}>
              <CapsuleSwitch
                {...{
                  label: "",
                  options: [true, false],
                  show_label: false,
                  txt_fn: (o) => capmap[o],
                  path: null,
                  nf: my,
                  set_nf: set_my,
                  tag_cn: "xs:w-[5rem] lg:w-[8rem] py-1",
                }}
              />

              <div class="flex-1"></div>
              <p class="text-r2lig font-digi resp-text-0">
                Highlighted Bikes are qualified for the Finale
              </p>
            </div>
          )}
          <table
            id="fqual-leaderboard"
            className={twMerge(tablecn.table, "w-full table-basic-border")}
          >
            <thead>
              <tr
                className={twMerge(
                  tablecn.tr,
                  "text-white font-bold bg-r2dark",
                )}
              >
                <td>
                  <div class="fr-sc resp-gap-2">
                    <SortHead {...{ ...sortargs, k: "rank" }} />
                  </div>
                  {/*
                    <span className="resp-text-0">Rank</span>
                  */}
                </td>

                {["bike", "vault", "faction"].includes(leader_mode) ? (
                  <td>
                    {leader_mode == "faction_agent" ? (
                      <span class="resp-text-0">Agent</span>
                    ) : leader_mode == "faction" ? (
                      <span class="resp-text-0">Factions</span>
                    ) : leader_mode == "vault" ? (
                      <span class="resp-text-0">Vault</span>
                    ) : leader_mode == "bike" ? (
                      <SortHead {...{ ...sortargs, k: "hid" }} />
                    ) : null}
                  </td>
                ) : leader_mode == "faction_agent" ? (
                  <>
                    <td>
                      <span class="resp-text-0">Agent</span>
                    </td>
                    <td>
                      <span class="resp-text-0">Faction</span>
                    </td>
                  </>
                ) : null}
                {f_by == "mission_points" ? (
                  <>
                    <td>
                      <SortHead {...{ ...sortargs, k: "score" }} />
                    </td>
                  </>
                ) : f_by == "relay" ? (
                  <>
                    <td>
                      <p className="text-center w-full">Score</p>
                    </td>
                    {_.sortBy(getv(f, "scoring.relay_cbs") ?? [], (e) =>
                      parseInt(e),
                    ).map((e) => {
                      return (
                        <td>
                          <p className="text-center w-full">CB{e}</p>
                        </td>
                      );
                    })}
                  </>
                ) : f_by == "jackpot_wins" ? (
                  <>
                    <td>
                      <p className="text-center w-full">Jackpot Wins</p>
                    </td>
                  </>
                ) : f_by == "quest_cards" ? (
                  <>
                    <td>
                      <p className="text-center w-full">Hand</p>
                    </td>

                    <td>
                      <p className="text-center w-full">Combo</p>
                    </td>
                  </>
                ) : (
                  <>
                    <td>
                      <SortHead {...{ ...sortargs, k: "totraces" }} />
                      {/*
                  <span className="resp-text-0">#Tot.Races</span>
                  */}
                    </td>
                    <td>
                      <SortHead {...{ ...sortargs, k: "selraces" }} />
                      {/*
                  <span className="resp-text-0">#Sel.Races</span>
                  */}
                    </td>
                    <td>
                      <SortHead {...{ ...sortargs, k: "score" }} />
                      {/*
                  <span className="resp-text-0">Score</span>
                  */}
                    </td>
                    {f?.use_skinteams === true && (
                      <>
                        <td>
                          <SortHead {...{ ...sortargs, k: "skin" }} />
                        </td>
                      </>
                    )}
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {sorted_hs.map((h) => {
                if (nils(h) || !h.show) return <></>;

                const is_my_bike =
                  auth && leader_mode == "bike" ? vhids.includes(h.hid) : false;
                const doreset = is_my_bike && entry_user_resettable;

                const exp_prec = getv(f, "scoring.exp_prec", null);

                const ag = getv(h, "extras.attached_agent", null);

                return (
                  <tr
                    key={h.key}
                    className={twMerge(
                      tablecn.tr,
                      skinevent == "xmas2023"
                        ? get_xmas_highlight(h)
                        : h.qualified == true
                          ? "bg-r2lig/20"
                          : "",
                    )}
                  >
                    <td className={tablecn.td}>
                      <div class="fr-sc resp-gap-2">
                        <span className="resp-text-0">#{h.rank}</span>
                        {!_.isEmpty(f?.rvmode) && leader_mode == "bike" && (
                          <RVImg
                            className="xs:w-[2rem] lg:w-[4rem]"
                            rvmode={h.rvmode}
                          />
                        )}
                      </div>
                    </td>
                    {["bike", "vault", "faction"].includes(leader_mode) && (
                      <>
                        <td className={tablecn.td}>
                          <div className="flex flex-col justify-start items-start">
                            {leader_mode == "vault" ? (
                              <>
                                <Link
                                  target={"_blank"}
                                  to={`/vault/${h.vault}`}
                                >
                                  <div className="fc-ss w-full resp-gap-1">
                                    <span className="font-digi resp-text-1">
                                      {h.vault_name}
                                    </span>
                                    <span className="text-r2lig resp-text--3">
                                      {h.vault}
                                    </span>
                                  </div>
                                </Link>
                              </>
                            ) : leader_mode == "faction" ? (
                              <>
                                <div class="fc-cc">
                                  <span className="font-digi w-full text-center resp-text-1">
                                    {_.chain(h.faction)
                                      .split("-")
                                      .map(_.capitalize)
                                      .join(" ")
                                      .value()}
                                  </span>
                                  <div class="aspect-[1/1] xs:w-[3rem] lg:w-[5rem]">
                                    <Img img={faction_img(h.faction)} />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <Link target={"_blank"} to={`/bike/${h.hid}`}>
                                  <div className="fr-sc w-full resp-gap-1">
                                    <span className="text-r2lig resp-text--1">
                                      {h.hid}
                                    </span>
                                    <span>-</span>
                                    <span className="font-digi resp-text-1">
                                      {h.name}
                                    </span>
                                  </div>
                                </Link>
                                <div class="italic font-digi resp-text--2 fr-sc resp-gap-2">
                                  <span>{_.capitalize(h.type)}</span>
                                  <span>/</span>
                                  <span>F{h.fno}</span>
                                  <span>/</span>
                                  <MiniGenderTag gender={h.gender} />
                                  <MiniElementTag element={h.element} />
                                </div>
                                <Link
                                  target={"_blank"}
                                  to={`/vault/${h.vault}`}
                                >
                                  <span className="text-r2lig resp-text-0">
                                    {h.vault_name}
                                  </span>
                                </Link>
                                {!_.isEmpty(ag) && (
                                  <div class="fr-sc resp-gap-1">
                                    <div class="lg:w-[2rem] xs:w-[1rem] aspect-[5/3]">
                                      <Img img={faction_img(ag.faction)} />
                                    </div>
                                    <span className="resp-text-acc4">
                                      Agent#{ag.agid}
                                    </span>
                                    <span>-</span>
                                    <span>{ag.agname}</span>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </td>
                      </>
                    )}

                    {leader_mode == "faction_agent" ? (
                      <>
                        <td>
                          <div class="fc-ss resp-gap-2">
                            <span className="resp-text--1 font-digi text-acc4">
                              {`#${h.agid}- ${h.agent_name}`}
                            </span>
                            <span className="resp-text--2 text-white">
                              {h.vault_name}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div class="fr-sc resp-gap-1">
                            <div class="xs:w-[2rem] lg:w-[3rem] aspect-[2/1]">
                              <Img
                                img={`https://cdn.dnaracing.run/factions-logos/${h.faction}.png`}
                              />
                            </div>
                            <p className="resp-text--1 font-digi text-center">
                              {nils(h.faction)
                                ? ""
                                : _.chain(h.faction)
                                    .split("-")
                                    .map((e) => _.capitalize(e))
                                    .join(" ")
                                    .value()}
                            </p>
                          </div>
                        </td>
                        {f_by == "mission_points" ? (
                          <td>
                            <p className="resp-text-1 text-acc4 font-digi text-center">
                              {dec(h.score, 1)}
                            </p>
                          </td>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : leader_mode == "faction" && f_by == "relay" ? (
                      <>
                        <td>
                          <span className="text-acc4 font-digi resp-text--1">
                            {nils(h.score) ? "--" : `${dec(-h.score, 3)}sec`}
                          </span>
                        </td>

                        {_.sortBy(getv(f, "scoring.relay_cbs") ?? [], (e) =>
                          parseInt(e),
                        ).map((cb) => {
                          let l = getv(h, `extras.lowmap.${cb}`);
                          let i = getv(h, `extras.infomap.${cb}`);
                          return (
                            <td>
                              <div class="fc-ss resp-text--2 xs:min-w-[7rem] lg:min-w-[12rem]">
                                <span className="resp-text--1 text-center w-full">
                                  {nils(l) ? "--" : `${dec(l, "3")}sec`}
                                </span>
                                {!nils(l) && (
                                  <>
                                    <hr className="w-full px-4 my-2" />
                                    <Link to={`/bike/${i.hid}`} class="fc-ss">
                                      <span className="text-acc4">
                                        {i.hid}#Core
                                      </span>
                                      <span className="text-acc4">
                                        {trim_n(i.name, 15)}
                                      </span>
                                    </Link>

                                    <Link
                                      to={`/vault/${i.racing_vault}`}
                                      class="fc-ss"
                                    >
                                      <span className="resp-text--2 my-1">
                                        >> {trim_n(i.vault_name, 13)}
                                      </span>
                                    </Link>
                                    <hr className="w-full px-4 my-2" />
                                    <span className="text-orange-300">
                                      {i.agid}#Agent
                                    </span>
                                    <span className="text-orange-300">
                                      {trim_n(i.agent_name, 15)}
                                    </span>
                                  </>
                                )}
                              </div>
                            </td>
                          );
                        })}
                      </>
                    ) : leader_mode == "faction" && f_by == "mission_points" ? (
                      <td>
                        <span className="text-acc4 font-digi resp-text--1">
                          {dec(h.score, 1)}
                        </span>
                      </td>
                    ) : f_by == "relay" ? (
                      <>
                        <td>
                          <span className="text-acc4 font-digi resp-text--1">
                            {nils(h.score) ? "--" : `${dec(-h.score, 3)}sec`}
                          </span>
                        </td>
                        {_.sortBy(getv(f, "scoring.relay_cbs") ?? [], (e) =>
                          parseInt(e),
                        ).map((cb) => {
                          let t = getv(h, `extras.lowmap.${cb}`);
                          return (
                            <td>
                              <span className="resp-text--3">
                                {nils(t) ? "--" : `${dec(t, "3")}sec`}
                              </span>
                            </td>
                          );
                        })}
                      </>
                    ) : f_by == "jackpot_wins" ? (
                      <td>
                        <div class="fr-sc text-acc4 resp-gap-2 resp-text-1 font-digi">
                          <FontAwesomeIcon icon={faUsd} className="" />
                          <span>{dec(h.wins, 2)}</span>
                        </div>
                      </td>
                    ) : f_by == "quest_cards" ? (
                      <>
                        <td className="h-full">
                          <div class="fr-sc h-full resp-gap-1 hover:scale-[1.4] transition duration-300 py-2">
                            {h.hand.map((c) => {
                              return (
                                <div class="aspect-[242/440] w-[4rem] hover:scale-[1.3] transition duration-300">
                                  <Img
                                    className="w-full h-full"
                                    img={gen_faction_playinc_card_img(c)}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </td>
                        <td>
                          <div class="fc-ss">
                            <p class="resp-text-1 font-digi italic">
                              {snake_str_to_caps(h.combo)}
                            </p>

                            {!_.isEmpty(getv(f, "scoring.quest_cards")) && (
                              <>
                                <span className="resp-text-1 font-digi text-acc4">
                                  {h.combo_txt ?? "--"}
                                </span>
                                <span className="resp-text-1">
                                  {h.disp_score}
                                </span>
                              </>
                            )}
                          </div>
                        </td>
                      </>
                    ) : null}

                    {[
                      null,
                      "pos",
                      "adv_points",
                      "prize_eth",
                      "prize_usd",
                      "time",
                      "hightime",
                      "consec_wins",
                    ].includes(f_by) && (
                      <>
                        <td
                          className={twMerge(
                            tablecn.td,
                            "resp-text-1 text-right",
                          )}
                        >
                          <div className="fr-sc resp-gap-2">
                            <FontAwesomeIcon icon={faFlagCheckered} />
                            <span className="resp-text-1">
                              {dec(h.races_n, 0)}
                            </span>
                          </div>
                        </td>
                        <td
                          className={twMerge(
                            tablecn.td,
                            "resp-text-1 text-right",
                          )}
                        >
                          <div className="fr-sc resp-gap-2">
                            <FontAwesomeIcon icon={faFlagCheckered} />
                            <span className="resp-text-1">
                              {dec(h.sel_races_n, 0)}
                            </span>
                          </div>
                        </td>
                        <td className={twMerge(tablecn.td, "font-digi")}>
                          <div class="fc-cc resp-gap-1">
                            {/*
                            <p className="resp-text--2">
                              {jstr({
                                doreset,
                                leader_mode,
                                auth,
                                is_my_bike,
                              })}
                            </p>
                            */}
                            {doreset ? (
                              <ResetScore
                                {...{
                                  ext:
                                    leader_mode == "bike"
                                      ? { hid: h.hid, rvmode: h.rvmode }
                                      : { vault: h.vault },
                                }}
                              />
                            ) : null}
                            <div className="fr-sc resp-gap-1">
                              {by == "prize_usd" ? (
                                <>
                                  <FontAwesomeIcon
                                    icon={faUsd}
                                    className="text-acc0"
                                  />
                                  <span className="font-digi text-acc0 resp-text--2">
                                    {dec(h.score, exp_prec ?? 2)}
                                  </span>
                                </>
                              ) : by == "prize_eth" ? (
                                <>
                                  <FontAwesomeIcon
                                    icon={faEthereum}
                                    className="text-acc0"
                                  />
                                  <span className="font-digi text-acc0 resp-text--2">
                                    {dec(h.score, exp_prec ?? 4)}
                                  </span>
                                </>
                              ) : ["time", "hightime"].includes(by) ? (
                                <p className="font-digi text-acc0 resp-text--2 text-right">
                                  {h.disp_score}
                                </p>
                              ) : ["consec_wins"].includes(by) ? (
                                <p className="font-digi text-acc0 resp-text--2 text-right">
                                  {h.disp_score}
                                </p>
                              ) : (
                                <span className="resp-text--1 ">
                                  {dec(h.score, exp_prec ?? 2)}
                                </span>
                              )}
                            </div>
                          </div>
                        </td>
                        {f?.use_skinteams === true && (
                          <>
                            <td>
                              <span
                                className={twMerge(
                                  "resp-text--1 font-digi",
                                  !nils(h.skinleague)
                                    ? "text-acc0"
                                    : "text-slate-400",
                                )}
                              >
                                {h.currskin}
                              </span>
                            </td>
                          </>
                        )}
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card>
      )}
    </>
  );
};

const QuestCardComboPrize = ({ combo, qc }) => {
  const msg = useMemo(() => {
    const token = getv(qc, "token");
    let amt = getv(qc, `card_amts.${combo}`);
    let tn = tokdecn(token);
    return (
      <div class="fr-sc resp-gap-1 font-digi w-max flex-nowrap">
        <span>{`${dec(amt, tn)} ${token}`}</span>
        <span>
          <TokenIcon {...{ token, size: "xs" }} />
        </span>
      </div>
    );
  }, [jstr(qc), combo]);
  return (
    <>
      <p>{msg}</p>
    </>
  );
};

const bgimgs = {
  xmas2023:
    "https://cdn.pixabay.com/photo/2017/11/07/20/43/christmas-tree-2928142_1280.jpg",
};

const Backdrop = ({ backdropimg, innercn = "", ...props }) => {
  return (
    <div className="relative">
      <div
        style={
          nils(backdropimg)
            ? {}
            : {
                background: `url(${backdropimg})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backdropFilter: "blur(10px)",
              }
        }
        className="absolute z-[5] h-page w-[100vw] overflow-hidden"
      ></div>
      <div
        className={twMerge(
          "absolute z-[10] h-page w-[100vw] top-0 left-0",
          innercn,
        )}
      ></div>
      <div className="absolute z-[15] h-page w-[100vw] overflow-auto">
        {props.children}
      </div>
    </div>
  );
};

const Entrants = () => {
  const { vault } = useAccountContext();
  const fcon = useFQualContext();
  const { f, fl, fe = {}, my, set_my } = fcon;

  return (
    <div>
      <table className={twMerge(tablecn.table, "w-max")}>
        <thead>
          <tr>
            <td></td>
            <td>Bike</td>
            <td>Vault</td>
            <td>Entry</td>
            <td>Status</td>
          </tr>
        </thead>
        <tbody>
          {fe.entrants.map((e) => {
            let { hid, vault } = e;
            let v = fe.vmap[vault];
            let h = fe.hmap[hid];
            let t = fe.txnsmap[hid];
            return (
              <tr className="thintdrow">
                <td>
                  {!nils(t) && (
                    <Link target="_blank" to={polytxnidlink(t.id)}>
                      <div className="w-[2rem]">
                        <Img img={polychainimg} />
                      </div>
                    </Link>
                  )}
                </td>
                <td>
                  <Link to={`/bike/${hid}`}>
                    <div className="fc-ss">
                      <div className="fr-sc w-full resp-gap-1">
                        <span className="text-acc0 resp-text--1">{h.hid}</span>
                        <span>-</span>
                        <span className="font-digi resp-text-1">{h.name}</span>
                      </div>
                      <div className="fr-sc resp-text--2 text-acc0 resp-gap-2">
                        <span>{_.capitalize(h.type)}</span>
                        <span>F{h.fno}</span>
                        <FontAwesomeIcon
                          className={twMerge(
                            elementmap[h.element].text,
                            "resp-text-1",
                          )}
                          icon={elementmap[h.element].icon}
                        />
                        <FontAwesomeIcon
                          className={twMerge(
                            gendermap[h.gender].text,
                            "resp-text-1",
                          )}
                          icon={gendermap[h.gender].icon}
                        />
                      </div>
                    </div>
                  </Link>
                </td>
                <td>
                  <Link to={`/vault/${vault}`}>
                    <div className="fc-ss">
                      <>
                        <span className="font-digi resp-text-1">{v}</span>
                        <span className="text-acc0 resp-text--1">{vault}</span>
                      </>
                    </div>
                  </Link>
                </td>
                <td>
                  <span>{iso_format(e.entered_at)}</span>
                </td>
                <td>
                  {e?.admin_entry == true ? (
                    <span className="text-yellow-400">Confirmed</span>
                  ) : !nils(t?.id) || e?.confirmed == true ? (
                    <span className="text-green-400">Confirmed</span>
                  ) : (
                    <span className="text-orange-400">Awaiting...</span>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const ArrayTable = ({ rows, cn }) => {
  return (
    <table className={twMerge(tablecn.table, "thintdrowp2-table-base w-full")}>
      <thead>
        {rows.slice(0, 1).map((r) => {
          return (
            <tr className={twMerge(tablecn.tr)}>
              {r.map((c) => {
                return <td className={twMerge(cn.table_head)}>{c}</td>;
              })}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {rows.slice(1).map((r) => {
          return (
            <tr className={twMerge(cn.table_data)}>
              {r.map((c, i) => {
                return <td className={twMerge("")}>{<>{c}</>}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const Info_SheetData = () => {
  const fcon = useFQualContext();
  const { fid, f, fl, fe } = fcon;

  const sheetdata = getv(f, "sheet_data");

  const cn = {
    title: "w-full text-center font-digi resp-text-1",
    head: "text-left text-acc4 font-digi resp-text--1",
    superhead: "text-left text-acc4 font-digi resp-text--1",
    info: "text-left font-digi resp-text--2",
    table_data: "resp-text--2",
    table_head: "text-acc4 resp-text--2",
  };

  return (
    <div
      class={twMerge(
        `w-full mx-auto xs:max-w-[95vw] grid grid-cols-12 gap-4 mt-4`,
      )}
    >
      {sheetdata.map(({ idx, size, title, view }) => {
        return (
          <Card
            key={idx}
            className={twMerge(
              "bg-r2lig/20 backdrop-blur-md",
              `w-full xs:col-span-${size.sm} lg:col-span-${size.lg}`,
            )}
          >
            <p className="resp-text-1 font-digi text-center">{title}</p>
            <div class="fc-cc w-full">
              {view.map((o) => {
                if (o.format == "divide") {
                  return (
                    <div class="h-[0.2rem] my-[1rem] bg-r2dark/50 w-[80%] mx-auto"></div>
                  );
                } else if (o.format == "empty") {
                  return null;
                } else if (["head", "superhead", "title"].includes(o.format)) {
                  return (
                    <div class="fc-ss mt-2 w-full flex-wrap resp-gap-1">
                      {o.rows.map((e) => {
                        return (
                          <div class="fr-sc w-full resp-gap-1 flex-wrap">
                            {e.map((a) => {
                              return <span className={cn[o.format]}>{a}</span>;
                            })}
                          </div>
                        );
                      })}
                    </div>
                  );
                } else if (o.format == "info") {
                  return (
                    <div class="fc-ss w-full flex-wrap resp-gap-1">
                      {o.rows.map((e) => {
                        return (
                          <div class="fr-sc w-full resp-gap-1 flex-wrap">
                            {e.map((a) => {
                              return <span className={cn[o.format]}>{a}</span>;
                            })}
                          </div>
                        );
                      })}
                    </div>
                  );
                } else if (o.format == "table") {
                  return <ArrayTable rows={o.rows} cn={cn} />;
                } else return <></>;
              })}
            </div>
          </Card>
        );
      })}
    </div>
  );
};

export const QualifyingRaces = () => {
  const { fid, f } = useFQualContext();
  const [qors] = useQueries([
    q_fqual_eligraces(
      { fid },
      {
        enabled: !nils(fid) && f?.status == "qualifying",
      },
    ),
  ]);
  const races = useMemo(() => {
    let rs = getv(qors, "data.result");
    return rs;
  }, [qors.dataUpdatedAt]);

  const [selrace, set_selrace] = useState(null);

  if (nils(f)) return null;
  const is_signup = !nils(f.signup_rid);

  if (f.status == "yettostart") {
    return (
      <p class="text-center font-digi resp-text-1 my-2 text-yellow-400 text-center">
        Start racing when race is in Qualifying stage
      </p>
    );
  } else if (f.status !== "qualifying") {
    return (
      <p class="text-center font-digi resp-text-1 my-2 text-yellow-400 text-center">
        Fqual is past Qualfying stage
      </p>
    );
  }

  return (
    <div class="w-full">
      {!_.isEmpty(f.entry_filters) && (
        <Card
          className={"w-full bg-r2lig/20 backdrop-blur-md border border-acc4"}
        >
          <p class="reps-text-1 font-digi">Bike Entry Criteria</p>
          {is_signup && (
            <p class="reps-text-1 font-digi">
              Fqual is of Signup type, Only use bikes that you have signed up
              for
            </p>
          )}

          <FqualEntryCriteriaSection
            txt_fn={(i) => (
              <span className="resp-text--1 font-digi text-acc4 mr-[1rem]">
                {`Criteria#${i + 1}`}
              </span>
            )}
          />
        </Card>
      )}
      <div class="my-2"></div>
      {qors.isLoading ? (
        <Loader01c />
      ) : qissuccesss(qors) ? (
        <RacesListInner
          {...{
            races,
            qo_races: qors,
            selrace,
            set_selrace,
          }}
        />
      ) : null}
    </div>
  );
};

export const FQual = () => {
  const { psearch, upd_psearch } = useAppContext();
  const { vault } = useAccountContext();
  const mat = useParams();
  const { fid } = mat;

  const [qi, ql] = useQueries([
    q_fqual_info({ fid }, {}),
    q_fqual_leader({ fid }, {}),
  ]);
  const f = useMemo(() => {
    return qissuccesss(qi) ? getv(qi, "data.result") : null;
  }, [qi.dataUpdatedAt]);

  const [qe] = useQueries([
    q_fqual_entrants(
      {
        fid,
        ...(getv(f, "signup.is_blind") ? { vault } : {}),
      },
      {
        enabled: !nils(f),
        staleTime: 20 * 1e3,
        refetchInterval: 20 * 1e3,
      },
    ),
  ]);

  const fl = useMemo(() => {
    return qissuccesss(ql) ? getv(ql, "data.result") : {};
  }, [ql.dataUpdatedAt]);
  const fe = useMemo(() => {
    return qissuccesss(qe) ? getv(qe, "data.result") : {};
  }, [qe.dataUpdatedAt]);

  const [my, set_my] = useState(false);

  const skinevent = getv(f, "skinevent") ?? null;

  const [tab, set_tab] = useState(psearch?.tab ?? undefined);
  useEffect(() => {
    // upd_psearch({ tab });
  }, [jstr(tab)]);
  const tabs = useMemo(() => {
    if (!f) return ["leaderboard"];
    let has_sheet_data =
      !nils(getv(f, "sheet_link")) && !_.isEmpty(getv(f, "sheet_data"));
    let is_signup = !nils(getv(f, "signup_rid"));
    let is_finale = !nils(getv(f, "finale_rid"));
    let tabs = [
      has_sheet_data ? ["details"] : null,
      f.status == "qualifying" ? ["races"] : null,
      ...(is_signup ? ["entrants"] : []),
      "leaderboard",
      ...(is_finale ? ["finale"] : []),
    ];

    if (tab == undefined) {
      if (is_signup && f.status === "yettostart") set_tab("entrants");
      if (is_finale && f.status === "running") set_tab("finale");
      else set_tab("leaderboard");
    }

    return tabs;
  }, [jstr(f)]);

  const conf_entrants_n = useMemo(() => {
    if (_.isEmpty(fe?.entrants)) return 0;
    let n =
      _.filter(fe.entrants, (e) => {
        if (e.confirmed == true) return true;
        let t = fe.txnsmap[e.hid];
        if (!nils(t?.id)) return true;
        return false;
      })?.length || 0;
    return n;
  }, [jstr(fe)]);

  const fcon = {
    fid,
    f,
    fl,
    fe,
    // copytosheet,
    my,
    set_my,

    qi,
    ql,
    qe,

    conf_entrants_n,
  };
  return (
    <FQualContext.Provider value={fcon}>
      <Backdrop
        {...(skinevent == "xmas2023"
          ? {
              innercn: "bg-dark/50",
              backdropimg: bgimgs.xmas2023,
            }
          : {
              innercn: "bg-transparent",
            })}
      >
        <div className="max-w-[98vw] w-[70rem] mx-auto">
          <div class="fr-sc">
            <Link to={`/fqual`}>
              <Tag className="text-acc4 -skew-x-12 border border-acc4 bg-r2lig/20">
                <div class="fr-sc resp-gap-2 resp-text--2">
                  <FontAwesomeIcon icon={faArrowLeft} />
                  <span class="">All Leaderboards</span>
                </div>
              </Tag>
            </Link>
          </div>

          <div className="h-[2rem]"></div>

          {qi.isLoading ? (
            <Loader01c />
          ) : qissuccesss(qi) ? (
            <InfoCard />
          ) : (
            <></>
          )}

          <div className="fr-cc resp-gap-2 my-2">
            {tabs.map((_tab) => {
              let cn = "font-digi";
              let active = tab == _tab;
              return (
                <Tag
                  onClick={() => {
                    set_tab(_tab);
                  }}
                  className={twMerge(
                    cn,
                    "transition duration-300",
                    active
                      ? "bg-r2lig/40 text-white -skew-x-12 "
                      : "bg-transparent text-white",
                  )}
                >
                  <span className="resp-text-1">{_.capitalize(_tab)}</span>
                </Tag>
              );
            })}
          </div>
          <hr className="mx-auto max-w-[95vw] w-[30rem] mb-[2rem] opacity-30" />

          {tab == "races" && (
            <>
              <QualifyingRaces />
            </>
          )}

          {tab == "details" && (
            <>{!_.isEmpty(getv(f, "sheet_data")) && <Info_SheetData />}</>
          )}

          {tab == "entrants" && (
            <>
              {getv(f, "signup.is_blind") === true ? (
                <div class="text-center fc-cc resp-gap-1 resp-text-1 text-yellow-300">
                  <span>{`Signup of Blind Type `}</span>
                  <span>{"[only showing your vault bikes entered]"}</span>
                </div>
              ) : null}
              {qe.isLoading ? (
                <Loader01c />
              ) : qissuccesss(qe) ? (
                <>
                  {getv(fe, "entrants", []).length == 0 ? null : <Entrants />}
                </>
              ) : (
                <></>
              )}
            </>
          )}

          {tab == "leaderboard" && (
            <>
              {ql.isLoading ? (
                <Loader01c />
              ) : qissuccesss(ql) ? (
                <>
                  {/*
                  {getv(f, "leader_mode") == "faction" ? null : (
                    <Leaderboard />
                  )}
                  */}
                  {<Leaderboard />}
                </>
              ) : (
                <></>
              )}
            </>
          )}

          <div class="h-[8rem]"></div>
        </div>
      </Backdrop>
    </FQualContext.Provider>
  );
};

export const FQualActives = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const acccon = useAccountContext();
  const vhids = getv(acccon, "hids") || [];
  const { bikesob } = acccon;

  const [mybikes, set_mybikes] = useState(
    psearch?.mybikes == "true" ? true : false,
  );

  const [actstatus, set_actstatus] = useState(
    !nils(psearch?.actstatus) ? psearch.actstatus.split(",") : ["qualifying"],
  );

  const [q] = useQueries([
    q_fqual_actives(
      {
        ...(mybikes ? { filthids: vhids } : {}),
      },
      {},
    ),
  ]);

  const actives = useMemo(() => {
    if (qissuccesss(q)) {
      let fs = getv(q, "data.result");
      fs = _.filter(fs, (f) => actstatus.includes(f.status));
      return fs;
    }
    return [];
  }, [q.dataUpdatedAt, jstr(actstatus)]);

  useEffect(() => {
    let rem = {};
    rem.mybikes = mybikes ? "true" : "false";
    // upd_psearch(rem);
  }, [mybikes]);

  useEffect(() => {
    let r = {};
    r.mybikes = mybikes ? "true" : "false";
    r.actstatus = actstatus.join(",");
    // upd_psearch({ ...psearch, ...r });
  }, [jstr(actstatus), mybikes]);

  const selection_list = (
    <div class="fr-cc my-2">
      {["yettostart", "qualifying", "running", "ended"].map((_st) => {
        let active = actstatus.includes(_st);

        return (
          <Tag
            onClick={() => {
              if (active) set_actstatus(_.without(actstatus, _st));
              else set_actstatus([...actstatus, _st]);
            }}
            className={twMerge(
              "font-digi resp-text-1",
              active
                ? "bg-r2lig/60 text-white -skew-x-12 "
                : "bg-transparent text-white",
            )}
          >
            {_.upperCase(_st)}
          </Tag>
        );
      })}
    </div>
  );

  let trainer_fids = _.chain(actives)
    .filter((f) => /trainer/i.test(f.name))
    .map("fid")
    .value();
  const fqual_list = (
    <>
      <div class="fqual-list">
        {q.isLoading ? (
          <Loader01c />
        ) : qissuccesss(q) && _.isEmpty(actives) ? (
          <p className="text-white resp-text--1 text-center">
            No Active Leaderboards
          </p>
        ) : qissuccesss(q) && !_.isEmpty(actives) ? (
          <div className="fc-cc resp-gap-2">
            {actives.map((f) => {
              let commhids = _.intersection(vhids, f.hids);
              let is_trainer = trainer_fids.indexOf(f.fid);
              return (
                <Card
                  className={twMerge(
                    is_trainer > -1 ? `trainer-fqual-${is_trainer}` : "",
                    "bg-r2dark/60 w-full resp-text--2",
                  )}
                >
                  <Link to={`/fqual/${f.fid}`}>
                    <div className="fr-sc">
                      <div className="flex-1 font-digi resp-px-2">{f.name}</div>
                      <span className="text-green-300">
                        {_.upperCase(f.status)}
                      </span>
                    </div>
                    <div class="fr-sc resp-gap-4  font-digi italic my-2">
                      {commhids.map((hid) => {
                        let h = bikesob[hid] || {};
                        let qual = f.qual_hids.includes(hid);
                        return (
                          <div className="fr-sc resp-gap-1">
                            <FontAwesomeIcon
                              className={`text-${h.color}`}
                              icon={faCircle}
                            />
                            <span
                              className={twMerge(
                                // qual ? "text-acc0" : "text-slate-500",
                                "",
                              )}
                            >
                              {h.name ?? hid}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </Link>
                </Card>
              );
            })}
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  const capmap = _.chain([
    [true, "My Bikes"],
    [false, "All Bikes"],
  ])
    .fromPairs()
    .value();

  return (
    <>
      <div className="h-page">
        <div className="max-w-[98vw] w-[70rem] mx-auto">
          <div className="h-[2rem]"></div>
          <RacingDashBackLink />

          <div class="small-screen xs:block lg:hidden">
            <p className="text-center font-digi resp-text-1 resp-my-2">
              Events
            </p>

            <div class="fr-sc my-2">
              <CapsuleSwitch
                {...{
                  label: "",
                  options: [true, false],
                  show_label: false,
                  txt_fn: (o) => capmap[o],
                  path: null,
                  nf: mybikes,
                  set_nf: set_mybikes,
                  tag_cn: "w-[4rem] resp-text--1",
                }}
              />
            </div>
            {selection_list}
            <Card class="resp-p-2 bg-r2lig/20 backdrop-blur-md">
              {fqual_list}
            </Card>
          </div>
          <div class="large-screen xs:hidden lg:block">
            <Card
              className={twMerge(
                "bg-r2lig/20 backdrop-blur-md border border-acc4 w-full",
              )}
            >
              <p className="text-center font-digi resp-text-1 resp-my-2">
                Events
              </p>
              <div class="fr-sc my-2">
                <CapsuleSwitch
                  {...{
                    label: "",
                    options: [true, false],
                    show_label: false,
                    txt_fn: (o) => capmap[o],
                    path: null,
                    nf: mybikes,
                    set_nf: set_mybikes,
                    tag_cn: "w-[8rem]",
                  }}
                />
              </div>
              <div class="flex flex-row justify-start items-stretch">
                <div class="w-[20rem]">
                  {["yettostart", "qualifying", "running", "ended"].map(
                    (idd) => {
                      let txt = _.capitalize(idd);
                      return (
                        <SkewBtn
                          // onClick={() => {
                          //   let active = actstatus.includes(idd);
                          //   if (active) set_actstatus(_.without(actstatus, idd));
                          //   else set_actstatus([...actstatus, idd]);
                          // }}
                          // onClick={() => set_actstatus(idd)}
                          onClick={() => set_actstatus([idd])}
                          text={txt}
                          active={actstatus.includes(idd)}
                          cont_cn="w-[14rem] my-2"
                        />
                      );
                    },
                  )}
                </div>
                <div class="flex-1 bg-r2lig/20 backdrop-blur-md p-2 rounded-lg overflow-auto max-h-[40rem]">
                  {fqual_list}
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
