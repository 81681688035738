import {
  faCheck,
  faCheckCircle,
  faCircle,
  faFire,
  faInfoCircle,
  faSpinner,
  faSync,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  forwardRef,
  createContext,
  useContext,
} from "react";
import { useQueries, useQuery } from "react-query";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useAppContext } from "../App";
import { Loader01c } from "../components/anims";
import { useLayoutContext } from "../components/Layout";
import { PopUp, PopupCloseBtn } from "../components/popup";
import { CopyBtn, PageWrapper } from "../components/ShortComps";
import { Card, Img, Tag } from "../components/utilityComps";
import {
  polytxnidlink,
  qiserr,
  qissuccesss,
  q_quest_cards_burn,
  q_quest_cards_burnall,
  q_quest_cards_cardslist,
  q_quest_cards_cashout,
  q_quest_cards_est_prize,
  q_quest_cards_reveal,
  q_quest_cards_rewards,
  q_vaultinfo,
  q_quest_cards_vhand,
  q_quest_cards_precommit,
  q_quest_cards_reveal_w_seed,
  q_quest_cards_burn_hand,
} from "../queries/queries";
import { tablecn } from "../utils/cn_map";
import { polychainimg } from "../utils/links";
import {
  cdelay,
  getv,
  iso_format,
  jstr,
  nils,
  snake_str_to_caps,
  trim_n,
} from "../utils/utils";
import { useAccountContext } from "../wrappers/AccountWrapper";
import { useAuthContext } from "../wrappers/AuthWrapper";
import { ErrorBoundary } from "../utils/errbou";

const QuestCardsContext = createContext({});
const useQuestCardsContext = () => useContext(QuestCardsContext);

const sel_cards_n = 5;
const tot_cards_n = 5;
const imgbase = "https://cdn.dnaracing.run/imgs/playing-cards-factions";
const gen_img = (k) => {
  let link = null;
  if (k == "back" || k == "brightback" || nils(k))
    link = `${imgbase}/card_back_p01.png`;
  else {
    let [a, b] = k.split("-");
    link = `${imgbase}/${a}s_${b}.png`;
  }
  // console.log("gen_img", k, link);
  return link;
};
export const gen_faction_playinc_card_img = gen_img;

const generate_random_client_seed = () => {
  const client_seed = Math.random().toString(36).substring(2, 12);
  return client_seed;
};

export const format_prizename = (p) => {
  if (p == "no-prize" || nils(p)) return "No Prize";
  let [a, b, c] = p.split("-");
  if (p.includes("USD")) {
    return `${a} USD`;
  } else {
    return `${a}x ${_.capitalize(b)} `;
  }
};

class PokerHandEvaluator {
  constructor(hand, ext = {}) {
    this.def_hand_size = 5;
    this.hand = hand;
    // this.hand = _.uniq(hand);
    this.valueMap = {
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
      7: 7,
      8: 8,
      9: 9,
      10: 10,
      J: 11,
      Q: 12,
      K: 13,
      A: 14,
    };
    if (ext.skip_size) this.skip_size = ext.skip_size;
  }

  getCardValue(card) {
    // console.log("getCardValue", card);
    return card.split("-")[1];
  }

  getCardSuit(card) {
    return card.split("-")[0];
  }

  is_valid() {
    if (this.skip_size !== true) {
      if (this.hand.length !== 5) return false;
    }
    // console.log("hand, this.hand.length", this.hand, this.hand.length);

    let dups = _.chain(this.hand)
      .countBy((e) => e)
      .entries()
      .map(([k, v]) => {
        // console.log(k, v);
        if (v > 1) {
          return `${k}=x${v}`;
        }
        return null;
      })
      .compact()
      .value();
    if (dups.length > 0) {
      console.log("has duplicates", this.hand, dups);
      return false;
    }
    // return this.hand.length >= 2 && this.hand.length <= 5;
    return true;
  }

  get_first_mxn_usingcards(valueCounts, mxn) {
    let ks = _.chain(valueCounts)
      .entries()
      .sortBy(([k, v]) => -v)
      .slice(0, mxn)
      .map(0)
      .value();
    let using = this.hand.filter((c) => {
      let match = false;
      for (let k of ks) {
        if (c.endsWith(`-${k}`)) {
          match = true;
          break;
        }
      }
      return match;
    });
    return using;
  }

  evaluateHand() {
    if (!this.is_valid()) {
      return "Invalid hand size";
    }

    const values = this.hand.map((card) => this.getCardValue(card));
    const suits = this.hand.map((card) => this.getCardSuit(card));
    const valueCounts = this.countOccurrences(values);
    const suitCounts = this.countOccurrences(suits);

    const counts = Object.values(valueCounts);
    const isFlush = Object.values(suitCounts).some(
      (count) => count === this.hand.length,
    );
    const isStraight = this.checkStraight(values);
    const maxCount = Math.max(...counts);
    const cards_n = this.hand.length;

    return this.determineCombo(
      isFlush,
      isStraight,
      counts,
      maxCount,
      valueCounts,
      cards_n,
    );
  }

  countOccurrences(arr) {
    return arr.reduce((acc, value) => {
      acc[value] = (acc[value] || 0) + 1;
      return acc;
    }, {});
  }

  checkStraight(values) {
    const numericValues = values
      .map((value) => this.valueMap[value])
      .sort((a, b) => a - b);

    for (let i = 0; i < numericValues.length - 1; i++) {
      if (numericValues[i + 1] !== numericValues[i] + 1) {
        return false;
      }
    }

    return true;
  }

  get_tot_values(using, combo) {
    let tot = 0;
    let vals = [];
    for (let c of using) {
      let val = this.getCardValue(c);
      val = this.valueMap[val];
      vals.push(val);
    }
    if (combo == "two-pair") {
      vals = vals.sort((a, b) => b - a);
      // console.log("vals", vals);
      vals[2] = vals[2] / 100;
      vals[3] = vals[3] / 100;
    }
    vals.map((v) => {
      tot += v;
    });
    return tot;
  }

  determineCombo(isFlush, isStraight, counts, maxCount, valueCounts, cards_n) {
    const isFullHand = cards_n === 5; // Check if the hand has exactly 5 cards
    const values = Object.values(valueCounts);
    const ks = Object.keys(valueCounts);
    let using = [];
    let combo = null;

    const pairCount = Object.values(valueCounts).filter(
      (count) => count === 2,
    ).length;

    // console.log( "determineCombo", { isFullHand, isFlush, isStraight }, values.join(","), ks.join(","), valueCounts,);

    // if (
    //   isFullHand &&
    //   isFlush &&
    //   isStraight &&
    //   ks.includes("A") &&
    //   ks.includes("K")
    // ) {
    //   using = this.hand;
    //   combo = "royal-flush";
    // } else
    //   if (isFullHand && isFlush && isStraight) {
    //   using = this.hand;
    //   combo = "straight-flush";
    // } else
    if (maxCount === 4) {
      using = this.get_first_mxn_usingcards(valueCounts, 1);
      combo = "four-of-a-kind";
    } else if (maxCount === 3 && counts.includes(2)) {
      using = this.get_first_mxn_usingcards(valueCounts, 2);
      combo = "full-house";
    } else if (isFlush && isFullHand) {
      using = this.hand;
      combo = "flush";
    }
    // else if (isFullHand && isStraight) {
    //   using = this.hand;
    //   combo = "straight";
    // }
    else if (maxCount === 3) {
      using = this.get_first_mxn_usingcards(valueCounts, 1);
      combo = "three-of-a-kind";
    } else if (
      maxCount === 2 &&
      ((cards_n === 5 && counts.length === 3) ||
        (cards_n < 5 && pairCount === 2))
    ) {
      using = this.get_first_mxn_usingcards(valueCounts, 2);
      combo = "two-pair";
    } else if (maxCount === 2) {
      using = this.get_first_mxn_usingcards(valueCounts, 1);
      combo = "pair";
    } else {
      combo = null;
    }
    if (!combo) return [null, [], 0];

    let totval = this.get_tot_values(using, combo);

    if (combo == "pair" && totval >= 22) {
      combo = "jpair";
    }
    return [combo, using, totval];
  }
}

const CardImg = forwardRef(({ k, cn, delay, auto_flip = true }, ref) => {
  const img = useMemo(() => gen_img(k), [k]); // Assuming gen_img is defined elsewhere

  const [reveal, set_reveal] = useState(false);

  useEffect(() => {
    if (!auto_flip) return;
    const timer = setTimeout(() => {
      set_reveal(true);
    }, delay);
    return () => clearTimeout(timer); // Clear timeout on unmount
  }, [delay, k]);

  return (
    <div class="w-full">
      <div
        ref={ref}
        className={twMerge(
          "playcard relative w-full aspect-[242/340]",
          reveal ? "reveal" : "",
          cn,
        )}
      >
        <div
          className={twMerge(
            "playcard-front absolute w-full h-full",

            k == "brightback" ? "" : k == "back" ? "grayscale" : "",
            cn,
          )}
        >
          <Img img={img} className="w-full h-full" />
        </div>
        <div className="playcard-back absolute w-full h-full">
          <Img img={gen_img("back")} className="w-full h-full" />
        </div>
      </div>
    </div>
  );
});
export const QuestCardImg = CardImg;
const RevealerCard = ({ id }) => {
  const ccon = useQuestCardsContext();
  const {
    cards_ob,
    cards,
    set_cards,
    opened,
    use_cards,
    set_use_cards,
    revealing,
    set_revealing,
  } = ccon;

  const ref = useRef();
  const [cn, set_cn] = useState("");
  const [c, set_c] = useState(cards_ob[id]);

  const reveal_card = async () => {
    try {
      set_revealing(id);
      if (opened.length >= tot_cards_n) {
        throw new Error(
          `max open cards you can have is ${tot_cards_n}, please burn some olds cards first`,
        );
      }
      set_revealing(id);
      let res = await q_quest_cards_reveal({ id }).queryFn();
      // console.log("reveal_card", id, res?.result);
      if (!nils(res.err)) throw new Error(res.err);
      let nc = res.result;
      set_c(nc);
      setTimeout(() => {
        set_cn("reveal");
      }, 1 * 1e3);
      setTimeout(() => {
        let ncs = _.chain(cards)
          .filter((e) => e.id !== id)
          .value();
        set_cards([...ncs, nc]);
        // console.log("new cards", ncs);
      }, 5 * 1e3);
    } catch (err) {
      // console.log("reveal_card", err);
    }
    setTimeout(() => {
      set_revealing(null);
    }, 3 * 1e3);
  };

  return (
    <div
      onClick={() => {
        if (!nils(revealing)) return;
        reveal_card();
      }}
      className={twMerge(
        "cusor-pointer col-span-1 w-full transition duration-300",
        nils(revealing)
          ? ""
          : revealing == id
            ? ""
            : "grayscale opacity-50 blur-md",
      )}
    >
      <CardImg
        {...{
          k: c?.card ?? "back",
          cn,
          delay: 1 * 1e3,
          auto_flip: false,
        }}
      />
    </div>
  );
};

const CreateComboSection = () => {
  const ccon = useQuestCardsContext();
  const { cards, cards_loaded } = ccon;
  const card_refs = useRef([]);

  return (
    <div class="w-full fr-sc xs:gap-[0.5rem] lg:gap-[1rem] my-[1rem]">
      {[...new Array(sel_cards_n)].map((a, i) => {
        let k = getv(cards, `${i}.card`, "back");
        let posfromcenter = Math.abs(i - Math.floor(sel_cards_n / 2));
        let delay = (0.2 + posfromcenter * 0.3) * 1e3;
        return (
          <CardImg
            key={i}
            ref={(re) => card_refs.current[i]}
            k={k}
            delay={delay}
            auto_flip={k !== "back"}
          />
        );
      })}
    </div>
  );
};

const ResultSection = () => {
  const ccon = useQuestCardsContext();
  const { auth } = useAuthContext();
  const { vault } = useAccountContext();
  const lcon = useLayoutContext();

  const {
    cards,
    set_cards,
    use_cards,
    set_use_cards,

    hand,
    hand_combo,

    qcs,
    qrs,

    is_au,
    qovhand,
  } = ccon;
  const hand_valid = !nils(hand_combo);

  const [resp, set_resp] = useState({});
  const [popup, set_popup] = useState(false);

  const open_cashout_popup = () => {
    set_popup(true);
    set_resp({});
  };
  const close_cashout_popup = () => {
    set_popup(false);
    set_resp({});
  };

  const post_cashout = async () => {
    set_resp({});
    set_cards([]);
    close_cashout_popup();
    await qovhand.refetch();
    await qrs.refetch();
  };
  const do_cashout = async () => {
    try {
      set_resp({ type: "loading", msg: "sending cashout request..." });
      await cdelay(2 * 1e3);
      // console.log("do_cashout", use_cards, vault);
      let res = await q_quest_cards_cashout({
        cardids: use_cards,
        vault,
      }).queryFn();
      if (res?.err) throw new Error(res.err);

      set_resp({
        type: "success",
        msg: "cashout request sent, please contact admin to process",
      });
      setTimeout(() => {
        post_cashout();
      }, 3 * 1e3);
    } catch (err) {
      // console.log("do_cashout", err);
      set_resp({ type: "error", msg: err.message });
      setTimeout(() => {
        set_resp({});
      }, 2 * 1e3);
    }
  };

  const enabled = !nils(vault) && popup;
  const [qoprize] = useQueries([
    q_quest_cards_est_prize({ hand }, { enabled }),
  ]);
  const handprize = useMemo(
    () => getv(qoprize, "data.result"),
    [qoprize.dataUpdatedAt],
  );

  const [burning, set_burning] = useState(false);
  const burn_hand = async () => {
    try {
      set_burning(true);

      let bresp = await q_quest_cards_burn_hand({ vault }).queryFn();
      if (bresp.err) throw new Error(bresp.err);
      await await cdelay(2 * 1e3);
      set_cards([]);
      await qovhand.refetch();
    } catch (err) {
      console.log("burn_hand", err);
    }
    set_burning(false);
  };

  return (
    <div class="fc-cc">
      {false && (
        <>
          <p className="resp-text-1">{hand.join(", ")}</p>
          <p class="text-center resp-text-2">
            valid: {hand_valid ? "yes" : "no"}
          </p>
          <div class="fr-sc">
            <p class="text-center resp-text-2">Combo: {hand_combo}</p>
          </div>
        </>
      )}
      {hand_valid ? (
        <>
          {auth ? (
            <Tag
              onClick={open_cashout_popup}
              className={twMerge("border-2 border-acc4 text-acc4")}
            >
              <p class="resp-text-1 font-digi font-reg ">CASHOUT</p>
            </Tag>
          ) : (
            <Tag
              onClick={() => lcon.set_loginpop(true)}
              className={twMerge("border-2 border-acc4 text-acc4")}
            >
              <p class="resp-text-1 font-digi font-reg ">Login to Cashout</p>
            </Tag>
          )}
        </>
      ) : (
        <>
          {auth && (
            <Tag
              onClick={burn_hand}
              className={twMerge(
                "border border-red-400 fr-sc resp-gap-2 text-acc4",
              )}
            >
              {burning ? (
                <>
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="resp-text-2 spin-anim"
                  />
                  <p class="resp-text-1 font-digi font-reg ">Burning...</p>
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faFire} className="resp-text-2" />
                  <p class="resp-text-1 font-digi font-reg ">Burn Hand</p>
                </>
              )}
            </Tag>
          )}
        </>
      )}
      <PopUp
        wrapcn={"xs:top-[1rem] lg:top-[5rem]"}
        innercn={"translate-y-[0%]"}
        openstate={popup}
        onclose={close_cashout_popup}
      >
        <PopupCloseBtn {...{ closepopup: close_cashout_popup }} />
        <Card
          className={twMerge(
            "xs:w-[95vw] lg:w-[60rem] bg-r2dark/40 backdrop-blur-md border border-acc4 p-4 overflow-auto h-[40rem]",
          )}
        >
          <div class="w-full p-2 my-2 overflow-auto grid xs:grid-cols-1 lg:grid-cols-2 gap-4">
            <div class="col-span-1 ">
              <div class="w-full relative flex-wrap h-[30rem]">
                {hand.map((e, i) => {
                  let posfromcenter = i - Math.floor(hand.length / 2) - 1;
                  let off = 20;
                  return (
                    <div
                      class={twMerge(
                        "w-[10rem] aspect-[242/340] absolute top-[5rem] left-[50%]",
                        `transform rotate-[${posfromcenter * off}deg] cardtransform-origin`,
                        "z-[10] hover:z-[20] transition duration-300",
                      )}
                    >
                      <CardImg
                        key={i}
                        k={e}
                        cn=""
                        delay={(2 + i * 0.3) * 1e3}
                        auto_flip={true}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div class="col-span-1 fc-cc h-full">
              <p className="resp-text-3 font-digi text-acc4">
                {snake_str_to_caps(hand_combo)}
              </p>
              {qoprize.isLoading ? (
                <Loader01c size="s" />
              ) : qiserr(qoprize) ? (
                <p className="resp-text-1 font-digi text-red-400">
                  {qiserr(qoprize)}
                </p>
              ) : qissuccesss(qoprize) ? (
                <div className="fr-sc resp-gap-2 resp-text-1 font-digi text-white">
                  <span>Est Prize: </span>
                  <span className="font-reg text-green-300">
                    {format_prizename(getv(handprize, "prize"))}
                  </span>
                </div>
              ) : null}
              <div class="h-[1rem]"></div>
              {qissuccesss(qoprize) &&
                !nils(getv(handprize, "prize")) &&
                resp.type !== "loading" && (
                  <>
                    <Tag
                      onClick={() => {
                        if (resp?.type == "loading") return;
                        do_cashout();
                      }}
                      className={twMerge(
                        "border-2 border-acc4 text-acc4 hover:bg-acc4/30 hover:text-white",
                      )}
                    >
                      <p className="resp-text-1 font-digi text-white">
                        CASHOUT NOW
                      </p>
                    </Tag>
                    <p class="my-2 text-acc0">
                      This operation will reward you with{" "}
                      {format_prizename(getv(handprize, "prize"))}
                      and burn your selected cards.
                    </p>
                  </>
                )}
              {!_.isEmpty(resp) && (
                <div
                  class={twMerge(
                    "fr-sc resp-gap-2 border rounded-md w-full mx-4 px-4 py-2 resp-text-1 font-digi",
                    nils(resp)
                      ? "text-white border-white"
                      : resp.type == "loading"
                        ? "text-blue-400 border-blue-400"
                        : resp.type == "info"
                          ? "text-blue-400 border-blue-400"
                          : resp.type == "success"
                            ? "text-green-300 border-green-300"
                            : resp.type == "error"
                              ? "text-red-400 border-red-400"
                              : "text-white border-white",
                  )}
                >
                  {resp.type == "loading" ? (
                    <Loader01c size="s" />
                  ) : (
                    <FontAwesomeIcon
                      className="resp-text-2"
                      icon={
                        resp.type == "info"
                          ? faInfoCircle
                          : resp.type == "success"
                            ? faCheckCircle
                            : resp.type == "error"
                              ? faTimesCircle
                              : null
                      }
                    />
                  )}
                  <p className="">{resp.msg}</p>
                </div>
              )}
            </div>
          </div>
        </Card>
      </PopUp>
    </div>
  );
};

const DispProvablesSection = ({ rresp }) => {
  const inf = getv(rresp, "result", null);

  // Extract or set default values
  const currHand = getv(inf, "curr_hand") || [];
  const handLength = currHand.length;
  const drawCardNumber = handLength + 1;
  const hashedServerSeed = getv(inf, "hashed_server_seed") || "";
  const index = getv(inf, "pick_idx");

  // Mapping object for Tailwind CSS classes for each dynamic variable
  const cnmap = {
    card: "text-blue-400",
    serverSeed: "text-red-400",
    clientSeed: "text-green-400",
    handPos: "text-yellow-400",
    hashedServerSeed: "text-orange-400",
    combinedSeed: "text-purple-400",
    pickHash: "text-pink-400",
    pickHashNum: "text-teal-400",
    pickIndex: "text-indigo-400",
  };

  return (
    <div className="fc-ss">
      <p>
        <strong>Draw Card #{drawCardNumber} Details:</strong>
      </p>
      <p>
        Card: <span className={cnmap.card}>{getv(inf, "card")}</span>
      </p>
      <p>
        Server Seed:{" "}
        <span className={cnmap.serverSeed}>{getv(inf, "server_seed")}</span>
      </p>
      <p>
        Client Seed:{" "}
        <span className={cnmap.clientSeed}>{getv(inf, "client_seed")}</span>
      </p>
      <p>
        Hand Position: <span className={cnmap.handPos}>{handLength}</span>
      </p>
      <p>
        Hashed Server Seed:{" "}
        <span className={cnmap.hashedServerSeed}>{hashedServerSeed}</span>
      </p>
      <br />
      <p>
        <strong>How to Verify This Draw:</strong>
      </p>
      <ol>
        <li>
          <p>
            <strong>Check Server Seed Commitment:</strong> Hash the Server Seed
            (
            <span className={cnmap.serverSeed}>{getv(inf, "server_seed")}</span>
            ) using SHA-256. It should match the Hashed Server Seed shown before
            reveal: (
            <span className={cnmap.hashedServerSeed}>{hashedServerSeed}</span>).
            Try an online tool like sha256.online.
          </p>
        </li>
        <li>
          <p>
            <strong>Compute Card Selection:</strong> Combine Server Seed +
            Client Seed + Hand Position (
            <span className={cnmap.combinedSeed}>
              {getv(inf, "combined_seed")}
            </span>
            ). Hash it with SHA-256 to get:{" "}
            <span className={cnmap.pickHash}>{getv(inf, "pick_hash")}</span>.
          </p>
        </li>
        <li>
          <p>
            <strong>Get Index:</strong> Take the first 8 hex digits (
            <span className={cnmap.pickHash}>{getv(inf, "pick_hash")}</span>),
            convert to decimal (
            <span className={cnmap.pickHashNum}>
              {getv(inf, "pick_hash_num")}
            </span>
            ), and mod by deck size ({52 - handLength}) ={" "}
            <span className={cnmap.pickIndex}>{index}</span>.
          </p>
        </li>
        <li>
          <p>
            <strong>Match Card:</strong> In a deck sorted 2♠ to A♣ (52 cards
            initially), position{" "}
            <span className={cnmap.pickIndex}>{index}</span> (0-based) after{" "}
            <span className={cnmap.handPos}>{handLength}</span> cards removed
            should be (<span className={cnmap.card}>{getv(inf, "card")}</span>).
          </p>
        </li>
      </ol>
      <em>Repeat for each draw. Deck shrinks by 1 each time.</em>
    </div>
  );
};

const ControlSection = () => {
  const ccon = useQuestCardsContext();
  const {
    vault,
    is_au,

    qovhand,
    closed_n,
    set_closed_n,

    cards,
    set_cards,

    full_hand,
  } = ccon;

  const [mode, set_mode] = useState(
    localStorage.getItem("questcards-mode") ?? "basi",
  );
  useEffect(() => {
    localStorage.setItem("questcards-mode", mode);
  }, [mode]);

  const enabled = is_au;
  const [qopre] = useQueries([q_quest_cards_precommit({ vault }, { enabled })]);
  const p = useMemo(
    () => getv(qopre, "data.result", {}),
    [qopre.dataUpdatedAt],
  );

  const redid_client_seed = "loc-client-seed";
  const get_client_seed = () => {
    let seed = localStorage.getItem(redid_client_seed);
    if (nils(seed)) seed = generate_random_client_seed();
    return seed;
  };
  const [client_seed, set_client_seed] = useState(get_client_seed());
  useEffect(() => {
    localStorage.setItem(redid_client_seed, client_seed);
  }, [client_seed]);

  const init_rresp = () => ({ type: "idle", msg: "Draw a Card" });
  const [rresp, set_rresp] = useState(init_rresp());
  useEffect(() => {
    console.log("reveal_resp", rresp);
  }, [jstr(rresp)]);

  const get_dummy_resp = () => ({
    status: "success",
    result: {
      vault: "0xaf1320faa9a484a4702ec16ffec18260cc42c3c2",
      server_seed: "dcqeck8bdl9",
      hashed_server_seed:
        "fd8b863031cda2d35ac61218dc6ae9af1ac53fe86b698e7f5912923228446d79",
      client_seed: "8ntvoquldm",
      combined_seed: "dcqeck8bdl98ntvoquldm0",
      pick_hash:
        "c5e668bce9d6ead6b2054714a88d0cfe1f7fd0463552e80df221eee815239fae",
      pick_hash_sub: "c5e668bc",
      pick_hash_num: 3320211644,
      pick_idx: 48,
      card: "club-J",
      cardid: "150d7646fc4f39ff1083",
      curr_hand: [],
    },
  });

  const draw_card = async () => {
    try {
      if (
        (rresp.type == "success" && rresp.action == "clear") ||
        rresp.type == "error"
      ) {
        set_client_seed(generate_random_client_seed());
        await qopre.refetch();

        set_rresp(init_rresp());
        return;
      }
      if (rresp.type !== "idle" || closed_n === 0) return;
      set_rresp({ type: "loading", msg: "Drawing Card..." });
      await cdelay(1 * 1e3);
      if (nils(client_seed)) throw new Error("client_seed not found");

      let rr = await q_quest_cards_reveal_w_seed({
        vault,
        client_seed,
      }).queryFn();
      if (!nils(rr.err)) throw new Error(rr.err);
      // let rr = get_dummy_resp();
      rr = getv(rr, "result", null);

      set_rresp({ type: "success", msg: `Revealed: ${rr?.card}`, result: rr });
      let ncard = {
        cardid: rr.cardid,
        vault,
        card: rr.card,
      };
      set_cards((cs) => [...cs, ncard]);
      set_closed_n((n) => n - 1);
      await cdelay(3 * 1e3);

      set_rresp({
        type: "success",
        action: "clear",
        msg: `Revealed: ${rr?.card}... Move Ahead?`,
        result: rr,
      });
      await qopre.refetch();
      await qovhand.refetch();
    } catch (err) {
      let errmsg = err.message;
      errmsg = trim_n(errmsg, 100);
      set_rresp({ type: "error", msg: errmsg });
    }
  };

  const iconmap = {
    loading: [faSpinner, "spin-anim text-blue-400"],
    error: [faTimes, "text-red-400"],
    success: [faCheck, "text-green-400"],
  };

  return (
    <>
      {full_hand == true ? (
        <div class="resp-text-1 font-digi fc-cc">
          <>
            <ResultSection />
          </>
        </div>
      ) : (
        <div class="resp-p-2 w-full">
          <div class="fr-sc my-2">
            <div class="flex-1"></div>

            {["basic", "advanced"].map((_mode) => {
              return (
                <Tag
                  key={_mode}
                  onClick={() => {
                    set_mode(_mode);
                  }}
                  className={twMerge(
                    "border-2 border-acc4 text-acc4 hover:bg-acc4/30 hover:text-white",
                    mode == _mode ? "bg-acc4/30 text-white" : "",
                  )}
                >
                  <p class="resp-text-1 font-digi text-center">
                    {_.capitalize(_mode)}
                  </p>
                </Tag>
              );
            })}
          </div>
          <div class="fc-cc resp-gap-1 resp-text--1 font-mono">
            {mode == "advanced" && (
              <>
                <p>{`[Hashed ServerSeed]: ${getv(p, "hashed_server_seed")}`}</p>
                <div class="fr-sc">
                  <p>{`[ClientSeed]: ${client_seed}`}</p>
                  <Tag
                    onClick={() => {
                      if (rresp.type == "loading") return;
                      set_client_seed(generate_random_client_seed());
                    }}
                    className="resp-p-1 cursor-pointer text-acc4"
                  >
                    <FontAwesomeIcon icon={faSync} />
                  </Tag>
                </div>
              </>
            )}

            <div class="fr-sc resp-gap-2 relative">
              <Tag
                onClick={draw_card}
                className={twMerge(
                  "fr-sc resp-gap-1",
                  "resp-p-1 cursor-pointer bg-acc4 text-white border border-acc4 duration-200 ",
                  rresp.type == "loading"
                    ? "bg-blue-400/30 border-blue-400"
                    : "",
                  rresp.type == "error" ? "bg-red-400/30 border-red-400" : "",
                  rresp.type == "success" ? "bg-acc4/30" : "",
                )}
              >
                {!nils(iconmap[rresp.type]) && (
                  <FontAwesomeIcon
                    icon={getv(iconmap, `${rresp.type}.0`)}
                    className={twMerge(
                      "resp-text-2",
                      getv(iconmap, `${rresp.type}.1`),
                    )}
                  />
                )}

                <span className="resp-text-1 font-digi">{rresp.msg}</span>
              </Tag>
              <div className="absolute w-[0] top-[25%] right-0 ">
                <p className="font-digi w-max resp-text-0">
                  / {closed_n} Closed Cards
                </p>
              </div>
            </div>

            {mode == "advanced" && (
              <>
                {rresp.type == "success" && (
                  <div class="resp-p-2 border border-acc4 bg-acc4/30 mx-auto xs:w-full lg:w-[75%] font-mon rounded-md resp-text-0 rounded-md">
                    <DispProvablesSection {...{ rresp }} />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const RewardsSection = () => {
  const ccon = useQuestCardsContext();
  const { qrs } = ccon;
  const card_refs = useRef([]);
  const rewards = useMemo(
    () => getv(qrs, "data.result", []),
    [qrs.dataUpdatedAt],
  );

  return (
    <div class="w-full fr-sc gap-[1rem] my-[1rem] overflow-auto resp-text--1">
      {_.isEmpty(rewards) ? (
        <></>
      ) : (
        <div className="w-full">
          <Card className={"w-full bg-r2lig/20 backdrop-blur-md"}>
            <p class="resp-text-2 font-digi">Rewards</p>
            <table
              className={twMerge(tablecn.table, "thintdrowp4-table-r2lig")}
            >
              <tbody>
                {rewards.map((e, i) => {
                  return (
                    <tr key={e.id}>
                      <td>
                        <div class="fr-sc resp-gap-1 resp-text--2">
                          {e.minted ? (
                            <div class="xs:w-[1rem] lg:w-[3rem] aspect-[1/1]">
                              <Link to={polytxnidlink(getv(e, "mint.hash"))}>
                                <Img img={polychainimg} />
                              </Link>
                            </div>
                          ) : (
                            <div class="">
                              <FontAwesomeIcon
                                icon={faSpinner}
                                className="text-acc4 resp-text-2"
                              />
                            </div>
                          )}
                          <span>{e.id}</span>
                        </div>
                      </td>

                      <td>
                        <div class="fr-sc gap-2">
                          {e.hand.map((c) => {
                            return (
                              <div class="xs:w-[2rem] lg:w-[4rem]">
                                <CardImg key={i} k={c} cn="reveal" />
                              </div>
                            );
                          })}
                        </div>
                      </td>

                      <td>
                        <div class="fc-ss resp-text--1 font-digi">
                          <p className="text-acc4">
                            Combo: {snake_str_to_caps(e.combo)}
                          </p>
                          <p className="text-acc4">
                            Prize: {format_prizename(getv(e, "prize"))}
                          </p>
                          <div class="xs:bloc lg:hidden resp-text--2">
                            {iso_format(e.cashout_at)}
                          </div>
                        </div>
                      </td>

                      <td>
                        <div class="xs:hidden lg:block">
                          {iso_format(e.cashout_at)}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Card>
        </div>
      )}
    </div>
  );
};

export const QuestCards = () => {
  const { psearch } = useAppContext();
  const accon = useAccountContext();
  const { vault: auth_vault } = accon;
  const { auth } = useAuthContext();

  const vault = useMemo(() => {
    let v = nils(psearch.v) ? null : psearch.v.toLowerCase();
    if (nils(v)) return auth_vault;
    return v;
  }, [auth_vault, psearch.v]);

  const [qo_vaultinfo] = useQueries([
    q_vaultinfo({ vault }, { enabled: !nils(vault) }),
  ]);
  const v = useMemo(
    () => getv(qo_vaultinfo, "data.result", {}),
    [qo_vaultinfo.dataUpdatedAt],
  );

  const enabled = !nils(vault) && auth == true;
  const [qrs] = useQueries([q_quest_cards_rewards({ vault }, { enabled })]);

  const [cards, set_cards] = useState([]);
  const [closed_n, set_closed_n] = useState(0);
  const [cards_loaded, set_cards_loaded] = useState(false);

  const [qovhand] = useQueries([q_quest_cards_vhand({ vault }, { enabled })]);
  useEffect(() => {
    if (!qissuccesss(qovhand)) return;
    let d = getv(qovhand, "data.result", {});
    set_cards(d.opened_cards ?? []);
    set_closed_n(d.closed_n ?? 0);
    set_cards_loaded(true);
  }, [qovhand.dataUpdatedAt]);

  const is_au = useMemo(() => {
    return auth_vault === vault && auth === true;
  }, [vault, auth_vault, auth]);

  const full_hand = cards.length == 5;
  const [hand, hand_combo] = useMemo(() => {
    if (cards.length !== 5) return [null, [], 0];
    let hand = cards.map((e) => e.card);
    let [combo, using, totval] = new PokerHandEvaluator(hand).evaluateHand();
    if (combo == "pair") {
      combo = null;
    }
    return [hand, combo];
  }, [jstr(cards)]);

  const log = {
    hand,
    hand_combo,
  };
  useEffect(() => {
    console.log("ccon:log", log);
  }, [jstr(log)]);

  const ccon = {
    vault,
    qrs,
    qovhand,
    closed_n,
    cards,
    set_cards,
    closed_n,
    set_closed_n,
    cards_loaded,

    full_hand,
    hand,
    hand_combo,
  };

  const page_title_fn = () => "Quest Cards";
  return (
    <PageWrapper page_title_fn={page_title_fn} cont_cn="xs:w-full lg:w-[80rem]">
      <QuestCardsContext.Provider value={ccon}>
        <div class="fc-cc w-full resp-gap-2">
          <div class="font-digi resp-text-2">Quest Cards</div>
          <p class="text-acc0 font-digi resp-text-1">{v?.vault_name}</p>
          <p class="text-acc0 resp-text--1">{vault}</p>

          <CreateComboSection />
          <div class="fr-sc w-full">
            <div class="flex-1"></div>
            <span className="resp-p-1 resp-text-1 text-acc0 font-digi">
              {closed_n} Closed Cards
            </span>
          </div>
          {cards_loaded && is_au && (
            <>
              <ControlSection />
            </>
          )}
          <RewardsSection />
        </div>
      </QuestCardsContext.Provider>
    </PageWrapper>
  );
};

export const RacePage_QuestCard = ({ cs, racing_vaults, vmap }) => {
  const { vault: auth_vault } = useAccountContext();

  return (
    <Link to={`/quest-cards`}>
      <div class="w-full font-digi resp-text--1 resp-gap-2 grid xs:grid-cols-2 lg:grid-cols-3">
        {(cs || []).map((c) => {
          let show = true;
          // console.log(c.card, issamevault);
          return (
            <div class="w-full h-[10rem] fr-cc border-2 bg-r2dark/40 border border-acc4 p-2 rounded-md">
              <div class="w-max p-2">
                <CardImg
                  {...{
                    k: show ? c.card : "back",
                    cn: twMerge(
                      "xs:w-[2rem] lg:w-[5rem]",
                      show ? "reveal" : "",
                    ),
                    auto_flip: show ? false : true,
                  }}
                />
              </div>
              <div class="fc-ss flex-1 text-white">
                <p className="resp-text--2">Rewarded to</p>
                <p className="resp-text--1">
                  {!nils(vmap[c.vault]) ? trim_n(vmap[c.vault], 25) : c.vault}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </Link>
  );
};
