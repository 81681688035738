import _ from "lodash";
import React, {
  useEffect,
  useState,
  useMemo,
  createContext,
  useContext,
} from "react";
import { Loader01c } from "../components/anims";
import { Card, Img, InpText, Tag } from "../components/utilityComps";
import {
  qiserr,
  qissuccesss,
  q_bikeinfo,
  q_fqual_entrants,
  q_fqual_info,
  q_fqual_leader,
  q_open_races,
  q_token_prices,
  q_trainer_leaderboards,
  q_trainer_vault_stage,
  q_trainer_myraces,
} from "../queries/queries";
import { metamaskimg } from "../utils/links";
import { cdelay, dec, getv, iso, jstr, nils } from "../utils/utils";
import { useAuthContext } from "../wrappers/AuthWrapper";
import { Link, useNavigate } from "react-router-dom";
import { ContactTag, Footer, HomeBannerVideo } from "./HomePage.js";
import { useMetaMaskContext } from "../wrappers/MetaMaskWrapper";
import { useLayoutContext } from "../components/Layout";
import { faArrowDown, faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RectBtn, RectBtn_SMLong } from "../utils/raceutils2";
import { useAccountContext } from "../wrappers/AccountWrapper";
import { useQueries } from "react-query";
import { PopUp, PopupCloseBtn } from "../components/popup";
import { twMerge } from "tailwind-merge";
import {
  faArrowLeft,
  faArrowLeftLong,
  faArrowRight,
  faArrowRightLong,
  faUsd,
} from "@fortawesome/free-solid-svg-icons";
import { BikeImg } from "../components/BikeImg";
import { colormap } from "../wrappers/colormap";
import { elementmap, gendermap } from "../utils/cn_map";
import { Helmet } from "react-helmet";
import BikeCard from "../components/BikeCard";
import { useAppContext } from "../App";
import {
  InfoCard as FQualInfoCard,
  Leaderboard as FQualLeaderboard,
} from "./FQual";
import { RacesListInner } from "./Races.js";
import { QuestSelect } from "./Quest/QuestSelect";
import { Roadmap } from "./Roadmap.js";

const TLContext = createContext({});
const useTLContext = () => useContext(TLContext);

const TrainerRacesSection = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch, tok_to_usd_val, tokmap } = appcon;

  const acccon = useAccountContext();
  const { vault, vdoc, hids, bikesob } = acccon;
  const tlcon = useTLContext();
  const { filthids } = tlcon;

  const [subtab, set_subtab] = useState(
    // localStorage.getItem("trainer_races_section_subtab") ?? null,
    psearch.subtab ?? "races",
  );
  useEffect(() => {
    // localStorage.setItem("trainer_races_section_subtab", subtab);
    if (psearch.subtab !== subtab) upd_psearch({ subtab });
  }, [subtab, psearch.subtab]);

  const [qo_open_races] = useQueries([
    q_open_races({ class: 90, eventtags: ["Trainer"] }, { enabled: true }),
  ]);

  const [races, races_n] = useMemo(() => {
    let rs = getv(qo_open_races.data, "result");
    if (_.isEmpty(rs)) return [[], 0];
    let races = [];
    for (let r of rs) {
      let race = r;
      if (race) {
        race.fee_usd = tok_to_usd_val(race.fee, race.paytoken);
        race.prize_usd = tok_to_usd_val(race.prize, race.paytoken);
        // console.log(race);
      }
      races.push(race);
    }
    races = _.compact(races);
    races = _.compact(races);
    return [races, races.length];
  }, [qo_open_races.dataUpdatedAt, jstr(tokmap)]);
  const [selrace, set_selrace] = useState(null);

  return (
    <>
      {
        <div class="w-max mx-auto max-w-full pointer-events-none">
          {filthids.map((hid, idx) => {
            let b = getv(bikesob, `${hid}`);
            if (_.isEmpty(b)) return;
            return <BikeCard key={hid} bike={b} {...{}} />;
          })}
        </div>
      }

      <hr className="my-[1rem]" />

      <div class="">
        <p class="text-center my-1 font-digi resp-text-1">Choose your Path</p>
        <p class="resp-text-1 text-center my-1 mb-2 font-digi">
          You qualify for our trainer tourney EITHER way you choose
        </p>
        <div class="grid xs:grid-cols-1 md:grid-cols-2 gap-2 w-max mx-auto">
          {[
            ["races", "Race Against Other Opponents", "Enter a Race"],
            ["quest", "Test your Skills against Game Bots", "Create a Quest"],
          ].map(([k, info, btntxt]) => {
            let active = k == subtab;
            return (
              <div
                onClick={() => {
                  set_subtab(k);
                }}
                class={twMerge(
                  "border border-acc4 cursor-pointer p-[1rem] w-[20rem] rounded-[1rem] border-acc4",
                  active ? "bg-acc4/40" : "bg-r2dark/60",
                )}
              >
                <p class="resp-text-1 text-center my-1">{info}</p>
              </div>
            );
          })}
        </div>
        <hr className="my-[1rem]" />
        {subtab == "races" && (
          <div class="my-1">
            <p class="font-digi my-2 resp-text-1 text-center">
              Just click enter and enter your core in a race against other
              players
              <br />
              Your finish position in each race will determine your points for
              the tourney leaderboard
            </p>
            <RacesListInner
              {...{ races, qo_races: qo_open_races, selrace, set_selrace }}
            />
          </div>
        )}
        {subtab == "quest" && (
          <div class="my-1">
            <p class="font-digi my-2 resp-text-1 text-center">
              In Quest mode you will battle against bots
              <br />
              First choose a distance and follow the prompts, good luck you will
              need it
              <br />
              Finish position in a quest race will determine points earned for
              tourney leaderboard
            </p>
            <QuestSelect
              custom_txt_fns={{
                cb: (cb, rv) => `${cb}00 M`,
              }}
              override_info={{
                k: "trainer",
                feeusd: 0,
                hid: getv(tlcon, "filthids.0"),
                h: getv(bikesob, getv(tlcon, "filthids.0")),
                quest_type: "3-rounds",
                skipstages: [2, 3, 4],
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

const MyTrainerRacesSection = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch, tok_to_usd_val, tokmap } = appcon;

  const acccon = useAccountContext();
  const { vault, vdoc, hids, bikesob } = acccon;
  const tlcon = useTLContext();
  const { filthids } = tlcon;

  const [qo_races] = useQueries([
    q_trainer_myraces(
      { vault },
      { enabled: true, staleTime: 30 * 1e3, refetchInterval: 30 * 1e3 },
    ),
  ]);

  const [races, races_n] = useMemo(() => {
    let rs = getv(qo_races.data, "result");
    if (_.isEmpty(rs)) return [[], 0];
    let races = [];
    for (let r of rs) {
      let race = r;
      if (race) {
        race.fee_usd = tok_to_usd_val(race.fee, race.paytoken);
        race.prize_usd = tok_to_usd_val(race.prize, race.paytoken);
        // console.log(race);
      }
      races.push(race);
    }
    races = _.compact(races);
    races = _.compact(races);
    return [races, races.length];
  }, [qo_races.dataUpdatedAt, jstr(tokmap)]);
  const [selrace, set_selrace] = useState(null);

  return (
    <>
      {
        <div class="w-max mx-auto max-w-full pointer-events-none">
          {filthids.map((hid, idx) => {
            let b = getv(bikesob, `${hid}`);
            if (_.isEmpty(b)) return;
            return <BikeCard key={hid} bike={b} {...{}} />;
          })}
        </div>
      }

      <hr className="my-[1rem]" />
      {qo_races.isLoading && (
        <div class="w-max mx-auto">
          <Loader01c />
        </div>
      )}
      <div class="">
        <div class="my-1">
          <RacesListInner
            {...{ races, qo_races: qo_races, selrace, set_selrace }}
          />
        </div>
      </div>
    </>
  );
};

const TrainerLeaderboardSection = () => {
  const tlcon = useTLContext();
  const { my, set_my, fid, set_fid, qi, ql, f, fl, fe } = tlcon;

  const [show, set_show] = useState(true);
  const scrollToLeaderboard = () => {
    const element = document.getElementById("fqual-leaderboard");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const fn = () => {
      set_show(false);
      if (show) scrollToLeaderboard();
    };
    const el = document.getElementById("pagemain");
    if (el) el.addEventListener("scroll", fn);
    return () => {
      if (el) el.removeEventListener("scroll", fn);
    };
  }, [show]);

  return (
    <>
      {qissuccesss(qi) && qissuccesss(ql) && show && (
        <>
          <div
            onClick={() => {
              scrollToLeaderboard();
              set_show(false);
            }}
            style={{ mixBlendMode: "difference" }}
            class="fixed bottom-[2rem] z-[10] p-[2rem] left-[48%] translate-x-[-50%] bounce-anim cursor-pointer"
          >
            <FontAwesomeIcon
              className="xs:text-[4rem] lg:text-[8rem]"
              icon={faArrowDown}
            />
          </div>
        </>
      )}
      {qi.isLoading ? (
        <Loader01c />
      ) : qissuccesss(qi) ? (
        <FQualInfoCard useContext={useTLContext} />
      ) : (
        <></>
      )}
      {ql.isLoading ? null : <FQualLeaderboard useContext={useTLContext} />}
    </>
  );
};

export const TrainerLeaderboard = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const { auth } = useAuthContext();
  const acccon = useAccountContext();
  const { vault, vdoc, hids, bikesob } = acccon;

  const pagetitle = useMemo(() => {
    return `Trainer Leaderboard | FBike DNA`;
  }, []);

  const [qostage] = useQueries([
    q_trainer_vault_stage({ vault }, { enabled: true }),
  ]);
  useEffect(() => {
    qostage.refetch();
  }, []);

  const [vstage, filthids] = useMemo(() => {
    let d = getv(qostage, "data.result", {});
    if (nils(d)) return [null, []];
    let t_hid = getv(d, "dets.hid");
    return [d, _.compact([t_hid])];
  }, [qostage.dataUpdatedAt]);

  const [tab, set_tab] = useState(
    null,
    // psearch.tab ?? localStorage.getItem("trainer_leaderboard_tab") ?? "races",
    // "roadmap",
  );
  useEffect(() => {
    if (tab == null) {
      let a =
        psearch.tab ??
        localStorage.getItem("trainer_leaderboard_tab") ??
        "roadmap";
      return set_tab(a);
    }
    localStorage.setItem("trainer_leaderboard_tab", tab);
    if (psearch.tab !== tab) upd_psearch({ tab });
  }, [tab]);
  useEffect(() => {
    if (psearch.tab !== tab) set_tab(psearch.tab);
  }, [psearch.tab]);

  const [fid, set_fid] = useState(null);
  const [qofids] = useQueries([q_trainer_leaderboards({}, {})]);
  const fids = useMemo(() => {
    let fs = getv(qofids, "data.result", []);
    if (!nils(getv(fs, "0"))) return set_fid(fs[0]);
    return fs;
  }, [qofids.dataUpdatedAt]);

  const enabled = !nils(fid);
  const [qi, ql, qe] = useQueries([
    q_fqual_info({ fid }, { enabled }),
    q_fqual_leader({ fid }, { enabled }),
    q_fqual_entrants(
      { fid },
      {
        enabled,
        staleTime: 20 * 1e3,
        refetchInterval: 20 * 1e3,
      },
    ),
  ]);

  const [my, set_my] = useState(false);

  const f = useMemo(() => {
    return qissuccesss(qi) ? getv(qi, "data.result") : null;
  }, [qi.dataUpdatedAt]);
  const fl = useMemo(() => {
    return qissuccesss(ql) ? getv(ql, "data.result") : {};
  }, [ql.dataUpdatedAt]);
  const fe = useMemo(() => {
    return qissuccesss(qe) ? getv(qe, "data.result") : {};
  }, [qe.dataUpdatedAt]);

  const tlcon = {
    vault,
    auth,
    filthids,
    tab,
    set_tab,

    my,
    set_my,
    fid,
    set_fid,

    qi,
    ql,
    qe,
    f,
    fl,
    fe,
  };

  if (qiserr(qostage)) {
    return (
      <>
        <Helmet>
          <title>{pagetitle}</title>
        </Helmet>
        <div class="h-[5rem]"></div>
        <div class="fc-cc gap-2 mx-auto w-[60rem] resp-text-2">
          <p className="font-digi text-justify">{qiserr(qostage)}</p>
          <div class="h-[2rem]"></div>
          <Link to={`/roadmap`}>
            <div class="bg-acc4/50 resp-p-2 font-digi resp-text-1 rounded-md -skew-x-12">
              Check your Roadmap Progress
            </div>
          </Link>
        </div>
      </>
    );
  }

  if (_.isEmpty(filthids)) {
    return (
      <>
        <Helmet>
          <title>{pagetitle}</title>
        </Helmet>
        <div class="h-[5rem]"></div>
        <div class="fc-cc gap-2 mx-auto w-[50rem] resp-text-2">
          <p>You don't own a Trainer Bike currently</p>
          <Link to={`/claim-trainer`}>
            <Tag className={twMerge("bg-acc4/40 -skew-x-12")}>
              <span class="resp-text-1">
                <>Claim a Trainer Now</>
              </span>
            </Tag>
          </Link>
        </div>
      </>
    );
  }
  // const tabs = ["races", "my_races", "leaderboard"];
  const tabs = [
    // "roadmap",
    "races",
    "my_races",
    "leaderboard",
  ];

  return (
    <TLContext.Provider value={tlcon}>
      <div className="">
        <Helmet>
          <title>{pagetitle}</title>
        </Helmet>
        <div id="pagemain" className="h-page relative">
          <div className="max-w-[98vw] w-[80rem] mx-auto">
            <div className="h-[5rem]"></div>
            <div class="fr-cc resp-gap-2">
              {tabs.map((_tab) => {
                let cn = "font-digi";
                let active = tab == _tab;
                return (
                  <Tag
                    onClick={() => {
                      set_tab(_tab);
                    }}
                    className={twMerge(
                      cn,
                      "transition duration-300",
                      active
                        ? "bg-r2lig/40 text-white -skew-x-12 "
                        : "bg-transparent text-white",
                    )}
                  >
                    <span className="resp-text-1">
                      {_.chain(_tab)
                        .split("_")
                        .map((e) => _.capitalize(e))
                        .join(" ")
                        .value()}
                    </span>
                  </Tag>
                );
              })}
            </div>

            <div className="h-[1rem]"></div>
            {tab == "roadmap" && <Roadmap {...{ vault }} />}
            {tab == "races" && <TrainerRacesSection />}
            {tab == "my_races" && <MyTrainerRacesSection />}

            {tab == "leaderboard" && !nils(fid) && (
              <TrainerLeaderboardSection />
            )}
          </div>
        </div>
      </div>
    </TLContext.Provider>
  );
};
