import {
  base64_to_json,
  getv,
  iso,
  jparse,
  jstr,
  nano,
  nils,
  tofeth,
} from "../../utils/utils";
import { contractAddress_list } from "../constants";
import { ethers } from "ethers";
import abi from "./abis/DNA_Splicing.json";
import { t3_contract_call } from "../contract_funcs";
import _ from "lodash";
import { polygon } from "thirdweb/chains";
import { useThirdWebLoginContext } from "../../views/ThirdWebLogin";

const k = "splicing_cv2";

const abi_methods_map = _.keyBy(abi, "name");

class Contract {
  constructor({ contractAddress, provider, signer, aumode, ...ext }) {
    this.provider = provider;
    this.signer = signer;
    // this.abi = abi;
    // this.abi_methods_map = abi_methods_map;
    this.contractAddress = contractAddress;
    this.contract = new ethers.Contract(this.contractAddress, abi, signer);
    this.aumode = aumode;
    if (aumode == "thirdweb") {
      this.active_account = ext.active_account;
    }
  }

  async getParsedLogs(logs) {
    if (!logs) return [];

    let logrows = [];
    for (let log of logs) {
      let par = this.contract.interface.parseLog(log);

      if (nils(par)) continue;

      let name = par.name;
      let o = {
        name,
        logIndex: log.logIndex,
        hash: log.transactionHash,
        blockNumber: log.blockNumber,
      };
      o.id = `${o.hash}:${o.logIndex}`;

      // event SpliceRequested( string reqid, uint fid, uint mid, address payingvault, address tovault, string encoded);
      // event SplicePayment( address pay_from, address pay_to, uint pay_amt, string pay_token);
      // event SpliceCreated(string reqid, address tovault, uint hid);

      if (name == "SpliceRequested") {
        let a = par.args;
        o.args = {
          reqid: a[0],
          fid: Number(a[1]),
          mid: Number(a[2]),
          payingvault: a[3].toLowerCase(),
          tovault: a[4].toLowerCase(),
          encoded: a[5],
        };
        if (o.args.encoded) o.args.encoded = base64_to_json(o.args.encoded);
      } else if (name == "SpliceCreated") {
        let a = par.args;
        o.args = {
          reqid: a[0],
          tovault: a[1].toLowerCase(),
          hid: Number(a[2]),
        };
      } else if (name == "SplicePayment") {
        let a = par.args;
        o.args = {
          pay_from: a[0].toLowerCase(),
          pay_to: a[1].toLowerCase(),
          pay_amt: Number(a[2]),
          pay_token: a[3],
        };
      }

      logrows.push(o);
    }
    return logrows;
  }

  // function request_splice( address tovault, uint fid, uint mid, string memory encoded) public {
  async request_splice(tovault, fid, mid, encoded, info) {
    if (this.aumode == "thirdweb") {
      let tx = await t3_contract_call(
        this.contractAddress,
        "request_splice",
        [tovault, fid, mid, encoded],
        "txn",
        info.wait === false ? false : true,
        { abi_methods_map, active_account: this.active_account },
      );
      console.log(`${k}:request_splice`, tx);
      return tx;
    } else {
      let gasinfo = {};
      let tx = await this.contract.request_splice(
        tovault,
        fid,
        mid,
        encoded,
        gasinfo,
      );
      console.log(`${k}:request_splice`, tx.hash);
      if (info.wait !== false) tx = await tx.wait();
      return tx;
    }
  }
}

const get_contract_address = () => {
  return contractAddress_list[k];
};

const get_contract = async (ext = {}) => {
  const aumode = getv(ext, "aumode");
  const contractAddress = get_contract_address();
  if (aumode == "wallet") {
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = ext.nosigner === true ? null : await provider.getSigner();
    const runner = new Contract({ contractAddress, provider, signer, aumode });
    return runner;
  } else if (aumode == "thirdweb") {
    const provider = new ethers.JsonRpcProvider(polygon.rpc);
    const signer = null;
    const runner = new Contract({
      contractAddress,
      provider,
      signer,
      aumode,
      active_account: ext.active_account,
    });
    return runner;
  }
  return null;
};

export const DNA_Splicing = {
  k,
  get_contract,
  get_contract_address,
};
