import { useState, useEffect, useMemo } from "react";
import { useNowContext } from "../App";
import { PageWrapper } from "../components/ShortComps";
import { from_time_mini } from "../utils/utils";

export const Maintenence = ({ children, maintenance = true }) => {
  const endsat = "2025-02-23T13:00:32Z";
  // const { now } = useNowContext();

  if (maintenance === false) {
    return <>{children}</>;
  }

  return (
    <div class="relative">
      <div class="scroll-none pointer-events-none ">{children}</div>
      <div class="absolute top-0 left-0 w-full backdrop-blur-md">
        <PageWrapper>
          <div class="h-[4rem]"></div>
          <div class="fc-cc resp-gap-2 resp-text-1">
            <p class="resp-text-2 font-digi text-acc4">
              Page is under maintenance
            </p>
            <p>Unlocking in {from_time_mini(endsat)}</p>
          </div>
        </PageWrapper>
      </div>
    </div>
  );
};
