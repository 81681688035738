import React, { useEffect, useState } from "react";
import { getv, iso } from "../utils/utils.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestion,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { twMerge } from "tailwind-merge";
import _ from "lodash";
import { Img, Tag } from "../components/utilityComps.js";
import { useAppContext } from "../App.js";

const base_s3 = "https://cdn.dnaracing.run/help_imgs";

const tmap = _.chain([
  [
    "getting started",
    [
      // `${base_s3}/getting-started-01.png`,
      // `${base_s3}/getting-started-02.png`,
      // `${base_s3}/h_trainer_bike.png`,
      // `${base_s3}/h_roadmap.png`,
      `${base_s3}/new_help_posters/getting_started/221.png`,
      `${base_s3}/new_help_posters/getting_started/222.png`,
      `${base_s3}/new_help_posters/getting_started/223.png`,
      `${base_s3}/new_help_posters/getting_started/224.png`,
    ],
  ],
  [
    "splicing",
    [
      // `${base_s3}/splicing-08.png`,
      // `${base_s3}/splicing-09.png`,
      // `${base_s3}/splicing_breed_cycles.png`,
      // `${base_s3}/h_how_newcore.png`,
      // `${base_s3}/h_splicing.png`,
      // `${base_s3}/h_coreinarena.png`,
      // `${base_s3}/h_family_splicing.png`,
      // `${base_s3}/h_breeding_chart.png`,
      // `${base_s3}/h_splice_fee.png`,
      // `${base_s3}/h_quick_splice.png`,
      `${base_s3}/new_help_posters/splicing/170.png`,
      `${base_s3}/new_help_posters/splicing/171.png`,
      `${base_s3}/new_help_posters/splicing/172.png`,
      `${base_s3}/new_help_posters/splicing/173.png`,
      `${base_s3}/new_help_posters/splicing/174.png`,
      `${base_s3}/new_help_posters/splicing/175.png`,
      `${base_s3}/new_help_posters/splicing/176.png`,
      `${base_s3}/new_help_posters/splicing/177.png`,
      `${base_s3}/new_help_posters/splicing/178.png`,
      `${base_s3}/new_help_posters/splicing/179.png`,
    ],
  ],
  [
    "skins",
    [
      // `${base_s3}/skins-04.png`,
      // `${base_s3}/skins-05.png`,
      // `${base_s3}/h_mintskin.png`,
      // `${base_s3}/h_skincounts.png`,
      // `${base_s3}/h_custom_skins.png`,
      `${base_s3}/new_help_posters/skins/181.png`,
      `${base_s3}/new_help_posters/skins/182.png`,
      `${base_s3}/new_help_posters/skins/183.png`,
      `${base_s3}/new_help_posters/skins/184.png`,
      `${base_s3}/new_help_posters/skins/185.png`,
    ],
  ],
  [
    "tokens",
    [
      // `${base_s3}/tokens-02.png`,
      // `${base_s3}/tokens-03.png`,
      // `${base_s3}/tokens-04.png`,
      // `${base_s3}/tokens-05.png`,
      // `${base_s3}/tokens-06.png`,
      // `${base_s3}/tokens-07.png`,
      // `${base_s3}/h_gametokens.png`,
      // `${base_s3}/h_buy_dez.png`,
      `${base_s3}/new_help_posters/tokens/187.png`,
      `${base_s3}/new_help_posters/tokens/188.png`,
      `${base_s3}/new_help_posters/tokens/189.png`,
      `${base_s3}/new_help_posters/tokens/190.png`,
      `${base_s3}/new_help_posters/tokens/191.png`,
      `${base_s3}/new_help_posters/tokens/192.png`,
      `${base_s3}/new_help_posters/tokens/193.png`,
      `${base_s3}/new_help_posters/tokens/194.png`,
    ],
  ],
  [
    "racing",
    [
      // `${base_s3}/racing-09.png`,
      // `${base_s3}/racing-10.png`,
      // `${base_s3}/racing-11.png`,
      // `${base_s3}/racing-12.png`,
      // `${base_s3}/racing-13.png`,
      // `${base_s3}/racing-14.png`,
      // `${base_s3}/raing-star.png`,
      // `${base_s3}/h_leader_bounties.png`,
      // `${base_s3}/h_starting_fresh.png`,
      // `${base_s3}/h_quest_mode.png`,
      // `${base_s3}/h_trainer_bike.png`,
      // `${base_s3}/h_bikeslist.png`,
      // `${base_s3}/h_priezebox.png`,
      // `${base_s3}/h_enter_race.png`,
      // `${base_s3}/h_odds.png`,
      // `${base_s3}/h_spinngo.png`,
      // `${base_s3}/h_satellites.png`,
      // `${base_s3}/h_corestats.png`,
      // `${base_s3}/h_jackpot.png`,
      // `${base_s3}/h_claiming_race.png`,
      // `${base_s3}/h_challenge.png`,
      `${base_s3}/new_help_posters/racing/trainer_bike.png`,
      `${base_s3}/new_help_posters/racing/196.png`,
      `${base_s3}/new_help_posters/racing/197.png`,
      `${base_s3}/new_help_posters/racing/198.png`,
      `${base_s3}/new_help_posters/racing/199.png`,
      `${base_s3}/new_help_posters/racing/200.png`,
      `${base_s3}/new_help_posters/racing/201.png`,
      `${base_s3}/new_help_posters/racing/202.png`,
      `${base_s3}/new_help_posters/racing/203.png`,
      `${base_s3}/new_help_posters/racing/204.png`,
      `${base_s3}/new_help_posters/racing/205.png`,
      `${base_s3}/new_help_posters/racing/206.png`,
      `${base_s3}/new_help_posters/racing/207.png`,
      `${base_s3}/new_help_posters/racing/208.png`,
      `${base_s3}/new_help_posters/racing/209.png`,
      `${base_s3}/new_help_posters/racing/210.png`,
      `${base_s3}/new_help_posters/racing/211.png`,
      `${base_s3}/new_help_posters/racing/212.png`,
      `${base_s3}/new_help_posters/racing/213.png`,
      `${base_s3}/new_help_posters/racing/214.png`,
      `${base_s3}/new_help_posters/racing/215.png`,
      `${base_s3}/new_help_posters/racing/216.png`,
    ],
  ],
  [
    "market",
    [
      // "https://i.imgur.com/NbSQ0Ca.jpg",
      // "https://i.imgur.com/7YYK5lZ.jpg",
      // "https://i.imgur.com/31mLNG2.jpg",
    ],
  ],
  [
    "help",
    [
      // "https://i.imgur.com/FhCxQaX.jpg",
      // "https://i.imgur.com/fq5TTcM.jpg"
      // `${base_s3}/help-01.png`,
      // `${base_s3}/help-02.png`,
      `${base_s3}/new_help_posters/help/218.png`,
      `${base_s3}/new_help_posters/help/219.png`,
    ],
  ],
])
  .map((e) => ({ tab: e[0], imgs: e[1] }))
  .keyBy("tab")
  .value();

function HelpPage() {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;
  const [tab, set_tab] = useState(psearch.tab ?? "splicing");
  useEffect(() => {
    upd_psearch({ tab });
  }, [tab]);

  return (
    <div className="h-page">
      <div className="max-w-[95vw] w-[60rem] mx-auto">
        <div className="h-[3rem]"></div>
        <div className="text-acc0 resp-text-1 text-center my-2">
          <FontAwesomeIcon icon={faQuestionCircle} />
          <span>{" Help"}</span>
        </div>

        <div className="fr-cc resp-gap-1 my-2">
          {[
            "getting started",
            "splicing",
            "skins",
            "tokens",
            "racing",
            "market",
            "help",
            "features",
          ].map((_tab) => {
            let active = tab === _tab;
            return (
              <Tag
                onClick={() => set_tab(_tab)}
                className={twMerge(
                  "resp-text--1 font-digi",
                  active ? "text-acc0 bg-reg/40 -skew-x-12" : "text-white",
                )}
              >
                {_.chain(_tab)
                  .split(" ")
                  .map((e) => _.capitalize(e))
                  .join(" ")
                  .value()}
              </Tag>
            );
          })}
        </div>

        {tab && tmap[tab] && (
          <div className="mx-auto w-[40rem] max-w-full">
            {tmap[tab].imgs.map((e, ei) => {
              // console.log(e);
              return (
                <div className="" key={`${tab}-${ei}`}>
                  <Img img={e} className="w-full" />
                </div>
              );
            })}
          </div>
        )}
        {tab == "features" && (
          <>
            <div className="w-full">
              <iframe
                src="https://www.youtube.com/embed/YgC77AyKVdU"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube Video"
                className="w-full aspect-[16/9]"
              />
            </div>
          </>
        )}

        <div className="h-[5rem]"></div>
      </div>
    </div>
  );
}

export default HelpPage;
