import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { BImg, Card, Img, Tag, TokenIcon } from "../components/utilityComps.js";
import { useLocation, useMatch, useParams } from "react-router";
import { tokdecn, useAppContext, useNowContext } from "../App.js";
import { useQueries } from "react-query";
import {
  polytxnlink,
  q_arcade_race,
  q_arcade_race_hstats,
  q_arcade_racerun,
  q_hstats,
  q_open_hs_in,
  q_race,
  q_racerun,
  q_token_prices,
  qiserr,
  qissuccesss,
  useStepQuery,
  q_vaultraces,
  q_races_rid_rel_rs,
  q_races_payout_report,
} from "../queries/queries.js";
import {
  base64_to_json,
  cdelay,
  dec,
  getv,
  is_valid_txhash,
  iso_format,
  jstr,
  nano,
  nils,
  to_time_mini,
} from "../utils/utils.js";
import { LeftSidebar, useRacesContext } from "./Races.js";
import { Loader01c } from "../components/anims.js";
import _ from "lodash";
import {
  BY_Star,
  PosTag,
  RaceBaseInfoCard,
  RaceCard,
  RaceCardInner,
  RaceHRow,
  get_race_rtstatus,
} from "../utils/raceutils.js";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import { twMerge } from "tailwind-merge";
import { RacePage_Run } from "./RacePage_Run.js";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEthereum, faHackerNews } from "@fortawesome/free-brands-svg-icons";
import {
  faArrowLeft,
  faArrowLeftLong,
  faArrowRightLong,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faExclamationTriangle,
  faHourglass,
  faSatelliteDish,
  faSpinner,
  faTimesCircle,
  faUsd,
} from "@fortawesome/free-solid-svg-icons";
import { RoundRaces } from "./RoundRaces.js";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { tablecn } from "../utils/cn_map.js";
import { polychainimg, prizebox_img, wethimg } from "../utils/links.js";
import { PopUp } from "../components/popup.js";
import { VaultRacesWindow } from "./VaultRacesWindow.js";
import { SatelliteV2PageInner } from "./SatellitesV2.js";
import { RaceCardR2_full, SplitRaceNameView } from "../utils/raceutils2.js";
import { ErrorBoundary } from "../utils/errbou.js";
import { QuestRaces } from "./QuestRaces.js";
import { RacePage_QuestCard } from "./QuestCards.js";
import { useOverlayContext } from "../wrappers/OverlayWrapper.js";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { RaceCard_cv2_full } from "../utils/raceutils3.js";

export const RaceContext = createContext();
export const useRaceContext = () => useContext(RaceContext);

const FinishedCard = () => {
  const { r, hsob, watching2d, set_watching2d, racerun, qo_racerun } =
    useRaceContext();
  const { hids: vhids } = useAccountContext();

  const pos_hs_order = useMemo(() => {
    let ord = _.chain(r.hs)
      .sortBy((hp) => hp.pos)
      .value();
    return ord;
  }, [r]);

  const rvob = r.racing_vaults;
  const cvob = r.current_vaults;
  const vnob = r.vaults_names;

  const [showresults, set_showresults] = useState(false);

  return (
    <div>
      <div className="my-[1rem]"></div>
      <div className="relative">
        <div
          className={twMerge(
            "absolute w-full h-full z-20 box-border",
            showresults == false
              ? "bg-gradient-to-b from-dark/20 to-acc0/20"
              : "bg-tranparent z-0",
          )}
        >
          {showresults == false && (
            <div className="fr-cc w-full h-full">
              <div className="aboluste bottom-[1rem]">
                <Tag
                  onClick={() => {
                    set_showresults(true);
                  }}
                  className="bg-dark text-acc0 border border-acc0 resp-px-4 font-digi resp-text-1"
                >
                  Show Results
                </Tag>
              </div>
            </div>
          )}
        </div>
        <div className="bg-dark resp-p-4 relative">
          <div
            className={twMerge(
              "grid resp-gap-2 z-10 xs:grid-cols-1",
              r.rgate >= 6 ? "lg:grid-cols-3" : "",
              r.rgate >= 2 ? "lg:grid-cols-2" : "",
            )}
          >
            {pos_hs_order.map((hp) => {
              let hid = hp.hid;
              let is_vhhid = vhids.includes(hid);

              let h = hsob[hid];
              if (nils(h)) return <></>;

              const c_vau = getv(cvob, hid) ?? null;
              const c_vauname = nils(c_vau) ? "" : getv(vnob, c_vau);
              const r_vau = getv(rvob, hid) ?? null;
              const r_vauname = nils(r_vau) ? "" : getv(vnob, r_vau);

              console.log(hid, c_vau, r_vau);

              return (
                <div
                  className={twMerge(
                    "bg-reg resp-p-2 rounded-md border-2 border-transparent",

                    showresults && is_vhhid
                      ? "bg-lig shadow-md shadow-acc0 border-acc0"
                      : "",

                    showresults && !nils(h.bg_card) ? h.bg_card : "",
                  )}
                >
                  <div className="fr-sc resp-gap-2 resp-my-1">
                    <PosTag className={"font-digi"} pos={hp.pos} />
                    <div className="flex-1"></div>
                    <Tag
                      className={twMerge(
                        showresults ? "opacity-100" : "opacity-0",
                        "font-digi",
                        hp.pos == 1 ? "text-green-300" : "",
                      )}
                    >
                      {dec(hp.time, 2)}sec
                    </Tag>
                  </div>

                  <div className="fr-sc resp-gap-2 resp-my-1 w-full">
                    <Link to={`/bike/${hid}`}>
                      <BImg
                        className={
                          "xs:w-[2rem] xs:h-[2rem] lg:w-[4rem] lg:h-[4rem]"
                        }
                        hex_code={showresults ? h.hex_code : "000000"}
                      />
                    </Link>
                    <div className="fc-ss flex-1 resp-gap-1">
                      <Link className="w-full" to={`/bike/${hid}`}>
                        <div styleName={twMerge("fr-ss resp-gap-1 w-full")}>
                          <span
                            className={twMerge(
                              showresults
                                ? "text-white "
                                : "text-transparent bg-dark rounded-sm",
                              "transition duration-300 resp-text-1 font-digi",
                            )}
                          >
                            {h.name}
                          </span>
                          <div className="flex-1"></div>
                        </div>
                      </Link>
                      <div
                        className={twMerge(
                          "fc-ss resp-gap-2 w-full",
                          showresults
                            ? "text-acc0 "
                            : "text-transparent bg-dark rounded-sm",
                        )}
                      >
                        {r_vauname == c_vauname ? (
                          <span className="text-acc0 resp-text--1">
                            {r_vauname}
                          </span>
                        ) : (
                          <>
                            <span className="text-acc0 resp-text--1">
                              Racing: {r_vauname}
                            </span>
                            <span className="text-slate-400 resp-text--1 ">
                              LiveOwner: {c_vauname}
                            </span>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col justify-start items-end">
                      {showresults && <BY_Star star={hp.star} />}
                      <div
                        className={twMerge(
                          "fr-cc",
                          showresults
                            ? "text-acc0 "
                            : "text-transparent bg-dark rounded-sm",
                        )}
                      >
                        <span className="resp-text--1">F{h.fno}</span>
                        <span className="resp-text--1">/</span>
                        <span className="resp-text--1">
                          {_.capitalize(h.type)}
                        </span>
                      </div>
                    </div>
                  </div>

                  {hp.prize_eth > 0 && (
                    <div className="fr-sc resp-gap-2 mt-1 resp-text-0">
                      <div className="flex-1"></div>
                      <span className="font-bold">Prize:</span>
                      <div className="fr-sc resp-gap-1 text-green-300">
                        <FontAwesomeIcon icon={faUsd} />
                        <span>{dec(hp.prize_usd, 2)}</span>
                      </div>
                      <div className="fr-sc resp-gap-1 text-white">
                        <TokenIcon token={r.paytoken ?? "WETH"} size="xs" />
                        <span>
                          {dec(hp.prize_eth, tokdecn(r.paytoken ?? "WETH"))}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const watchbtncn = twMerge(
  "img-btn-grayed font-digi resp-text-0 text-center",
  "xs:px-4 lg:px-6 ",
  "xs:py-1 lg:py-3",
  "xs:dont-grayscale lg:do-grayscale",
);
export const Watch2d = () => {
  const rcon = useRaceContext() || {};
  const { r, watching2d, set_watching2d, set_watching2d_st, rtstatus } = rcon;

  return (
    <div
      onClick={() => {
        set_watching2d(true);
      }}
      style={{
        backgroundImage: "url('https://i.imgur.com/0vONHNc.jpg')",
      }}
      className={twMerge(watchbtncn)}
    >
      Watch in 2D
    </div>
  );
};
export const ExitWatch2d = () => {
  const rcon = useRaceContext() || {};
  const { r, watching2d, set_watching2d } = rcon;

  return (
    <div
      onClick={() => set_watching2d(false)}
      style={{
        backgroundImage: "url('https://i.imgur.com/0vONHNc.jpg')",
      }}
      className={twMerge(watchbtncn, "exit")}
    >
      Exit 2D race
    </div>
  );
};
export const Watch3d = () => {
  const rcon = useRaceContext() || {};
  const { r, watching2d, set_watching2d } = rcon;

  let extq = r.is_arcade ? `&is_arcade=true` : ``;

  let link = nils(r.track)
    ? `https://3d.fbike.dnaracing.run/?race_id=${r.rid}${extq}`
    : `https://3d.${r.track}.fbike.dnaracing.run/?race_id=${r.rid}${extq}`;

  return (
    <a target="_blank" href={link}>
      <div
        style={{
          backgroundImage: "url('https://i.imgur.com/p3Hffmx.jpg')",
        }}
        className={twMerge(watchbtncn)}
      >
        Watch in 3D
      </div>
    </a>
  );
};
export const WatchRow = () => {
  const rcon = useRaceContext() || {};
  const { watching2d } = rcon;
  return (
    <div className="fr-sc w-full resp-my-2">
      <div className="flex-1"></div>
      {watching2d == true ? <ExitWatch2d /> : <Watch2d />}
      <Watch3d />
    </div>
  );
};

const txtypecn = {
  race_refund: "text-purple-400",
  race_entry: "text-yellow-400",
  race_payout: "text-green-400",
};
const cn_txtype = (type) => txtypecn[type] ?? "text-white";
const RaceTransactions = () => {
  const [show_txns, set_show_txns] = useState(false);

  const rcon = useRaceContext();
  const { r, rtstatus, watching2d, racerun, qo_racerun, is_subround, hsob } =
    rcon;

  const valid_payout_txns = useMemo(() => {
    if (nils(r.payout_txns)) return [];
    return (r?.payout_txns || []).filter((hash) => is_valid_txhash(hash));
  }, [jstr(r?.payout_txns)]);

  const kickmap = useMemo(() => {
    if (_.isEmpty(r.kicks)) return {};
    let refundmap = _.chain(r.transactions)
      .filter((tx) => tx.type == "race_refund")
      .keyBy("hid")
      .value();
    let k = _.chain(r.kicks)
      .keyBy("hid")
      .entries()
      .map(([k, v]) => {
        let refunded = !nils(refundmap[k]?.id);
        v.refunded = refunded;
        // if (refunded) return null;
        return [k, v];
      })
      .compact()
      .fromPairs()
      .value();
    // console.log("kickmap", k, refundmap);
    return k;
  }, [jstr(r.kicks), jstr(r.transactions)]);

  return (
    !_.isEmpty(r.transactions) && (
      <>
        {show_txns ? (
          <Card className="w-full resp-p-1 overflow-auto">
            {!_.isEmpty(valid_payout_txns) && (
              <div class="fr-sc">
                <div class="flex-1"></div>
                <p className="resp-text-1">Tried Payout Txns:</p>
                {valid_payout_txns.map((hash) => {
                  return (
                    <div className="fr-sc">
                      <Tag
                        redirect={polytxnlink(hash)}
                        className="xs:w-[1rem] lg:w-[3rem]"
                      >
                        <Img img={polychainimg} />
                      </Tag>
                    </div>
                  );
                })}
              </div>
            )}

            {!_.isEmpty(r.transactions) ? (
              <>
                <table className={twMerge(tablecn.table, "w-full")}>
                  <tbody>
                    {r.transactions.map((tx) => {
                      const tdcn = twMerge(tablecn.td);

                      let date = nils(tx.date)
                        ? tx.pending == true
                          ? "pending.."
                          : "--"
                        : moment().diff(tx.date, "seconds") < 5 * 60
                          ? moment(tx.date).fromNow()
                          : iso_format(tx.date, "DD-MMM YY, h:mm:ss a");
                      let hash = nils(tx.id) ? null : tx.id.split(":")[0];
                      let { vault, vault_name, hid, hname } = tx;

                      const td_amt = (
                        <div className="fr-sc resp-gap-1 resp-text--2">
                          <div className="flex-1"></div>
                          {!nils(tx.token) ? (
                            <>
                              <TokenIcon token={tx.token ?? "WETH"} size="xs" />
                              <span>
                                {dec(tx.amt, tokdecn(tx.token ?? "WETH"))}
                              </span>
                            </>
                          ) : (
                            <>
                              <TokenIcon token={"WETH"} size="xs" />
                              <span>{dec(tx.amt, tokdecn("WETH"))}</span>
                            </>
                          )}
                        </div>
                      );

                      const is_autofiller =
                        !nils(hash) && hash.startsWith("auto-");

                      const td_polyimg = (
                        <div className="fr-sc">
                          {tx.pending ? (
                            <Tag className="">
                              <div class="lg:w-[1rem] xs:w-[0.8rem] aspect-square ">
                                <FontAwesomeIcon
                                  className="resp-text-2 spin-anim"
                                  icon={faHourglass}
                                />
                              </div>
                            </Tag>
                          ) : nils(hash) ? null : is_autofiller ? (
                            <span className="resp-text--2 text-purple-300">
                              {hash}
                            </span>
                          ) : (
                            <Tag
                              redirect={polytxnlink(hash)}
                              className="xs:w-[1rem] lg:w-[3rem]"
                            >
                              <Img img={polychainimg} />
                            </Tag>
                          )}

                          {!nils(getv(tx, "pwin_txid")) && (
                            <>
                              <Tag
                                redirect={polytxnlink(getv(tx, "pwin_txid"))}
                                className="xs:w-[1rem] lg:w-[3rem]"
                              >
                                <Img img={prizebox_img} />
                              </Tag>
                            </>
                          )}
                        </div>
                      );

                      const td_names = (
                        <div className="fc-ss resp-gap-1 resp-text--2">
                          <div className="fr-sc resp-gap-2">
                            <span className="italic text-acc0">{hid}</span>
                            <span className="text-white">{hname}</span>
                          </div>
                          <span className="italic text-acc0">{vault_name}</span>
                        </div>
                      );

                      const kick = kickmap[hid];
                      const td_type = (
                        <div class="fc-ss">
                          <span
                            className={twMerge(
                              "resp-text--2 uppercase",
                              cn_txtype(tx.type),
                            )}
                          >
                            {tx.type}
                          </span>
                          {tx.type == "race_entry" && !_.isEmpty(kick) && (
                            <div
                              className={twMerge(
                                "text-red-400 font-bold resp-text--2 fr-sc resp-gap-1",
                                kick.refunded ? "text-green-400" : "opacity-0",
                              )}
                            >
                              <FontAwesomeIcon
                                icon={kick.refunded ? faCheck : faSpinner}
                              />
                              <span>
                                {`kick and refund ${dec(kick.amt, tokdecn(kick.token))} ${kick.token}`}
                              </span>
                            </div>
                          )}
                        </div>
                      );

                      const td_inout = (
                        <Tag className="text-center resp-text--2">
                          {(["race_payout", "race_refund"].includes(
                            tx.type,
                          ) && (
                            <span className={twMerge("text-green-400")}>
                              IN
                            </span>
                          )) |
                            (["race_entry"].includes(tx.type) && (
                              <span className={twMerge("text-red-400")}>
                                OUT
                              </span>
                            )) || <span></span>}
                        </Tag>
                      );
                      const td_date = (
                        <span className="resp-text--2">{date}</span>
                      );

                      return (
                        <React.Fragment>
                          <tr
                            className={
                              "xs:hidden lg:table-row border-b border-acc0"
                            }
                          >
                            <td className={tdcn}>{td_polyimg}</td>
                            <td className={tdcn}>{td_names}</td>
                            <td className={tdcn}>{td_type}</td>
                            <td className={tdcn}>{td_inout}</td>
                            <td className={tdcn}>{td_amt}</td>
                            <td className={tdcn}>{td_date}</td>
                          </tr>

                          <tr
                            className={
                              "xs:table-row lg:hidden border-b border-acc0"
                            }
                          >
                            {/* <td className={tdcn}></td> */}
                            <td className={tdcn}>
                              <div className="fc-cs">
                                <div className="fr-ss">
                                  {td_polyimg}
                                  {td_names}
                                </div>
                                {td_date}
                              </div>
                            </td>
                            <td className={tdcn}>
                              <div className="fc-ss">
                                <div className="fr-cc">
                                  {td_type}
                                  {td_inout}
                                </div>
                                {td_amt}
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <p className="text-right text-yellow-400">
                  No Transactions Yet
                </p>
              </>
            )}

            {/*           {!_.isEmpty(r.kicks) && (
              <>
                <div class="fc-ss w-full">
                  {r.kicks.map((row) => {
                    let h = hsob[row.hid];
                    return (
                      <div class="fr-sc resp-gap-2">
                        {row.mode == "chain" && (
                          <p>
                            Will Kick #{row.hid} and refund{" "}
                            {dec(row.amt, tokdecn(row.token))} {row.token}
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
              </>
            )} */}
          </Card>
        ) : (
          <div className="fr-cc">
            <div className="flex-1"></div>
            <Tag
              onClick={() => set_show_txns(true)}
              className="fr-cc resp-gap-2 text-acc0"
            >
              <span className="italic">Transactions</span>
              <FontAwesomeIcon icon={faChevronDown} />
            </Tag>
          </div>
        )}
      </>
    )
  );
};

const RacePageInner = () => {
  const rcon = useRaceContext();
  const { r, rtstatus, watching2d, racerun, qo_racerun, is_subround, hsob } =
    rcon;

  const { hids: vhids } = useAccountContext();

  return (
    <Card className={twMerge("w-full")}>
      {is_subround && <SubRoundHead />}
      <ErrorBoundary>
        <RaceBaseInfoCard {...{ race: r, rtstatus }} />
      </ErrorBoundary>

      {watching2d === true ? (
        <>
          {qo_racerun.isLoading && <Loader01c size="s" />}
          {<RacePage_Run {...{ vhids, showrun: qissuccesss(qo_racerun) }} />}
        </>
      ) : (
        <>
          {["open", "scheduled"].includes(rtstatus) && (
            <RaceCardInner
              {...{
                race: r,
                active: true,
                def_enter: false,
                rtstatus,
                show_baseinfo: false,
              }}
            />
          )}

          {rtstatus == "finished" && <FinishedCard />}
        </>
      )}
    </Card>
  );
};

const SubRoundHead = () => {
  const appcon = useAppContext();
  const { tok_to_usd_val, usd_to_tok_val } = appcon;
  const rcon = useRaceContext();
  const { race: r, ethusd } = rcon;
  const [qobaserace] = useQueries([q_race({ rid: r.baserid })]);
  const baserace = useMemo(() => {
    let d = getv(qobaserace, "data.result");
    return d;
  }, [qobaserace.dataUpdatedAt]);
  return (
    <>
      <div className="fc-sc h-0 relative w-full">
        <div className="flex-1"></div>
        <div className="absolute top-[0rem] right-0 bg-reg z-[10] xs:h-[5rem] lg:h-[8em] min-w-[40%]">
          <Link to={`/race/${r.baserid}`} target="_self">
            <Tag
              className={twMerge(
                "font-digi italic text-acc0 border border-acc0",
              )}
            >
              {"Rounds Summary"}
            </Tag>
          </Link>
          <Tag className="text-green-500 font-thin">
            PrizePool of
            <span className="text-green-500 font-digi resp-text-1">
              <FontAwesomeIcon icon={faUsd} />{" "}
              <span>
                {dec(tok_to_usd_val(baserace?.prize, baserace?.paytoken), 2)}
              </span>
            </span>
          </Tag>
        </div>
      </div>
    </>
  );
};

const RacesNav = ({ rid }) => {
  const aucon = useAuthContext();
  const { auth, vault } = aucon;
  const [qo_vaultraces] = useQueries([
    q_vaultraces(
      { auth, vault },
      {
        enabled: !nils(vault) && auth === true,
      },
    ),
  ]);
  const [qo_relrs] = useQueries([q_races_rid_rel_rs({ rid })]);
  const vauraces = useMemo(() => {
    return getv(qo_vaultraces, "data.result", []);
  }, [qo_vaultraces.dataUpdatedAt]);
  const relrs = useMemo(() => {
    return getv(qo_relrs, "data.result");
  }, [qo_relrs.dataUpdatedAt]);

  const [showmyraces, set_showmyraces] = useState(false);
  const [v_i, v_prev, v_next, v_centlink] = useMemo(() => {
    if (nils(vauraces)) return [null, null];
    let i = _.findIndex(vauraces, (v) => v.rid == rid);
    // console.log(rid, i);
    let prev = vauraces[i - 1];
    let next = vauraces[i + 1];
    // console.log("vault_row", [prev, next]);
    let lk = `/races?showmyraces=true`;
    return [i, prev, next, lk];
  }, [jstr(vauraces), rid]);

  const [showsat, set_showsat] = useState(false);
  const [s_i, s_sat, s_prev, s_next, s_centlink] = useMemo(() => {
    if (nils(relrs?.satellite)) return [null, null];
    let satrs = getv(relrs, "satellite");
    let sati = _.findIndex(satrs.rs, (v) => v.rid == rid);
    let prev = satrs.rs[sati - 1];
    let next = satrs.rs[sati + 1];
    let lk = `/satellitesv2/${getv(satrs, "satid")}`;
    let resp = [sati, satrs, prev, next, lk];
    console.log(resp);
    return resp;
  }, [jstr(relrs), rid]);

  const [showrou, set_showrou] = useState(false);
  const [r_i, r_rou, r_prev, r_next, r_centlink] = useMemo(() => {
    if (nils(relrs?.rounds)) return [null, null];
    let o = getv(relrs, "rounds");
    let ri = _.findIndex(o.rs, (v) => v.rid == rid);
    let prev = o.rs[ri - 1];
    let next = o.rs[ri + 1];
    let lk = `/race/${getv(o, "baserid")}`;
    let resp = [ri, o, prev, next, lk];
    // console.log(resp);
    return resp;
  }, [jstr(relrs), rid]);

  const linkcn = "fr-sc resp-gap-1 text-white";
  const rinfo_jsx = (r) => {
    return (
      <div class="fc-ss">
        <SplitRaceNameView
          className="resp-text--1"
          {...{ n: 12, format: r.format, race_name: r.race_name }}
        />
        <div className="fr-sc resp-gap-1">
          <span
            className={twMerge(
              "capitalize text-slate-300",
              // r.status == "open"
              //   ? "text-blue-400"
              //   : r.status == "finished"
              //     ? "text-green-400"
              //     : r.status == "scheduled"
              //       ? "text-yellow-400"
              //       : null,
            )}
          >
            {r.status}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="w-[60rem] max-w-[98vw] resp-p-2 mx-auto resp-text--2 font-digi">
      {!nils(v_i) && v_i > -1 && (
        <div className="my-1">
          <div class="grid grid-cols-8">
            <div class="col-span-3">
              {v_prev && (
                <Link
                  to={`/race/${v_prev.rid}`}
                  className={twMerge("mr-auto w-max", linkcn)}
                >
                  <FontAwesomeIcon icon={faArrowLeftLong} />
                  {rinfo_jsx(v_prev)}
                </Link>
              )}
            </div>
            <div class="col-span-2 resp-text--1">
              <div
                onClick={() => {
                  set_showmyraces(!showmyraces);
                }}
                className="fr-cc cursor-pointer"
              >
                <span>Vault</span>
                <div class="fr-sc">
                  <span>[{v_i + 1}</span>
                  <span>/</span>
                  <span>{vauraces?.length}]</span>
                </div>
              </div>
            </div>
            <div class="col-span-3">
              {v_next && (
                <Link
                  to={`/race/${v_next.rid}`}
                  className={twMerge("ml-auto w-max", linkcn)}
                >
                  {rinfo_jsx(v_next)}
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </Link>
              )}
            </div>
          </div>

          <PopUp
            wrapcn={"top-[3rem]"}
            innercn={"translate-y-[0%]"}
            overlayclose={true}
            openstate={showmyraces}
          >
            <div class="max-w-[95vw] resp-p-2 w-[60rem] rounded-md border border-acc4 resp-p-2 rounded-md backdrop-blur-md bg-r2dark/50 lg:h-[50vh] xs:h-[70vh] overflow-auto">
              <VaultRacesWindow
                {...{
                  qo_vaultraces,
                  onRaceClick: () => {
                    set_showmyraces(false);
                  },
                }}
              />
            </div>
          </PopUp>
        </div>
      )}

      {!nils(s_i) && !nils(relrs?.satellite) && (
        <div className="my-1">
          <div class="grid grid-cols-8">
            <div class="col-span-3">
              {s_prev && (
                <Link
                  to={`/race/${s_prev.rid}`}
                  className={twMerge("mr-auto w-max", linkcn)}
                >
                  <FontAwesomeIcon icon={faArrowLeftLong} />
                  {rinfo_jsx(s_prev)}
                </Link>
              )}
            </div>
            <div class="col-span-2 resp-text--1">
              <div
                onClick={() => {
                  set_showsat(!showsat);

                  // window.open(`/satellitesv2/${getv(s_sat, "satid")}`, "_self");
                }}
                className="fc-cc cursor-pointer"
              >
                <span>Satellite:</span>
                <span>{getv(s_sat, "satellite_name")}</span>
                <div class="fr-sc">
                  <span>[{s_i + 1}</span>
                  <span>/</span>
                  <span>{s_sat?.rs?.length}]</span>
                </div>
              </div>
            </div>
            <div class="col-span-3">
              {s_next && (
                <Link
                  to={`/race/${s_next.rid}`}
                  className={twMerge("ml-auto w-max", linkcn)}
                >
                  {rinfo_jsx(s_next)}
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </Link>
              )}
            </div>
          </div>

          <PopUp
            wrapcn={"top-[3rem]"}
            innercn="translate-y-[0%]"
            overlayclose={true}
            openstate={showsat}
          >
            <div class="max-w-[95vw] resp-p-2 w-[60rem] rounded-md border border-acc4 resp-p-2 rounded-md backdrop-blur-md bg-r2dark/50 lg:h-[50vh] xs:h-[70vh] overflow-auto">
              <SatelliteV2PageInner
                {...{
                  satid: getv(s_sat, "satid"),
                  onRaceClick: () => {
                    set_showsat(false);
                  },
                }}
              />
            </div>
          </PopUp>
        </div>
      )}

      {!nils(r_i) && !nils(relrs?.rounds) && (
        <div className="my-1">
          <div class="grid grid-cols-8">
            <div class="col-span-3">
              {r_prev && (
                <Link
                  to={`/race/${r_prev.rid}`}
                  className={twMerge("mr-auto w-max", linkcn)}
                >
                  <FontAwesomeIcon icon={faArrowLeftLong} />
                  {rinfo_jsx(r_prev)}
                </Link>
              )}
            </div>
            <div class="col-span-2 resp-text--1">
              <Link
                to={r_centlink}
                // onClick={() => {
                // set_showrou(!showrou);
                // window.open(`/race/${getv(r_rou, "baserid")}`, "_self");
                // }}
                className="fc-cc"
              >
                <span>Rounds </span>
                <span> Summary:</span>
                <div class="fr-sc">
                  <span>[{r_i + 1}</span>
                  <span>/</span>
                  <span>{r_rou?.rs?.length}]</span>
                </div>
              </Link>
            </div>
            <div class="col-span-3">
              {r_next && (
                <Link
                  to={`/race/${r_next.rid}`}
                  className={twMerge("ml-auto w-max", linkcn)}
                >
                  {rinfo_jsx(r_next)}
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const RaceCard_FullV = ({
  rid,
  def_race = {},
  show_race_nav = true,
}) => {
  const appcon = useAppContext();
  const { tok_to_usd_val, usd_to_tok_val } = appcon;

  const { now } = useNowContext();
  const { psearch, upd_psearch } = useAppContext();

  const is_arcade = psearch.is_arcade == "true";
  // console.log({ is_arcade });

  const [qoraceext, set_qoraceext] = useState({ enabled: true });

  const [qo_tokpri] = useQueries([q_token_prices()]);
  const [qorace] = useQueries([
    is_arcade ? q_arcade_race({ rid }) : q_race({ rid }, qoraceext),
  ]);
  const [watching2d, set_watching2d] = useState(
    ["true", true].includes(psearch.watching2d),
  );
  const [watching2d_st, set_watching2d_st] = useState(null);

  useEffect(() => {
    // upd_psearch({ watching2d });
  }, [watching2d]);

  const ethusd = useMemo(() => {
    const tok = getv(qo_tokpri, "data.result") || {};
    let ethusd = tok.ethusd ?? 0;
    return ethusd;
  }, [qo_tokpri]);

  const race = useMemo(() => {
    let r = getv(qorace, "data.result");
    if (nils(r)) return def_race;
    r.fee_usd = tok_to_usd_val(r.fee, r.paytoken);
    r.prize_usd = tok_to_usd_val(r.prize, r.paytoken);
    if (nils(r.paytoken)) r.paytoken = "WETH";
    return r;
  }, [qorace.dataUpdatedAt, ethusd]);
  const r = race;

  const [rtstatus, rem_st, rem_ed] = useMemo(() => {
    let e = get_race_rtstatus(r, now);
    return e;
  }, [now, jstr(r)]);

  const is_roundraces = useMemo(() => {
    if (nils(race)) return null;
    if (
      ["sit_n_go", "rounds", "bounty", "quest"].includes(race.format) &&
      ["live", "scheduled", "finished"].includes(race.status)
    )
      return true;
    return false;
  }, [jstr(race)]);

  useEffect(() => {
    // console.log({ rtstatus, is_roundraces });
    if (nils(r)) return;
    if (is_roundraces !== false) return;
    if (rtstatus == "live") {
      set_watching2d(true);
      set_watching2d_st(nano(r.start_time));
    }
  }, [rtstatus, is_roundraces]);

  const c = r?.class;
  const cb = r?.cb;

  const qs_hs_enabled = !nils(c) && !nils(cb) && rtstatus !== "open";
  const qs_hs = useStepQuery({
    q_: is_arcade ? q_arcade_race_hstats : q_hstats,
    par_ar: (r?.hids || []).map((hid) => [
      { rid, hid, cb, class: c, rvmode: r?.rvmode },
      { enabled: qs_hs_enabled },
    ]),
    lim: 3,
  });
  const hsob = useMemo(() => {
    const hsob = {};
    if (!qs_hs_enabled) return {};
    for (let i = 0; i < r?.hids.length; i++) {
      let q = qs_hs.qs[i];
      let hid = r.hids[i];
      let d = getv(q, "data.result");
      if (nils(d)) continue;

      if (race.is_team == true && r.teamsmap) {
        let team = _.find(r?.teamsmap || [], (t) => t.hids.includes(hid));
        if (nils(team)) continue;
        d.team = team;
        // d.color = team.k == "A" ? "rvage-red" : "race-blue";
        d.bg_card = team.k == "A" ? "bg-blue-600/40" : "bg-red-600/40";
        d.dot_hex = team.k == "A" ? "0000FF" : "FF0000";
      }

      hsob[hid] = d;
    }
    // console.log("hsob", hsob);
    return hsob;
  }, [jstr(_.map(qs_hs.qs, "dataUpdatedAt")), jstr(r)]);

  const [qo_racerun] = useQueries([
    is_arcade
      ? q_arcade_racerun({ rid })
      : q_racerun(
          { rid },
          {
            enabled:
              rtstatus == "live" ||
              // rtstatus == "finished" ||
              (["live", "finished"].includes(rtstatus) && watching2d == true),
          },
        ),
  ]);
  const racerun = useMemo(() => {
    let d = getv(qo_racerun, "data.result");
    if (nils(d)) return null;
    d = base64_to_json(d);
    return d;
  }, [qo_racerun.dataUpdatedAt]);

  useEffect(() => {
    if (nils(r)) return;
    if (qissuccesss(qo_racerun)) {
      if (nils(watching2d_st) && now > nano(r.start_time)) {
        set_watching2d_st(nano(moment().add(5, "seconds").toISOString()));
      }
    }
    if (watching2d == false) {
      set_watching2d_st(null);
    }
  }, [watching2d, qo_racerun.dataUpdatedAt]);

  const is_subround = useMemo(() => {
    if (nils(race)) return false;
    if (["sit_n_go_subround", "sub_rounds", "sub_bounty"].includes(race.format))
      return true;
    return false;
  }, [jstr(race)]);

  useEffect(() => {
    if (nils(r)) return;
    if (nils(r.start_time) || nils(now)) return;
    if (rtstatus == "finished") return;
    let diff = moment(r.start_time).diff(moment(now), "seconds");

    if (diff <= 8 && qiserr(qo_racerun)) {
      qorace.refetch();
      qo_racerun.refetch();
    }
  }, [rtstatus, jstr(r?.start_time), now, qo_racerun.dataUpdatedAt]);

  const rcon = {
    rid,

    qorace,

    race,
    rtstatus,
    r,
    hsob,

    ethusd,

    watching2d,
    set_watching2d,
    watching2d_st,
    set_watching2d_st,

    qo_racerun,
    racerun,
    is_subround,
  };

  const pagetitle = useMemo(() => {
    if (nils(r)) return `Loading Race | FBike DNA`;
    let rn = r?.race_name;
    return `${rn} Race | FBike DNA`;
  }, [r?.race_name]);

  const [is_maiden, sat_redlink] = useMemo(() => {
    if (r?.is_satellite !== true) return [null, null];
    let is_maiden = getv(r, "satellite.is_maiden") === true;
    if (is_maiden) return [is_maiden, `/maiden/${getv(r, "satellite.satid")}`];
    else return [is_maiden, `/satellitesv2/${getv(r, "satellite.satid")}`];
  }, [jstr(r?.satellite)]);

  return (
    <RaceContext.Provider value={rcon}>
      <div className="">
        <div className="">
          <div className="h-[1rem]"></div>
          {is_arcade && (
            <div className="fr-sc my-2">
              <Tag
                redirect="/arcaderace"
                className="border border-acc0 text-acc0 font-mon resp-text--2 -skew-x-12 fr-sc resp-gap-1"
              >
                <FontAwesomeIcon icon={faChevronLeft} />
                <span>Create new Arcade Race</span>
              </Tag>
            </div>
          )}
          <>
            {show_race_nav && <RacesNav {...{ rid }} />}
            {qorace.isLoading ? (
              <Loader01c />
            ) : (
              <>
                {qiserr(qorace) && (
                  <div className="fc-cc resp-gap-1 resp-my-4">
                    <p className="text-center text-red-400 resp-text-1 ">
                      Couldn't get race
                    </p>
                    <p className="text-center text-red-400 resp-text-0 ">
                      ERROR!! {qiserr(qorace)}
                    </p>
                  </div>
                )}
                {qissuccesss(qorace) && (
                  <div className="w-full mx-auto">
                    {is_roundraces ? (
                      <ErrorBoundary>
                        <RoundRaces context={RaceContext} />
                      </ErrorBoundary>
                    ) : (
                      <ErrorBoundary>
                        <RacePageInner />
                      </ErrorBoundary>
                    )}

                    <ErrorBoundary>
                      <RaceTransactions />
                    </ErrorBoundary>
                    <div className="h-[4rem]"></div>
                  </div>
                )}
              </>
            )}
          </>
        </div>
      </div>
    </RaceContext.Provider>
  );
};

const RaceReportSection = () => {
  const { now } = useNowContext();
  const rcon = useRaceContext();
  const { qorace, race, rtstatus } = rcon;
  const r = race;

  const [haspays, postprocessed, payouts_n, format] = useMemo(() => {
    if (nils(race)) return [null];
    let format = getv(race, "format");
    let haspays = race.is_chain || !_.isEmpty(race.prizemap);
    let post = race.postprocessed === true;
    let payouts_n = (getv(race, "transactions") || []).filter(
      (tx) => tx.type == "race_payout",
    ).length;
    return [haspays, post, payouts_n, format];
  }, [jstr(race)]);

  const [supported, supported_err] = useMemo(() => {
    let [supported, supported_err] = [false, ""];
    if (["normal", "reduced", "spin_n_go"].includes(r.format)) {
      // throw new Error(`rid:${r.rid} format:${r.format} not supported`);
      supported = true;
    } else if (
      ["sub_rounds", "sub_roundsp", "sub_bounty", "sub_quest"].includes(
        r.format,
      )
    ) {
      supported = false;
      supported_err = `rid:${r.rid} format:${r.format} not supported`;
    } else if (["rounds", "roundsp", "bounty"].includes(r.format)) {
      let curr = getv(r, "format_inf.round_curr");
      let rounds_n = getv(r, "format_inf.rounds_n");
      if (curr == rounds_n) supported = true;
      else supported_err = `rid:${r.rid} format:${r.format} not final round`;
    } else if (["quest"].includes(r.format)) {
      let uhid = getv(r, "r_form.u_hid");
      let user_cashout_option = getv(r, "user_cashout_option");
      let isprogressive = (r?.eventtags || []).includes("Progressive");
      if (isprogressive) {
        let rou = getv(r, "r_form.rounds_n");
        let hidsout =
          getv(r, `format_inf.rounds.r${rou}.hids.A.hids_out`) || [];
        console.log("hidsout", uhid, hidsout);
        let wonlast = hidsout.includes(uhid);
        if (!wonlast) supported = false;
        if (user_cashout_option == "cashout") supported = true;
        else
          supported_err = `rid:${r.rid} format:${r.format} cashout:${user_cashout_option} not supported`;
      } else {
        let rou = 3;
        let hidsout =
          getv(r, `format_inf.roudns.r${rou}.hids.A.hids_out`) || [];
        let wonlast = hidsout.includes(uhid);
        if (!wonlast) supported = false;
        else supported = true;
      }
    }
    return [supported, supported_err];
  }, [jstr(race)]);

  const alwmin = 60;
  const [unlocked, diffmin, errmsg] = useMemo(() => {
    let diff = moment(now).diff(race.end_time, "minutes");
    // if (!supported) return [false, diff, supported_err];
    if (!supported) return [false, diff, ""];
    if (payouts_n > 0) return [false, null];
    if (!haspays) return [false, diff];
    if (diff > alwmin) return [true, diff];
    return [false, diff];
  }, [
    haspays,
    postprocessed,
    now,
    payouts_n,
    format,
    supported,
    supported_err,
  ]);
  const reports_n = getv(race, "payout_reported_n") || 0;

  const [resp, set_resp] = useState({});
  const send_report_payouts = async () => {
    try {
      set_resp({
        type: "loading",
        msg: "Reporting stuck payout on this race...",
      });
      let res = await q_races_payout_report({ rid: race.rid }).queryFn();
      if (res.err) throw new Error(res.err);

      await cdelay(2 * 1e3);
      await qorace.refetch();
      await cdelay(1 * 1e3);

      set_resp({
        type: "success",
        msg: "Reported stuck payout on this race!",
      });
    } catch (e) {
      set_resp({ type: "error", msg: e.message });
    }

    setTimeout(() => {
      set_resp({});
    }, 3 * 1e3);
  };

  // let rem = { supported, supported_err, unlocked, diffmin, errmsg, reports_n, }; useEffect(() => { console.log("report", rem); }, [jstr(rem)]);

  const c = {
    format,
    unlocked,
    diffmin,
    errmsg,
    reports_n,
  };
  // useEffect(() => {
  //   console.log("report", c);
  // }, [jstr(c)]);

  if (payouts_n > 0) return null;

  return (
    <>
      <div class="fr-sc resp-gap-1 w-full">
        {unlocked ? (
          <div class="fr-sc w-full resp-gap-2 resp-my-2 resp-text-1">
            <>
              {_.isEmpty(resp) ? (
                <Tag
                  onClick={() => {
                    send_report_payouts();
                  }}
                  className="text-acc4 "
                >
                  <span className="font-thin font-digi resp-text-1">
                    Its been {diffmin} minutes since finished!! Report Race
                    Payouts Stuck??
                  </span>
                </Tag>
              ) : (
                <div
                  class={twMerge(
                    "fr-sc resp-gap-2",
                    resp.type == "loading"
                      ? "text-acc4"
                      : resp.type == "info"
                        ? "text-acc4"
                        : resp.type == "success"
                          ? "text-green-400"
                          : resp.type == "error"
                            ? "text-red-400"
                            : "text-acc4",
                  )}
                >
                  {resp.type == "loading" && <Loader01c size="s" />}
                  {resp.type == "success" && (
                    <FontAwesomeIcon icon={faCheckCircle} />
                  )}
                  {resp.type == "error" && (
                    <FontAwesomeIcon icon={faTimesCircle} />
                  )}
                  <span className="font-thin font-digi resp-text-1">
                    {resp.msg}
                  </span>
                </div>
              )}
            </>
          </div>
        ) : (
          <>
            {!nils(errmsg) && <p class="resp-text-1 text-red-400">{errmsg}</p>}
          </>
        )}

        {reports_n > 0 && (
          <div className="fr-sc flex-nowrap resp-gap-1 font-digi text-yellow-400 resp-text-1">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <p className="font-thin font-digi resp-text-1 w-max">
              {reports_n} Reports
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default function RacePage() {
  const appcon = useAppContext();
  const location = useLocation();
  const { tok_to_usd_val, usd_to_tok_val } = appcon;

  const { rid } = useParams();
  const { now } = useNowContext();
  const { psearch, upd_psearch } = useAppContext();

  const is_arcade = psearch.is_arcade == "true";
  // console.log({ is_arcade });

  const [qoraceext, set_qoraceext] = useState({ enabled: true });

  const [qo_tokpri] = useQueries([q_token_prices()]);
  const [qorace] = useQueries([
    is_arcade
      ? q_arcade_race({ rid })
      : q_race(
          { rid },
          {
            enabled: true,
            staleTime: 20 * 1e3,
            refetchInterval: 20 * 1e3,
          },
        ),
  ]);
  const [watching2d, set_watching2d] = useState(
    ["true", true].includes(psearch.watching2d),
  );
  const [watching2d_st, set_watching2d_st] = useState(null);

  useEffect(() => {
    upd_psearch({ watching2d });
  }, [watching2d]);

  const ethusd = useMemo(() => {
    const tok = getv(qo_tokpri, "data.result") || {};
    let ethusd = tok.ethusd ?? 0;
    return ethusd;
  }, [qo_tokpri]);

  const race = useMemo(() => {
    let r = getv(qorace, "data.result");
    if (nils(r)) return null;
    r.fee_usd = tok_to_usd_val(r.fee, r.paytoken);
    r.prize_usd = tok_to_usd_val(r.prize, r.paytoken);
    if (nils(r.paytoken)) r.paytoken = "WETH";
    return r;
  }, [qorace.dataUpdatedAt, ethusd]);
  const r = race;

  const [rtstatus, rem_st, rem_ed] = useMemo(() => {
    let e = get_race_rtstatus(r, now);
    return e;
  }, [now, jstr(r)]);
  // console.log("rtstatus", rtstatus);

  const render_roundraces = useMemo(() => {
    if (nils(race)) return null;
    if (
      ["sit_n_go", "rounds", "roundsp", "bounty", "quest"].includes(
        race.format,
      ) &&
      ["live", "scheduled", "finished"].includes(rtstatus)
    )
      return true;
    return false;
  }, [jstr(race), rtstatus]);

  useEffect(() => {
    // console.log({ rtstatus, is_roundraces });
    if (nils(r)) return;
    if (render_roundraces !== false) return;
    if (rtstatus == "live") {
      set_watching2d(true);
      set_watching2d_st(nano(r.start_time));
    }
  }, [rtstatus, render_roundraces]);

  const c = r?.class;
  const cb = r?.cb;

  const qs_hs_enabled = !nils(c) && !nils(cb) && rtstatus !== "open";
  const qs_hs = useStepQuery({
    q_: is_arcade ? q_arcade_race_hstats : q_hstats,
    par_ar: (r?.hids || []).map((hid) => [
      { rid, hid, cb, class: c, rvmode: r?.rvmode },
      { enabled: qs_hs_enabled },
    ]),
    lim: 3,
  });
  const hsob = useMemo(() => {
    const hsob = {};
    if (!qs_hs_enabled) return {};
    for (let i = 0; i < r?.hids.length; i++) {
      let q = qs_hs.qs[i];
      let hid = r.hids[i];
      let d = getv(q, "data.result");
      if (nils(d)) continue;

      if (race.is_team == true && r.teamsmap) {
        let team = _.find(r?.teamsmap || [], (t) => t.hids.includes(hid));
        if (nils(team)) continue;
        d.team = team;
        // d.color = team.k == "A" ? "rvage-red" : "race-blue";
        d.bg_card = team.k == "A" ? "bg-blue-600/40" : "bg-red-600/40";
        d.dot_hex = team.k == "A" ? "0000FF" : "FF0000";
      }

      hsob[hid] = d;
    }
    // console.log("hsob", hsob);
    return hsob;
  }, [jstr(_.map(qs_hs.qs, "dataUpdatedAt")), jstr(r)]);

  const [qo_racerun] = useQueries([
    is_arcade
      ? q_arcade_racerun({ rid })
      : q_racerun(
          { rid },
          {
            enabled:
              rtstatus == "live" ||
              // rtstatus == "finished" ||
              (["live", "finished"].includes(rtstatus) && watching2d == true),
          },
        ),
  ]);
  const racerun = useMemo(() => {
    let d = getv(qo_racerun, "data.result");
    if (nils(d)) return null;
    d = base64_to_json(d);
    return d;
  }, [qo_racerun.dataUpdatedAt]);

  useEffect(() => {
    if (nils(r)) return;
    if (qissuccesss(qo_racerun)) {
      if (nils(watching2d_st) && now > nano(r.start_time)) {
        set_watching2d_st(nano(moment().add(5, "seconds").toISOString()));
      }
    }
    if (watching2d == false) {
      set_watching2d_st(null);
    }
  }, [watching2d, qo_racerun.dataUpdatedAt]);

  const is_subround = useMemo(() => {
    if (nils(race)) return false;
    if (["sit_n_go_subround", "sub_rounds", "sub_bounty"].includes(race.format))
      return true;
    return false;
  }, [jstr(race)]);

  useEffect(() => {
    if (nils(r)) return;
    if (nils(r.start_time) || nils(now)) return;
    if (rtstatus == "finished") return;
    let diff = moment(r.start_time).diff(moment(now), "seconds");

    if (diff <= 8 && qiserr(qo_racerun)) {
      qorace.refetch();
      qo_racerun.refetch();
    }
  }, [rtstatus, jstr(r?.start_time), now, qo_racerun.dataUpdatedAt]);

  const rcon = {
    rid,

    qorace,

    race,
    rtstatus,
    r,
    hsob,

    ethusd,

    watching2d,
    set_watching2d,
    watching2d_st,
    set_watching2d_st,

    qo_racerun,
    racerun,
    is_subround,
  };

  const pagetitle = useMemo(() => {
    if (nils(r)) return `Loading Race | FBike DNA`;
    let rn = r?.race_name;
    return `${rn} Race | FBike DNA`;
  }, [r?.race_name]);

  const ocon = useOverlayContext();
  useEffect(() => {
    if (_.isEmpty(r)) return;
    return;
    const fn = async () => {
      let basesel = `.large-screen.race-card-full.race-card-full-${r.rid}`;
      let base = document.querySelector(`${basesel}`);
      await ocon.highlight_element(base, [
        "bottom",
        "This is the race card section",
      ]);

      await cdelay(2 * 1e3);
      ocon.highlight_element(base.querySelector(".ov-class"), [
        "top",
        "Class of the race",
      ]);
      await cdelay(2 * 1e3);
      ocon.highlight_element(base.querySelector(".ov-cb"), [
        "top",
        `Cores will run ${r.cb * 100} meters`,
      ]);
      await cdelay(2 * 1e3);
      ocon.highlight_element(base.querySelector(".ov-redirectlink"), [
        "top",
        "open this race in a new tab",
      ]);
      await cdelay(2 * 1e3);
      ocon.highlight_element(base.querySelector(".ov-copylink"), [
        "top",
        "copy the race link to clipboard",
      ]);
      await cdelay(2 * 1e3);
      ocon.highlight_element(base.querySelector(".ov-format"), [
        "bottom-left",
        "Race Format tells you the race structure",
        { maxWidth: "20rem" },
      ]);

      await cdelay(2 * 1e3);
      ocon.highlight_element(base.querySelector(".ov-events"), [
        "bottom-left",
        "Event Tags - shows you special criteria or event information",
        { maxWidth: "20rem" },
      ]);

      await cdelay(2 * 1e3);
      ocon.highlight_element(base.querySelector(".ov-payout"), [
        "bottom-left",
        "Race Payout tells you the prize structure",
        { maxWidth: "20rem" },
      ]);

      await cdelay(2 * 1e3);
      ocon.highlight_element(base.querySelector(".ov-fee"), [
        "bottom-left",
        "Race entry fee in USD and tokeninfo",
        { maxWidth: "20rem" },
      ]);
      await cdelay(2 * 1e3);
      ocon.highlight_element(base.querySelector(".ov-prize"), [
        "bottom-left",
        "Race prize pool [hover to show breakdown]",
        { maxWidth: "20rem" },
      ]);
      await cdelay(2 * 1e3);
      upd_psearch({ showprizepool: true });
      await cdelay(1 * 1e3);
      ocon.highlight_element(base.querySelector(".ov-prizebreakdown"), [
        "top-left",
        "Prizepool breakdown",
        { maxWidth: "20rem" },
      ]);
      await cdelay(3 * 1e3);
      upd_psearch({ showprizepool: false });

      ocon.highlight_element(base.querySelector(".ov-rvmode"), [
        "top-left",
        `Core is racing as ${_.capitalize(r.rvmode)}`,
        { maxWidth: "20rem" },
      ]);
      await cdelay(2 * 1e3);

      ocon.highlight_element(base.querySelector(".ov-gates"), [
        "top-left",
        `Race runs when all ${r.rgate} entrants are here`,
        { maxWidth: "20rem" },
      ]);
      await cdelay(2 * 1e3);
    };
    setTimeout(fn, 1 * 1e3);
  }, [_.isEmpty(r)]);

  return (
    <RaceContext.Provider value={rcon}>
      <Helmet>
        <title>{pagetitle}</title>
      </Helmet>
      <div className="h-page">
        <div className="max-w-[98vw] w-[68rem] mx-auto">
          <div className="">
            <div className="">
              <div className="h-[1rem]"></div>

              {is_arcade && (
                <div className="fr-sc my-2">
                  <Tag
                    redirect="/arcaderace"
                    className="border border-acc0 text-acc0 font-mon resp-text--2 -skew-x-12 fr-sc resp-gap-1"
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                    <span>Create new Arcade Race</span>
                  </Tag>
                </div>
              )}
              <>
                <ErrorBoundary>
                  {!is_arcade && <RacesNav {...{ rid }} />}
                </ErrorBoundary>
                {qorace.isLoading ? (
                  <Loader01c />
                ) : (
                  <ErrorBoundary>
                    {qiserr(qorace) && (
                      <div className="fc-cc resp-gap-1 resp-my-4">
                        <p className="text-center text-red-400 resp-text-1 ">
                          Couldn't get race
                        </p>
                        <p className="text-center text-red-400 resp-text-0 ">
                          ERROR!! {qiserr(qorace)}
                        </p>
                      </div>
                    )}
                    {qissuccesss(qorace) && (
                      <div className="w-full mx-auto">
                        {render_roundraces ? (
                          <>
                            <ErrorBoundary>
                              {r.format == "quest" ? (
                                <QuestRaces />
                              ) : (
                                <RoundRaces context={RaceContext} />
                              )}
                            </ErrorBoundary>
                          </>
                        ) : (
                          <>
                            {["sub_quest"].includes(r.format) && (
                              <div class="fr-sc my-2">
                                <Link to={`/race/${r.baserid}`}>
                                  <Tag className="text-acc4 -skew-x-12 border border-acc4 bg-r2lig/20">
                                    <div class="fr-sc resp-gap-2 resp-text-0">
                                      <FontAwesomeIcon icon={faArrowLeft} />
                                      <span class="">
                                        {r.format == "sub_quest"
                                          ? "Back to Quest Summary"
                                          : null}
                                      </span>
                                    </div>
                                  </Tag>
                                </Link>
                              </div>
                            )}
                            {race.version == "cv2" ? (
                              <>
                                {/*                                 <p class="resp-text-2 font-digi text-yellow-400 text-center my-2">
                                  CV2 version [BETA]
                                </p> */}
                                <RaceCard_cv2_full {...{ race }} />
                              </>
                            ) : (
                              <RaceCardR2_full {...{ race }} />
                            )}
                          </>
                        )}

                        <ErrorBoundary>
                          <RaceReportSection />
                        </ErrorBoundary>

                        <ErrorBoundary>
                          <RaceTransactions />
                        </ErrorBoundary>
                        <div className="h-[4rem]"></div>
                      </div>
                    )}
                  </ErrorBoundary>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </RaceContext.Provider>
  );
}
