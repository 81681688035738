import {
  useState,
  useMemo,
  useEffect,
  useContext,
  createContext,
  useRef,
} from "react";
import {
  from_time_mini,
  getv,
  iso_format,
  jstr,
  nils,
  trim2,
  trim_n,
} from "../utils/utils";
import { CopyBtn, PageWrapper } from "../components/ShortComps";
import { useQueries } from "react-query";
import {
  q_vault_refs_info,
  q_vault_refs_update_reftxt,
  q_vaultinfo,
} from "../queries/queries";
import { useAppContext, useNowContext } from "../App";
import { useAuthContext } from "../wrappers/AuthWrapper";
import _ from "lodash";
import { Card, ContentEditableText } from "../components/utilityComps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faInfoCircle,
  faSpinner,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { twMerge } from "tailwind-merge";
import { tablecn } from "../utils/cn_map";

export const ReferralsContext = createContext({});
export const useReferralsContext = () => useContext(ReferralsContext);

export const ReferralsPage = () => {
  const appcon = useAppContext();
  const { now } = useNowContext();
  const aucon = useAuthContext();

  const { psearch, upd_psearch } = appcon;

  const vault = useMemo(() => {
    let v = psearch.v ?? psearch.vault;
    if (!nils(v)) return v.toLowerCase();
    return aucon.vault;
  }, [psearch?.v, psearch?.vault, aucon.vault]);

  const [qovdoc, qovref] = useQueries([
    q_vaultinfo({ vault }, { enabled: !nils(vault) }),
    q_vault_refs_info({ vault }, { enabled: !nils(vault) }),
  ]);
  const vdoc = useMemo(() => {
    return getv(qovdoc, "data.result", {});
  }, [qovdoc.dataUpdatedAt]);

  const [reftxt, set_reftxt] = useState("");
  const [resprefupd, set_resprefupd] = useState(null);

  const redoc = useMemo(() => {
    let d = getv(qovref, "data.result", {});
    if (!nils(d.reftxt) && nils(reftxt)) set_reftxt(d.reftxt);
    return d;
  }, [qovref.dataUpdatedAt]);

  const update_ref = async () => {
    try {
      if (nils(reftxt)) return;
      if (!nils(reftxt) && reftxt === redoc?.reftxt) return;

      if (reftxt.length < 3)
        throw new Error("Referal text too short [min 3 characters required]");
      if (reftxt.length > 15)
        throw new Error("Referal text too long [max 15 characters allowed]");

      let resp = await q_vault_refs_update_reftxt({
        vault,
        reftxt,
      }).queryFn();
      let done = getv(resp, "result.done") === 1;
      if (done) {
        set_resprefupd({ type: "success", msg: getv(resp, "result.msg") });
        setTimeout(() => {
          qovref.refetch();
          set_reftxt(null);
        }, 3 * 1e3);
      } else {
        let err = getv(resp, "err");
        throw new Error(err);
      }

      // set_resprefupd({ type: "success", msg: "Updated Referal" });

      return true;
    } catch (e) {
      let errmsg = e.message;
      errmsg = trim_n(errmsg, 100);
      set_resprefupd({
        type: "error",
        msg: errmsg,
      });
    }
  };
  useEffect(() => {
    console.log("update:reftxt", reftxt);
    if (nils(reftxt)) set_resprefupd({});
    else update_ref(reftxt);
  }, [reftxt]);

  const [_cn_icon, _icon, _cn_text] = useMemo(() => {
    if (nils(resprefupd)) return ["", ""];
    const { type } = resprefupd;

    let tcn = "resp-text-1";
    let icn = "resp-text-2";
    let a = ["", "", ""];

    if (type == "info") a = ["text-blue-400", faInfoCircle, "text-blue-400"];
    else if (type == "success")
      a = ["text-green-400", faCheckCircle, "text-green-400"];
    else if (type == "error")
      a = ["text-red-400", faTimesCircle, "text-red-400"];
    else if (type == "action")
      a = ["text-orange-400", faInfoCircle, "text-orange-400"];
    else if (type == "loading")
      a = ["spin-anim text-blue-400", faSpinner, "text-blue-400"];

    a[0] = twMerge(icn, a[0]);
    a[2] = twMerge(tcn, a[2]);
    return a;
  }, [jstr(resprefupd)]);

  const reflink = useMemo(() => {
    let r = getv(redoc, "reftxt");
    if (nils(r)) return "";
    let base = window.location.origin;
    return `${base}/claim-trainer?ref=${r}`;
    // return `https://fbike.dnaracing.run/claim-trainer?ref=${r}`;
  }, [getv(redoc, "reftxt")]);
  const reflink_copyref = useRef(null);

  const refcon = {
    vault,
  };

  return (
    <>
      <ReferralsContext.Provider value={refcon}>
        <PageWrapper page_title_fn={() => `Referrals`}>
          <div class="fc-cc font-digi resp-text-1 resp-gap-1">
            <p className="resp-text-2">
              <span className="text-acc4">{vdoc?.vault_name}</span>
              <span>{" - "}</span>
              <span className="text-white">Referrals</span>
            </p>
            <p className="resp-text--2">{vault}</p>
          </div>
          <div class="my-2">
            <hr />
          </div>

          <div class="my-2">
            <div class="resp-p-1 bg-acc4/10 rounded-md w-max  min-w-[50%] w-max mx-auto ">
              <div class="rounded-md border-dashed border-acc4 border-4 resp-p-4 font-digi w-full font-digi resp-text-2 text-center">
                <ContentEditableText
                  def_val={redoc?.reftxt}
                  setter={(val) => {
                    val = val.trim();
                    set_reftxt(val);
                  }}
                  inpprops={{
                    className: "text-center w-full",
                  }}
                />
              </div>
            </div>
            {!_.isEmpty(resprefupd) ? (
              <div class="fr-cc resp-gap-2">
                {_icon && <FontAwesomeIcon className={_cn_icon} icon={_icon} />}
                <span className={_cn_text}>{getv(resprefupd, "msg")}</span>
              </div>
            ) : null}

            {!nils(reflink) && (
              <div
                onClick={() => {
                  let btn = reflink_copyref.current;
                  console.log("btn", btn);
                  if (btn) btn.click();
                }}
                class="fr-cc resp-gap-2 my-2 text-acc4 cursor-pointer select-none"
              >
                <CopyBtn ref={reflink_copyref} txt={reflink} />
                <p class="resp-text-1 font-digi">{reflink}</p>
              </div>
            )}
          </div>

          {!nils(redoc?.reftxt) || !_.isEmpty(redoc.joined_refs) ? (
            <>
              <div class="w-full">
                <hr />
                <div class="my-1"></div>
                {_.isEmpty(redoc.joined_refs) ? (
                  <>
                    <p class="font-digi resp-text-1 text-center w-[80%] mx-auto">
                      0 Joined Your Referrals
                    </p>
                    <p class="font-digi resp-text-0 text-justify w-[80%] mx-auto">
                      ** Your list starts populating when new users join using
                      your ref link
                    </p>
                  </>
                ) : (
                  <Card
                    className={twMerge(
                      "w-full overflow-auto resp-p-4",
                      "border border-acc4 bg-acc4/10 backdrop-blur-md",
                    )}
                  >
                    <table
                      className={twMerge(
                        tablecn.table,
                        "w-full thintdrowp4-table-r2lig resp-text-0",
                      )}
                    >
                      <thead>
                        <tr className="font-digi text-acc4">
                          <td>Vault</td>
                          <td>RefTxt</td>
                          <td>#Cores</td>
                          <td>#Splices</td>
                          <td>Joined At</td>
                        </tr>
                      </thead>
                      <tbody>
                        {redoc.joined_refs.map((r, i) => {
                          return (
                            <tr className="font-mon">
                              <td>
                                <div class="fc-cs">
                                  <p className="resp-text-1 font-digi xs:pl-2 lg:pl-4 text-acc4">
                                    {r.vault_name}
                                  </p>
                                  <CopyBtn
                                    txt={r.vault}
                                    className="resp-text--1"
                                    right_jsx={
                                      <span>{trim2(r.vault, 6, 6)}</span>
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                <span>{r.ref_by_txt}</span>
                              </td>
                              <td>
                                <p className="resp-text-1 font-digi">
                                  <span>{`${getv(r, "cores.cores_n", 0)} `}</span>
                                  {getv(r, "cores.trainer_n", 0) > 0 && (
                                    <span className="text-slate-400">
                                      {`+ ${getv(r, "cores.trainer_n", 0)}T `}
                                    </span>
                                  )}
                                </p>
                              </td>
                              <td>
                                <p className="resp-text-1 font-digi">
                                  <span>{`${getv(r, "splices.splices_n", 0)} `}</span>
                                </p>
                              </td>
                              <td>
                                <span>{from_time_mini(r.joined_at)}</span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Card>
                )}
              </div>
            </>
          ) : null}
        </PageWrapper>
      </ReferralsContext.Provider>
    </>
  );
};
