import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useQueries } from "react-query";
import {
  polytxnlink,
  q_jackpot_get,
  q_jackpot_history,
  q_jackpot_pot,
  qiserr,
  qissuccesss,
} from "../queries/queries.js";
import { dec, getv, iso_format, nils, trim2 } from "../utils/utils.js";
import { Link } from "react-router-dom";
import { Card, Img, Tag } from "../components/utilityComps.js";
import { polychainimg, wethimg } from "../utils/links.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEquals, faPlus, faUsd } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { tablecn } from "../utils/cn_map.js";
import { twMerge } from "tailwind-merge";
import { Loader01c } from "../components/anims.js";
import { useAppContext } from "../App.js";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import { ErrorBoundary } from "../utils/errbou.js";

const jtypes = ["minorpot", "majorpot", "maxpot"];

export const JackpotContext = createContext();
export const useJackpotContext = () => useContext(JackpotContext);

const versiontxt = {
  1: "v1",
  2: "v2",
  3: "v3",
  cv2: "CV2",
};

const Jackpot = () => {
  const appcon = useAppContext();
  const { tok_ethusd } = useAccountContext();
  const { psearch, upd_psearch } = appcon;

  const [jtype, set_jtype] = useState(psearch?.jtype ?? "minorpot");
  const [jid, setjid] = useState(psearch.jid ?? null);
  const [qolive, qohistory] = useQueries([
    q_jackpot_get({ jtype, jackpotid: jid }, {}),
    q_jackpot_history({ jtype }, {}),
  ]);
  const j = useMemo(() => {
    if (qissuccesss(qolive)) return getv(qolive, "data.result");
    else return null;
  }, [qolive.dataUpdatedAt]);
  const [history, his_vns] = useMemo(() => {
    if (qissuccesss(qohistory)) {
      let o = getv(qohistory, "data.result");
      let history = o.history;
      let his_vns = o.vault_names;
      return [history, his_vns];
    } else return [[], {}];
  }, [qohistory.dataUpdatedAt]);

  useEffect(() => {
    upd_psearch({ jtype, jid });
  }, [jtype, jid]);

  const jcon = {};

  const Collected = (
    <>
      <div className="fr-cc resp-gap-2 resp-my-2">
        <div className="fr-cc resp-gap-1 resp-px-4 resp-text-1">
          <Img className={"w-[2rem]"} img={wethimg} />
          <span className="font-digi resp-text-2">{dec(j?.cummpot, 4)}</span>
        </div>
        <div className="fr-cc resp-gap-1 resp-px-4 resp-text-1">
          <FontAwesomeIcon icon={faUsd} fontSize={"1.5rem"} />
          <span className="font-digi resp-text-2">
            {dec(j?.cummpot * tok_ethusd, 2)}
          </span>
        </div>
      </div>
    </>
  );

  return (
    <JackpotContext.Provider value={jcon}>
      <div className="page-wrapper">
        <div className="h-[2rem]"></div>
        <div className="xs:w-[98vw] lg:w-[60rem] w-max mx-auto">
          <div className="fr-cc">
            {jtypes.map((k) => {
              return (
                <Tag
                  onClick={() => set_jtype(k)}
                  className={twMerge(
                    "font-digi resp-text-2",
                    "rounded-[0]",
                    k == jtype
                      ? "text-acc0 italic shadow-sm shadow-acc0/40"
                      : "text-white",
                    "px-4",
                  )}
                >
                  {_.capitalize(k)}
                </Tag>
              );
            })}
          </div>
          <p className="text-center font-digi resp-my-2 resp-text-3">JackPot</p>
          {(qolive.isLoading || qohistory.isLoading) && <Loader01c />}
          {qissuccesss(qolive) && (
            <ErrorBoundary>
              <Card className={"w-full resp-text--1"}>
                {/* <span>Current Jackpot</span> */}
                {Collected}
                <div className="fr-sc resp-gap-2 resp-my-2 w-full">
                  <span>Status:</span>
                  {j.status == "ended" ? (
                    <span className="text-red-400 italic font-digi resp-text-0">
                      {_.capitalize(j.status)}
                    </span>
                  ) : (
                    <span className="text-acc0 italic font-digi resp-text-0">
                      {_.capitalize(j.status)}
                    </span>
                  )}

                  <div className="flex-1"></div>
                  <div className="fc-ss">
                    <span>Must Trigger Before</span>
                    <p className="font-digi resp-text-1 text-center w-full">
                      {jtype == "minorpot" ? (
                        <>{dec("0.03", 2)}</>
                      ) : jtype == "majorpot" ? (
                        <>{dec("7", 2)}</>
                      ) : jtype == "maxpot" ? (
                        <>{dec(j.maxcap, 2)}</>
                      ) : null}
                    </p>
                  </div>
                </div>
                <div className="fr-ss resp-gap-2 resp-my-2">
                  <div className="fc-ss">
                    <div className="fr-ccresp-gap-2">
                      <span>Started At</span>
                      <span>{iso_format(j.start_time)}</span>
                    </div>
                    {!nils(j.end_time) && (
                      <div className="fr-ccresp-gap-2">
                        <span>Ended At</span>
                        <span>{iso_format(j.end_time)}</span>
                      </div>
                    )}
                  </div>
                </div>
                {!_.isEmpty(j.winner_pay) && (
                  <div class="fr-sc resp-gap-2 resp-text-1">
                    <div class="flex-1"></div>
                    <Link
                      target={"_blank"}
                      to={polytxnlink(j.winner_pay?.hash)}
                    >
                      <div class="aspect-square xs:w-[1rem] lg:w-[2rem]">
                        <Img img={polychainimg} />
                      </div>
                    </Link>
                    <span className="text-acc4">
                      {getv(j, `vault_names.${j?.winner_pay.vault}`)}
                    </span>
                    <span>won</span>
                    <span className="text-acc4 font-digi">
                      ${dec(j?.winner_pay?.amt_usd, 2)}
                    </span>
                  </div>
                )}
                <div class="fr-sc"></div>
              </Card>
            </ErrorBoundary>
          )}

          {qissuccesss(qohistory) && history.length > 0 && (
            <Card className={"w-full"}>
              <div className="fr-cc">
                <p className="text-center resp-text-1 text-acc0 font-digi">
                  JackPot History
                </p>
                <div className="flex-1"></div>

                {jid !== null && (
                  <Tag
                    onClick={() => setjid(null)}
                    className="bg-yellow-400 text-black"
                  >
                    View LIVE
                  </Tag>
                )}
              </div>
              {/* <Card className={"w-full overflow-auto"}> */}
              <>
                <table className={twMerge(tablecn.table)}>
                  <thead>
                    <td className="font-digi text-slate-400">JackPot</td>
                    <td className="font-digi text-slate-400">Type</td>
                    <td className="font-digi text-slate-400">Starts</td>
                    <td className="font-digi text-slate-400">Winner</td>
                    <td className="font-digi text-slate-400">WinAmt</td>
                    <td className="font-digi text-slate-400"></td>
                  </thead>
                  <ErrorBoundary>
                    <tbody>
                      {history.map((h) => {
                        let v = getv(h, "winner.vault")?.toLowerCase();
                        let hash = getv(h, "winner.hash")?.toLowerCase();
                        return (
                          <tr className={tablecn.tr}>
                            <td className={twMerge(tablecn.td)}>
                              {h.jackpotid}
                            </td>
                            <td className={twMerge(tablecn.td)}>{h.jtype}</td>
                            <td className={twMerge(tablecn.td)}>
                              {iso_format(h.start_time)}
                            </td>
                            <td className={twMerge(tablecn.td)}>
                              <div className="fc-ss resp-gap-1">
                                <span className="resp-text-0 italic">
                                  {his_vns[v]}
                                </span>
                                <span className="text-[10px] text-acc0">
                                  {v}
                                </span>
                                {!nils(hash) && (
                                  <Link target="_blank" to={polytxnlink(hash)}>
                                    <div className="fr-cc">
                                      <Img img={polychainimg} />
                                      <span>{hash.slice(0, 5)}...</span>
                                    </div>
                                  </Link>
                                )}
                              </div>
                            </td>

                            <td
                              className={twMerge(
                                tablecn.td,
                                "text-right text-acc0 resp-text-1",
                              )}
                            >
                              {h.cummpot}
                            </td>

                            <td
                              className={twMerge(
                                tablecn.td,
                                "text-right text-acc0 resp-text-1",
                              )}
                            >
                              <Tag
                                onClick={() => setjid(h.jackpotid)}
                                className="bg-acc0 text-black"
                              >
                                View
                              </Tag>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </ErrorBoundary>
                </table>
              </>
            </Card>
          )}

          <div className="h-[2rem]"></div>

          {qolive.isLoading == false && qiserr(qolive) && (
            <p className="text-red-400 text-[1.2rem] text-center">
              {qiserr(qolive)}
            </p>
          )}

          {qissuccesss(qolive) && !_.isEmpty(j.entries) && (
            <ErrorBoundary>
              <div className="fr-cc resp-text-0">
                <p>
                  {jid == null || j?.status !== "done" ? "LIVE" : "OLD"} Jackpot
                  Entries
                </p>
                <div className="flex-1"></div>
                <p>Last {j.entries.length}</p>
              </div>
              <hr />
              <Card className={"w-full overflow-auto"}>
                <table className={twMerge(tablecn.table, "resp-text--1")}>
                  <thead>
                    <td></td>
                    <td></td>
                    <td className="font-digi text-slate-400">Vault</td>
                    <td className="font-digi text-slate-400">Date</td>
                    <td className="font-digi text-slate-400">Contribution</td>
                  </thead>
                  <tbody>
                    {j.entries.map((e, i) => {
                      let v = e.vault.toLowerCase();
                      let hash = e.id && e.id.substring(0, 66);
                      return (
                        <tr
                          className={twMerge(
                            j.done == true && j.entries.length - 1 == i
                              ? "bg-lig border-2 border-acc0"
                              : "",
                          )}
                        >
                          <td className={tablecn.td}>
                            <Link target="_blank" to={`/race/${e.rid}`}>
                              <div class="fc-cc resp-p-1 border border-acc4 text-acc4 rounded-md">
                                <span>{versiontxt[e.version]}</span>
                                <span>{`${trim2(e?.rid, 12, 4)}-#${e.hid}`}</span>
                              </div>
                            </Link>
                          </td>
                          <td className={tablecn.td}>
                            <Link target="_blank" to={polytxnlink(hash)}>
                              <Img
                                className={"xs:w-[1rem] lg:w-[1.5rem] mx-auto"}
                                img={polychainimg}
                              />
                            </Link>
                          </td>
                          <td className={tablecn.td}>
                            <div className="fc-ss resp-gap-1">
                              <span className="resp-text-0 italic resp-text--1">
                                {j.vault_names[v]}
                              </span>
                              <span className="text-[10px] text-acc0 resp-text--2">
                                {v}
                              </span>
                            </div>
                          </td>
                          <td className={tablecn.td}>
                            <span className="resp-text--1">
                              {iso_format(e.date)}
                            </span>
                          </td>
                          <td className={tablecn.td}>
                            <div className="flex flex-col justify-end items-end text-[1.2rem] resp-text-1">
                              <div className="flex flex-rowresp-gap-2 justify-end items-center w-full">
                                <FontAwesomeIcon
                                  className="resp-text--1"
                                  icon={faPlus}
                                />
                                <Img className={"w-[2rem]"} img={wethimg} />
                                <span className="text-white font-mono font-bold">
                                  {dec(e.eacontri, 9)}
                                </span>
                              </div>
                              <div className="flex flex-rowresp-gap-2 justify-end items-center w-full text-green-400 resp-text-1">
                                <FontAwesomeIcon
                                  className="resp-text--1"
                                  icon={faEquals}
                                />
                                <Img className={"w-[2rem]"} img={wethimg} />
                                <span className="font-mono font-bold">
                                  {dec(e.cumm_contri, 9)}
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Card>
            </ErrorBoundary>
          )}
        </div>

        <div className="h-[4rem]"></div>
      </div>
    </JackpotContext.Provider>
  );
};
export default Jackpot;

export const JackpotNav = () => {
  const { tok_ethusd } = useAccountContext() || {};
  const [qo] = useQueries([q_jackpot_pot({}, {})]);
  const j = useMemo(() => {
    if (qissuccesss(qo)) return getv(qo, "data.result");
    else return null;
  }, [qo.dataUpdatedAt]);
  if (nils(j)) return <></>;
  return (
    <Link to="/jackpot">
      <div className="fc-cc resp-gap-1">
        <p className="text-acc0 text-center">Current JackPot Balance</p>
        <div className="fr-cc resp-gap-2 flex-wrap">
          <div className="fc-cc resp-gap-1 flex-wrap">
            <span>MinorPot</span>
            {nils(j?.minorpot[0]) ? (
              <span className="text-acc0">---</span>
            ) : (
              <span className="text-acc0">
                ${dec(j?.minorpot[0] * tok_ethusd, 2)}
              </span>
            )}
          </div>
          <div className="w-[2rem]"></div>
          <div className="fc-cc resp-gap-1">
            <span>MajorPot</span>
            {nils(j?.majorpot[0]) ? (
              <span className="text-acc0">---</span>
            ) : (
              <span className="text-acc0">
                ${dec(j?.majorpot[0] * tok_ethusd, 2)}
              </span>
            )}
          </div>
          <div className="w-[2rem]"></div>
          <div className="fc-cc resp-gap-1">
            <span>MaxPot</span>
            {nils(j?.maxpot[0]) ? (
              <span className="text-acc0">---</span>
            ) : (
              <span className="text-acc0">
                ${dec(j?.maxpot[0] * tok_ethusd, 2)}
              </span>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};
