import {
  faArrowDown,
  faArrowRight,
  faArrowRightLong,
  faArrowsUpDown,
  faArrowUp,
  faCheck,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCopy,
  faFire,
  faFlagCheckered,
  faInfoCircle,
  faLink,
  faSignInAlt,
  faSpinner,
  faSquare,
  faTimesCircle,
  faTriangleExclamation,
  faTrophy,
  faUndoAlt,
  faUsd,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import { tokdecn, tokdecn2, useAppContext, useNowContext } from "../App";
import {
  CapsuleSwitch,
  F_OptionBtn_R2_rounded,
  F_OptionInput_Raw,
  InpOptionsWrap_Raw,
  set_state_ob,
} from "../components/input";
import {
  ToolTip,
  Card,
  Dropdown,
  Img,
  InpText,
  Tag,
} from "../components/utilityComps";
import {
  base64_to_json,
  cdelay,
  copy_clip,
  dec,
  disp_dec,
  emp,
  from_time_mini,
  getv,
  iso,
  iso_format,
  jparse,
  json_to_base64,
  jstr,
  nano,
  nils,
  toeth,
  tofeth,
  trim2,
  trim_n,
} from "./utils";
import { BY_Star, get_race_rtstatus, PosTag, RoundsInfo } from "./raceutils.js";
import { TokenIcon } from "../components/utilityComps.js";
import {
  btbk,
  qiserr,
  qissuccess,
  qissuccesss,
  q_arcade_open_hs_in,
  q_arcade_racerun,
  q_arcade_race_hstats,
  q_arcade_race_result,
  q_hstats,
  q_logtxnqueue,
  q_open_enter,
  q_open_hs_in,
  q_open_vault_valid_hs,
  q_race,
  q_racerun,
  q_race_result,
  useStepQuery,
  q_roadmap_entry_tickets,
  q_chain_create_race,
  q_chain_racedoc,
  q_open_hvalid,
  polytxnidlink,
  q_que_txn,
  q_bike_quick_listings,
} from "../queries/queries";
import { useAccountContext } from "../wrappers/AccountWrapper";
import moment from "moment/moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  cb_cn,
  cb_cn_hex,
  cb_txt,
  class_text,
  elementmap,
  gendermap,
  get_payout_cn,
  get_payout_txt,
  get_cprofile_hex,
} from "./cn_map";
import { Loader01c } from "../components/anims";
import {
  au_wait_for_tx,
  mm_asset_signer,
  que_con_fn_runner,
  t3_asset_signer,
  t3_contract_call,
} from "../contracts/contract_funcs";
import { PopUp, PopupCloseBtn } from "../components/popup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BikeCircle, RacePage_Run } from "../views/RacePage_Run";
import { useQueries } from "react-query";
import { useAuthContext } from "../wrappers/AuthWrapper";
import { ethers } from "ethers";
import { useRef } from "react";
import { contractAddress_list } from "../contracts/constants";
import {
  BikeImg,
  BikeSCircle,
  CarImg,
  HorseImg,
  RVImg,
} from "../components/BikeImg";
import { gen_filters_from_valob } from "./filt";
import { ErrorBoundary } from "./errbou";
import { fpost } from "../queries/fetch";
import { faction_img } from "./links";
import {
  EventTag,
  RaceBottomActionBtn,
  RaceBottomActionBtnSm,
  RaceCardR2_baseinfo,
  RaceR2_FinishedSection,
  RaceR2_Watching2D_Section,
  SplitRaceNameView,
  get_race3d_link,
  SheetRowChainIndicator,
  RaceR2_HRow,
  get_racestake_contractAddress,
  get_racestake_contract,
  RectBtn_SMLong,
} from "./raceutils2";
import { QuestCardImg, RacePage_QuestCard } from "../views/QuestCards";
import { RacePage_TrailsCard, TrailsCardImg } from "../views/TrailsCards.js";
import { useThirdWebLoginContext } from "../views/ThirdWebLogin.js";
import {
  tokenaumode_locpath,
  useMetaMaskContext,
} from "../wrappers/MetaMaskWrapper.js";
import { get_roadmap_status } from "../views/Roadmap.js";
import { RaceStake } from "../contracts/v2/DNA_RaceStake.js";
import { CopyBtn } from "../components/ShortComps.js";
import { polygon } from "thirdweb/chains";
import { PayEmbed } from "thirdweb/react";
import { LogBox } from "../contracts/txns_logbox_helper.js";
import { DNA_PayReceiver } from "../contracts/v2/DNA_PayReceiver.js";

const RaceR2_EnterPopup_SelectCore = ({ race, select_core }) => {
  const r = race;
  const history = useNavigate();

  const acc = useAccountContext();
  const {
    vault,
    hids: vhids,
    roadmap_post_enter_race,
    qostaminamap,
    bikesob,
    acc_config = {},
    s_acc_config,
    g_acc_config,
    bikeslist_hids_show_fn,
    all_bikes_loaded,
  } = acc;
  const t3con = useThirdWebLoginContext();
  const { active_account } = t3con;

  const rid = race.rid;
  const cb = race.cb;
  const c = race.class;
  const payout = race.payout;

  const [mode, set_mode] = useState(getv(acc_config, "last_mode"));
  const [bikelist_hids, hordmap] = useMemo(() => {
    let hs = bikeslist_hids_show_fn({
      hids: vhids,
      mode,
      // mode: "filtered",
      c: getv(race, "class"),
      cb: getv(race, "cb"),
      rvmode: getv(race, "rvmode"),
    });
    hs = _.filter(hs, (h) => {
      if (h.stamina <= 0) return false;
      if (h.ageing <= 0) return false;
      return true;
    });
    hs = _.map(hs, "hid");
    // console.log("bikelist_hids:fin", hs);
    let ordmap = {};
    for (let i = 0; i < hs.length; i++) {
      ordmap[hs[i]] = i;
    }
    return [hs, ordmap];
  }, [mode, jstr(vhids), jstr(race)]);

  const uses_spstamina = race.uses_spstamina ?? false;
  const uses_stamina = uses_spstamina !== true && race.class != 90;

  const fn_get_loc_valids = async () => {
    // console.log("fn_get_loc_valids:fn");
    let hids = bikelist_hids;
    let staminamap = getv(qostaminamap, "data.result", {});
    // console.log("st", { uses_stamina, uses_spstamina, });
    if (uses_stamina || uses_spstamina) {
      if (!qostaminamap.isLoading) {
        let diff = moment().diff(moment(qostaminamap.dataUpdatedAt), "seconds");
        if (diff > 30) qostaminamap.refetch();
      }
      if (qissuccesss(qostaminamap)) {
        if (_.isEmpty(staminamap)) return [];

        let k = uses_spstamina ? 1 : 0;
        hids = bikelist_hids.filter((hid) => {
          let s = getv(staminamap, `${hid}.${k}`);
          return s > 0;
        });
      }
    }
    if (_.isEmpty(hids)) return [];

    hids = hids.filter((hid) => {
      return !(race.hids || []).includes(hid);
    });
    if (_.isEmpty(hids)) return [];

    hids = _.filter(hids, (hid) => {
      let name = getv(bikesob, `${hid}.name`, null);
      if (nils(name) || name == "Unnamed DNA Splice") return false;
      return true;
    });
    if (_.isEmpty(hids)) return [];

    let ef = getv(race, "r_form.entry_filt");
    let eventtags = getv(race, "eventtags");
    if (!_.isEmpty(eventtags) && eventtags.includes("Trainer")) {
      if (emp(ef?.fno)) ef.fno = { mi: 22, mx: 1000 };
      // if (_.isEmpty(ef?.type)) ef.type = ["trainer"];
      // else ef.type.push("trainer");
    }

    let is_trial = !_.isEmpty(eventtags) && eventtags.includes("Trial");
    let is_free =
      race.class == 90 && parseFloat(getv(_.values(race.fee_fixed), "0")) === 0;
    // console.log("is_free", is_free);
    if (!is_free && !is_trial) {
      hids = hids.filter((hid) => {
        let is_trainer = getv(bikesob, `${hid}.type`, "") == "trainer";
        return !is_trainer;
      });
    }

    if (emp(ef)) return hids;

    if (!_.isEmpty(ef.fno)) {
      // console.log("ef_filt_fno", ef.fno);
      hids = hids.filter((hid) => {
        let h_fno = getv(bikesob, `${hid}.fno`, null);
        if (nils(h_fno)) return false;
        let mi = getv(ef, "fno.mi", 0);
        let mx = getv(ef, "fno.mx", 0) + 0.1;

        let mat = _.inRange(parseInt(h_fno) ?? null, mi, mx);
        return mat;
      });
    }
    for (let k of ["gender", "type", "element"]) {
      if (!_.isEmpty(ef[k])) {
        // console.log("ef_filt_", k, ef[k]);
        hids = hids.filter((hid) => {
          let h_element = getv(bikesob, `${hid}.${k}`, null);
          return ef[k].includes(h_element);
        });
      }
    }

    let is_me = ef.birthday == "me";
    if (is_me) {
      // console.log("ef_filt_birthday", ef.birthday);
      hids = hids.filter((hid) => {
        let is_maiden = getv(bikesob, `${hid}.is_maiden`, false);
        // console.log("ef_filt_birthday:is_maiden", hid, is_maiden);
        return is_maiden === true;
      });
    }

    let has_extra = r.class == 50;
    if (has_extra) {
      if (r.class == 50) {
        let listhids = await q_bike_quick_listings({ hids }).queryFn();
        hids = getv(listhids, "result", []);
        if (_.isEmpty(hids)) return [];
      }

      if (!_.isEmpty(hids)) {
        let qresp = await q_open_vault_valid_hs({ rid, hids: hids }).queryFn();
        //   hids = _.map(getv(qresp, "result", []), 0);
      }
    }
    // console.log("fn_get_loc_valids:end", hids);
    return hids;
  };
  const [valid_hids, set_valid_hids] = useState([]);
  const [valid_loading, set_valid_loading] = useState(false);

  useEffect(() => {
    if (!all_bikes_loaded) return;
    if (!qissuccesss(qostaminamap)) return;
    const fn = async () => {
      set_valid_loading(true);
      let resp_hids = await fn_get_loc_valids();
      set_valid_hids(resp_hids);
      set_valid_loading(false);
    };
    fn();
  }, [qostaminamap.dataUpdatedAt, all_bikes_loaded, jstr(bikelist_hids)]);

  const validhs = useMemo(() => {
    let hs = valid_hids.map((hid) => {
      let name = getv(bikesob, `${hid}.name`, `core#${hid}`);
      return [hid, name];
    });
    hs = hs.map((h, i) => ({ hid: h[0], name: h[1], idx: i }));
    hs = _.sortBy(hs, (h) => {
      return hordmap[h.hid];
    });
    return hs;
  }, [jstr(valid_hids), jstr(hordmap)]);

  const qvalidenter_err = null;

  const [searchtxt, set_searchtxt] = useState(null);
  const filths = useMemo(() => {
    if (nils(searchtxt)) return validhs;
    let sech_hid = parseInt(searchtxt);
    if (nils(sech_hid)) sech_hid = null;
    let sear = _.lowerCase(searchtxt);

    let filt = _.chain(validhs)
      .map((h) => {
        let hname = _.lowerCase(h.name);
        if (!nils(sech_hid) && sech_hid == h.hid) return [h, 1];
        else if (
          !nils(sech_hid) &&
          h.hid.toString().startsWith(sech_hid.toString())
        )
          return [h, 2];
        else if (hname.startsWith(sear)) return [h, 3];
        else if (hname.includes(sear)) return [h, 4];
        else return null;
      })
      .compact()
      .sortBy((e) => e[1])
      .map(0)
      .value();
    console.log("enter filt", filt);
    return filt;
  }, [jstr(validhs), searchtxt]);

  const rvmode = race?.rvmode;
  const entertxt = `Select ${_.capitalize(rvmode)} To Enter ${cb_txt(cb, rvmode)} ${_.upperCase(payout)}`;
  const get_cac_hstats = (hid) => {
    const { class: c, cb } = race;
    let h = bikesob[hid];
    if (!h) return null;
    let keys =
      "hid,name,vault,vault_name,element,gender,type,hex_code,fno".split(",");
    let h0 = {};
    for (let k of keys) {
      h0[k] = h[k];
    }
    h0.hstat = {
      ov: getv(h, `hstats_${rvmode}.${"career"}`),
      cb: getv(h, `hstats_${rvmode}.${cb}`),
    };
    return h0;
  };

  const [st_type, set_st_type] = useState("cb");
  const [st_paid, set_st_paid] = useState("paid");

  const shorted_hs = useMemo(() => {
    let path = `hstat.${st_type}.${st_paid == "paid" ? "paid_" : ""}win_p`;
    let ar = _.chain(filths)
      .map((h0) => {
        let hid = h0.hid;
        let h = get_cac_hstats(hid);
        if (!h) return null;
        return h;
      })
      .compact()
      .sortBy((h) => {
        h.sortval = getv(h, path, 0);
        return -h.sortval;
      })
      .value();
    // console.log("shorted_hs", ar);
    return ar;
  }, [jstr(filths), st_type, st_paid, jstr(bikesob)]);

  const mode_switcher = (
    <CapsuleSwitch
      {...{
        nf: mode,
        set_nf: set_mode,
        options: ["all", "filtered"],
        txt_fn: (a) => _.capitalize(a),
        tag_cn: "xs:w-[5rem] lg:w-[6.5rem] resp-py-1",
      }}
    />
  );
  const select_row_controls = (
    <>
      <div class="fr-sc w-full resp-gap-2 resp-text-1">
        {mode_switcher}
        <div class="flex-1"></div>
        <Tag
          className={
            "fr-sc resp-gap-1 text-acc0  underline-acc0 underline-offset-2"
          }
          onClick={() => set_st_type((a) => (a !== "cb" ? "cb" : "ov"))}
        >
          <FontAwesomeIcon icon={faArrowsUpDown} />
          <span>
            {st_type == "ov" ? "Overall" : st_type == "cb" ? `CB${cb}00` : ""}
          </span>
        </Tag>

        <Tag
          className={
            "fr-sc resp-gap-1 text-acc0 underline-acc0 underline-offset-2"
          }
          onClick={() => set_st_paid((a) => (a !== "paid" ? "paid" : "all"))}
        >
          <FontAwesomeIcon icon={faArrowsUpDown} />
          <span>{st_paid == "paid" ? "Paid" : "All"}</span>
        </Tag>
      </div>
    </>
  );

  return (
    <>
      {qostaminamap.isLoading || valid_loading ? (
        <>
          <div className="fc-cc">
            <p className="resp-my-2 resp-text--1 text-acc0">
              Finding Eligible {_.capitalize(rvmode)} in your Vault....
            </p>
            <div class="fr-sc">
              {mode_switcher}
              <div class="flex-1"></div>
            </div>
            <Loader01c />
          </div>
        </>
      ) : (
        <>
          {!nils(qvalidenter_err) ? (
            <>
              <div class="fr-sc">
                {mode_switcher}
                <div class="flex-1"></div>
              </div>
              <p className="text-center text-red-400">{qvalidenter_err}</p>
            </>
          ) : _.isEmpty(validhs) ? (
            <>
              <div class="fr-sc">
                {mode_switcher}
                <div class="flex-1"></div>
              </div>
              <p className="text-center text-red-400">
                No Eligible bikes for this race
              </p>
            </>
          ) : (
            <>
              <div className="fc-cc">
                <p className="text-acc0 italic  resp-text-1 resp-my-1">
                  {entertxt}
                </p>
              </div>
              {select_row_controls}

              <Card className={"resp-p-2 bg-r2dark/20 w-full"}>
                <div className="fr-cc resp-gap-1 resp-px-1 resp-my-1">
                  <div className="flex-1">
                    <InpText
                      {...{
                        id: `entry-search-race-${rid}`,
                        placeholder: "Search Core",
                        inpprops: {
                          className:
                            "resp-text-1 font-digi bg-tranparent text-acc0 w-full",
                        },
                        contprops: {
                          className:
                            "resp-text-1 font-digi bg-r2dark/20 text-acc0 w-full",
                        },
                        setter: (v) => {
                          console.log("setter", v);
                          if (nils(v)) v = null;
                          set_searchtxt(v);
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="bikes-enter-list w-full xs:h-[60vh] lg:h-[30vh] overflow-auto resp-px-1">
                  {shorted_hs.map((h, idx) => {
                    if (nils(h)) return;
                    let hid = h.hid;
                    let h0 = h;

                    const msg = g_acc_config(
                      `vaulthconfs.${race?.rvmode}.${hid}.msg`,
                    );
                    const hrow = (
                      <div className="relative">
                        <div class="absolute right-[40%] top-[25%]">
                          <p class="resp-text-1 font-digi">{msg}</p>
                        </div>
                        {!nils(h) ? (
                          <RaceR2_HRow
                            {...{
                              r: race,
                              h,
                              hid: h?.hid,
                              st_type,
                              st_paid,
                              show_entry_confirmation: false,
                              right_flex_jsx: <></>,
                              cont_props: {
                                onClick: (e) => {
                                  select_core(hid);
                                },
                                className:
                                  "bg-tranparent resp-text--2 cursor-pointer",
                                style: {
                                  borderLeft: undefined,
                                  borderRight: undefined,
                                },
                              },
                              arrow_link: true,
                            }}
                          />
                        ) : (
                          <div className="fr-sc w-full rep-gap-2 resp-p-2">
                            <Loader01c size="s" />
                            <div className="fc-ss w-full resp-gap-2">
                              <span className="font-digi resp-text--3">
                                {hid}
                              </span>
                              <span className="font-digi resp-text-1">
                                {h0?.name}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    );

                    const is_trainer = h?.type == "trainer";

                    return (
                      <div
                        key={hid}
                        className={twMerge(
                          "bike-row",
                          "bg-r2lig/20 border border-acc4 rounded-md resp-my-2",
                          is_trainer ? "trainer-0" : "",
                        )}
                      >
                        <div className="large-screen xs:hidden lg:block">
                          <div className="fr-cc">
                            <div className="flex-1">{hrow}</div>
                          </div>
                        </div>
                        <div className="small-screen xs:block lg:hidden">
                          <div className="fc-cc w-full">
                            <div className="w-full">{hrow}</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Card>
            </>
          )}
        </>
      )}
    </>
  );
};

const RaceR2_EnterPopup_SelectFeeToken = ({
  race,
  select_feetoken,
  popdata,
  set_popdata,
  go_next,
  go_prev,
}) => {
  const appcon = useAppContext();
  const { usd_to_tok_val } = appcon;

  const t3con = useThirdWebLoginContext();
  const aucon = useAuthContext();
  const { auth, vault, aumode } = aucon;

  const r = race;
  const entertxt = `Select Fee Token`;

  const [token, set_token] = useState(null);

  const [logbox, set_logbox] = useState({});
  const [fee, set_fee] = useState(null);
  const lb_ref = useRef(null);
  const cancelref = useRef(null);

  useEffect(() => {
    if (!nils(token)) return;
    let fs = getv(race, "feetokens", []);
    if (!_.isEmpty(fs) && fs.length === 1) {
      set_token(fs[0]);
    }
  }, [jstr(race?.feetokens)]);

  const [action, set_action] = useState(null);

  const skip_ahead = () => {
    set_popdata((d) => ({ ...d, sel_feetoken: token, stage: d.stage + 1 }));
  };

  const run_token_validate_fn = async () => {
    console.log("run_token_validate_fn", { token });
    if (nils(token)) return null;
    let tprec = tokdecn(token);
    let lb_params = {
      vault,
      token,
      aumode,
      logbox,
      set_logbox,
      appcon,
      t3con,
    };
    if (cancelref.current) return;

    let lb = new LogBox(lb_params, { update_log: true, wait: true });
    lb_ref.current = lb;
    await lb.init();

    let sendto = contractAddress_list["cv2_dnapayrouter"];
    let amt_tok = fee;
    let { done, err } = await lb.tok_bal_alw_apv([sendto, amt_tok]);
    if (!nils(err)) return;
    if (cancelref.current) return;

    await cdelay(0.5 * 1e3);
    if (cancelref.current) return;
    set_popdata((d) => ({ ...d, sel_feetoken: token, stage: d.stage + 1 }));
  };

  const pre_preloaded = auth === true && !nils(token) && !nils(fee);

  const start_fn = async () => {
    // set_logbox({});
    // lb_ref.current = null;
    cancelref.current = null;

    await cdelay(1 * 1e3);
    run_token_validate_fn();
  };
  const reset_fn = async () => {
    set_fee(null);
    set_token(null);

    set_logbox({});
    lb_ref.current = null;
    cancelref.current = true;
  };

  useEffect(() => {
    console.log("data", { fee, token, auth });
    if (!auth) return;
    if (nils(token) || nils(race?.fee_fixed)) return;
    let amt_tok = race.fee_fixed[token];
    set_fee(amt_tok);
    if (!nils(token)) {
      cancelref.current = false;
    }
  }, [token, auth, jstr(race?.fee_fixed)]);

  useEffect(() => {
    if (pre_preloaded !== true) return;
    console.log({ fee });
    if (fee === 0) {
      const fn = async () => {
        await cdelay(1 * 1e3);
        skip_ahead();
      };
      fn();
    } else if (!nils(fee)) {
      start_fn();
    }
  }, [pre_preloaded, fee]);

  const token_jsx = <span className="text-purple-400">{token}</span>;
  const [show_payembed, set_show_payembed] = useState(false);

  return (
    <>
      <div class="p-2 w-full">
        <div class="fr-sc">
          {nils(token) ? (
            <>
              <div class="my-2 font-digi italic resp-p-2">{entertxt}</div>
            </>
          ) : (
            <>
              <div class="my-2 font-digi italic resp-p-2 resp-text-1">{`Fee: ${dec(fee, tokdecn(token))} ${token}`}</div>
              <div class="flex-1"></div>
              <Tag
                onClick={() => {
                  reset_fn();
                }}
                className="fr-sc resp-gap-1 text-red-400 border border-red-400"
              >
                <FontAwesomeIcon icon={faUndoAlt} />
                <span className="font-digi resp-text-0">Reset</span>
              </Tag>
            </>
          )}
        </div>
        <div class="grid grid-cols-2 resp-gap-2 mx-auto max-w-[100%] w-[40rem]">
          {(r.feetokens || []).map((feetoken) => {
            let active = token == feetoken;
            return (
              <>
                <div
                  onClick={() => set_token(feetoken)}
                  class={twMerge(
                    "resp-p-2 rounded-md font-digi cursor-pointer transition duration-400",
                    "fr-cc resp-gap-2 border-2 border-acc4 bg-acc4/30 text-acc4",
                    active ? "text-r2dark bg-acc4" : "",
                  )}
                >
                  <div class="">
                    <TokenIcon {...{ token: feetoken, size: "lg" }} />
                  </div>
                  <span class="resp-text-1">{feetoken}</span>
                </div>
              </>
            );
          })}
        </div>

        {!nils(action) ? (
          <>
            {action == "insufficient_balance" && (
              <div class="fc-cc resp-gap-2 mt-2 rounded-md border border-red-400 resp-text-0 resp-p-1">
                {aumode == "thirdweb" ? (
                  <>
                    <p>Logged in using ThiredWeb</p>
                    <p>Transfer required {token_jsx} to your wallet</p>
                    <div class="fr-cc resp-gap-1">
                      <span className="font-mono">{trim2(vault, 6, 6)}</span>
                      <CopyBtn txt={vault} />
                    </div>
                    <p>--OR--</p>
                    <p
                      onClick={() => {
                        set_show_payembed((e) => !e);
                      }}
                      className="text-acc0 cursor-pointer"
                    >
                      Buy {token_jsx} with crypto/fiat
                    </p>
                    {show_payembed && (
                      <PayEmbed
                        client={t3con.thirdweb_client}
                        payOptions={{
                          mode: "fund_wallet",
                          prefillBuy: {
                            chain: polygon,
                            token: t3con.polytokens[token],
                            amount: dec(fee, tokdecn(token)),
                            allowEdits: {
                              amount: true,
                              token: false,
                              chain: false,
                            },
                          },
                        }}
                        supportedTokens={{ 137: [t3con.polytokens[token]] }}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <p>Logged in using MetaMask</p>
                    <p>Transfer some {token_jsx} to metamask wallet</p>
                    <div class="fr-cc resp-gap-1">
                      <span className="font-mono">{trim2(vault, 6, 6)}</span>
                      <CopyBtn txt={vault} />
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        ) : (
          <div class="fc-cc w-full">
            <div class="max-w-full w-[50rem] mx-auto resp-px-2 my-1 max-h-[30vh] overflow-auto">
              <div class="h-full">
                {!nils(lb_ref?.current) &&
                  getv(logbox, "steps", []).map((step) => {
                    return (
                      <div class="w-full font-digi my-2">
                        {lb_ref.current.render_step_row(step)}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div class="fr-sc my-1">
              <div class=""></div>
              {_.isEmpty(logbox) ? null : logbox?.status == "error" ? (
                <Tag
                  onClick={() => {
                    if (logbox.status == "loading") return;
                    start_fn();
                  }}
                  className={twMerge("bg-red-500 -skew-x-12")}
                >
                  <span className="resp-text-1 font-digi ">{"Retry"}</span>
                </Tag>
              ) : logbox?.status == "idle" ? (
                <Tag
                  onClick={() => {
                    if (logbox.status == "loading") return;
                    reset_fn();
                  }}
                  className={twMerge("bg-acc4/50 -skew-x-12")}
                >
                  <span className="resp-text-1 font-digi ">
                    {"Start a New Challenge"}
                  </span>
                </Tag>
              ) : logbox?.status == "loading" ? (
                <FontAwesomeIcon
                  className={twMerge("resp-text-2 text-acc4 spin-anim")}
                  icon={faSpinner}
                />
              ) : null}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const RaceR2_EnterPopup_EnterCore = ({
  race,
  popdata,
  set_popdata,
  closepopup,

  post_enterrace,
  qorace,
  qo_rginf,
}) => {
  const aucon = useAuthContext();
  const { aumode, vault } = aucon;

  const tcon = useThirdWebLoginContext();

  const [resp, set_resp] = useState({});

  const [rid, hid, token] = useMemo(() => {
    let rid = race.rid;
    let hid = getv(popdata, "sel_core");
    let token = getv(popdata, "sel_feetoken");
    return [rid, hid, token];
  }, [jstr(popdata)]);

  const cancelref = useRef(null);
  const enabled =
    !nils(hid) && !nils(rid) && !nils(token) && aucon.auth == true;

  const bigiconcn = "text-[6rem]";

  useEffect(() => {
    return () => {
      cancelref.current = true;
    };
  }, []);

  const enter_action = async () => {
    if (!enabled) return;
    try {
      if (cancelref.current) return;
      set_resp({ status: "loading", msg: "Checking Validity" });

      const rhvalid = await q_open_hvalid({ rid, hid }).queryFn();
      let done = getv(rhvalid, "result.done");
      if (done) {
        set_resp({ status: "success", msg: "Core is Valid" });
      } else {
        set_resp({ status: "error", msg: `Error:${getv(rhvalid, "err")}` });
      }
      if (!done) return;

      let fee =
        race.version == "cv2"
          ? parseFloat(getv(_.values(race.fee_fixed), "0"))
          : parseFloat(race.fee);
      let is_free = race.class == 90 && fee === 0;
      let no_txn_needed = is_free;

      if (no_txn_needed) {
        let resp = await q_open_enter({ rid, hid }).queryFn();
        if (post_enterrace) await post_enterrace({ rid, hid });
      } else {
        await cdelay(2 * 1e3);
        set_resp({ status: "action", msg: "Confirm Transaction" });

        let initparams = aucon.get_contract_init_params();
        // console.log("initparams", initparams);
        let rcon = await RaceStake.get_contract(initparams);

        // console.log("rcon", rcon);
        if (cancelref.current) return;
        let tx = await rcon.u_enterRace(race.rid, hid, token, { wait: false });
        set_resp({ status: "loading", msg: "Entering Core" });

        // console.log("tx", tx);
        if (!nils(tx.hash)) {
          let resp = await q_open_enter({ rid, hid }).queryFn();
          if (post_enterrace) await post_enterrace({ rid, hid });
          await q_que_txn({
            service: "races",
            hash: tx.hash,
            dets: {
              version: "cv2",
              rid: race.rid,
              hid: hid,
              token: token,
            },
          }).queryFn();
          if (qo_rginf) await qo_rginf.refetch();
          tx = await au_wait_for_tx(tx, { aumode });
        }
        console.log("tx", tx);
        await cdelay(1 * 1e3);
      }
      await closepopup();
    } catch (err) {
      console.log("err:enter_action", err, _.keys(err));
      let errmsg = !nils(err.reason) ? err.reason : err.message;
      let reg = /^([^\n]*)/;
      let mat = null;
      if ((mat = reg.exec(errmsg.toString()))) {
        errmsg = mat[1];
      }
      errmsg = trim_n(errmsg, 100);
      set_resp({ status: "error", msg: errmsg });
    }
  };

  const retry = async () => {
    set_resp({});
    cancelref.current = null;
    await cdelay(1 * 1e3);
    enter_action();
  };

  useEffect(() => {
    if (enabled) enter_action();
  }, [enabled]);

  return (
    <ErrorBoundary>
      <div class="h-full fc-cc w-full text-center">
        <div class="font-digi resp-text-0">
          {nils(resp) ? null : resp.status == "special" ? (
            <>{resp.jsx}</>
          ) : (
            <p
              class={twMerge(
                "my-2 font-digi",
                resp.status == "loading"
                  ? "text-acc4"
                  : resp.status == "error"
                    ? "text-red-400"
                    : resp.status == "success"
                      ? "text-green-400"
                      : resp.status == "info"
                        ? "text-blue-400"
                        : resp.status == "action"
                          ? "text-orange-400"
                          : "text-white",
              )}
            >
              {getv(resp, "msg")}
            </p>
          )}
        </div>
        <div class="w-[10rem] aspect-[1/1] mx-auto ">
          {resp.status == "loading" ? (
            <FontAwesomeIcon
              icon={faSpinner}
              className={twMerge("text-acc4 spin-anim", bigiconcn)}
            />
          ) : resp.status == "error" ? (
            <FontAwesomeIcon
              icon={faTimesCircle}
              className={twMerge("text-red-400", bigiconcn)}
            />
          ) : resp.status == "success" ? (
            <FontAwesomeIcon
              icon={faCheckCircle}
              className={twMerge("text-green-400", bigiconcn)}
            />
          ) : resp.status == "info" ? (
            <FontAwesomeIcon
              icon={faInfoCircle}
              className={twMerge("text-blue-400", bigiconcn)}
            />
          ) : resp.status == "action" ? (
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className={twMerge("text-orange-400", bigiconcn)}
            />
          ) : resp.status == "special" ? (
            <>{resp.icon_jsx}</>
          ) : null}
        </div>

        <div class="fr-cc w-full">
          {!_.isEmpty(resp) && resp.status == "error" && (
            <>
              <Tag
                onClick={() => {
                  retry();
                }}
                className={twMerge("bg-red-500 -skew-x-12")}
              >
                <span className="font-digi resp-text-1">Retry</span>
              </Tag>
            </>
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export const RaceR2_EnterPopup = ({
  race,
  trigger_jsx = null,
  qo_rginf = null,
  qorace = null,
  post_enterrace = null,
}) => {
  const accon = useAccountContext();
  const { bikesob } = accon;

  const [popdata, set_popdata] = useState({ stage: 1 });
  const [popup_open, set_popup_open] = useState(false);
  const stage = getv(popdata, "stage");

  const closepopup = () => {
    set_popup_open(false);
    set_popdata({});
  };

  const select_core = (hid) => {
    console.log("select_core", hid);
    set_popdata((d) => ({ ...d, sel_core: hid, stage: 2 }));
  };

  const prev_allowed = useMemo(() => {
    if (stage == 1) return false;
    if (stage == 3) return false;
    return true;
  }, [stage]);
  const next_allowed = useMemo(() => {
    if (stage == 1) return !nils(popdata?.sel_core);
    if (stage == 2) return !nils(popdata?.sel_feetoken);
    return false;
  }, [stage, jstr(popdata)]);

  const go_next = () => {
    console.log("go_next", { next_allowed });
    if (next_allowed == false) return;
    let ns = stage + 1;
    // set_stage((s) => s + 1);
    set_popdata((d) => ({ ...d, stage: ns }));
  };
  const go_prev = () => {
    let ns = Math.max(1, stage - 1);
    // set_stage((s) => Math.max(1, s - 1));
    set_popdata((d) => ({ ...d, stage: ns }));
  };

  const glob = {
    popdata,
    set_popdata,

    closepopup,

    go_next,
    go_prev,

    qorace,
    qo_rginf,

    post_enterrace,
  };

  const bread_cn =
    "fr-sc resp-p-1 resp-gap-1 font-digi text-white bg-acc4/40 -skew-x-12 rounded-md resp-text-0";
  const bred_break = (
    <FontAwesomeIcon icon={faArrowRightLong} className="text-white" />
  );

  const reset_fn = () => {
    set_popdata({ stage: 1 });
  };

  const selhid = useMemo(() => {
    return getv(popdata, "sel_core");
  }, [jstr(popdata)]);
  const selh = useMemo(() => {
    return getv(bikesob, selhid, {});
  }, [selhid, jstr(bikesob?.[selhid])]);

  return (
    <>
      <div
        onClick={(e) => {
          e.preventDefault();
          set_popup_open(true);
          set_popdata({ stage: 1 });
        }}
        class="cursor-pointer "
      >
        {trigger_jsx}
      </div>

      <PopUp
        wrapcn={"top-[6rem]"}
        innercn={"translate-y-[0%]"}
        openstate={popup_open}
        overlayclose={false}
        custom_z="z-[2000]"
      >
        <Card
          className={twMerge(
            "bike-enter-selection-card",
            "relative w-[60rem] max-w-[90vw] bg-r2lig/10 border border-acc4",
          )}
        >
          <PopupCloseBtn {...{ closepopup }} />

          <div class="fr-sc resp-gap-2 resp-p-1 flex-nowrap">
            <div class="fr-sc resp-gap-2 flex-wrap">
              {stage >= 1 && !nils(popdata?.sel_core) && (
                <div class={twMerge(bread_cn)}>
                  <div class="xs:w-[1rem] xs:h-[1rem] lg:w-[2rem] lg:h-[2rem]">
                    <RVImg rvmode={race?.rvmode} hex_code={`FFFFFF`} />
                  </div>
                  <span>{`#${selhid}-${trim_n(selh?.name, 10)}`}</span>
                </div>
              )}
              {stage >= 2 && !nils(popdata?.sel_feetoken) && (
                <>
                  {bred_break}
                  <div class={twMerge(bread_cn)}>
                    <TokenIcon
                      token={getv(popdata, "sel_feetoken")}
                      size="xs"
                    />
                    <span>{`${getv(popdata, "sel_feetoken")}`}</span>
                  </div>
                </>
              )}
            </div>
            <div class="flex-1"></div>
            <Tag
              onClick={() => {
                reset_fn();
              }}
              className="fr-sc resp-gap-1 text-acc4 border border-acc4"
            >
              <FontAwesomeIcon icon={faUndoAlt} />
              <span className="resp-text--1">Reset</span>
            </Tag>
          </div>

          {/*           <div class="p-2 w-full  fr-sc">
            <p className="resp-text-0">{jstr({ stage, next_allowed })}</p>
            <p className="resp-text-0">{jstr(popdata)}</p>
          </div> */}

          <div class="p-2 w-full xs:h-[l0vh] lg:h-[40vh] fc-sc">
            {stage == 1 && (
              <ErrorBoundary>
                <RaceR2_EnterPopup_SelectCore
                  {...{ race, select_core, ...glob }}
                />
              </ErrorBoundary>
            )}
            {stage == 2 && (
              <>
                <ErrorBoundary>
                  <RaceR2_EnterPopup_SelectFeeToken {...{ race, ...glob }} />
                </ErrorBoundary>
              </>
            )}
            {stage == 3 && (
              <>
                <ErrorBoundary>
                  <RaceR2_EnterPopup_EnterCore {...{ race, ...glob }} />
                </ErrorBoundary>
              </>
            )}
          </div>

          <div className="fr-sc">
            <Tag
              onClick={() => {
                if (!prev_allowed) return;
                go_prev();
              }}
              className={twMerge(
                "border border-acc4 bg-acc4/10 backdrop-blur-md text-acc4 fr-sc resp-gap-2 ",
                prev_allowed ? "" : "opacity-20",
              )}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span class="font-digi resp-text-0">{"Back"}</span>
            </Tag>
            <div class="flex-1"></div>
            <Tag
              onClick={() => {
                if (!next_allowed) return;
                go_next();
              }}
              className={twMerge(
                "border border-acc4 bg-acc4/10 backdrop-blur-md text-acc4 fr-sc resp-gap-2 ",
                next_allowed ? "" : "opacity-20",
              )}
            >
              <span class="font-digi resp-text-0">{"Next"}</span>
              <FontAwesomeIcon icon={faChevronRight} />
            </Tag>
          </div>
        </Card>
      </PopUp>
    </>
  );
};

export const RaceR2_HS_Section = ({ r, hids, hconfirmed }) => {
  const accon = useAccountContext();
  const { hids: vhids = [] } = accon;
  const { rid, cb, class: c, payout } = r;
  const active = true;

  const rvmode = r.rvmode;
  const qshs = useStepQuery({
    q_: r.is_arcade ? q_arcade_race_hstats : q_hstats,
    par_ar: (hids || []).map((hid) => [{ rid, hid, cb, class: c, rvmode }]),
    lim: 3,
    options: { enabled: active },
  });
  const hs_loading = _.some(qshs.qs, "isLoading");

  const aucon = useAuthContext();
  const { auth } = aucon;
  const { vault, balance, dezbalance } = useAccountContext();

  const [st_type, set_st_type] = useState("cb");
  const [st_paid, set_st_paid] = useState("paid");

  const hs = useMemo(() => {
    // console.log("hsstats:memo", hids, qshs);
    let hs = [];
    let brep = r?.botinfo_replacement;
    for (let q of qshs.qs) {
      if (!qissuccesss(q)) continue;
      let h = getv(q, "data.result");
      let hid = h?.hid;
      let hrep = getv(brep, hid);
      if (!_.isEmpty(hrep)) {
        h = {
          ...h,
          ...hrep,
          replaced: true,
          type: "AG",
          vault_name: getv(r, `vaults_names.${hrep.vault}`),
        };
        // console.log("hrep", h);
      }
      hs.push(h);
    }
    // console.log(r.rid, "hstats", hs);
    return hs;
  }, [jstr(_.map(qshs.qs, "dataUpdatedAt")), jstr(r?.botinfo_replacement)]);
  const vault_bike_bgcn = "bg-r2lig/30 shadow-md shadow-acc4 border-r2lig";
  const bike_over_cmap = useMemo(() => {
    let o = {};
    let teams_map = {};
    if (r.payout == "tag" && !_.isEmpty(r.teams_ar)) {
      for (let [teamk, [h1, h2]] of r.teams_ar) {
        let cn = `bg-${teamk}-500/40`;
        let inf = {
          cn,
          top_inf_jsx: (
            <span className={`italic resp-text--1`}>
              {`Team ${_.capitalize(teamk)}`}
            </span>
          ),
        };
        teams_map[h1] = inf;
        teams_map[h2] = inf;
      }
    }

    for (let hid of r.hids) {
      let is_vhid = vhids.includes(hid);
      if (teams_map[hid]) {
        o[hid] = teams_map[hid];
      } else if (is_vhid) {
        o[hid] = { cn: vault_bike_bgcn };
      } else {
        o[hid] = { cn: "" };
      }
    }
    return o;
  }, [jstr(r), jstr(vhids)]);

  if (_.isEmpty(hs)) return null;
  return (
    <div class="p-4 my-1 bg-r2dark/10 backdrop-blur-md rounded-md">
      <div class="fr-sc resp-gap-2 resp-text-1">
        {hs_loading && <Loader01c size="s" />}
        <div class="flex-1"></div>
        <Tag
          className={
            "fr-sc resp-gap-1 text-acc0  underline-acc0 underline-offset-2"
          }
          onClick={() => set_st_type((a) => (a !== "cb" ? "cb" : "ov"))}
        >
          <FontAwesomeIcon icon={faArrowsUpDown} />
          <span>
            {st_type == "ov" ? "Overall" : st_type == "cb" ? `CB${cb}00` : ""}
          </span>
        </Tag>

        <Tag
          className={
            "fr-sc resp-gap-1 text-acc0 underline-acc0 underline-offset-2"
          }
          onClick={() => set_st_paid((a) => (a !== "paid" ? "paid" : "all"))}
        >
          <FontAwesomeIcon icon={faArrowsUpDown} />
          <span>{st_paid == "paid" ? "Paid" : "All"}</span>
        </Tag>
      </div>
      {hs.map((h) => {
        h.entry_confirmed = getv(hconfirmed, h.hid) === true;
        let hid = h.hid;
        let ch = bike_over_cmap[hid] || {};
        return (
          <RaceR2_HRow
            {...{
              r,
              h,
              hid: h?.hid,
              st_type,
              st_paid,
              top_inf_jsx: ch.top_inf_jsx,
              cont_props: {
                className: twMerge(ch.cn),
              },
            }}
          />
        );
      })}
    </div>
  );
};

export const PrizePoolInfo = ({ r }) => {
  const appcon = useAppContext();
  const { psearch, tok_to_usd_val } = appcon;
  const { rtstatus, paytoken } = r;

  const infocn = "ov-prizebreakdown";
  const pcardcn = `${infocn} bg-r2dark/60 backdrop-blur-md`;

  const [force_hover, set_force_hover] = useState(null);
  useEffect(() => {
    // console.log("psearch.showprizepool", psearch.showprizepool);
    let a = psearch.showprizepool;
    if (nils(a)) {
      return set_force_hover(null);
    }
    if (a == "true") set_force_hover(true);
  }, [psearch.showprizepool]);
  // useEffect(() => { console.log("force_hover", force_hover); }, [force_hover]);

  return (
    <div className="ov-prize">
      {r.version == 4 && r.toburn == true && r.prize == "burn_credits" ? (
        <>
          <div className="fr-cc resp-gap-2 resp-text--2">
            <Dropdown
              force_hover={force_hover}
              wrapcn=""
              trigger={
                <div className=" text-slate-200 resp-text--2 font-digi fr-cc resp-gap-2">
                  <span>Prize Pool (Burn Credits)</span>
                </div>
              }
              dropcn={"w-max top-[2rem] right-0"}
            >
              <Card className={twMerge(pcardcn, " w-full")}>
                <p className="text-yellow-300 resp-text--1">
                  Prizepool is sum total all burn credits of losing bikes
                </p>
                <p className="text-yellow-300 resp-text--1">
                  to be calculated in the end of the race
                </p>
              </Card>
            </Dropdown>
          </div>
        </>
      ) : (
        <>
          {(r.format == "spin_n_go" ||
            ["spin_n_go", "pityspin", "lowspin"].includes(r.payout)) && (
            <>
              <Dropdown
                force_hover={force_hover}
                wrapcn=""
                trigger={
                  <div className=" text-slate-200 resp-text--2 font-digi fr-cc resp-gap-2">
                    <span>Prize Pool</span>
                  </div>
                }
                dropcn={"w-max top-[2rem] right-0"}
              >
                <Card className={twMerge(pcardcn, " w-full")}>
                  {(rtstatus == "open" && (
                    <>
                      <p className="text-yellow-300 resp-text--1">
                        Prize Pool is revealed when the race is scheduled
                      </p>
                    </>
                  )) || (
                    <div className="grid grid-cols-3 resp-gap-2 resp-text--2">
                      {_.entries(r.prize_map).map((e) => {
                        return (
                          <>
                            <PosTag pos={e[0]} className="m-0 resp-py-1" />
                            <div className="flex flex-row justify-end items-center text-white resp-gap-1">
                              <TokenIcon token={r.paytoken} size="xs" />
                              <span className="">
                                {dec(e[1], tokdecn(paytoken))}
                              </span>
                            </div>
                            <div className="flex flex-row justify-end items-center text-white resp-gap-1">
                              <FontAwesomeIcon className="" icon={faUsd} />
                              <span className="">
                                {dec(
                                  tok_to_usd_val(e[1], paytoken),
                                  tokdecn("USD"),
                                )}
                              </span>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  )}
                </Card>
              </Dropdown>
            </>
          )}

          {r.format == "sit_n_go" && (
            <>
              <Dropdown
                force_hover={force_hover}
                wrapcn=""
                trigger={
                  <div className=" text-slate-200 resp-text--2 font-digi fr-cc resp-gap-2">
                    <span>Prize Pool</span>
                    <span>
                      <FontAwesomeIcon icon={faUsd} />
                    </span>
                    <span>
                      {dec(tok_to_usd_val(r.prize, r.paytoken), tokdecn("USD"))}
                    </span>
                    <span>**</span>
                  </div>
                }
                dropcn={"w-max top-[2rem] right-0"}
              >
                <Card className={twMerge(pcardcn, " w-full max-w-[20rem]")}>
                  <p className="text-center text-yellow-500 resp-my-2">
                    {getv(r, "format_inf.prizepool_msg")}
                  </p>
                  <div className="grid grid-cols-3 resp-gap-2">
                    {_.entries(r.prize_map).map((e) => {
                      return (
                        <>
                          <PosTag
                            pos={e[0]}
                            className="resp-text--2 m-0 resp-py-1"
                          />
                          <div className="flex flex-row justify-end items-center text-white resp-gap-1">
                            <TokenIcon token={r.paytoken} size="xs" />
                            <span className="">
                              {dec(e[1], tokdecn(paytoken))}
                            </span>
                          </div>
                          <div className="flex flex-row justify-end items-center text-white resp-gap-1">
                            <FontAwesomeIcon icon={faUsd} />
                            <span className="">
                              {dec(tok_to_usd_val(e[1]), tokdecn("USD"))}
                            </span>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </Card>
              </Dropdown>
            </>
          )}

          {(r.format == "quest" || r.format == "sub_quest") && (
            <div className="fr-sc text-acc4 resp-text-0 resp-gap-1">
              <span>{"Quest Prize"}</span>
              <span>
                <FontAwesomeIcon className="resp-text-1" icon={faUsd} />
              </span>
              <span className="resp-text-1 font-digi">
                {dec(tok_to_usd_val(r.prize, r.paytoken), tokdecn("USD"))}
              </span>
            </div>
          )}

          {r.format == "bounty" && r.prize > 0 && (
            <>
              <div className="fr-sc text-acc4 resp-text-0 resp-gap-1">
                <span>{"Bounty Prize Pool "}</span>
                <span>
                  <FontAwesomeIcon className="resp-text-1" icon={faUsd} />
                </span>
                <span className="resp-text-1 font-digi">
                  {dec(tok_to_usd_val(r.prize, r.paytoken), tokdecn("USD"))}
                </span>
              </div>
            </>
          )}

          {["rounds", "roundsp"].includes(r.format) && r.prize > 0 && (
            <>
              <Dropdown
                force_hover={force_hover}
                wrapcn=""
                trigger={
                  <div className="fc-ss">
                    <div className=" text-slate-200 resp-text--2 font-digi fr-cc resp-gap-2">
                      <span>Prize Pool</span>
                      <span>
                        <FontAwesomeIcon icon={faUsd} />
                      </span>
                      <span>
                        {dec(
                          tok_to_usd_val(r.prize, r.paytoken),
                          tokdecn("USD"),
                        )}
                      </span>
                      <span>**</span>
                    </div>
                  </div>
                }
                dropcn={"w-max top-[2rem] right-0"}
              >
                <Card className={twMerge(pcardcn, " w-full max-w-[20rem]")}>
                  <p className="text-center text-yellow-500 resp-my-2">
                    {getv(r, "format_inf.prizepool_msg")}
                  </p>

                  {r.class !== 72 && (
                    <div className=" text-green-300 resp-text--2 font-digi fr-cc resp-gap-2">
                      <span>Guranteed[30%]</span>
                      <span>
                        <FontAwesomeIcon icon={faUsd} />
                      </span>
                      <span>
                        {dec(
                          tok_to_usd_val(
                            getv(r, "r_form.thresh_prize"),
                            r.paytoken,
                          ),
                          tokdecn("USD"),
                        )}
                      </span>
                      <span>**</span>
                    </div>
                  )}
                  <div className="grid grid-cols-3 resp-gap-2">
                    {_.entries(r.prize_map).map((e) => {
                      return (
                        <>
                          <PosTag
                            pos={e[0]}
                            className="resp-text--text-slate-200 m-0 resp-py-1"
                          />
                          <div className="flex flex-row justify-end items-center text-white resp-gap-1">
                            <TokenIcon token={r.paytoken} size="xs" />
                            <span>{dec(e[1], tokdecn(paytoken))}</span>
                          </div>
                          <div className="flex flex-row justify-end items-center text-white resp-gap-1">
                            <FontAwesomeIcon icon={faUsd} />
                            <span>
                              {dec(
                                tok_to_usd_val(e[1], r.paytoken),
                                tokdecn("USD"),
                              )}
                            </span>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </Card>
              </Dropdown>
            </>
          )}

          {(r.format == "normal" ||
            r.format == "reduced" ||
            r.format == "sub_bounty" ||
            r.format == "sub_roundsp" ||
            (r.format == "teams" &&
              !["spin_n_go", "pityspin", "lowspin"].includes(r.payout))) && (
            <>
              {nils(r.prize) || r.prize === 0 ? (
                <></>
              ) : (
                <Dropdown
                  force_hover={force_hover}
                  wrapcn=""
                  trigger={
                    <div className=" text-slate-200 resp-text--2 font-digi fr-cc resp-gap-2">
                      <span>Prize Pool</span>
                      <span>
                        <FontAwesomeIcon icon={faUsd} />
                      </span>
                      <span>
                        {dec(
                          tok_to_usd_val(r.prize, r.paytoken),
                          tokdecn("USD"),
                        )}
                      </span>
                    </div>
                  }
                  dropcn={"w-max top-[2rem] right-0"}
                >
                  <Card className={twMerge(pcardcn, " w-full")}>
                    <div className="grid grid-cols-3 resp-gap-2 resp-text--2">
                      {_.entries(r.prize_map).map((e) => {
                        return (
                          <>
                            <PosTag
                              pos={e[0]}
                              className="resp-text--2 m-0 resp-py-1"
                            />
                            <div className="flex flex-row justify-end items-center text-white resp-gap-1">
                              <TokenIcon token={r.paytoken} size="xs" />
                              <span>{dec(e[1], tokdecn(paytoken))}</span>
                            </div>
                            <div className="flex flex-row justify-end items-center text-white resp-gap-1">
                              <FontAwesomeIcon icon={faUsd} />
                              <span>
                                {dec(
                                  tok_to_usd_val(e[1], r.paytoken),
                                  tokdecn("USD"),
                                )}
                              </span>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </Card>
                </Dropdown>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export const RaceCard_cv2_full = ({ race: r, post_enterrace }) => {
  const appcon = useAppContext();
  const { now } = useNowContext();
  const { tok_to_usd_val } = appcon;
  const { paytoken } = r;
  const { auth } = useAuthContext();
  const { vault, balance, dezbalance } = useAccountContext();

  const feeusd = r.feeusd;

  const [rtstatus, rem_st, rem_ed] = useMemo(() => {
    let e = get_race_rtstatus(r, now);
    return e;
  }, [now, jstr(r)]);

  const isburn = r.toburn == true || (r.eventtags || []).includes("burn");

  const r_feesection = (
    <ErrorBoundary>
      <div class="ov-fee">
        {feeusd == 0 && !isburn ? (
          <Tag className="bg-yellow-500/60 w-max -skew-x-12 text-white">
            <span class="resp-text--1">FREE</span>
          </Tag>
        ) : (
          <div class="fr-sc resp-gap-1">
            {(r.feetokens || []).map((token) => {
              return <TokenIcon {...{ key: token, token, size: "xs" }} />;
            })}
            <span>Fee: </span>

            {feeusd > 0 && (
              <div class="fr-sc resp-text-1 resp-gap-1">
                <FontAwesomeIcon icon={faUsd} />
                <span className="font-digi">{dec(feeusd, 2)}</span>
              </div>
            )}

            {isburn && (
              <>
                {feeusd > 0 && <span>+</span>}
                <div class="fr-sc resp-text-1 resp-gap-1 text-orange-500">
                  <FontAwesomeIcon icon={faFire} />
                  <span className="font-digi">CORE</span>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <PrizePoolInfo r={r} />
    </ErrorBoundary>
  );

  const r_leftsidebar = (
    <ErrorBoundary>
      <div
        class={twMerge(
          "font-digi",
          `bg-c${r.class} bg-opacity-10`,
          "fc-ss resp-p-4 w-[8rem]",
        )}
      >
        <span className={cb_cn(r.cb)}>{cb_txt(r.cb, r.rvmode)}</span>
        <span className={twMerge(`text-c${r.class}`)}>
          {class_text(r.class)}
        </span>
      </div>
    </ErrorBoundary>
  );

  const r_format = (
    <div class=" relative fr-sc resp-p-2 resp-gap-2 dark-round-btn-basic-bg rounded-full m-1">
      {r.format == "normal" ? (
        <Tag className={twMerge("ov-format text-acc0 uppercase")}>
          {r.format}
        </Tag>
      ) : r.format == "spin_n_go" ? null : [
          "rounds",
          "roundsp",
          "bounty",
        ].includes(r.format) ? (
        <RoundsInfo race={r} />
      ) : null}
      {["rounds", "bounty", "roundsp"].includes(r.payout) ? null : r.payout ==
        "spin_n_go" ? (
        <Tag className={twMerge("ov-payout text-red-400")}>
          {get_payout_txt(r.payout)}
        </Tag>
      ) : (
        <Tag className={twMerge("ov-payout text-white")}>
          {get_payout_txt(r.payout)}
        </Tag>
      )}
    </div>
  );
  const r_events = (
    <div class="ov-events">
      <ErrorBoundary>
        {!_.isEmpty(r.eventtags) && (
          <div class="fr-sc resp-p-2 dark-round-btn-basic-bg rounded-full m-1">
            {r.eventtags.map((e) => {
              return (
                <EventTag
                  tag={e}
                  efil={getv(r, "r_form.entry_filt")}
                  race={r}
                />
              );
            })}
          </div>
        )}
        {!nils(r.giveeacards) && (
          <div class="fr-sc resp-gap-2">
            {r.giveeacardstype == "questcard" ? (
              <QuestCardImg
                {...{
                  k: "brightback",
                  cn: "xs:w-[2rem] lg:w-[4rem]",
                  auto_flip: true,
                }}
              />
            ) : r.giveeacardstype == "trailscard" ? (
              <TrailsCardImg
                img="back.png"
                className="xs:h-[2rem] lg:h-[4rem]"
              />
            ) : null}
            <span className="resp-text-0 font-digi">x{r.giveeacards}</span>
          </div>
        )}
      </ErrorBoundary>
    </div>
  );

  const rupddiff = useMemo(() => {
    if (nils(r.fetch_time)) return 20;
    let diff = nano(now) - nano(r.fetch_time);
    return parseInt(diff / 1e3);
  }, [jstr(r), now]);

  const [qo_rginf] = useQueries([
    r.is_arcade
      ? q_arcade_open_hs_in({ rid: r.rid })
      : q_open_hs_in(
          { rid: r.rid },
          {
            enabled: r.status == "open" && rupddiff > 20,
            staleTime: 10 * 1e3,
            refetchInterval: 10 * 1e3,
          },
        ),
  ]);
  // useEffect(() => {
  //   console.log("qo_rginf", qo_rginf.data, { rupddiff });
  // }, [qo_rginf.dataUpdatedAt, rupddiff]);

  const hsin = getv(qo_rginf, "data.result.hs_in") ?? r.hs_in;
  const hids = getv(qo_rginf, "data.result.hids") ?? r.hids;
  const hconfirmed =
    r.is_chain !== true
      ? {}
      : _.isEmpty(qo_rginf.data)
        ? getv(r, "entry_txns_confirmed")
        : _.chain(getv(qo_rginf, "data.result.hs"))
            .keyBy("hid")
            .mapValues("confirmed")
            .value();

  const r_gates = (
    <ErrorBoundary>
      {r.status == "open" ? (
        <>
          <div className="flex flex-col jusitfy-center items-end ov-gates">
            <div class="fr-sc resp-gap-1 resp-text-1">
              <span>{hsin}</span>
              <span>/</span>
              <span className="font-bold resp-text-2">{r.rgate}</span>
              <div class="xs:w-[2rem] lg:w-[4rem] ov-rvmode">
                <RVImg rvmode={r.rvmode} hex_code={`FFFFFF`} />
              </div>
            </div>
            <div class="fr-sc resp-gap-2 text-white">
              <div class="relative">
                <div class="xs:w-[4rem] lg:w-[8rem] bg-dark rounded-full xs:h-[0.4rem] lg:h-[0.8rem]"></div>

                <div
                  style={{
                    width: `${(hsin / r.rgate) * 100}%`,
                  }}
                  class={twMerge(
                    "absolute  bg-white rounded-full ",
                    "xs:h-[0.2rem] lg:h-[0.4rem]",
                    "xs:top-[0.1rem] xs:left-[0.1rem]",
                    "lg:top-[0.2rem] lg:left-[0.2rem]",
                  )}
                ></div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div class="fc-se resp-gap-2 text-white ">
          <div class="fr-sc resp-text-1 font-digi resp-gap-1 ov-gates">
            <div class="xs:w-[3rem] lg:w-[5rem] ov-rvmode">
              <RVImg rvmode={r.rvmode} hex_code={`FFFFFF`} />
            </div>
            <span>{r.rgate} Gates</span>
          </div>
          <div class="ov-status">
            {rtstatus == "scheduled" ? (
              <p class="text-yellow-300 ov-starttime">
                Starting in {from_time_mini(r.start_time)}
              </p>
            ) : rtstatus == "finished" ? (
              <p class="text-green-300 ov-starttime">
                Started at {iso_format(r.start_time, "DD,MMM hh:mm")}
              </p>
            ) : rtstatus == "live" ? (
              <Tag className="bg-yellow-400 text-black">LIVE</Tag>
            ) : rtstatus == "cancelled" ? (
              <p class="text-red-300"> Cancelled</p>
            ) : null}
          </div>
        </div>
      )}
    </ErrorBoundary>
  );

  const show_enter_race = useMemo(() => {
    if (r.admin_entry === true) return false;
    if (r.status !== "open") return false;
    if (hids.length >= r.rgate) return false;
    if (!auth) return "please login to enter race";
    // console.log(r.paytoken, r.fee, balance, dezbalance);
    let bal = r.paytoken == "DEZ" ? dezbalance : balance;
    if (!nils(bal) && bal < r.fee) return `insufficient balance to enter`;
    return true;
  }, [r.status, hids.length, auth, balance]);

  const [watching, set_watching] = useState(false);
  const [showresults, set_showresults] = useState(false);
  useEffect(() => {
    if (rtstatus == "live") {
      set_watching(true);
    }
  }, [rtstatus, rem_st]);

  const r_innersection = (
    <ErrorBoundary>
      {watching ? (
        <RaceR2_Watching2D_Section
          {...{
            race: r,
            hids,
            watching,
            set_watching,
          }}
        />
      ) : nils(rtstatus) ? null : rtstatus == "open" ||
        rtstatus == "scheduled" ? (
        <>
          <RaceR2_HS_Section {...{ r, hids, hconfirmed }} />
          {r.format == "quest" ? (
            <>
              <div class="fc-cc resp-gap-2 font-digi text-acc0 resp-text-1 my-2">
                <p class="text-center">Trying to confirm your entry</p>
                <div class="fr-sc resp-gap-2 resp-text-2">
                  <Loader01c size="s" />
                  <p>Quest Starts soon</p>
                  <Loader01c size="s" />
                </div>
              </div>
            </>
          ) : null}
        </>
      ) : rtstatus == "cancelled" ? (
        <div class="fc-cc resp-gap-1 text-red-300 resp-text-0">
          <p className="text-center ">This race has been cancelled</p>

          {!nils(getv(r, "cancellation_msg")) && (
            <p className="text-center ">{getv(r, "cancellation_msg")}</p>
          )}
        </div>
      ) : rtstatus == "finished" ? (
        <RaceR2_FinishedSection
          {...{ race: r, showresults, set_showresults }}
        />
      ) : null}
    </ErrorBoundary>
  );

  const is_bulk_valid = useMemo(() => {
    if (!r) return false;
    if (!_.isEmpty(r.eventtags) && r.eventtags.includes("Trail Points"))
      return false;

    return (
      [50, 60, 70, 80].includes(r.class) && [2, 3, "cv2"].includes(r.version)
    );
  }, [jstr(r)]);

  const r_bottomaction_row = (
    <ErrorBoundary>
      <div class="xs:hidden lg:block resp-p-2">
        <div class="fr-sc resp-gap-4">
          {watching ? (
            <RaceBottomActionBtn
              text="Exit 2D"
              onClick={() => {
                set_watching(false);
              }}
            />
          ) : (
            <RaceBottomActionBtn
              text={
                <>
                  <div class="fr-sc resp-gap-0">
                    <div class="w-[2rem] aspect-square">
                      <img src={encodeURI("/r2_design/play_btn2.png")} alt="" />
                    </div>
                    <span>2D</span>
                  </div>
                </>
              }
              onClick={() => {
                set_watching(true);
              }}
            />
          )}
          <RaceBottomActionBtn
            onClick={() => {
              window.open(get_race3d_link(r));
            }}
            text={
              <>
                <div class="fr-sc resp-gap-0">
                  <div class="w-[2rem] aspect-square">
                    <img src={encodeURI("/r2_design/play_btn3.png")} alt="" />
                  </div>
                  <span>3D</span>
                </div>
              </>
            }
          />
          <div class="flex-1"></div>

          {r.status == "open" && show_enter_race && (
            <>
              {is_bulk_valid && (
                <Link
                  target="_self"
                  to={`/races-auto-filler?tab=enter&bulkid=${r.bulkid}`}
                >
                  <RaceBottomActionBtn text="Bulk Enter" />
                </Link>
              )}
              <RaceR2_EnterPopup
                {...{
                  race: r,
                  trigger_jsx: (
                    <RaceBottomActionBtn text="Enter Race" alltime={true} />
                  ),
                  qo_rginf: qo_rginf,
                  post_enterrace,
                }}
              />
            </>
          )}
        </div>
      </div>

      <div class="xs:block lg:hidden resp-p-2">
        <div class="fr-sc font-digi">
          {watching ? (
            <RaceBottomActionBtnSm
              className="bg-dark hover:bg-red-500/60"
              onClick={() => {
                set_watching(false);
              }}
            >
              {"Exit 2D"}
            </RaceBottomActionBtnSm>
          ) : (
            <RaceBottomActionBtnSm
              className="bg-dark hover:bg-acc0/60"
              onClick={() => {
                set_watching(true);
              }}
            >
              {"View 2D"}
            </RaceBottomActionBtnSm>
          )}

          <RaceBottomActionBtnSm
            className="bg-dark hover:bg-acc0/60"
            onClick={() => {
              window.open(get_race3d_link(r));
            }}
          >
            {"View 3D"}
          </RaceBottomActionBtnSm>

          <div class="flex-1"></div>

          {r.status == "open" && show_enter_race && (
            <>
              {is_bulk_valid && (
                <Link
                  target="_self"
                  to={`/races-auto-filler?tab=enter&bulkid=${r.bulkid}`}
                >
                  <RaceBottomActionBtnSm className="bg-dark hover:bg-acc0/60">
                    {"Bulk"}
                  </RaceBottomActionBtnSm>
                </Link>
              )}
              <RaceR2_EnterPopup
                {...{
                  race: r,
                  trigger_jsx: (
                    <RaceBottomActionBtnSm className="bg-dark hover:bg-acc0/60">
                      {"Enter Race"}
                    </RaceBottomActionBtnSm>
                  ),
                  qo_rginf: qo_rginf,
                  post_enterrace,
                }}
              />
            </>
          )}
        </div>
      </div>
    </ErrorBoundary>
  );

  const { hids: vhids = [], bikesob = {} } = useAccountContext();
  const inr_vhids = useMemo(() => {
    let hids = getv(r, "hids") || [];
    return _.intersection(hids, vhids);
  }, [jstr(vhids), jstr(r)]);
  const r_inr_vhids_list = _.isEmpty(inr_vhids) ? null : (
    <ErrorBoundary>
      <div class="resp-p-2 my-2 grid xs:grid-cols-3 lg:grid-cols-5 resp-gap-1">
        {inr_vhids.map((hid) => {
          let h = getv(bikesob, hid);
          if (!h) return null;
          return (
            <div class="fr-sc resp-gap-2 resp-text--1">
              <BikeSCircle color={h.color} />
              <span>{h.name}</span>
            </div>
          );
        })}
      </div>
    </ErrorBoundary>
  );

  const copy_jsx = (
    <>
      <Tag
        className="ov-copylink xs:px-1 lg:px-1 xs:mx-0 lg:mx-0"
        onClick={() => copy_clip(`https://fbike.dnaracing.run/race/${r.rid}`)}
      >
        <FontAwesomeIcon icon={faCopy} />
      </Tag>
    </>
  );

  const race = r;

  return (
    <>
      <div
        className={twMerge(
          `large-screen xs:hidden lg:block `,
          `race-card-full race-card-full-${r.rid}`,
        )}
      >
        <div
          className={twMerge(
            "bg-r2lig/20",
            "backdrop-blur-md",
            "rounded-md w-full resp-m-1 resp-my-2",
            "p-4",
            "resp-text--1",
            "border border-acc4",
          )}
        >
          <div class=" flex flex-row justify-start items-stretch resp-gap-2">
            <div class="flex-1">
              <div class="fc-ss resp-gap-1">
                <div class="fr-sc resp-gap-2">
                  <SheetRowChainIndicator {...{ r }} />
                  <span className={`font-digi text-white`}>
                    <span
                      className={twMerge("ov-class")}
                    >{`${class_text(r.class)} - `}</span>
                    <span className={twMerge("ov-cb", cb_cn(r.cb))}>
                      {cb_txt(r.cb, r.rvmode)}
                    </span>
                  </span>
                  <span className="resp-text--1 font-digi">{r.race_name}</span>
                  <Link to={`/race/${r.rid}`} className="ov-redirectlink px-2">
                    <FontAwesomeIcon icon={faLink} />
                  </Link>
                  {copy_jsx}
                </div>
                <div class="fr-sc resp-gap-1">
                  {r_format}
                  {r_events}
                </div>
                {rtstatus == "scheduled" ? (
                  <div className="fr-sc resp-gap-1 resp-text--1 my-1 px-4 text-yellow-400 font-digi">
                    <span>Starts in </span>
                    <span>{from_time_mini(r.start_time)}</span>
                  </div>
                ) : rtstatus == "live" ? (
                  <div className="fr-sc resp-gap-1 resp-text--1 my-1 px-4 text-yellow-400 font-digi">
                    <span>LIVE</span>
                  </div>
                ) : rtstatus == "finished" ? (
                  <div className="fr-sc resp-gap-1 resp-text--1 my-1 px-4 text-green-400 font-digi">
                    <span>Started </span>
                    <span>{iso_format(r.start_time, "DD,MMM hh:mm:ss")}</span>
                  </div>
                ) : null}
              </div>
            </div>
            <div class="fc-se p-4 resp-gap-1">
              {r_gates}
              {r_feesection}
            </div>
          </div>
          {r_inr_vhids_list}
          {r_innersection}

          {showresults && !_.isEmpty(race.reward_cards) && (
            <ErrorBoundary>
              {/* <p className="reps-text-1"> {race.giveeacards}-{race.giveeacardstype} </p> */}
              {race.giveeacardstype == "questcard" ? (
                <RacePage_QuestCard
                  {...{
                    cs: race.reward_cards,
                    racing_vaults: race.racing_vaults,
                    vmap: race.vaults_names,
                  }}
                />
              ) : race.giveeacardstype == "trailscard" ? (
                <RacePage_TrailsCard
                  {...{
                    cs: race.reward_cards,
                    racing_vaults: race.racing_vaults,
                    vmap: race.vaults_names,
                  }}
                />
              ) : null}
            </ErrorBoundary>
          )}
          {r_bottomaction_row}
        </div>
      </div>

      <div class="xs:block lg:hidden">
        <div
          className={twMerge(
            "bg-r2lig/20 backdrop-blur-md border border-acc4 rounded-sm w-full resp-m-1 resp-my-2",
            "",
            "resp-text--1",
            `border-c${r.class}`,
            "resp-p-2",
          )}
        >
          <div class="fr-sc resp-gap-2 resp-p-2 resp-my-2">
            <div class="fr-sc resp-gap-2">
              <span className={`font-digi text-white`}>
                {class_text(r.class)} - {cb_txt(r.cb, r.rvmode)}
              </span>
            </div>
            <div class="flex-1"></div>
            {r_gates}
          </div>
          <div class="block">
            <div class="fr-sc resp-gap-2 resp-px-4 resp-my-1">
              <SplitRaceNameView
                className="resp-text-1"
                {...{ format: r.format, race_name: r.race_name }}
              />
              <Link to={`/race/${r.rid}`} className="px-2">
                <FontAwesomeIcon icon={faLink} />
              </Link>
              {copy_jsx}
              <div class="flex-1"></div>
              <div class="fc-se">{r_feesection}</div>
            </div>
            <div class="fr-sc ">
              {r_format}
              {r_events}
            </div>
          </div>
          {r_inr_vhids_list}
          {r_innersection}
          {r_bottomaction_row}
        </div>
      </div>
    </>
  );
};

export const RaceCard_cv2_mini = ({ race: r, set_selrace, post_enterrace }) => {
  const appcon = useAppContext();
  const { now } = useNowContext();
  const { tok_to_usd_val } = appcon;
  const { paytoken } = r;

  const [rtstatus] = useMemo(() => {
    return get_race_rtstatus(r, now);
  }, [jstr(r)]);

  const feeusd = r.feeusd;

  const location = useLocation();
  const custcbtxtfn =
    location.pathname == "/trainer-leaderboard" ? (cb) => `${cb}00M` : cb_txt;

  const rupddiff = useMemo(() => {
    if (nils(r.fetch_time)) return true;
    let diff = nano(r.fetch_time) - nano(now);
    return parseInt(diff / 1e3);
  }, [jstr(r), now]);

  const [qo_rginf] = useQueries([
    r.is_arcade
      ? q_arcade_open_hs_in({ rid: r.rid })
      : q_open_hs_in(
          { rid: r.rid },
          { enabled: r.status == "open" && rupddiff > 60 },
        ),
  ]);

  const hsin = getv(qo_rginf, "data.result.hs_in") ?? r.hs_in;
  const hids = getv(qo_rginf, "data.result.hids") ?? r.hids;

  const isburn = r.toburn == true || (r.eventtags || []).includes("burn");

  const r_feesection = (
    <>
      <div class="fr-sc resp-gap-4">
        <div class="ov-fee">
          {feeusd == 0 && !isburn ? (
            <Tag className="bg-yellow-500/60 w-max -skew-x-12 text-white">
              <span class="resp-text--1">FREE</span>
            </Tag>
          ) : (
            <div class="fr-sc resp-gap-1">
              {r.feetokens.map((token) => {
                return <TokenIcon {...{ key: token, token, size: "xs" }} />;
              })}
              <span>Fee: </span>

              {feeusd > 0 && (
                <div class="fr-sc resp-text-1 resp-gap-1">
                  <FontAwesomeIcon icon={faUsd} />
                  <span className="font-digi">{dec(feeusd, 2)}</span>
                </div>
              )}

              {isburn && (
                <>
                  {feeusd > 0 && <span>+</span>}
                  <div class="fr-sc resp-text-1 resp-gap-1 text-orange-500">
                    <FontAwesomeIcon icon={faFire} />
                    <span className="font-digi">CORE</span>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <PrizePoolInfo r={r} />
      </div>
    </>
  );

  const r_leftsidebar = (
    <>
      <div
        class={twMerge("font-digi", `bg-r2dark/60`, "fc-ss resp-p-4 w-[10rem]")}
      >
        <div class="fr-sc w-full">
          <SheetRowChainIndicator {...{ r }} />
          <div class="flex-1"></div>
          <RVImg
            {...{
              rvmode: r.rvmode,
              hex_code: cb_cn_hex(r.cb),
            }}
          />
        </div>

        <span className={twMerge("resp-text-1", cb_cn(r.cb))}>
          {custcbtxtfn(r.cb, r.rvmode)}
        </span>
        <span className={twMerge(`text-acc4 resp-text-1`)}>
          {class_text(r.class)}
        </span>

        {rtstatus == "open" ? (
          <div
            onClick={(e) => {
              e.preventDefault();
            }}
            class="fr-sc w-full"
          >
            <RaceR2_EnterPopup
              {...{
                race: r,
                trigger_jsx: (
                  <Tag
                    className={twMerge(
                      `resp-text-2 bg-acc0 fr-sc resp-gap-1`,
                      "quick-enter-btn",
                    )}
                  >
                    <span>Enter</span>
                    <FontAwesomeIcon icon={faSignInAlt} />
                  </Tag>
                ),
                qo_rginf: qo_rginf,
                post_enterrace,
              }}
            />
            <div class="flex-1"></div>
          </div>
        ) : rtstatus == "scheduled" ? (
          <div class="fc-ss">
            <span>Starts in </span>
            <span>{from_time_mini(r.start_time)}</span>
          </div>
        ) : rtstatus == "live" ? (
          <div class="fc-ss">
            <Tag className="bg-yellow-400 text-black">LIVE</Tag>
          </div>
        ) : rtstatus == "finished" ? (
          <div class="fc-ss">
            <span>Starts </span>
            <span>{iso_format(r.start_time, "DD,MMM hh:mm")}</span>
          </div>
        ) : null}
      </div>
    </>
  );

  const is_bulk_valid = useMemo(() => {
    if (!r) return false;
    if (!_.isEmpty(r.eventtags) && r.eventtags.includes("Trail Points"))
      return false;

    return [50, 60, 70, 80].includes(r.class) && [2, 3].includes(r.version);
  }, [jstr(r)]);

  const r_sm_bottomrow = (
    <div
      onClick={(e) => {
        e.preventDefault();
      }}
      class="mb-[0.5rem] fr-cc resp-gap-2 flex-nowrap w-full "
    >
      <RaceR2_EnterPopup
        {...{
          race: r,
          trigger_jsx: (
            <div class="quick-enter-btn">
              <RectBtn_SMLong text="Enter Race" />
            </div>
          ),
          qo_rginf: qo_rginf,
          post_enterrace,
        }}
      />
      {is_bulk_valid && (
        <Link
          target="_self"
          to={`/races-auto-filler?tab=enter&bulkid=${r.bulkid}`}
        >
          <RectBtn_SMLong text="Enter Bulk Race" />
        </Link>
      )}
    </div>
  );

  const r_sm_topbar = (
    <>
      <div class="fr-sc w-max resp-gap-1 font-digi resp-text-2 text-acc4">
        <span className={twMerge(`text-white`)}>{class_text(r.class)}</span>
        <span>-</span>
        <span className={cb_cn(r.cb)}>CB{r.cb}</span>
      </div>
    </>
  );
  const r_format = (
    <div class="relative fr-sc resp-p-2 resp-gap-2 dark-round-btn-basic-bg rounded-full m-1">
      {r.format == "normal" ? (
        <Tag className={twMerge("text-acc0 uppercase")}>{r.format}</Tag>
      ) : r.format == "spin_n_go" ? null : [
          "rounds",
          "roundsp",
          "bounty",
        ].includes(r.format) ? (
        <RoundsInfo race={r} />
      ) : null}
      {["rounds", "bounty", "roundsp"].includes(r.payout) ? null : r.payout ==
        "spin_n_go" ? (
        <Tag className={twMerge("text-red-400")}>
          {get_payout_txt(r.payout)}
        </Tag>
      ) : (
        <Tag className={twMerge("text-white")}>{get_payout_txt(r.payout)}</Tag>
      )}
    </div>
  );
  const r_events = (
    <>
      {!_.isEmpty(r.eventtags) && (
        <div class="fr-sc resp-p-2 dark-round-btn-basic-bg rounded-full m-1">
          {r.eventtags.map((e) => {
            return (
              <EventTag tag={e} efil={getv(r, "r_form.entry_filt")} race={r} />
            );
          })}
        </div>
      )}

      {!nils(r.giveeacards) && (
        <div class="fr-sc resp-gap-2">
          {r.giveeacardstype == "questcard" ? (
            <QuestCardImg
              {...{
                k: "brightback",
                cn: "xs:w-[2rem] lg:w-[4rem]",
                auto_flip: true,
              }}
            />
          ) : r.giveeacardstype == "trailscard" ? (
            <TrailsCardImg img="back.png" className="xs:h-[2rem] lg:h-[4rem]" />
          ) : null}
          <span className="resp-text-0 font-digi">x{r.giveeacards}</span>
        </div>
      )}
    </>
  );

  const r_gates =
    rtstatus == "open" ? (
      <div class="flex flex-col jusitfy-center items-end">
        <div class="fr-sc resp-gap-1 resp-text-1 ov-gates">
          <span>{hsin}</span>
          <span>/</span>
          <span className="font-bold resp-text-2">{r.rgate}</span>
          <div class="xs:w-[2rem] lg:w-[4rem] ov-rvmode">
            <RVImg rvmode={r.rvmode} hex_code={`FFFFFF`} />
          </div>
        </div>
        <div class="fr-sc resp-gap-2 text-white">
          <div class="relative">
            <div class="xs:w-[4rem] lg:w-[8rem] bg-dark rounded-full xs:h-[0.4rem] lg:h-[0.8rem]"></div>

            <div
              style={{
                width: `${(hsin / r.rgate) * 100}%`,
              }}
              class={twMerge(
                "absolute  bg-white rounded-full ",
                "xs:h-[0.2rem] lg:h-[0.4rem]",
                "xs:top-[0.1rem] xs:left-[0.1rem]",
                "lg:top-[0.2rem] lg:left-[0.2rem]",
              )}
            ></div>
          </div>
        </div>
      </div>
    ) : (
      <div class="fc-se">
        <div class="flex flex-col justify-center items-end resp-gap-2 text-white">
          <div class="fr-sc resp-text--1 font-digi resp-gap-1">
            <div class="xs:w-[3rem] lg:w-[5rem]">
              <RVImg rvmode={r.rvmode} hex_code={`FFFFFF`} />
            </div>
            <span>{r.rgate} Gates</span>
          </div>

          {rtstatus == "scheduled" ? (
            <p class="text-yellow-300">
              Starting in {from_time_mini(r.start_time)}
            </p>
          ) : rtstatus == "finished" ? (
            <p class="text-green-300">
              Started at {iso_format(r.start_time, "DD,MMM hh:mm")}
            </p>
          ) : rtstatus == "live" ? (
            <Tag className="bg-yellow-400 text-black">LIVE</Tag>
          ) : rtstatus == "cancelled" ? (
            <p class="text-red-300"> Cancelled</p>
          ) : null}
        </div>
      </div>
    );

  const { hids: vhids = [], bikesob = {} } = useAccountContext();
  const inr_vhids = useMemo(() => {
    let hids = getv(r, "hids") || [];
    return _.intersection(hids, vhids);
  }, [jstr(vhids), jstr(r)]);
  const r_inr_vhids_list = _.isEmpty(inr_vhids) ? null : (
    <>
      <div class="resp-p-2 my-2 grid xs:grid-cols-3 lg:grid-cols-5 resp-gap-1">
        {inr_vhids.map((hid) => {
          let h = getv(bikesob, hid);
          if (!h) return null;
          return (
            <div class="fr-sc resp-gap-2 resp-text--1">
              <BikeSCircle color={h.color} />
              <span>{h.name}</span>
            </div>
          );
        })}
      </div>
    </>
  );

  return (
    <div className="RaceCardR2_mini">
      <div className="large-screen xs:hidden lg:block">
        <div
          className={twMerge(
            "w-full resp-m-1 resp-my-2",
            "resp-text--1",
            "border border-acc4 bg-r2lig/20 rounded-md",
            "border-yellow-400",
          )}
        >
          <div class=" flex flex-row justify-start items-stretch resp-gap-2">
            {r_leftsidebar}
            <div
              onClick={() => {
                set_selrace(r.rid);
              }}
              class="fc-ss p-4 resp-gap-1 flex-1"
            >
              <div class="fr-sc w-full">
                <span className="resp-text-1 flex-1">{r.race_name}</span>
                {r_gates}
              </div>
              <div class="fr-sc flex-nowrap w-full ">
                <div class="fr-sc flex-wrap">
                  {r_format}
                  {r_events}
                </div>
                <div class="flex-1"></div>
                {r_feesection}
              </div>
              {r_inr_vhids_list}
            </div>
          </div>
        </div>
      </div>

      <div className="small-screen xs:block lg:hidden">
        <div
          className={twMerge(
            "bg-r2lig/20 backdrop-blur-md border border-acc4 rounded-sm w-full resp-m-1 resp-my-2",
            "resp-text--2",
            "resp-p-1",
            "border-yellow-400",
          )}
        >
          <div>
            <div
              onClick={() => {
                set_selrace(r.rid);
              }}
              class="fc-ss p-4 resp-gap-1 flex-1"
            >
              <div class="fr-sc resp-gap-2 w-full ">
                {r_sm_topbar}
                <div class="flex-1">
                  <SplitRaceNameView
                    {...{ format: r.format, race_name: r.race_name }}
                  />
                </div>
                {r_gates}
              </div>
              <div class="fr-sc flex-nowrap w-full ">
                <div class="fr-sc flex-wrap">
                  {r_format}
                  {r_events}
                </div>
                <div class="flex-1"></div>
                {r_feesection}
              </div>
            </div>

            {r_inr_vhids_list}
            {r_sm_bottomrow}
          </div>
        </div>
      </div>
    </div>
  );
};
